import { Component } from '@angular/core';

@Component({
  selector: 'app-hosting-vps',
  templateUrl: './hosting-vps.component.html',
  styleUrls: ['./hosting-vps.component.css']
})
export class HostingVpsComponent {
  navClass = 'nav-light';
    // Set Topbar Option
  Menuoption = 'hosting';
  Settingicon = true
  footerVariant = 'footer-ten';
}
