<!-- Hero Start -->
<section
  class="bg-half-170 d-table w-100"
  style="background: url('assets/images/company/aboutus.jpg')"
  id="home"
>
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading title-heading">
          <h2 class="text-white title-dark">About Us</h2>
          <p class="text-white-50 para-desc mb-0 mx-auto">
            Start working with Tenzed that can provide everything you need to
            generate awareness, drive traffic, connect.
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="position-breadcrumb">
      <nav aria-label="breadcrumb" class="d-inline-block">
        <ul
          class="breadcrumb rounded shadow mb-0 px-4 py-2"
          style="
            background: #2b3949 !important;
            border: 1.8px solid white !important;
          "
        >
          <li class="breadcrumb-item">
            <a routerLink="/index" style="color: white">Tenzed</a>
          </li>
          <li class="breadcrumb-item">
            <a href="javascript:void(0)" style="color: white">Company</a>
          </li>
          <li
            class="breadcrumb-item active"
            aria-current="page"
            style="color: #ffc01d !important"
          >
            Aboutus
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <!--end container-->
</section>
<!--end section-->

<!-- <div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div> -->
<!-- Hero End -->
<section class="section bg-dark" style="padding-bottom: 0px">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h4 class="title mb-4 text-white">Overview</h4>
          <p class="text-muted mx-auto mb-0">
            <span class="text-primary fw-bold">Tenzed</span> is Globally
            renowned for its consultancy and next-generation digital services.
            We assist clients in more than 50 countries as they navigate their
            digital transformation. We expertly guide our clients through their
            digital journey thanks to our more than 7 years of experience
            managing the systems and operations of large, international
            corporations. We accomplish this by providing the organization with
            a core driven by AI that aids in prioritizing the implementation of
            change. We also equip the company with agile digital at scale to
            deliver previously unheard-of levels of performance and customer
            satisfaction. Through the development and dissemination of digital
            skills, knowledge, and concepts from our innovation ecosystem, our
            always-on learning agenda propels their continual advancement.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section bg-dark" style="padding-bottom: 0px">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h4 class="title mb-4 text-white">Our Purpose</h4>
          <p class="text-muted mx-auto mb-0 text-center">
            <span class="text-primary fw-bold"></span>Our goal is to maximise
            human potential and open up new opportunities for individuals,
            organisations, and communities.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section">
  <div class="container">
    <div class="row" id="counter">
      <div class="col-md-4">
        <img
          src="assets/images/aboutus2.1.png"
          class="img-fluid"
          alt=""
          style="border-radius: 7px; box-shadow: 0.8px 0.8px 6px #ed193f"
        />
      </div>
      <!--end col-->

      <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="ms-lg-4">
          <div class="d-flex mb-4">
            <span class="text-primary h1 mb-0"
              ><span
                class="counter-value display-1 fw-bold"
                [CountTo]="7"
                [from]="0"
                [duration]="1"
              ></span
              >+</span
            >
            <span
              class="h6 align-self-end ms-2"
              style="color: #ffc01d !important"
              >Years <br />
              Experience</span
            >
          </div>
          <div class="section-title">
            <h4 class="title mb-4 text-color-white">
              Greater futures are created via innovation and collective wisdom
            </h4>
            <p class="text-muted">
              <span class="text-primary fw-bold"></span> Through innovation and
              communal knowledge, constructing better futures For the past 50
              years, <span class="text-primary fw-bold">Tenzed</span> Tech has
              collaborated with many of the biggest companies in the world to
              provide IT services, consulting, and business solutions. We think
              innovation and all of our collective knowledge can change our
              futures for the better.
            </p>
            <div class="mt-4">
              <a routerLink="/page-contact-two" class="btn btn-primary mt-4"
                ><i class="mdi mdi-phone"></i> Contact us</a
              >
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <!-- <div class="container mt-5">
    <app-clients-logo brand_class="col-lg-2 col-md-2 col-6 text-center pt-4"></app-clients-logo>
  </div> -->
  <!--end container-->
</section>
<!--end section-->

<!--end section-->

<!-- Start -->
<section class="section bg-light pt-0">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="video-solution-cta position-relative" style="z-index: 1">
          <div class="position-relative">
            <img
              src="assets/images/cta-bg.jpg"
              class="img-fluid rounded-md shadow-lg"
              style="box-shadow: 0.8px 0.8px 6px #ed193f !important;"
              alt=""
            />
            <!-- <div class="play-icon">
              <a
                (click)="openWindowCustomClass(content)"
                href="javascript:void(0);"
                class="play-btn video-play-icon border-0"
              >
                <i
                  class="mdi mdi-play text-primary rounded-circle shadow-lg"
                ></i>
              </a>
              <ng-template #content let-modal>
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    aria-label="Close"
                    (click)="modal.dismiss('Cross click')"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <youtube-player
                    [videoId]="'yba7hPeTSjk'"
                    height="450"
                    width="780"
                  >
                  </youtube-player>
                </div>
              </ng-template>
            </div> -->
          </div>
          <div class="content mt-md-4 pt-md-2">
            <div class="row justify-content-center">
              <div class="col-lg-10 text-center">
                <div class="row align-items-center">
                  <div class="col-md-6 mt-4 pt-2">
                    <div class="section-title text-md-start">
                      <h6 class="text-white-50">Team</h6>
                      <h4 class="title text-white title-dark mb-0">
                        Meet Experience <br />
                        Team Member
                      </h4>
                    </div>
                  </div>

                  <div class="col-md-6 col-12 mt-4 pt-md-2">
                    <div class="section-title text-md-start">
                      <p class="text-white-50 para-desc">
                        Start working with Tenzed that can provide everything
                        you need to generate awareness, drive traffic, connect.
                      </p>
                      <a href="javascript:void(0)" class="text-white title-dark"
                        >Read More
                        <i-feather
                          name="arrow-right"
                          class="fea icon-sm"
                        ></i-feather>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row -->
    <div class="feature-posts-placeholder bg-primary"></div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Back to top -->
<a
  href="javascript: void(0);"
  class="btn btn-icon btn-primary back-to-top"
  id="back-to-top"
  [ngxScrollTo]="'#home'"
>
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
