import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { first } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "../../services/user.service";

@Component({
  selector: "app-page-contact-two",
  templateUrl: "./page-contact-two.component.html",
  styleUrls: ["./page-contact-two.component.css"],
})

/**
 * Page Contact-Two Component
 */
export class PageContactTwoComponent implements OnInit {
  // Set Topbar Option
  Menuoption = "center";
  contactForm: FormGroup;
  successmsg = false;
  submitted = false;
  hasError: boolean;
  error: "";
  constructor(
    private modalService: NgbModal,
    private userService: UserService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      fullName: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      phone: ["", Validators.required],
      subject: [""],
      comments: [""],
    });
  }
  get f() { return this.contactForm.controls; }

  mapView(content) {
    this.modalService.open(content, {
      windowClass: "dark-modal",
      size: "lg",
      centered: true,
    });
  }
  submit() {
    this.hasError = false;
    this.submitted = true;
    const data = {
      fullName: this.contactForm.value?.fullName,
      email: this.contactForm.value?.email,
      phone: this.contactForm.value?.phone,
      subject: this.contactForm.value?.subject,
      comments: this.contactForm.value?.comments,
    };
    if (this.contactForm.invalid) {
      return;
    } else {
      {
        this.userService.saveContact(data).subscribe(
          (response: any) => {
            console.log("saveContact ::::", response);
            this.successmsg = true;
            this.contactForm.reset();
          },
          (error) => {
            this.error = error ? error : "";
          }
        );
      }
    }
  }
}
