<!-- Hero Start -->
<section class="bg-half-170 bg-primary d-table w-100 " style="background-image: url('assets/images/job/bg.png');" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title text-white title-dark mb-0"> Frequently Asked Questions </h4>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a routerLink="/index-job">Careers</a></li>
                    <li class="breadcrumb-item active" aria-current="page">FAQs</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<!-- Start Section -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-7 col-12">
                <div class="section-title" id="general">
                    <h4>General Questions</h4>
                </div>

                <div class="accordion mt-4 pt-2" id="generalquestion">
                    <div class="accordion-item rounded">
                        <h2 class="accordion-header" id="headingfive">
                            <button class="accordion-button border-0 bg-light" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapsefive" aria-expanded="true" aria-controls="collapsefive">
                                How does it work ?
                            </button>
                        </h2>
                        <div id="collapsefive" class="accordion-collapse border-0 collapse show"
                            aria-labelledby="headingfive" data-bs-parent="#generalquestion">
                            <div class="accordion-body text-muted">
                                There are many variations of passages of Lorem Ipsum available, but the majority have
                                suffered alteration in some form.
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item rounded mt-2">
                        <h2 class="accordion-header" id="headingsix">
                            <button class="accordion-button border-0 bg-light collapsed" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false"
                                aria-controls="collapsesix">
                                Do I need a designer to use Landrick ?
                            </button>
                        </h2>
                        <div id="collapsesix" class="accordion-collapse border-0 collapse" aria-labelledby="headingsix"
                            data-bs-parent="#generalquestion">
                            <div class="accordion-body text-muted">
                                There are many variations of passages of Lorem Ipsum available, but the majority have
                                suffered alteration in some form.
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item rounded mt-2">
                        <h2 class="accordion-header" id="headingseven">
                            <button class="accordion-button border-0 bg-light collapsed" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="false"
                                aria-controls="collapseseven">
                                What do I need to do to start selling ?
                            </button>
                        </h2>
                        <div id="collapseseven" class="accordion-collapse border-0 collapse"
                            aria-labelledby="headingseven" data-bs-parent="#generalquestion">
                            <div class="accordion-body text-muted">
                                There are many variations of passages of Lorem Ipsum available, but the majority have
                                suffered alteration in some form.
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item rounded mt-2">
                        <h2 class="accordion-header" id="headingeight">
                            <button class="accordion-button border-0 bg-light collapsed" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseeight" aria-expanded="false"
                                aria-controls="collapseeight">
                                What happens when I receive an order ?
                            </button>
                        </h2>
                        <div id="collapseeight" class="accordion-collapse border-0 collapse"
                            aria-labelledby="headingeight" data-bs-parent="#generalquestion">
                            <div class="accordion-body text-muted">
                                There are many variations of passages of Lorem Ipsum available, but the majority have
                                suffered alteration in some form.
                            </div>
                        </div>
                    </div>
                </div>

                <div class="section-title mt-5" id="payment">
                    <h4>Payments Questions</h4>
                </div>

                <div class="accordion mt-4 pt-2" id="paymentquestion">
                    <div class="accordion-item rounded">
                        <h2 class="accordion-header" id="headingnine">
                            <button class="accordion-button border-0 bg-light" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapsenine" aria-expanded="true" aria-controls="collapsenine">
                                How does it work ?
                            </button>
                        </h2>
                        <div id="collapsenine" class="accordion-collapse border-0 collapse show"
                            aria-labelledby="headingnine" data-bs-parent="#paymentquestion">
                            <div class="accordion-body text-muted">
                                There are many variations of passages of Lorem Ipsum available, but the majority have
                                suffered alteration in some form.
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item rounded mt-2">
                        <h2 class="accordion-header" id="headingten">
                            <button class="accordion-button border-0 bg-light collapsed" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseten" aria-expanded="false"
                                aria-controls="collapseten">
                                Do I need a designer to use Landrick ?
                            </button>
                        </h2>
                        <div id="collapseten" class="accordion-collapse border-0 collapse" aria-labelledby="headingten"
                            data-bs-parent="#paymentquestion">
                            <div class="accordion-body text-muted">
                                There are many variations of passages of Lorem Ipsum available, but the majority have
                                suffered alteration in some form.
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item rounded mt-2">
                        <h2 class="accordion-header" id="headingeleven">
                            <button class="accordion-button border-0 bg-light collapsed" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseeleven" aria-expanded="false"
                                aria-controls="collapseeleven">
                                What do I need to do to start selling ?
                            </button>
                        </h2>
                        <div id="collapseeleven" class="accordion-collapse border-0 collapse"
                            aria-labelledby="headingeleven" data-bs-parent="#paymentquestion">
                            <div class="accordion-body text-muted">
                                There are many variations of passages of Lorem Ipsum available, but the majority have
                                suffered alteration in some form.
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item rounded mt-2">
                        <h2 class="accordion-header" id="headingtwelve">
                            <button class="accordion-button border-0 bg-light collapsed" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapsetwelve" aria-expanded="false"
                                aria-controls="collapsetwelve">
                                What happens when I receive an order ?
                            </button>
                        </h2>
                        <div id="collapsetwelve" class="accordion-collapse border-0 collapse"
                            aria-labelledby="headingtwelve" data-bs-parent="#paymentquestion">
                            <div class="accordion-body text-muted">
                                There are many variations of passages of Lorem Ipsum available, but the majority have
                                suffered alteration in some form.
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->