import { Component } from '@angular/core';

@Component({
  selector: 'app-crypto-services',
  templateUrl: './crypto-services.component.html',
  styleUrls: ['./crypto-services.component.css']
})
export class CryptoServicesComponent {
// Set Topbar
Menuoption = 'cryptocurrency'
footerVariant = 'footer-three';

}
