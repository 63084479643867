<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" 
id="home">
  <div class="bg-overlay"></div>
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0 text-white">Blog </h4>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2" style="background: #2B3949 !important;
                border: 1.8px solid white !important;">
                    <li class="breadcrumb-item"><a routerLink="/index" style="color: white;">Tenzed</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0)"  style="color: white;">Page</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0)" style="color: white;">Blog</a></li>
                    <li class="breadcrumb-item active" aria-current="page" style="color:#FFC01D !important">Blog Grid</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->
<section class="section">
    <div class="container">
        <div class="row">
            <h1 class="text-center text-white">Coming Soon....</h1>
        </div>
    </div>
</section>
<!-- Blog STart -->
<!-- <section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 mb-4 pb-2" *ngFor="let data of blogGridData">
                <div class="card blog rounded border-0 shadow overflow-hidden">
                    <div class="position-relative">
                        <img src="{{data.image}}" class="card-img-top" alt="...">
                        <div class="overlay rounded-top bg-dark"></div>
                    </div>
                    <div class="card-body content">
                        <h5><a href="javascript:void(0)" class="card-title title text-dark">{{data.title}}</a></h5>
                        <div class="post-meta d-flex justify-content-between mt-3">
                            <ul class="list-unstyled mb-0">
                                <li class="list-inline-item me-2 mb-0"><a href="javascript:void(0)"
                                        class="text-muted like"><i class="uil uil-heart me-1"></i>{{data.like}}</a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i
                                            class="uil uil-comment me-1"></i>{{data.message}}</a></li>
                            </ul>
                            <a routerLink="/page-blog-detail" class="text-muted readmore">Read More <i
                                    class="uil uil-angle-right-b align-middle"></i></a>
                        </div>
                    </div>
                    <div class="author">
                        <small class="text-light user d-block"><i class="uil uil-user"></i> {{data.name}}</small>
                        <small class="text-light date"><i class="uil uil-calendar-alt"></i> {{data.date}}</small>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <ul class="pagination justify-content-center mb-0">
                    <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Previous">Prev</a>
                    </li>
                    <li class="page-item active"><a class="page-link" href="javascript:void(0)">1</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Next">Next</a></li>
                </ul>
            </div>

        </div>
       </div>

</section> -->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" style="background:  #FFC01D !important; border-color: #FFC01D !important;" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->