        <!-- Hero Start -->
        <section class="bg-half-170 bg-light d-table w-100" style="background: url('assets/images/shape02.png') top left;" id="home">
            <div class="container">
                <div class="row mt-5 justify-content-center">
                    <div class="col-lg-12 text-center">
                        <div class="pages-heading">
                            <h4 class="title mb-0"> Whitepapers </h4>
                        </div>
                    </div>  <!--end col-->
                </div><!--end row-->
                
                <div class="position-breadcrumb">
                    <nav aria-label="breadcrumb" class="d-inline-block">
                        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                            <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                            <li class="breadcrumb-item"><a routerLink="/index-crypto">Crypto</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Whitepapers</li>
                        </ul>
                    </nav>
                </div>
            </div> <!--end container-->
        </section><!--end section-->
        <!-- Hero End -->

        <!-- Shape Start -->
        <div class="position-relative">
            <div class="shape overflow-hidden text-color-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <!--Shape End-->

        <!-- Start -->
        <section class="section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7 col-md-6">
                        <div class="section-title">
                            <h4 class="mb-4">What Is a White Paper?</h4>
                            <ul class="list-unstyled mb-5">
                                <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>Whitepapers explain the purpose and technology behind a project.</li>

                                <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>They usually provide statistics, diagrams and facts to convince interested investors to purchase the cryptocurrency.</li>
                                
                                <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>Producing a whitepaper is key a step required for a crypto startup to be considered legitimate and professional, as it helps investors understand how a business is different from rivals in the space.</li>
                                
                                <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>Whitepapers differ from litepapers, which tend to be shorter, less technical and easier to understand.</li>
                                
                                <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>One of the most famous cryptocurrency whitepapers is undoubtedly Bitcoin: A Peer-to-Peer Electronic Cash System, which was published by Satoshi Nakamoto in 2008.</li>
                            </ul>


                            <h5 class="mb-4">The Bitcoin whitepaper proposed the following:</h5>

                            <ul class="list-unstyled mb-0">
                                <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>A peer-to-peer (P2P) system for payments across an online network</li>
                                <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>Removing third parties and replacing them with verification (decentralization)</li>
                                <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>Transactions would be irreversible</li>
                                <li class="d-flex text-muted mt-2"><i class="uil uil-arrow-right text-primary h5 mb-0 me-2"></i>A P2P distributed timestamp server. This would generate mathematical proof of an order of different transactions.</li>
                            </ul>
                        </div>

                        <div class="mt-4">
                            <a href="javascript:void(0)" class="btn btn-soft-primary">Download</a>
                        </div>
                    </div><!--end col-->

                    <div class="col-lg-4 offset-lg-1 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div class="p-4 shadow rounded bg-black">
                            <img src="assets/images/crypto/bills.svg" class="img-fluid" alt="">
                            <h4 class="text-white title-dark mb-3 mt-4">Terms & Conditions</h4>
                            <p class="text-muted mb-0">A document released by a crypto project that gives investors technical information about its concept, and a roadmap for how it plans to grow and succeed.</p>
                        </div>

                        <div class="tagcloud mt-4">
                            <h5 class="mb-4">Related Terms & Conditions</h5>

                            <a href="jvascript:void(0)" class="rounded">Circulating Supply</a>
                            <a href="jvascript:void(0)" class="rounded">Currency</a>
                            <a href="jvascript:void(0)" class="rounded">Digital Art</a>
                            <a href="jvascript:void(0)" class="rounded">All-Time-High (ATH)</a>
                            <a href="jvascript:void(0)" class="rounded">Immutable</a>
                            <a href="jvascript:void(0)" class="rounded">Futures</a>
                            <a href="jvascript:void(0)" class="rounded">Contract Account</a>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container--><div class="container mt-100 mt-60">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <div class="section-title text-center">
                            <h4 class="title mb-4">Be the first to know about Crypto news everyday</h4>
                            <p class="para-desc mx-auto text-muted mb-0">Get crypto analysis, news and updates right to your inbox! Sign up here so you don't miss a single newsletter.</p>
                        
                            <div class="subcribe-form mt-5">
                                <form>
                                    <div class="mb-2">
                                        <input type="email" id="email2" name="email" class="rounded-pill border" placeholder="E-mail :">
                                        <button type="submit" class="btn btn-pills btn-primary">Notify Me</button>
                                    </div>
                                </form><!--end form-->
                            </div><!--end subscribe form-->
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- End -->

        <!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->
