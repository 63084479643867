<section class="section bg-dark">
  <div class="container mt-50">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4 text-white">
            Elevate your business with Cloud ERP for enhanced performance and efficiency.
            <span style="color: #ffc01d">ERP</span>
          </h4>
          <p class="text-muted para-desc mb-0 mx-auto">
            Our ERP software, hosted in the cloud, is crafted to equip businesses with cutting-edge functionalities and resources aimed at enhancing efficiency and fostering growth.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0"
          style="
            background: #2b3949 !important;
            border: 1.8px solid white !important;
          "
        >
          <span class="h1 icon2 text-primary">
            <i class="uil uil-chart-line"></i>
          </span>
          <div class="card-body p-0 content">
            <h5 class="text-white">Document Management</h5>
            <p class="text-muted" style="color: #c9cfd7 !important">
              Enhance your ability to manage sizable files with improved efficiency, while bolstering security measures for your data. Enjoy seamless access to all your files across various devices and effortlessly monitor version changes.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0"
          style="
            background: #2b3949 !important;
            border: 1.8px solid white !important;
            height: 295px;
          "
        >
          <span class="h1 icon2 text-primary">
            <i class="uil uil-crosshairs"></i>
          </span>
          <div class="card-body p-0 content">
            <h5 class="text-white">History Log</h5>
            <p class="text-muted" style="color: #c9cfd7 !important">
              It is beneficial to observe any alterations within the system, particularly when individuals are uncertain about the modifications since their previous visit.

            </p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0"
          style="
            background: #2b3949 !important;
            border: 1.8px solid white !important;
            height: 295px;

          "
        >
          <span class="h1 icon2 text-primary">
            <i class="uil uil-airplay"></i>
          </span>
          <div class="card-body p-0 content">
            <h5 class="text-white">Bank Reconciliation</h5>
            <p class="text-muted" style="color: #c9cfd7 !important">
              The reconciliation of accounts with bank statements ensures that all transactions have been accurately recorded in the appropriate accounting records.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0"
          style="
            background: #2b3949 !important;
            border: 1.8px solid white !important;
          "
        >
          <span class="h1 icon2 text-primary">
            <i class="uil uil-rocket"></i>
          </span>
          <div class="card-body p-0 content">
            <h5 class="text-white">User Authorization</h5>
            <p class="text-muted" style="color: #c9cfd7 !important">
              This measure ensures that only authorized individuals can access sensitive data, enhancing the security of your company. Additionally, it streamlines access to data, saving time and enabling efficient retrieval.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0"
          style="
            background: #2b3949 !important;
            border: 1.8px solid white !important;
            height: 295px;

          "
        >
          <span class="h1 icon2 text-primary">
            <i class="uil uil-clock"></i>
          </span>
          <div class="card-body p-0 content">
            <h5 class="text-white">Taxation and Compliance</h5>
            <p class="text-muted" style="color: #c9cfd7 !important">
              Minimizes the chance of facing penalties or legal repercussions due to failure to comply with tax regulations.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0"
          style="
            background: #2b3949 !important;
            border: 1.8px solid white !important;
            height: 295px;

          "
        >
          <span class="h1 icon2 text-primary">
            <i class="uil uil-users-alt"></i>
          </span>
          <div class="card-body p-0 content">
            <h5 class="text-white">Other Valuable Features</h5>
            <p class="text-muted" style="color: #c9cfd7 !important">
              Enables swift and effortless importing of large amounts of data into Excel, leading to time savings.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0"
          style="
            background: #2b3949 !important;
            border: 1.8px solid white !important;
            height: 345px;

          "
        >
          <span class="h1 icon2 text-primary">
            <i class="uil uil-file-alt"></i>
          </span>
          <div class="card-body p-0 content">
            <h5 class="text-white">Shortest Go Live</h5>
            <p class="text-muted" style="color: #c9cfd7 !important">
              We provide an extensive array of solutions and services aimed at facilitating a swift transition to going live.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0"
          style="
            background: #2b3949 !important;
            border: 1.8px solid white !important;
            height: 345px;

          "
        >
          <span class="h1 icon2 text-primary">
            <i class="uil uil-search"></i>
          </span>
          <div class="card-body p-0 content">
            <h5 class="text-white">Import from Excel</h5>
            <p class="text-muted" style="color: #c9cfd7 !important">
              Enables swift and effortless importing of large volumes of data into Excel, resulting in time savings.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->
      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0"
          style="
            background: #2b3949 !important;
            border: 1.8px solid white !important;
          "
        >
          <span class="h1 icon2 text-primary">
            <i class="uil uil-chart-line"></i>
          </span>
          <div class="card-body p-0 content">
            <h5 class="text-white">MIS Reporting</h5>
            <p class="text-muted" style="color: #c9cfd7 !important">
              Create a series of reports containing essential data to facilitate the assessment and analysis of your business's strengths and weaknesses. These reports will offer a detailed overview of your company's operations, providing you with insights to understand its activities and functions effectively.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="bg-dark pb-3">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4 text-white">Our Modules</h4>
          <p class="text-muted para-desc mb-0 mx-auto">
            Our ERP solution hosted in the cloud is tailored to equip businesses with cutting-edge functionalities and resources aimed at fostering success and enhancing efficiency.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6 mt-4 pt-3 pb-3">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0"
          style="
            background: #2b3949 !important;
            border: 1.8px solid white !important;

          "
        >
        <span class="h1 icon2 text-primary">
          <i class="uil uil-chart-line"></i>
        </span>
          <div class="card-body p-0 content">
            <h5 class="text-white">
              CRM
            </h5>
            <p class="text-muted" style="color: #c9cfd7 !important">
              Organizations can enhance their decision-making processes regarding both customer service and product development.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 mt-4 pt-3 pb-3">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0"
          style="
            background: #2b3949 !important;
            border: 1.8px solid white !important;
          "
        >
        <span class="h1 icon2 text-primary">
          <i class="uil uil-crosshairs"></i>
        </span>
          <div class="card-body p-0 content">
            <h5 class="text-white" >
              Sales
            </h5>
            <p class="text-muted" style="color: #c9cfd7 !important">
              The module enhances data precision to ensure the generation of precise statements and financial reports.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 mt-4 pt-3 pb-3">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0"
          style="
            background: #2b3949 !important;
            border: 1.8px solid white !important;

          "
        >
        <span class="h1 icon2 text-primary">
          <i class="uil uil-airplay"></i>
        </span>
          <div class="card-body p-0 content">
            <h5 class="text-white" >
              Purchase
            </h5>
            <p class="text-muted" style="color: #c9cfd7 !important">
              You can achieve substantial savings by accurately tracking spending and identifying overestimated and duplicate purchase orders.
            </p>
            <p></p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 mt-4 pt-3 pb-3">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0"
          style="
            background: #2b3949 !important;
            border: 1.8px solid white !important;
          "
        >
        <span class="h1 icon2 text-primary">
          <i class="uil uil-rocket"></i>
        </span>
          <div class="card-body p-0 content">
            <h5 class="text-white" >
              Inventory Management
            </h5>
            <p class="text-muted" style="color: #c9cfd7 !important">
              Enhanced inventory control aids in averting excess and deficiencies, enhances operational effectiveness, and mitigates the likelihood of costly inventory depletions.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 mt-4 pt-3 pb-3">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0"
          style="
            background: #2b3949 !important;
            border: 1.8px solid white !important;
          "
        >
        <span class="h1 icon2 text-primary">
          <i class="uil uil-clock"></i>
        </span>
          <div class="card-body p-0 content">
            <h5 class="text-white" >
              Accounting and Finance
            </h5>
            <p class="text-muted" style="color: #c9cfd7 !important">
              Creating and generating internal financial reports streamlines processes, allowing you to dedicate more time and energy to your primary tasks effectively.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 mt-4 pt-3 pb-3">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0"
          style="
            background: #2b3949 !important;
            border: 1.8px solid white !important;
          "
        >
        <span class="h1 icon2 text-primary">
          <i class="uil uil-users-alt"></i>
        </span>
          <div class="card-body p-0 content">
            <h5 class="text-white" >
              Banking
            </h5>
            <p class="text-muted" style="color: #c9cfd7 !important">
              You are capable of managing all types of financial transactions and also have the capability to utilize the Payment Engine for automating payment processing.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 mt-4 pt-3 pb-3">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0"
          style="
            background: #2b3949 !important;
            border: 1.8px solid white !important;
          "
        >
        <span class="h1 icon2 text-primary">
          <i class="uil uil-file-alt"></i>
        </span>
          <div class="card-body p-0 content">
            <h5 class="text-white" >
              Subcontracting Process
            </h5>
            <p class="text-muted" style="color: #c9cfd7 !important">
              Offering a procurement procedure that diminishes vendor exposure, enhances oversight of subcontractor standards, and lowers expenses by securing favorable pricing.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 mt-4 pt-3 pb-3">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0"
          style="
            background: #2b3949 !important;
            border: 1.8px solid white !important;
            height: 270px;

          "
        >
        <span class="h1 icon2 text-primary">
          <i class="uil uil-search"></i>
        </span>
          <div class="card-body p-0 content">
            <h5 class="text-white" >
              Design Management
            </h5>
            <p class="text-muted" style="color: #c9cfd7 !important">
              Documents can be quickly located and accessed within seconds, ensuring they are never misplaced, and the information remains readily available at all times.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="bg-dark py-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4 text-white">
            Key benefits of using Cloud <span style="color: #ffc01d">ERP</span>
          </h4>
          <p class="text-muted para-desc mb-0 mx-auto">
            Our cloud-based ERP solution aims to offer businesses cutting-edge features and resources geared towards enhancing success and efficiency.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6 mt-4 pt-3 pb-3">
        <div
          class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow"
          style="
            background: #2b3949 !important;
            border: 1.8px solid white !important;
            height: 195px;

          "
        >
          <div class="card-body p-0">
            <h2 class="mb-3 mt-3" style="font-size: 26px; color: #ffc01d">
              Real-time data sync
            </h2>
            <p class="text-muted" style="color: #c9cfd7 !important">
              Ensuring that data remains current and precise consistently results in time and cost savings.

            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 mt-4 pt-3 pb-3">
        <div
          class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow"
          style="
            background: #2b3949 !important;
            border: 1.8px solid white !important;
          "
        >
          <div class="card-body p-0">
            <h2 class="mb-3 mt-3" style="font-size: 26px; color: #ffc01d">
              Flexibility of Access
            </h2>
            <p class="text-muted" style="color: #c9cfd7 !important">
              You no longer need to carry physical files as all your information is easily accessible, ensuring you stay informed and organized at all times.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 mt-4 pt-3 pb-3">
        <div
          class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow"
          style="
            background: #2b3949 !important;
            border: 1.8px solid white !important;
            height: 195px;

          "
        >
          <div class="card-body p-0">
            <h2 class="mb-3 mt-3" style="font-size: 26px; color: #ffc01d">
              Auto backups
            </h2>
            <p class="text-muted" style="color: #c9cfd7 !important">
              Guarantees automatic data backup, eliminating the requirement for manual backup procedures.
            </p>
            <p></p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 mt-4 pt-3 pb-3">
        <div
          class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow"
          style="
            background: #2b3949 !important;
            border: 1.8px solid white !important;
            height: 220px;

          "
        >
          <div class="card-body p-0">
            <h2 class="mb-3 mt-3" style="font-size: 26px; color: #ffc01d">
              Auto updates
            </h2>
            <p class="text-muted" style="color: #c9cfd7 !important">
              You'll never need to concern yourself with updating or upgrading your system anymore.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 mt-4 pt-3 pb-3">
        <div
          class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow"
          style="
            background: #2b3949 !important;
            border: 1.8px solid white !important;
          "
        >
          <div class="card-body p-0">
            <h2 class="mb-3 mt-3" style="font-size: 26px; color: #ffc01d">
              Always Secure
            </h2>
            <p class="text-muted" style="color: #c9cfd7 !important">
              Provides a robust and dependable hosting infrastructure that prioritizes safety and security, ensuring your data is shielded from any potential attacks effectively.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 mt-4 pt-3 pb-3">
        <div
          class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow"
          style="
            background: #2b3949 !important;
            border: 1.8px solid white !important;
            height: 220px;

          "
        >
          <div class="card-body p-0">
            <h2 class="mb-3 mt-3" style="font-size: 26px; color: #ffc01d">
              Easy Scalability for Every Stage of Growth
            </h2>
            <p class="text-muted" style="color: #c9cfd7 !important">
              This characteristic facilitates the seamless expansion of your company, enabling accelerated growth without encountering any issues.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 mt-4 pt-3 pb-3">
        <div
          class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow"
          style="
            background: #2b3949 !important;
            border: 1.8px solid white !important;
          "
        >
          <div class="card-body p-0">
            <h2 class="mb-3 mt-3" style="font-size: 26px; color: #ffc01d">
              Efficient, Paper-Free Accounting
            </h2>
            <p class="text-muted" style="color: #c9cfd7 !important">
              You have convenient access to your financial records without the need for tedious filing and organization.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 mt-4 pt-3 pb-3">
        <div
          class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow"
          style="
            background: #2b3949 !important;
            border: 1.8px solid white !important;
            height: 205px;

          "
        >
          <div class="card-body p-0">
            <h2 class="mb-3 mt-3" style="font-size: 26px; color: #ffc01d">
              Service Support at All Times
            </h2>
            <p class="text-muted" style="color: #c9cfd7 !important">
              Ensure the continuous workflow within the organization by promptly addressing service requests.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
