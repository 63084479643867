import { Component } from '@angular/core';

@Component({
  selector: 'app-crypto-blog',
  templateUrl: './crypto-blog.component.html',
  styleUrls: ['./crypto-blog.component.css']
})
export class CryptoBlogComponent {
// Set Topbar
Menuoption = 'cryptocurrency'
footerVariant = 'footer-three';


}
