<!-- TAGLINE START-->
<div class="tagline" *ngIf="sliderTopbar == true">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="text-slider">
          <ul class="list-inline mb-0 move-text">
            <li class="list-inline-item px-2 mb-0">
              <b>EOG</b> $ 55.88
              <span class="text-success">+$ 4.62 ( +9.01% )</span>
            </li>
            <li class="list-inline-item px-2 mb-0">
              <b>CHKP</b> $ 120.03
              <span class="text-danger">-$ 14.07 ( -10.49% )</span>
            </li>
            <li class="list-inline-item px-2 mb-0">
              <b>FANG</b> $ 63.58
              <span class="text-success">+$ 5.17 ( +8.84% )</span>
            </li>
            <li class="list-inline-item px-2 mb-0">
              <b>M</b> $ 14.75
              <span class="text-success">+$ 1.05 ( +7.66% )</span>
            </li>
            <li class="list-inline-item px-2 mb-0">
              <b>PKI</b> $ 139.72
              <span class="text-danger">-$ 11.41 ( -7.55% )</span>
            </li>
            <li class="list-inline-item px-2 mb-0">
              <b>ABMD</b> $ 326.23
              <span class="text-danger">-$ 21.61 ( -6.21% )</span>
            </li>
            <li class="list-inline-item px-2 mb-0">
              <b>BITCOIN</b> $ 37,471.47
              <span class="text-danger">+$ 492.60 ( +1.33% )</span>
            </li>
            <li class="list-inline-item px-2 mb-0">
              <b>XRP</b> <span> $ 0.39</span
              ><span class="text-muted"> UNCH</span>
            </li>
            <li class="list-inline-item px-2 mb-0">
              <b>LITECOIN</b> <span> $ 148.67</span
              ><span class="text-danger">-$ 5.58 ( -3.62% )</span>
            </li>
            <li class="list-inline-item px-2 mb-0">
              <b>BITCOIN CASH</b> <span> $ 427.37</span
              ><span class="text-danger">-$ 15.98 ( -3.60% )</span>
            </li>
            <li class="list-inline-item px-2 mb-0">
              <b>ETHEREUM</b> $ 1,647.87
              <span class="text-danger">+$ 14.51 ( +0.89% )</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- TAGLINE END-->

<!-- Navbar STart -->
<header
  id="topnav"
  class="defaultscroll sticky"
  style="background-color: #2b3949 !important"
  (window:scroll)="windowScroll()"
  [class]="navClass"
  [ngClass]="{ 'tagline-height': sliderTopbar == true }"
>
  <div class="container" style="max-width: 1300px !important;">
    <!-- Logo container-->
    <div>
      <a class="logo" routerLink="/" *ngIf="navClass !== 'nav-light'">
        <img
          src="../../../assets//images/logo-svg-53.svg"
          class="l-dark logo-light-mode"
          height="75"
          alt=""
        />
        <img
          src="../../../assets//images/logo-svg-53.svg"
          class="logo-dark-mode"
          height="75"
          alt=""
        />
      </a>

      <div style="color: white" class="menu-extras">
        <div class="menu-item">
          <!-- Mobile menu toggle-->
          <a
            class="navbar-toggle"
            id="isToggle"
            (click)="toggleMenu()"
            [ngClass]="{ open: isCondensed === true }"
          >
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
          <!-- End mobile menu toggle-->
        </div>
      </div>
      <ul class="buy-button list-inline mb-0" *ngIf="shopPages == true">
        <li class="list-inline-item mb-0 pe-1">
          <div class="dropdown" ngbDropdown>
            <button
              type="button"
              class="btn dropdown-toggle p-0"
              ngbDropdownToggle
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="uil uil-search text-dark fs-5 align-middle"></i>
            </button>
            <div
              class="dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-3 py-0"
              style="width: 300px"
              ngbDropdownMenu
            >
              <!-- <div class="search-bar">
                <div id="itemSearch" class="menu-search mb-0"> -->
              <form class="searchform">
                <input
                  type="text"
                  id="text"
                  name="name"
                  class="form-control border rounded"
                  placeholder="Search..."
                />
                <input type="submit" id="searchItemsubmit" value="Search" />
              </form>
              <!-- </div>
              </div> -->
            </div>
          </div>
        </li>
        <li class="list-inline-item mb-0 pe-1">
          <div class="dropdown" ngbDropdown>
            <button
              type="button"
              class="btn btn-icon btn-pills btn-primary dropdown-toggle"
              ngbDropdownToggle
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="uil uil-shopping-cart align-middle icons"></i>
            </button>
            <div
              class="dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-3 p-4"
              ngbDropdownMenu
              style="width: 300px"
            >
              <div class="pb-4">
                <a href="javascript:void(0)" class="d-flex align-items-center">
                  <img
                    src="assets/images/shop/product/s-1.jpg"
                    class="shadow rounded"
                    style="max-height: 64px"
                    alt=""
                  />
                  <div class="flex-1 text-start ms-3">
                    <h6 class="text-dark mb-0">T-shirt (M)</h6>
                    <p class="text-muted mb-0">$320 X 2</p>
                  </div>
                  <h6 class="text-dark mb-0">$640</h6>
                </a>

                <a
                  href="javascript:void(0)"
                  class="d-flex align-items-center mt-4"
                >
                  <img
                    src="assets/images/shop/product/s-2.jpg"
                    class="shadow rounded"
                    style="max-height: 64px"
                    alt=""
                  />
                  <div class="flex-1 text-start ms-3">
                    <h6 class="text-dark mb-0">Bag</h6>
                    <p class="text-muted mb-0">$50 X 5</p>
                  </div>
                  <h6 class="text-dark mb-0">$250</h6>
                </a>

                <a
                  href="javascript:void(0)"
                  class="d-flex align-items-center mt-4"
                >
                  <img
                    src="assets/images/shop/product/s-3.jpg"
                    class="shadow rounded"
                    style="max-height: 64px"
                    alt=""
                  />
                  <div class="flex-1 text-start ms-3">
                    <h6 class="text-dark mb-0">Watch (Men)</h6>
                    <p class="text-muted mb-0">$800 X 1</p>
                  </div>
                  <h6 class="text-dark mb-0">$800</h6>
                </a>
              </div>

              <div
                class="d-flex align-items-center justify-content-between pt-4 border-top"
              >
                <h6 class="text-dark mb-0">Total($):</h6>
                <h6 class="text-dark mb-0">$1690</h6>
              </div>

              <div class="mt-3 text-center">
                <a href="javascript:void(0)" class="btn btn-primary me-2"
                  >View Cart</a
                >
                <a href="javascript:void(0)" class="btn btn-primary"
                  >Checkout</a
                >
              </div>
              <p class="text-muted text-start mt-1 mb-0">*T&amp;C Apply</p>
            </div>
          </div>
        </li>
        <li class="list-inline-item mb-0 pe-1">
          <a
            href="javascript:void(0);"
            class="btn btn-icon btn-pills btn-primary"
            (click)="wishListModal(content)"
            ><i class="uil uil-heart align-middle icons"></i
          ></a>
        </li>

        <ng-template #content>
          <div class="modal-content rounded shadow-lg border-0 overflow-hidden">
            <div class="modal-body py-5">
              <div class="text-center">
                <div
                  class="icon d-flex align-items-center justify-content-center bg-soft-danger rounded-circle mx-auto"
                  style="height: 95px; width: 95px"
                >
                  <h1 class="mb-0">
                    <i class="uil uil-heart-break align-middle"></i>
                  </h1>
                </div>
                <div class="mt-4">
                  <h4>Your wishlist is empty.</h4>
                  <p class="text-muted">
                    Create your first wishlist request...
                  </p>
                  <div class="mt-4">
                    <a href="javascript:void(0)" class="btn btn-outline-primary"
                      >+ Create new wishlist</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>

        <li class="list-inline-item mb-0">
          <div class="dropdown dropdown-primary" ngbDropdown>
            <button
              type="button"
              class="btn btn-icon btn-pills btn-primary dropdown-toggle"
              ngbDropdownToggle
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="uil uil-user align-middle icons"></i>
            </button>
            <div
              class="dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-3 py-3"
              ngbDropdownMenu
              style="width: 200px"
            >
              <a class="dropdown-item text-dark" href="javascript:void(0)"
                ><i class="uil uil-user align-middle me-1"></i> Account</a
              >
              <a class="dropdown-item text-dark" href="javascript:void(0)"
                ><i class="uil uil-clipboard-notes align-middle me-1"></i> Order
                History</a
              >
              <a class="dropdown-item text-dark" href="javascript:void(0)"
                ><i class="uil uil-arrow-circle-down align-middle me-1"></i>
                Download</a
              >
              <div class="dropdown-divider my-3 border-top"></div>
              <a class="dropdown-item text-dark" href="javascript:void(0)"
                ><i class="uil uil-sign-out-alt align-middle me-1"></i>
                Logout</a
              >
            </div>
          </div>
        </li>
      </ul>

      <ul class="buy-button list-inline mb-0" *ngIf="buttonList == true">
        <li class="list-inline-item mb-0 ms-1">
          <div class="dropdown">
            <button
              type="button"
              class="btn btn-link text-decoration-none dropdown-toggle p-0 pe-2"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="uil uil-search text-muted"></i>
            </button>
            <div
              class="dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-3 py-0"
              style="width: 300px"
            >
              <form>
                <input
                  type="text"
                  id="text"
                  name="name"
                  class="form-control border bg-white"
                  placeholder="Search..."
                />
              </form>
            </div>
          </div>
        </li>
        <li class="list-inline-item mb-0 ms-1">
          <a href="javascript:void(0)" class="btn btn-icon btn-primary"
            ><i class="uil uil-facebook-f icons"></i
          ></a>
        </li>
        <li class="list-inline-item mb-0 ms-1">
          <a href="javascript:void(0)" class="btn btn-icon btn-primary"
            ><i class="uil uil-github icons"></i
          ></a>
        </li>
        <li class="list-inline-item mb-0 ms-1">
          <a href="javascript:void(0)" class="btn btn-icon btn-primary"
            ><i class="uil uil-twitter icons"></i
          ></a>
        </li>
        <li class="list-inline-item mb-0 ms-1">
          <a href="javascript:void(0)" class="btn btn-icon btn-primary"
            ><i class="uil uil-linkedin-alt icons"></i
          ></a>
        </li>
      </ul>

      <ul class="buy-button list-inline mb-0" *ngIf="isdeveloper == true">
        <li class="list-inline-item mb-0">
          <div class="dropdown">
            <button
              type="button"
              class="btn dropdown-toggle p-0"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="uil uil-search text-dark fs-5 align-middle pe-2"></i>
            </button>
            <div
              class="dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-3 py-0"
              style="width: 300px"
            >
              <form>
                <input
                  type="text"
                  id="text2"
                  name="name"
                  class="form-control border bg-white"
                  placeholder="Search..."
                />
              </form>
            </div>
          </div>
        </li>
        <!-- <li class="list-inline-item mb-0 pe-2">
          <a href="javascript:void(0)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight" (click)="openright(content)">
            <div class="btn btn-icon btn-soft-primary" id="settingbtn"><i-feather name="settings"
                class="fea icon-sm"></i-feather></div>
          </a>
        </li> -->
        <li class="list-inline-item mb-0">
          <a href="javascript:void(0)" class="btn btn-icon btn-primary"
            ><i class="uil uil-github icons"></i
          ></a>
        </li>
        <!-- <li class="list-inline-item mb-0 pe-1">
          <a href="javascript:void(0)" class="btn btn-icon btn-soft-primary"><i class="mdi mdi-stack-overflow mdi-18px icons"></i></a>
        </li>
        <li class="list-inline-item mb-0">
          <a href="javascript:void(0)" class="btn btn-icon btn-soft-primary" (click)="developerModal(content)"
            data-bs-toggle="modal" data-bs-target="#loginform"><i class="uil uil-user icons"></i></a>
        </li> -->
        <ng-template #content let-modal>
          <div class="modal-lg modal-dialog-centered" role="document">
            <div
              class="modal-content rounded shadow-lg border-0 overflow-hidden position-relative"
            >
              <button
                type="button"
                class="btn-close position-absolute top-0 end-0 mt-2 me-2"
                (click)="modal.dismiss('Cross click')"
                style="z-index: 1"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div class="modal-body p-0">
                <div class="container-fluid px-0">
                  <div class="row align-items-center g-0">
                    <div class="col-lg-6 col-md-5">
                      <img
                        src="assets/images/course/online/ab02.jpg"
                        class="img-fluid"
                        alt=""
                      />
                    </div>
                    <!--end col-->

                    <div class="col-lg-6 col-md-7">
                      <form class="login-form p-4">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="mb-3">
                              <label class="form-label"
                                >Your Email
                                <span class="text-danger">*</span></label
                              >
                              <div class="form-icon position-relative">
                                <i-feather
                                  name="user"
                                  class="fea icon-sm icons"
                                ></i-feather>
                                <input
                                  type="email"
                                  class="form-control ps-5"
                                  placeholder="Email"
                                  name="email"
                                  required=""
                                />
                              </div>
                            </div>
                          </div>
                          <!--end col-->

                          <div class="col-lg-12">
                            <div class="mb-3">
                              <label class="form-label"
                                >Password
                                <span class="text-danger">*</span></label
                              >
                              <div class="form-icon position-relative">
                                <i-feather
                                  name="key"
                                  class="fea icon-sm icons"
                                ></i-feather>
                                <input
                                  type="password"
                                  class="form-control ps-5"
                                  placeholder="Password"
                                  required=""
                                />
                              </div>
                            </div>
                          </div>
                          <!--end col-->

                          <div class="col-lg-12">
                            <div class="d-flex justify-content-between">
                              <div class="mb-3">
                                <div class="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customCheck1"
                                  />
                                  <label
                                    class="custom-control-label ms-1"
                                    for="customCheck1"
                                    >Remember me</label
                                  >
                                </div>
                              </div>
                              <p class="forgot-pass mb-0">
                                <a
                                  routerLink="/auth-re-password"
                                  class="text-dark fw-bold"
                                  >Forgot password ?</a
                                >
                              </p>
                            </div>
                          </div>
                          <!--end col-->

                          <div class="col-lg-12 mb-0">
                            <div class="d-grid">
                              <button class="btn btn-primary">Sign in</button>
                            </div>
                          </div>
                          <!--end col-->

                          <div class="col-12 text-center">
                            <p class="mb-0 mt-3">
                              <small class="text-dark me-2"
                                >Don't have an account ?</small
                              >
                              <a
                                routerLink="/auth-signup"
                                class="text-dark fw-bold"
                                >Sign Up</a
                              >
                            </p>
                          </div>
                          <!--end col-->
                        </div>
                        <!--end row-->
                      </form>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </div>
                <!--end container-->
              </div>
            </div>
          </div>
        </ng-template>
      </ul>

      <a class="logo" routerLink="/" *ngIf="navClass === 'nav-light'">
        <img
          src="../../../assets//images/logo-svg-53.svg"
          class="l-dark"
          height="75"
          alt=""
        />
        <img
          src="../../../assets//images/logo-svg-53.svg"
          class="l-light"
          height="75"
          alt=""
        />
      </a>
    </div>

    <span
      *ngIf="
        !buttonList &&
        !isdeveloper &&
        !shopPages &&
        !Settingicon &&
        Menuoption != 'cryptocurrency'
      "
    >
      <div class="buy-button">
        <ng-template [ngIf]="navClass === 'nav-light'">
          <!-- <a href="javascript: void(0);" class="btn btn-primary login-btn-primary">Buy Now</a> -->
          <!-- <a href="javascript: void(0);" class="btn btn-light login-btn-light">Buy Now</a> -->
        </ng-template>
        <ng-template [ngIf]="navClass !== 'nav-light'">
          <!-- <a href="javascript: void(0);" class="btn btn-primary">Buy Now</a> -->
        </ng-template>
      </div>
    </span>
    <!--end login button-->

    <!--Login button Start-->
    <!-- <ul class="buy-button list-inline mb-0" *ngIf="Settingicon == true && navClass === 'nav-light'">
      <li class="list-inline-item mb-0 login-btn-primary">
        <a href="javascript:void(0)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight" (click)="openright(content)">
          <div class="btn btn-icon btn-pills btn-soft-primary" id="settingbtn"><i-feather name="settings"
              class="fea icon-sm"></i-feather></div>
        </a>
      </li>

      <li class="list-inline-item mb-0 login-btn-light">
        <a href="javascript:void(0)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight" (click)="openright(content)">
          <div class="btn btn-icon btn-pills btn-light" id="settingbtn"><i-feather name="settings"
              class="fea icon-sm"></i-feather></div>
        </a>
      </li>

      <li class="list-inline-item ps-2 mb-0 login-btn-primary">
        <a href="https://1.envato.market/landrick" target="_blank">
          <div class="btn btn-icon btn-pills btn-primary" id="settingbtn"><i-feather name="shopping-cart"
              class="fea icon-sm"></i-feather></div>
        </a>
      </li>

      <li class="list-inline-item ps-2 mb-0 login-btn-light">
        <a href="https://1.envato.market/landrick" target="_blank">
          <div class="btn btn-icon btn-pills btn-light" id="settingbtn"><i-feather name="shopping-cart"
              class="fea icon-sm"></i-feather></div>
        </a>
      </li>
    </ul> -->

    <ul
      class="buy-button list-inline mb-0"
      *ngIf="Settingicon == true && navClass !== 'nav-light'"
    >
      <!-- <li class="list-inline-item mb-0">
        <a href="javascript:void(0)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight" (click)="openright(content)">
          <div class="btn btn-icon btn-pills btn-soft-primary" id="settingbtn"><i-feather name="settings"
              class="fea icon-sm"></i-feather></div>
        </a>
      </li> -->

      <!-- <li class="list-inline-item ps-2 mb-0">
        <a href="https://1.envato.market/landrick" target="_blank">
          <div class="btn btn-icon btn-pills btn-primary" id="settingbtn"><i-feather name="shopping-cart"
              class="fea icon-sm"></i-feather></div>
        </a>
      </li> -->
    </ul>
    <!--Login button End-->

    <!--Login button Start-->
    <ul
      class="buy-button list-inline mb-0"
      *ngIf="Menuoption == 'cryptocurrency'"
    >
      <li class="list-inline-item mb-0">
        <a
          href="javascript:void(0)"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <div class="login-btn-primary">
            <span class="btn btn-icon btn-pills btn-soft-primary"
              ><i-feather name="settings" class="fea icon-sm"></i-feather
            ></span>
          </div>
          <div class="login-btn-light">
            <span class="btn btn-icon btn-pills btn-light"
              ><i-feather name="settings" class="fea icon-sm"></i-feather
            ></span>
          </div>
        </a>
      </li>

      <li class="list-inline-item ps-1 mb-0">
        <a href="https://1.envato.market/landrick" target="_blank">
          <div class="btn btn-icon btn-pills btn-primary">
            <i-feather name="user" class="fea icon-sm"></i-feather>
          </div>
        </a>
      </li>
    </ul>
    <!--Login button End-->

    <ul
      class="buy-button list-inline mb-0 app-header-tzd"
      *ngIf="appicons == true"
    >
      <li class="list-inline-item mb-0">
        <a href="javascript:void(0)" class="btn btn-icon btn-light">
          <img
            src="assets/images/app/app-store.png"
            class="avatar avatar-ex-small p-1"
            alt=""
          />
        </a>
      </li>

      <li class="list-inline-item mb-0 ps-2">
        <a href="javascript:void(0)" class="btn btn-icon btn-light">
          <img
            src="assets/images/app/play-store.png"
            class="avatar avatar-ex-small p-1"
            alt=""
          />
        </a>
      </li>
    </ul>

    <ul class="buy-button list-inline mb-0" *ngIf="Nfticons == true">
      <li class="list-inline-item mb-0">
        <div class="dropdown">
          <button
            type="button"
            class="btn dropdown-toggle p-0"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <!-- <i class="uil uil-search text-white title-dark btn-icon-light fs-5 align-middle"></i>
                  <i class="uil uil-search text-dark btn-icon-dark fs-5 align-middle"></i> -->
            <i class="uil uil-search text-dark fs-5 align-middle"></i>
          </button>
          <div
            class="dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-3 p-0"
            style="width: 300px"
          >
            <div class="search-bar">
              <div id="itemSearch" class="menu-search mb-0">
                <form
                  role="search"
                  method="get"
                  id="searchItemform"
                  class="searchform"
                >
                  <input
                    type="text"
                    class="form-control border rounded"
                    name="s"
                    id="searchItem"
                    placeholder="Search..."
                  />
                  <input type="submit" id="searchItemsubmit" value="Search" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </li>

      <li class="list-inline-item ps-1 mb-0">
        <a
          href="https://1.envato.market/landrick"
          target="_blank"
          class="btn btn-icon btn-pills btn-primary"
          ><i class="uil uil-wallet"></i
        ></a>
      </li>
    </ul>
    <!--end login button-->

    <div id="navigation" *ngIf="Menuoption == 'center'">
      <!-- Navigation Menu-->
      <ul class="navigation-menu" [class]="navClass">
        <li><a routerLink="/" class="nav-link-ref text-white">Home</a></li>
        <li class="has-submenu">
          <a
            class="text-white"
            href="javascript:void(0)"
            (click)="onMenuClick($event)"
            >Services</a
          ><span class="menu-arrow"></span>
          <ul class="submenu megamenu">
            <li>
              <ul>
                <li class="megamenu-head">
                  <img src="../../../assets/images/icons8-integration-24.png" alt="">
                  Integrations
                </li>
                <li><a class="nav-link-ref" routerLink="">Email</a></li>
                <li><a class="nav-link-ref" routerLink="">SMS Saas</a></li>
                <li><a class="nav-link-ref" routerLink="">Payment</a></li>
                <li><a class="nav-link-ref" routerLink="">Theme</a></li>
                <li>
                  <a class="nav-link-ref" routerLink="">Chat Application</a>
                </li>
                <li>
                  <a class="nav-link-ref" routerLink="">Other Third-Party</a>
                </li>
                <!-- <li><a class="nav-link-ref" routerLink="/index-studio">Studio</a></li>
                <li><a class="nav-link-ref" routerLink="/index-marketing">Marketing</a></li>
                <li><a class="nav-link-ref" routerLink="/index-enterprise">Enterprise</a></li>
                <li><a class="nav-link-ref" routerLink="/index-services">Service</a></li>
                <li><a class="nav-link-ref" routerLink="/index-payments">Payments</a></li>
                <li><a class="nav-link-ref" routerLink="/index-it-solution">IT Solution </a></li>
                <li><a class="nav-link-ref" routerLink="/index-it-solution-tzd">IT Solution tzd </a></li>
                <li><a class="nav-link-ref" routerLink="/index-it-solution-two">IT Solution Two</a></li> -->
              </ul>
            </li>

            <li>
              <ul>
                <li class="megamenu-head">
                  <img
                    src="../../../assets/images/icons8-software-development-24.png"
                    alt=""
                  />
                  Software Development
                </li>
                <li>
                  <a class="nav-link-ref" routerLink=""
                    >Application Development</a
                  >
                </li>
                <li>
                  <a class="nav-link-ref" routerLink="">Product Development</a>
                </li>
                <li>
                  <a class="nav-link-ref" routerLink=""
                    >Product re-Engineering</a
                  >
                </li>
                <li>
                  <a class="nav-link-ref" routerLink="">Enterprise Solution</a>
                </li>
                <li>
                  <a class="nav-link-ref" routerLink="">Software Consulting</a>
                </li>
                <li>
                  <a class="nav-link-ref" routerLink="">Custom Development</a>
                </li>
                <!-- <li><a class="nav-link-ref" routerLink="/index-finance">Finance</a></li>
                <li><a class="nav-link-ref" routerLink="/index-logistics">Delivery & Logistics </a></li>
                <li><a class="nav-link-ref" routerLink="/index-social-marketing">Social Media</a></li>
                <li><a class="nav-link-ref" routerLink="/index-digital-agency">Digital Agency</a></li>
                <li><a class="nav-link-ref" routerLink="/index-customer">Customer</a></li>
                <li><a class="nav-link-ref" routerLink="/index-software">Software</a></li> -->
              </ul>
            </li>
            <li>
              <ul>
                <li class="megamenu-head">
                  <img
                    src="../../../assets/images/icons8-mobile-development-24.png"
                    alt=""
                  />
                  Mobile Development
                </li>
                <li>
                  <a class="nav-link-ref" routerLink=""
                    >Native App Development</a
                  >
                </li>
                <li>
                  <a class="nav-link-ref" routerLink=""
                    >Cross Plateform Development</a
                  >
                </li>
                <li>
                  <a class="nav-link-ref" routerLink=""
                    >Hybrid App Development</a
                  >
                </li>
                <li>
                  <a class="nav-link-ref" routerLink=""
                    >Progressive Web Development</a
                  >
                </li>
                <li>
                  <a class="nav-link-ref" routerLink="">Custom Development</a>
                </li>
                <!-- <li><a class="nav-link-ref" routerLink="/index-crypto-two">Cryptocurrency Two</a></li>
                <li><a class="nav-link-ref" routerLink="/index-integration">Integration</a></li>
                <li><a class="nav-link-ref" routerLink="/index-task-management">Task Management</a></li>
                <li><a class="nav-link-ref" routerLink="/index-email-inbox">Email Inbox </a></li>
                <li><a class="nav-link-ref" routerLink="/index-travel">Travel </a></li>
                <li><a class="nav-link-ref" routerLink="/index-course">Course</a></li>
                <li><a class="nav-link-ref" routerLink="/index-online-learning">Online Learning</a></li> -->
              </ul>
            </li>
            <li>
              <ul>
                <li class="megamenu-head">
                  <img
                    src="../../../assets/images/icons8-web-development-24.png"
                    alt=""
                  />
                  Web Development
                </li>
                <li>
                  <a class="nav-link-ref" routerLink="">CMS Development</a>
                </li>
                <li>
                  <a class="nav-link-ref" routerLink=""
                    >Front-end Development</a
                  >
                </li>
                <li>
                  <a class="nav-link-ref" routerLink="">Back-end Development</a>
                </li>
                <li>
                  <a class="nav-link-ref" routerLink="">Saas App Development</a>
                </li>
                <li>
                  <a class="nav-link-ref" routerLink=""
                    >Ecommerce Development
                  </a>
                </li>
                <li>
                  <a class="nav-link-ref" routerLink="">Custom Development</a>
                </li>
                <!-- <li><a class="nav-link-ref" routerLink="/index-landing-four">Landing Four</a></li>
                <li><a class="nav-link-ref" routerLink="/index-personal">Personal</a></li>
                <li><a class="nav-link-ref" routerLink="/index-freelancer">Freelance</a></li>
                <li><a class="nav-link-ref" routerLink="/index-event">Event</a></li>
                <li><a class="nav-link-ref" routerLink="/index-ebook">E-Book</a></li>
                <li><a class="nav-link-ref" routerLink="/index-onepage">Saas <span
                      class="badge badge-pill bg-warning ms-2">Onepage</span></a> -->
              </ul>
            </li>
            <!-- <li>
              <ul>
                <li class="megamenu-head"><i class="uil uil-cube fs-6 align-middle"></i> Full Demos</li>
                <li><a class="nav-link-ref" routerLink="/index-corporate">Corporate Business</a></li>
                <li><a class="nav-link-ref" routerLink="/index-crypto">Cryptocurrency</a></li>
                <li><a class="nav-link-ref" routerLink="/index-shop">Shop</a></li>
                <li><a class="nav-link-ref" routerLink="/index-portfolio">Portfolio</a></li>
                <li><a class="nav-link-ref" routerLink="/helpcenter-overview">Helpcenter</a></li>
                <li><a class="nav-link-ref" routerLink="/index-hosting">Hosting & Domain</a></li>
                <li><a class="nav-link-ref" routerLink="/index-job">Job</a></li>
                <li><a class="nav-link-ref" routerLink="/forums">Forums</a></li>
                <li><a class="nav-link-ref" routerLink="/index-blog">Blog </a></li>
                <li><a class="nav-link-ref" routerLink="/index-nft">NFT Marketplace </a></li>
              </ul>
            </li> -->
          </ul>
        </li>
        <li class="has-submenu">
          <a
            class="text-white"
            href="javascript:void(0)"
            (click)="onMenuClick($event)"
            >Technologies </a
          ><span class="menu-arrow"></span>
          <ul class="submenu megamenu">
            <li>
              <ul>
                <li class="megamenu-head">
                  <i class="uil uil-book-open fs-6 align-middle"></i> Backend
                </li>
                <li><a class="nav-link-ref" routerLink="">.Net</a></li>
                <li><a class="nav-link-ref" routerLink="">.Net Core</a></li>
                <li><a class="nav-link-ref" routerLink="">PHP</a></li>
                <li><a class="nav-link-ref" routerLink="">Node.js</a></li>
                <li><a class="nav-link-ref" routerLink="">Python</a></li>
                <!-- <li><a class="nav-link-ref" routerLink="/index-studio">Studio</a></li>
                <li><a class="nav-link-ref" routerLink="/index-marketing">Marketing</a></li>
                <li><a class="nav-link-ref" routerLink="/index-enterprise">Enterprise</a></li>
                <li><a class="nav-link-ref" routerLink="/index-services">Service</a></li>
                <li><a class="nav-link-ref" routerLink="/index-payments">Payments</a></li>
                <li><a class="nav-link-ref" routerLink="/index-it-solution">IT Solution </a></li>
                <li><a class="nav-link-ref" routerLink="/index-it-solution-tzd">IT Solution tzd </a></li>
                <li><a class="nav-link-ref" routerLink="/index-it-solution-two">IT Solution Two</a></li> -->
              </ul>
            </li>

            <li>
              <ul>
                <li class="megamenu-head">
                  <i class="uil uil-book-open fs-6 align-middle"></i> Front End
                </li>
                <li><a class="nav-link-ref" routerLink="">Angular</a></li>
                <li><a class="nav-link-ref" routerLink="">Vue</a></li>
                <li><a class="nav-link-ref" routerLink="">React</a></li>
                <li><a class="nav-link-ref" routerLink="">Express JS</a></li>
                <li><a class="nav-link-ref" routerLink="">Javascript</a></li>
                <li><a class="nav-link-ref" routerLink="">Balzor</a></li>
                <!-- <li><a class="nav-link-ref" routerLink="/index-finance">Finance</a></li>
                <li><a class="nav-link-ref" routerLink="/index-logistics">Delivery & Logistics </a></li>
                <li><a class="nav-link-ref" routerLink="/index-social-marketing">Social Media</a></li>
                <li><a class="nav-link-ref" routerLink="/index-digital-agency">Digital Agency</a></li>
                <li><a class="nav-link-ref" routerLink="/index-customer">Customer</a></li>
                <li><a class="nav-link-ref" routerLink="/index-software">Software</a></li> -->
              </ul>
            </li>
            <li>
              <ul>
                <li class="megamenu-head">
                  <i class="uil uil-book-open fs-6 align-middle"></i> Mobile
                </li>
                <li><a class="nav-link-ref" routerLink="">iOS</a></li>
                <li><a class="nav-link-ref" routerLink="">Android</a></li>
                <li><a class="nav-link-ref" routerLink="">React Native</a></li>
                <li>
                  <a class="nav-link-ref" routerLink="">Flutter Conference</a>
                </li>
                <!-- <li><a class="nav-link-ref" routerLink="/index-blockchain">Blockchain</a></li>
                <li><a class="nav-link-ref" routerLink="/index-crypto-two">Cryptocurrency Two</a></li>
                <li><a class="nav-link-ref" routerLink="/index-integration">Integration</a></li>
                <li><a class="nav-link-ref" routerLink="/index-task-management">Task Management</a></li>
                <li><a class="nav-link-ref" routerLink="/index-email-inbox">Email Inbox </a></li>
                <li><a class="nav-link-ref" routerLink="/index-travel">Travel </a></li>
                <li><a class="nav-link-ref" routerLink="/index-course">Course</a></li>
                <li><a class="nav-link-ref" routerLink="/index-online-learning">Online Learning</a></li> -->
              </ul>
            </li>
            <li>
              <ul>
                <li class="megamenu-head">
                  <i class="uil uil-book-open fs-6 align-middle"></i>
                  Cloud/DevOps
                </li>
                <li><a class="nav-link-ref" routerLink="">AWS</a></li>
                <li><a class="nav-link-ref" routerLink="">Google</a></li>
                <li><a class="nav-link-ref" routerLink="">Azure</a></li>
                <!-- <li><a class="nav-link-ref" routerLink="/index-landing-one">Landing One</a></li>
                <li><a class="nav-link-ref" routerLink="/index-landing-two">Landing Two </a></li>
                <li><a class="nav-link-ref" routerLink="/index-landing-three">Landing Three</a></li>
                <li><a class="nav-link-ref" routerLink="/index-landing-four">Landing Four</a></li>
                <li><a class="nav-link-ref" routerLink="/index-personal">Personal</a></li>
                <li><a class="nav-link-ref" routerLink="/index-freelancer">Freelance</a></li>
                <li><a class="nav-link-ref" routerLink="/index-event">Event</a></li>
                <li><a class="nav-link-ref" routerLink="/index-ebook">E-Book</a></li>
                <li><a class="nav-link-ref" routerLink="/index-onepage">Saas <span
                      class="badge badge-pill bg-warning ms-2">Onepage</span></a> -->
              </ul>
            </li>
            <li>
              <ul>
                <li class="megamenu-head">
                  <i class="uil uil-cube fs-6 align-middle"></i> Ecommerce
                </li>
                <li><a class="nav-link-ref" routerLink="">Shopify</a></li>
                <li><a class="nav-link-ref" routerLink="">WooCommerce</a></li>
                <li><a class="nav-link-ref" routerLink="">NopCommerce</a></li>
                <!-- <li><a class="nav-link-ref" routerLink="/index-portfolio">Portfolio</a></li>
                <li><a class="nav-link-ref" routerLink="/helpcenter-overview">Helpcenter</a></li>
                <li><a class="nav-link-ref" routerLink="/index-hosting">Hosting & Domain</a></li>
                <li><a class="nav-link-ref" routerLink="/index-job">Job</a></li>
                <li><a class="nav-link-ref" routerLink="/forums">Forums</a></li>
                <li><a class="nav-link-ref" routerLink="/index-blog">Blog </a></li>
                <li><a class="nav-link-ref" routerLink="/index-nft">NFT Marketplace </a></li> -->
              </ul>
            </li>
            <li>
              <ul>
                <li class="megamenu-head">
                  <i class="uil uil-cube fs-6 align-middle"></i> CMS
                </li>
                <li><a class="nav-link-ref" routerLink="">Wordpress</a></li>
              </ul>
            </li>
          </ul>
        </li>

        <!-- <li class="has-submenu">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">Pages</a><span class="menu-arrow"></span>
          <ul class="submenu">
            <li class="has-submenu"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Company </a><span
                class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a class="nav-link-ref" routerLink="/page-aboutus"> About Us</a></li>
                <li><a class="nav-link-ref" routerLink="/page-aboutus-two"> About Us Two </a>
                </li>
                <li><a class="nav-link-ref" routerLink="/page-services">Services</a></li>
                <li><a class="nav-link-ref" routerLink="/page-history">History </a></li>
                <li><a class="nav-link-ref" routerLink="/page-team"> Team</a></li>
                <li><a class="nav-link-ref" routerLink="/page-pricing">Pricing</a></li>
              </ul>
            </li>
            <li class="has-submenu"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Account </a><span
                class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a class="nav-link-ref" routerLink="/account-profile">Profile</a></li>
                <li><a class="nav-link-ref" routerLink="/account-members">Members </a>
                </li>
                <li><a class="nav-link-ref" routerLink="/account-works">Works </a></li>
                <li><a class="nav-link-ref" routerLink="/account-messages">Messages </a>
                </li>
                <li><a class="nav-link-ref" routerLink="/account-chat">chat </a></li>
                <li><a class="nav-link-ref" routerLink="/account-payments">Payments </a>
                </li>
                <li><a class="nav-link-ref" routerLink="/account-setting">Setting</a></li>
                <li><a class="nav-link-ref" routerLink="/page-invoice">Invoice</a></li>
              </ul>
            </li>
            <li class="has-submenu parent-menu-item"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Email
                Template</a><span class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a routerLink="/email-confirmation" class="sub-menu-item">Confirmation</a></li>
                <li><a routerLink="/email-password-reset" class="sub-menu-item">Reset Password</a></li>
                <li><a routerLink="/email-alert" class="sub-menu-item">Alert</a></li>
                <li><a routerLink="/email-invoice" class="sub-menu-item">Invoice</a></li>
              </ul>
            </li>
            <li class="has-submenu"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Blog </a><span
                class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a class="nav-link-ref" routerLink="/page-blog-grid">Blog Grid</a></li>
                <li><a class="nav-link-ref" routerLink="/page-blog-sidebar">Blog with Sidebar</a></li>
                <li><a class="nav-link-ref" routerLink="/page-blog-list">Blog Listing</a></li>
                <li><a class="nav-link-ref" routerLink="/page-blog-list-sidebar">Blog List & Sidebar</a></li>
                <li><a class="nav-link-ref" routerLink="/page-blog-detail">Blog Detail</a></li>
                <li><a class="nav-link-ref" routerLink="/page-blog-detail-two">Blog Detail 2 </a></li>
              </ul>
            </li>

            <li class="has-submenu"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Case Study </a><span
                class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a class="nav-link-ref" routerLink="/page-cases">All Cases </a></li>
                <li><a class="nav-link-ref" routerLink="/page-case-detail">Case Detail </a></li>
              </ul>
            </li>
            <li><a href="/course-detail" class="nav-link-ref">Course Detail </a></li>
            <li class="has-submenu parent-menu-item"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Auth
                Pages </a>
              <span class="submenu-arrow"></span>
              <ul class="submenu">
                <li class="has-submenu parent-menu-item"><a href="javascript:void(0)"> Login </a>
                  <ul class="submenu">
                    <li><a routerLink="/auth-login" class="sub-menu-item">Login</a></li>
                    <li><a routerLink="/auth-cover-login" class="sub-menu-item">Login Cover</a></li>
                    <li><a routerLink="/auth-login-three" class="sub-menu-item">Login Simple</a></li>
                    <li><a routerLink="/auth-bs-login" class="sub-menu-item">BS5 Login</a></li>
                    <li><a routerLink="/auth-login-bg-video" class="sub-menu-item">Login Five </a></li>
                  </ul>
                </li>

                <li class="has-submenu parent-menu-item"><a routerLink="/auth-signup"> Signup </a>
                  <ul class="submenu">
                    <li><a routerLink="/auth-signup" class="sub-menu-item">Signup</a></li>
                    <li><a routerLink="/auth-cover-signup" class="sub-menu-item">Signup Cover</a></li>
                    <li><a routerLink="/auth-signup-three" class="sub-menu-item">Signup Simple</a></li>
                    <li><a routerLink="/auth-bs-signup" class="sub-menu-item">BS5 Singup</a></li>
                    <li><a routerLink="/auth-signup-bg-video" class="sub-menu-item">Singup Five </a></li>
                  </ul>
                </li>

                <li class="has-submenu parent-menu-item"><a href="javascript:void(0)"> Reset password </a>
                  <ul class="submenu">
                    <li><a routerLink="/auth-re-password" class="sub-menu-item">Reset Password</a></li>
                    <li><a routerLink="/auth-cover-re-password" class="sub-menu-item">Reset Password Cover</a></li>
                    <li><a routerLink="/auth-re-password-three" class="sub-menu-item">Reset Password Simple</a></li>
                    <li><a routerLink="/auth-bs-reset" class="sub-menu-item">BS5 Reset Password</a></li>
                    <li><a routerLink="/auth-reset-password-bg-video" class="sub-menu-item">Reset Pass Five </a></li>
                  </ul>
                </li>
              </ul>
            </li>
            <li class="has-submenu"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Utility </a><span
                class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a class="nav-link-ref" routerLink="/page-terms">Terms of Services</a></li>
                <li><a class="nav-link-ref" routerLink="/page-privacy">Privacy Policy</a></li>
              </ul>
            </li>
            <li class="has-submenu"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Special </a><span
                class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a class="nav-link-ref" routerLink="/page-comingsoon">Coming Soon</a></li>
                <li><a class="nav-link-ref" routerLink="/page-comingsoon2">Coming Soon Two</a></li>
                <li><a class="nav-link-ref" routerLink="/page-maintenance">Maintenance</a></li>
                <li><a class="nav-link-ref" routerLink="/page-error">Error</a></li>
                <li><a class="nav-link-ref" routerLink="/page-thankyou">Thank you</a></li>
              </ul>
            </li>
            <li class="has-submenu"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Contact </a><span
                class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a class="nav-link-ref" routerLink="/page-contact-detail">Contact Detail</a></li>
                <li><a class="nav-link-ref" routerLink="/page-contact-one">Contact One</a></li>
                <li><a class="nav-link-ref" routerLink="/page-contact-two">Contact Two</a></li>
                <li><a class="nav-link-ref" routerLink="/page-contact-three">Contact Three</a></li>
              </ul>
            </li>
            <li class="has-submenu parent-menu-item"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Multi
                Level Menu</a><span class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a href="javascript:void(0)" class="sub-menu-item">Level 1.0</a></li>
                <li class="has-submenu parent-menu-item"><a href="javascript:void(0)"> Level 2.0 </a><span
                    class="submenu-arrow"></span>
                  <ul class="submenu">
                    <li><a href="javascript:void(0)" class="sub-menu-item">Level 2.1</a></li>
                    <li><a href="javascript:void(0)" class="sub-menu-item">Level 2.2</a></li>
                  </ul>
                </li>
              </ul>
            </li>
            <li><a routerLink="/page-footer" class="sub-menu-item">Footer Layouts </a></li>
          </ul>
        </li> -->

        <li class="has-submenu parent-parent-menu-item">
          <a
            class="text-white"
            href="javascript:void(0)"
            (click)="onMenuClick($event)"
            >Work</a
          ><span class="menu-arrow"></span>
          <ul class="submenu megamenu">
            <li>
              <ul>
                <li>
                  <a class="sub-menu-item">
                    <div class="text-lg-center">
                      <!-- <span class="d-none d-lg-block"><img src="assets/images/demos/corporate.png"
                          class="img-fluid rounded shadow-md" alt=""></span> -->
                      <span class="mt-lg-2 d-block">ERP</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a class="sub-menu-item">
                    <div class="text-lg-center">
                      <!-- <span class="d-none d-lg-block"><img src="assets/images/demos/crypto.png"
                          class="img-fluid rounded shadow-md" alt=""></span> -->
                      <span class="mt-lg-2 d-block">Finance</span>
                    </div>
                  </a>
                </li>
              </ul>
            </li>

            <li>
              <ul>
                <li>
                  <a class="sub-menu-item">
                    <div class="text-lg-center">
                      <!-- <span class="d-none d-lg-block"><img src="assets/images/demos/shop.png"
                          class="img-fluid rounded shadow-md" alt=""></span> -->
                      <span class="mt-lg-2 d-block">Ecommarce</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a class="sub-menu-item">
                    <div class="text-lg-center">
                      <!-- <span class="d-none d-lg-block"><img src="assets/images/demos/portfolio.png"
                          class="img-fluid rounded shadow-md" alt=""></span> -->
                      <span class="mt-lg-2 d-block">Chat</span>
                    </div>
                  </a>
                </li>
              </ul>
            </li>

            <li>
              <ul>
                <li>
                  <a class="sub-menu-item">
                    <div class="text-lg-center">
                      <!-- <span class="d-none d-lg-block"><img src="assets/images/demos/help-center.png"
                          class="img-fluid rounded shadow-md" alt=""></span> -->
                      <span class="mt-lg-2 d-block">Medical Alliance</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a class="sub-menu-item">
                    <div class="text-lg-center">
                      <!-- <span class="d-none d-lg-block"><img src="assets/images/demos/hosting.png"
                          class="img-fluid rounded shadow-md" alt=""></span> -->
                      <span class="mt-lg-2 d-block">Accounting</span>
                    </div>
                  </a>
                </li>
              </ul>
            </li>

            <li>
              <ul>
                <li>
                  <a class="sub-menu-item">
                    <div class="text-lg-center">
                      <!-- <span class="d-none d-lg-block"><img src="assets/images/demos/job.png"
                          class="img-fluid rounded shadow-md" alt=""></span> -->
                      <span class="mt-lg-2 d-block">GST/TDS</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a class="sub-menu-item">
                    <div class="text-lg-center">
                      <!-- <span class="d-none d-lg-block"><img src="assets/images/demos/forums.png"
                          class="img-fluid rounded shadow-md" alt=""></span> -->
                      <span class="mt-lg-2 d-block">Travel Management</span>
                    </div>
                  </a>
                </li>
              </ul>
            </li>

            <li>
              <ul>
                <li>
                  <a class="sub-menu-item">
                    <div class="text-lg-center">
                      <!-- <span class="d-none d-lg-block"><img src="assets/images/demos/blog.png"
                          class="img-fluid rounded shadow-md" alt=""></span> -->
                      <span class="mt-lg-2 d-block">Health sector</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a class="sub-menu-item">
                    <div class="text-lg-center">
                      <!-- <span class="d-none d-lg-block"><img src="assets/images/demos/nft.png"
                          class="img-fluid rounded shadow-md" alt=""></span> -->
                      <span class="mt-lg-2 d-block">NFT Marketplace</span>
                    </div>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <!-- <li class="has-submenu">
          <a
            class="text-white"
            href="javascript:void(0)"
            (click)="onMenuClick($event)"
            >Products</a
          ><span class="menu-arrow"></span>
          <ul class="submenu">
                <li class="has-submenu">
                  <a class="nav-link-ref" routerLink="/products/erp">ERP
                  </a>
                  <a class="nav-link-ref" routerLink="/products/erp">CRM
                  </a>
                  <a class="nav-link-ref" routerLink="/products/erp">Inventory
                  </a>
                </li> -->
                <!-- <li>
                  <a class="sub-menu-item">
                    <div class="text-lg-center">
                      <span class="mt-lg-2 d-block">Finance</span>
                    </div>
                  </a>
                </li> -->


            <!-- <li>
              <ul>
                <li>
                  <a class="sub-menu-item">
                    <div class="text-lg-center">
                      <span class="mt-lg-2 d-block">Ecommarce</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a class="sub-menu-item">
                    <div class="text-lg-center">
                      <span class="mt-lg-2 d-block">Chat</span>
                    </div>
                  </a>
                </li>
              </ul>
            </li> -->

            <!-- <li>
              <ul>
                <li>
                  <a class="sub-menu-item">
                    <div class="text-lg-center">

                      <span class="mt-lg-2 d-block">Medical Alliance</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a class="sub-menu-item">
                    <div class="text-lg-center">
  
                      <span class="mt-lg-2 d-block">Accounting</span>
                    </div>
                  </a>
                </li>
              </ul>
            </li> -->

            <!-- <li>
              <ul>
                <li>
                  <a class="sub-menu-item">
                    <div class="text-lg-center">

                      <span class="mt-lg-2 d-block">GST/TDS</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a class="sub-menu-item">
                    <div class="text-lg-center">

                      <span class="mt-lg-2 d-block">Travel Management</span>
                    </div>
                  </a>
                </li>
              </ul>
            </li> -->

            <!-- <li>
              <ul>
                <li>
                  <a class="sub-menu-item">
                    <div class="text-lg-center">
 
                      <span class="mt-lg-2 d-block">Health sector</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a class="sub-menu-item">
                    <div class="text-lg-center">

                      <span class="mt-lg-2 d-block">NFT Marketplace</span>
                    </div>
                  </a>
                </li>
              </ul>
            </li> -->
          <!-- </ul>
        </li> -->

        <!-- <li class="has-submenu parent-parent-menu-item">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">Components</a><span class="menu-arrow"></span>
          <ul class="submenu megamenu">
            <li>
              <ul>
                <li><a routerLink="/ui-button" class="nav-link-ref"><i class="uil uil-cube fs-6 align-middle me-1"></i>
                    Buttons</a></li>
                <li><a routerLink="/ui-badges" class="nav-link-ref"><i class="uil uil-award fs-6 align-middle me-1"></i>
                    Badges</a></li>
                <li><a routerLink="/ui-alert" class="nav-link-ref"><i
                      class="uil uil-info-circle fs-6 align-middle me-1"></i> Alert</a></li>
                <li><a routerLink="/ui-dropdown" class="nav-link-ref"><i
                      class="uil uil-layers fs-6 align-middle me-1"></i> Dropdowns</a></li>
                <li><a routerLink="/ui-typography" class="nav-link-ref"><i
                      class="uil uil-align-center-alt fs-6 align-middle me-1"></i> Typography</a></li>
              </ul>
            </li>

            <li>
              <ul>
                <li><a routerLink="/ui-background" class="nav-link-ref"><i
                      class="uil uil-palette fs-6 align-middle me-1"></i> Background</a></li>
                <li><a routerLink="/ui-text" class="nav-link-ref"><i class="uil uil-text fs-6 align-middle me-1"></i>
                    Text Color</a></li>
                <li><a routerLink="/ui-accordion" class="nav-link-ref"><i
                      class="uil uil-list-ui-alt fs-6 align-middle me-1"></i> Accordions</a></li>
                <li><a routerLink="/ui-card" class="nav-link-ref"><i
                      class="uil uil-postcard fs-6 align-middle me-1"></i>
                    Cards</a></li>
                <li><a routerLink="/ui-tooltip-popover" class="nav-link-ref"><i
                      class="uil uil-backspace fs-6 align-middle me-1"></i> Tooltips & Popovers</a></li>
              </ul>
            </li>

            <li>
              <ul>
                <li><a routerLink="/ui-shadow" class="nav-link-ref"><i
                      class="uil uil-square-full fs-6 align-middle me-1"></i> Shadows</a></li>
                <li><a routerLink="/ui-border" class="nav-link-ref"><i
                      class="uil uil-border-out fs-6 align-middle me-1"></i> Border</a></li>
                <li><a routerLink="/ui-carousel" class="nav-link-ref"><i
                      class="uil uil-slider-h-range fs-6 align-middle me-1"></i> Carousel</a></li>
                <li><a routerLink="/ui-form" class="nav-link-ref"><i class="uil uil-notes fs-6 align-middle me-1"></i>
                    Form Elements</a></li>
                <li><a routerLink="/ui-breadcrumb" class="nav-link-ref"><i
                      class="uil uil-sort-amount-down fs-6 align-middle me-1"></i> Breadcrumb</a></li>
              </ul>
            </li>

            <li>
              <ul>
                <li><a routerLink="ui-pagination" class="nav-link-ref"><i
                      class="uil uil-copy fs-6 align-middle me-1"></i> Pagination</a></li>
                <li><a routerLink="/ui-avatar" class="nav-link-ref"><i class="uil uil-image fs-6 align-middle me-1"></i>
                    Avatars</a></li>
                <li><a routerLink="/ui-nav-tabs" class="nav-link-ref"><i
                      class="uil uil-bars fs-6 align-middle me-1"></i>
                    Nav Tabs</a></li>
                <li><a routerLink="/ui-modals" class="nav-link-ref"><i
                      class="uil uil-vector-square fs-6 align-middle me-1"></i> Modals</a></li>
              </ul>
            </li>

            <li>
              <ul>
                <li><a routerLink="/ui-tables" class="nav-link-ref"><i class="uil uil-table fs-6 align-middle me-1"></i>
                    Tables</a></li>
                <li><a routerLink="/ui-icons" class="nav-link-ref"><i class="uil uil-icons fs-6 align-middle me-1"></i>
                    Icons</a></li>
                <li><a routerLink="/ui-progressbar" class="nav-link-ref"><i
                      class="uil uil-brackets-curly fs-6 align-middle me-1"></i> Progressbar</a></li>
                <li><a routerLink="/ui-lightbox" class="nav-link-ref"><i
                      class="uil uil-play-circle fs-6 align-middle me-1"></i> Lightbox</a></li>
              </ul>
            </li>
          </ul>
        </li> -->
        <li>
          <a routerLink="/page-blog-grid" class="nav-link-ref text-white">
            Blog
          </a>
        </li>

        <li>
          <a routerLink="/page-contact-two" class="nav-link-ref text-white">
            Contact Us
          </a>
        </li>
        <li>
          <a routerLink="/page-aboutus-two" class="nav-link-ref text-white">
            About Us
          </a>
        </li>
      </ul>
      <!--end navigation menu-->
      <!-- <div class="buy-menu-btn d-none">
        <a href="javascript: void(0);" target="_blank" class="btn btn-primary">Buy Now</a>
      </div> -->
      <!--end login button-->
      <!--end login button-->
    </div>
    <!--end navigation-->

    <div id="navigation" *ngIf="Menuoption == 'shop'">
      <!-- Navigation Menu-->
      <ul class="navigation-menu" [class]="navClass">
        <li><a routerLink="/index-shop" class="nav-link-ref">Home</a></li>

        <li>
          <a routerLink="/shop-aboutus" class="nav-link-ref"> About Us</a>
        </li>

        <li class="has-submenu parent-menu-item">
          <a href="javascript:void(0)">Shop</a><span class="menu-arrow"></span>
          <ul class="submenu">
            <li>
              <a routerLink="/shop-fullwidth-grids" class="nav-link-ref"
                >Fullwidth Grid</a
              >
            </li>
            <li>
              <a routerLink="/shop-grids" class="nav-link-ref">Product Grids</a>
            </li>
            <li>
              <a routerLink="/shop-fullwidth-lists" class="nav-link-ref"
                >Fullwidth List</a
              >
            </li>
            <li>
              <a routerLink="/shop-lists" class="nav-link-ref">Product List</a>
            </li>
            <li>
              <a routerLink="/shop-product-detail" class="nav-link-ref"
                >Product Details</a
              >
            </li>
            <li>
              <a routerLink="/shop-cart" class="nav-link-ref">Shop Cart</a>
            </li>
            <li>
              <a routerLink="/shop-checkouts" class="nav-link-ref">Checkouts</a>
            </li>
            <li>
              <a routerLink="/shop-myaccount" class="nav-link-ref"
                >My Account</a
              >
            </li>
          </ul>
        </li>

        <li class="has-submenu parent-menu-item">
          <a href="javascript:void(0)">Pages</a><span class="menu-arrow"></span>
          <ul class="submenu">
            <li><a routerLink="/auth-login" class="nav-link-ref">Login</a></li>
            <li>
              <a routerLink="/auth-signup" class="nav-link-ref">Signup</a>
            </li>
            <li>
              <a routerLink="/auth-re-password" class="nav-link-ref"
                >Reset Password</a
              >
            </li>
            <li>
              <a routerLink="/page-comingsoon" class="nav-link-ref"
                >Coming Soon</a
              >
            </li>
            <li>
              <a routerLink="/page-maintenance" class="nav-link-ref"
                >Maintenance</a
              >
            </li>
            <li><a routerLink="/page-error" class="nav-link-ref">Error</a></li>
            <li>
              <a routerLink="/page-thankyou" class="nav-link-ref">Thank you</a>
            </li>
          </ul>
        </li>

        <li class="has-submenu parent-menu-item">
          <a href="javascript:void(0)">Blog</a><span class="menu-arrow"></span>
          <ul class="submenu">
            <li>
              <a routerLink="/shop-blog" class="nav-link-ref">Blog Grid</a>
            </li>
            <li>
              <a routerLink="/shop-blog-detail" class="nav-link-ref"
                >Blog Detail</a
              >
            </li>
          </ul>
        </li>
      </ul>
      <!--end navigation menu-->
    </div>
    <!--end navigation-->

    <div id="navigation" *ngIf="Menuoption == 'blog'">
      <!-- Navigation Menu-->
      <ul class="navigation-menu" [class]="navClass">
        <li><a routerLink="/index-blog" class="nav-link-ref">Home</a></li>

        <li><a routerLink="/blog-about" class="nav-link-ref">About</a></li>

        <li class="has-submenu parent-menu-item">
          <a href="javascript:void(0)">Post</a><span class="menu-arrow"></span>
          <ul class="submenu">
            <li>
              <a routerLink="/blog-standard-post" class="nav-link-ref"
                >Standard Post</a
              >
            </li>
            <li>
              <a routerLink="/blog-slider-post" class="nav-link-ref"
                >Slider Post</a
              >
            </li>
            <li>
              <a routerLink="/blog-gallery-post" class="nav-link-ref"
                >Gallery Post</a
              >
            </li>
            <li>
              <a routerLink="/blog-youtube-post" class="nav-link-ref"
                >Youtube Post</a
              >
            </li>
            <li>
              <a routerLink="/blog-vimeo-post" class="nav-link-ref"
                >Vimeo Post</a
              >
            </li>
            <li>
              <a routerLink="/blog-audio-post" class="nav-link-ref"
                >Audio Post</a
              >
            </li>
            <li>
              <a routerLink="/blog-blockquote-post" class="nav-link-ref"
                >Blockquote</a
              >
            </li>
            <li>
              <a routerLink="/blog-left-sidebar-post" class="nav-link-ref"
                >Left Sidebar</a
              >
            </li>
          </ul>
        </li>

        <li><a href="javascript:void(0)" class="nav-link-ref">Lifestyle</a></li>

        <li>
          <a href="javascript:void(0)" class="nav-link-ref">Technology</a>
        </li>
      </ul>
      <!--end navigation menu-->
    </div>
    <!--end navigation-->

    <div id="navigation" *ngIf="Menuoption == 'corporate'">
      <!-- Navigation Menu-->
      <ul class="navigation-menu" [class]="navClass">
        <li><a routerLink="/index-corporate" class="nav-link-ref">Home</a></li>
        <li>
          <a routerLink="/corporate-about" class="nav-link-ref"> About Us</a>
        </li>
        <li>
          <a routerLink="/corporate-services" class="nav-link-ref">Services</a>
        </li>
        <li class="has-submenu parent-menu-item">
          <a href="javascript:void(0)">Pages</a><span class="menu-arrow"></span>
          <ul class="submenu">
            <li>
              <a routerLink="/corporate-team" class="nav-link-ref"> Team</a>
            </li>
            <li>
              <a routerLink="/corporate-pricing" class="nav-link-ref"
                >Pricing</a
              >
            </li>
            <li class="has-submenu parent-menu-item">
              <a href="javascript:void(0)"> Blog </a
              ><span class="submenu-arrow"></span>
              <ul class="submenu">
                <li>
                  <a routerLink="/corporate-blog" class="nav-link-ref">
                    Blog
                  </a>
                </li>
                <li>
                  <a routerLink="/corporate-blog-detail" class="nav-link-ref"
                    >Blog Detail</a
                  >
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <!--end navigation menu-->
    </div>
    <!--end navigation-->

    <div id="navigation" *ngIf="Menuoption == 'cryptocurrency'">
      <!-- Navigation Menu-->
      <ul class="navigation-menu nav-right" [class]="navClass">
        <li><a routerLink="/index-crypto" class="nav-link-ref">Home</a></li>
        <li>
          <a routerLink="/crypto-about" class="nav-link-ref"> About Us</a>
        </li>
        <li><a routerLink="/crypto-market" class="nav-link-ref">Market</a></li>
        <li><a routerLink="/crypto-token" class="nav-link-ref"> Token</a></li>
        <li class="has-submenu parent-parent-menu-item">
          <a href="javascript:void(0)">Pages</a><span class="menu-arrow"></span>
          <ul class="submenu">
            <li>
              <a routerLink="/crypto-services" class="nav-link-ref">
                Features</a
              >
            </li>
            <li><a routerLink="/crypto-faqs" class="nav-link-ref">FAQs </a></li>
            <li>
              <a routerLink="/crypto-whitepaper" class="nav-link-ref"
                >Whitepaper
              </a>
            </li>
            <li class="has-submenu parent-menu-item">
              <a href="javascript:void(0)"> Blog </a
              ><span class="submenu-arrow"></span>
              <ul class="submenu">
                <li>
                  <a routerLink="/crypto-blog" class="nav-link-ref"> Blog </a>
                </li>
                <li>
                  <a routerLink="/crypto-blog-detail" class="nav-link-ref"
                    >Blog Detail</a
                  >
                </li>
              </ul>
            </li>
            <li class="has-submenu parent-menu-item">
              <a href="javascript:void(0)"> Auth pages </a
              ><span class="submenu-arrow"></span>
              <ul class="submenu">
                <li>
                  <a routerLink="/auth-bs-login" class="nav-link-ref">Login</a>
                </li>
                <li>
                  <a routerLink="/auth-bs-signup" class="nav-link-ref"
                    >Signup</a
                  >
                </li>
                <li>
                  <a routerLink="/auth-bs-reset" class="nav-link-ref"
                    >Reset Password</a
                  >
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <!--end navigation menu-->
    </div>
    <!--end navigation-->

    <div id="navigation" *ngIf="Menuoption == 'hosting'">
      <!-- Navigation Menu-->
      <ul class="navigation-menu" [class]="navClass">
        <li><a routerLink="/index-hosting" class="nav-link-ref">Home</a></li>

        <li><a routerLink="/hosting-domain" class="nav-link-ref">Domain</a></li>

        <li class="has-submenu parent-menu-item">
          <a href="javascript:void(0)">Hosting</a
          ><span class="menu-arrow"></span>
          <ul class="submenu">
            <li>
              <a routerLink="/hosting-shared" class="nav-link-ref"
                >Shared Hosting</a
              >
            </li>
            <li>
              <a routerLink="/hosting-vps" class="nav-link-ref">VPS Hosting</a>
            </li>
            <li>
              <a routerLink="/hosting-dedicated" class="nav-link-ref"
                >Dedicated Hosting</a
              >
            </li>
            <li>
              <a routerLink="/hosting-cloud" class="nav-link-ref"
                >Cloud Hosting</a
              >
            </li>
            <li>
              <a routerLink="/hosting-reseller" class="nav-link-ref"
                >Reseller Hosting</a
              >
            </li>
          </ul>
        </li>

        <li class="has-submenu parent-parent-menu-item">
          <a href="javascript:void(0)">Pages</a><span class="menu-arrow"></span>
          <ul class="submenu">
            <li>
              <a routerLink="/hosting-aboutus" class="nav-link-ref">
                About Us</a
              >
            </li>
            <li>
              <a routerLink="/hosting-services" class="nav-link-ref">
                Services</a
              >
            </li>
            <li>
              <a routerLink="/hosting-faqs" class="nav-link-ref"> FAQs</a>
            </li>
            <li class="has-submenu parent-menu-item">
              <a href="javascript:void(0)"> Blog </a
              ><span class="submenu-arrow"></span>
              <ul class="submenu">
                <li>
                  <a routerLink="/hosting-blog" class="nav-link-ref"> Blogs </a>
                </li>
                <li>
                  <a routerLink="/hosting-blog-detail" class="nav-link-ref"
                    >Blog Details</a
                  >
                </li>
              </ul>
            </li>
            <li class="has-submenu parent-menu-item">
              <a href="javascript:void(0)"> Auth pages </a
              ><span class="submenu-arrow"></span>
              <ul class="submenu">
                <li>
                  <a routerLink="/auth-cover-login" class="nav-link-ref"
                    >Login</a
                  >
                </li>
                <li>
                  <a routerLink="/auth-cover-signup" class="nav-link-ref"
                    >Signup</a
                  >
                </li>
                <li>
                  <a routerLink="/auth-cover-re-password" class="nav-link-ref"
                    >Reset Password</a
                  >
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <!--end navigation menu-->
    </div>
    <!--end navigation-->

    <div id="navigation" *ngIf="Menuoption == 'job'">
      <!-- Navigation Menu-->
      <ul class="navigation-menu" [class]="navClass">
        <li><a routerLink="/index-job" class="nav-link-ref">Home</a></li>

        <li class="has-submenu parent-parent-menu-item">
          <a href="javascript:void(0)">Jobs</a><span class="menu-arrow"></span>
          <ul class="submenu">
            <li>
              <a routerLink="/job-list-one" class="nav-link-ref">
                Job List One</a
              >
            </li>
            <li>
              <a routerLink="/job-list-two" class="nav-link-ref">
                Job List Two</a
              >
            </li>
            <li>
              <a routerLink="/job-list-three" class="nav-link-ref">
                Job List Three</a
              >
            </li>
            <li>
              <a routerLink="/job-list-four" class="nav-link-ref">
                Job List Four</a
              >
            </li>
            <li>
              <a routerLink="/job-list-five" class="nav-link-ref">
                Job List Five</a
              >
            </li>
            <li class="has-submenu parent-menu-item">
              <a href="javascript:void(0)"> Job Detail </a
              ><span class="submenu-arrow"></span>
              <ul class="submenu">
                <li>
                  <a routerLink="/job-detail-one" class="nav-link-ref">
                    Job Detail One</a
                  >
                </li>
                <li>
                  <a routerLink="/job-detail-two" class="nav-link-ref">
                    Job Detail Two</a
                  >
                </li>
                <li>
                  <a routerLink="/job-detail-three" class="nav-link-ref">
                    Job Detail Three</a
                  >
                </li>
              </ul>
            </li>
            <li>
              <a routerLink="/job-apply" class="nav-link-ref"> Job Apply</a>
            </li>
          </ul>
        </li>

        <li class="has-submenu parent-menu-item">
          <a href="javascript:void(0)">Candidate</a
          ><span class="menu-arrow"></span>
          <ul class="submenu">
            <li>
              <a routerLink="/job-candidate-list" class="nav-link-ref"
                >Candidate Listing</a
              >
            </li>
            <li>
              <a routerLink="/job-candidate" class="nav-link-ref"
                >Candidate Detail</a
              >
            </li>
          </ul>
        </li>

        <li class="has-submenu parent-menu-item">
          <a href="javascript:void(0)">Company</a
          ><span class="menu-arrow"></span>
          <ul class="submenu">
            <li>
              <a routerLink="/job-company-list" class="nav-link-ref"
                >Company Listing</a
              >
            </li>
            <li>
              <a routerLink="/job-company" class="nav-link-ref"
                >Company Detail</a
              >
            </li>
          </ul>
        </li>

        <li class="has-submenu parent-parent-menu-item">
          <a href="javascript:void(0)">Pages</a><span class="menu-arrow"></span>
          <ul class="submenu">
            <li>
              <a routerLink="/job-about" class="nav-link-ref">About us</a>
            </li>
            <li><a routerLink="/job-price" class="nav-link-ref">Pricing</a></li>
            <li><a routerLink="/job-faqs" class="nav-link-ref">FAQs</a></li>
            <li class="has-submenu parent-menu-item">
              <a href="javascript:void(0)">Authentication</a
              ><span class="submenu-arrow"></span>
              <ul class="submenu">
                <li>
                  <a routerLink="auth-bs-login" class="nav-link-ref">Login</a>
                </li>
                <li>
                  <a routerLink="auth-bs-signup" class="nav-link-ref">Signup</a>
                </li>
                <li>
                  <a routerLink="auth-bs-reset" class="nav-link-ref"
                    >Reset Password</a
                  >
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <!--end navigation menu-->
    </div>
    <!--end navigation-->

    <div id="navigation" *ngIf="Menuoption == 'nft'">
      <!-- Navigation Menu-->
      <ul class="navigation-menu" [class]="navClass">
        <li><a routerLink="/index-nft" class="nav-link-ref">Home</a></li>

        <li><a routerLink="/nft-explore" class="nav-link-ref"> Explore</a></li>

        <li>
          <a routerLink="/nft-creators" class="nav-link-ref"> Creators</a>
        </li>

        <li class="has-submenu parent-menu-item">
          <a href="javascript:void(0)">Pages</a><span class="menu-arrow"></span>
          <ul class="submenu">
            <li>
              <a routerLink="/auth-login-bg-video" class="nav-link-ref"
                >Login</a
              >
            </li>
            <li>
              <a routerLink="/auth-signup-bg-video" class="nav-link-ref"
                >Signup</a
              >
            </li>
            <li>
              <a routerLink="/auth-reset-password-bg-video" class="nav-link-ref"
                >Reset Password</a
              >
            </li>
            <li>
              <a routerLink="/page-comingsoon" class="nav-link-ref"
                >Coming Soon</a
              >
            </li>
            <li>
              <a routerLink="/page-maintenance" class="nav-link-ref"
                >Maintenance</a
              >
            </li>
            <li><a routerLink="/page-error" class="nav-link-ref">Error</a></li>
            <li>
              <a routerLink="/page-thankyou" class="nav-link-ref">Thank you</a>
            </li>
          </ul>
        </li>

        <li>
          <a routerLink="/helpcenter-overview" class="nav-link-ref">
            Help Center</a
          >
        </li>

        <li>
          <a routerLink="/page-contact-one" class="nav-link-ref"> Contact</a>
        </li>
      </ul>
      <!--end navigation menu-->
    </div>
    <!--end navigation-->

    <div id="navigation" *ngIf="Menuoption == 'portfolio'">
      <!-- Navigation Menu-->
      <ul class="navigation-menu" [class]="navClass">
        <li><a routerLink="/index-portfolio" class="nav-link-ref">Home</a></li>

        <li class="has-submenu parent-parent-menu-item">
          <a href="javascript:void(0)">Portfolio</a
          ><span class="menu-arrow"></span>
          <ul class="submenu megamenu">
            <li>
              <ul>
                <li class="megamenu-head">Modern Portfolio</li>
                <li>
                  <a routerLink="/portfolio-modern-two" class="nav-link-ref"
                    >Two Column</a
                  >
                </li>
                <li>
                  <a routerLink="/portfolio-modern-three" class="nav-link-ref"
                    >Three Column</a
                  >
                </li>
                <li>
                  <a routerLink="/portfolio-modern-four" class="nav-link-ref"
                    >Four Column</a
                  >
                </li>
                <li>
                  <a routerLink="/portfolio-modern-five" class="nav-link-ref"
                    >Five Column</a
                  >
                </li>
                <li>
                  <a routerLink="/portfolio-modern-six" class="nav-link-ref"
                    >Six Column</a
                  >
                </li>
              </ul>
            </li>

            <li>
              <ul>
                <li class="megamenu-head">Classic Portfolio</li>
                <li>
                  <a routerLink="/portfolio-classic-two" class="nav-link-ref"
                    >Two Column</a
                  >
                </li>
                <li>
                  <a routerLink="/portfolio-classic-three" class="nav-link-ref"
                    >Three Column</a
                  >
                </li>
                <li>
                  <a routerLink="/portfolio-classic-four" class="nav-link-ref"
                    >Four Column</a
                  >
                </li>
                <li>
                  <a routerLink="/portfolio-classic-five" class="nav-link-ref"
                    >Five Column</a
                  >
                </li>
                <li>
                  <a routerLink="/portfolio-classic-six" class="nav-link-ref"
                    >Six Column</a
                  >
                </li>
              </ul>
            </li>

            <li>
              <ul>
                <li class="megamenu-head">Grid Portfolio</li>
                <li>
                  <a routerLink="/portfolio-grid-two" class="nav-link-ref"
                    >Two Column</a
                  >
                </li>
                <li>
                  <a routerLink="/portfolio-grid-three" class="nav-link-ref"
                    >Three Column</a
                  >
                </li>
                <li>
                  <a routerLink="/portfolio-grid-four" class="nav-link-ref"
                    >Four Column</a
                  >
                </li>
                <li>
                  <a routerLink="/portfolio-grid-five" class="nav-link-ref"
                    >Five Column</a
                  >
                </li>
                <li>
                  <a routerLink="/portfolio-grid-six" class="nav-link-ref"
                    >Six Column</a
                  >
                </li>
              </ul>
            </li>

            <li>
              <ul>
                <li class="megamenu-head">Masonry Portfolio</li>
                <li>
                  <a routerLink="/portfolio-masonry-two" class="nav-link-ref"
                    >Two Column</a
                  >
                </li>
                <li>
                  <a routerLink="/portfolio-masonry-three" class="nav-link-ref"
                    >Three Column</a
                  >
                </li>
                <li>
                  <a routerLink="/portfolio-masonry-four" class="nav-link-ref"
                    >Four Column</a
                  >
                </li>
                <li>
                  <a routerLink="/portfolio-masonry-five" class="nav-link-ref"
                    >Five Column</a
                  >
                </li>
                <li>
                  <a routerLink="/portfolio-masonry-six" class="nav-link-ref"
                    >Six Column</a
                  >
                </li>
              </ul>
            </li>

            <li>
              <ul>
                <li class="megamenu-head">Portfolio Detail</li>
                <li>
                  <a routerLink="/portfolio-detail-one" class="nav-link-ref"
                    >Portfolio One</a
                  >
                </li>
                <li>
                  <a routerLink="/portfolio-detail-two" class="nav-link-ref"
                    >Portfolio Two</a
                  >
                </li>
                <li>
                  <a routerLink="/portfolio-detail-three" class="nav-link-ref"
                    >Portfolio Three</a
                  >
                </li>
                <li>
                  <a routerLink="/portfolio-detail-four" class="nav-link-ref"
                    >Portfolio Four</a
                  >
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <li>
          <a routerLink="/portfolio-about" class="nav-link-ref">About us</a>
        </li>

        <li>
          <a routerLink="/portfolio-service" class="nav-link-ref">Services</a>
        </li>
      </ul>
      <!--end navigation menu-->
    </div>

    <div id="navigation" *ngIf="Menuoption == 'help'">
      <!-- Navigation Menu-->
      <ul class="navigation-menu" [class]="navClass">
        <li>
          <a routerLink="/helpcenter-overview" class="nav-link-ref"
            >Helpcenter</a
          >
        </li>
        <li><a routerLink="/helpcenter-faqs" class="nav-link-ref">FAQS</a></li>
        <li>
          <a routerLink="/helpcenter-guides" class="nav-link-ref">Guides</a>
        </li>
        <li>
          <a routerLink="/helpcenter-support-request" class="nav-link-ref"
            >Support</a
          >
        </li>
      </ul>
      <!--end navigation menu-->
    </div>
    <!--end navigation-->
  </div>
  <hr style="color: whitesmoke; height: 2px; margin: -5px" />
  <!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->

<!-- Offcanvas Start -->
<ng-template #content let-offcanvas>
  <div class="offcanvas-header p-4 border-bottom">
    <h5 id="offcanvasRightLabel" class="mb-0">
      <img
        src="assets/images/Asset 72.png"
        height="50"
        class="light-version"
        alt=""
      />
      <img
        src="assets/images/Asset 55.png"
        height="50"
        class="dark-version"
        alt=""
      />
    </h5>
    <button
      type="button"
      class="btn-close d-flex align-items-center text-dark"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
      (click)="offcanvas.dismiss('Cross click')"
    >
      <i class="uil uil-times fs-4"></i>
    </button>
  </div>
  <div class="offcanvas-body p-4">
    <div class="row">
      <div class="col-12">
        <img
          src="assets/images/contact.svg"
          class="img-fluid d-block mx-auto"
          style="max-width: 256px"
          alt=""
        />
        <div class="card border-0 mt-5" style="z-index: 1">
          <div class="card-body p-0">
            <form
              method="post"
              name="myForm"
              id="myForm"
              onsubmit="return validateForm()"
            >
              <p id="error-msg" class="mb-0"></p>
              <div id="simple-msg"></div>
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label"
                      >Your Name <span class="text-danger">*</span></label
                    >
                    <div class="form-icon position-relative">
                      <i-feather
                        name="user"
                        class="fea icon-sm icons"
                      ></i-feather>
                      <input
                        name="name"
                        id="name"
                        type="text"
                        class="form-control ps-5"
                        placeholder="Name :"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label"
                      >Your Email <span class="text-danger">*</span></label
                    >
                    <div class="form-icon position-relative">
                      <i-feather
                        name="mail"
                        class="fea icon-sm icons"
                      ></i-feather>
                      <input
                        name="email"
                        id="email"
                        type="email"
                        class="form-control ps-5"
                        placeholder="Email :"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="mb-3">
                    <label class="form-label">Subject</label>
                    <div class="form-icon position-relative">
                      <i-feather
                        name="book"
                        class="fea icon-sm icons"
                      ></i-feather>
                      <input
                        name="subject"
                        id="subject"
                        class="form-control ps-5"
                        placeholder="subject :"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="mb-3">
                    <label class="form-label"
                      >Comments <span class="text-danger">*</span></label
                    >
                    <div class="form-icon position-relative">
                      <i-feather
                        name="message-circle"
                        class="fea icon-sm icons clearfix"
                      ></i-feather>
                      <i-feather
                        name="message-circle"
                        class="fea icon-sm icons clearfix"
                      ></i-feather>
                      <textarea
                        name="comments"
                        id="comments"
                        rows="4"
                        class="form-control ps-5"
                        placeholder="Message :"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="d-grid">
                    <button
                      type="submit"
                      id="submit"
                      name="send"
                      class="btn btn-primary"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="offcanvas-footer p-4 border-top text-center">
    <ul
      class="list-unstyled social-icon social mb-0 d-flex justify-content-center gap-1"
    >
      <li class="list-inline-item mb-0">
        <a
          href="https://1.envato.market/landrick"
          target="_blank"
          class="rounded"
          ><i class="uil uil-shopping-cart align-middle" title="Buy Now"></i
        ></a>
      </li>
      <li class="list-inline-item mb-0">
        <a
          href="https://dribbble.com/shreethemes"
          target="_blank"
          class="rounded"
          ><i class="uil uil-dribbble align-middle" title="dribbble"></i
        ></a>
      </li>
      <li class="list-inline-item mb-0">
        <a
          href="https://www.behance.net/shreethemes"
          target="_blank"
          class="rounded"
          ><i class="uil uil-behance align-middle" title="behance"></i
        ></a>
      </li>
      <li class="list-inline-item mb-0">
        <a
          href="https://www.facebook.com/shreethemes"
          target="_blank"
          class="rounded"
          ><i class="uil uil-facebook-f align-middle" title="facebook"></i
        ></a>
      </li>
      <li class="list-inline-item mb-0">
        <a
          href="https://www.instagram.com/shreethemes/"
          target="_blank"
          class="rounded"
          ><i class="uil uil-instagram align-middle" title="instagram"></i
        ></a>
      </li>
      <li class="list-inline-item mb-0">
        <a
          href="https://twitter.com/shreethemes"
          target="_blank"
          class="rounded"
          ><i class="uil uil-twitter align-middle" title="twitter"></i
        ></a>
      </li>
      <li class="list-inline-item mb-0">
        <a href="mailto:support@shreethemes.in" class="rounded"
          ><i class="uil uil-envelope align-middle" title="email"></i
        ></a>
      </li>
      <li class="list-inline-item mb-0">
        <a href="https://shreethemes.in" target="_blank" class="rounded"
          ><i class="uil uil-globe align-middle" title="website"></i
        ></a>
      </li>
    </ul>
    <!--end icon-->
  </div>
</ng-template>
<!-- Offcanvas End -->
