      <!-- Hero Start -->
      <section class="bg-half-170 bg-primary d-table w-100" style="background-image: url('assets/images/job/bg.png');" id="home">
        <div class="container">
            <div class="row mt-5 justify-content-center">
                <div class="col-lg-12 text-center">
                    <div class="pages-heading">
                        <h2 class="title text-white title-dark mb-0"> Jobs / Careers </h2>
                    </div>
                </div>  <!--end col-->
            </div><!--end row-->
            
            <div class="position-breadcrumb">
                <nav aria-label="breadcrumb" class="d-inline-block">
                    <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                        <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                        <li class="breadcrumb-item"><a routerLink="/index-job">Careers</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Jobs</li>
                    </ul>
                </nav>
            </div>
        </div> <!--end container-->
    </section><!--end section-->
    <!-- Hero End -->

    <!-- Shape Start -->
    <div class="position-relative">
        <div class="shape overflow-hidden text-color-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!--Shape End-->
    
    <!-- Job List Start -->
    <section class="section">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="sidebar sticky-bar p-4 rounded shadow">
                        <!-- SEARCH -->
                        <div class="widget">
                            <form role="search" method="get">
                                <div class="input-group mb-3 border rounded">
                                    <input type="text" id="s" name="s" class="form-control border-0" placeholder="Search Keywords...">
                                    <button type="submit" class="input-group-text bg-transparent border-0" id="searchsubmit"><i class="uil uil-search"></i></button>
                                </div>
                            </form>
                        </div>
                        <!-- SEARCH -->

                        <!-- Categories -->
                        <div class="widget mb-4 pb-4 border-bottom">
                            <h5 class="widget-title">Categories</h5>
                            <div class="mt-4 mb-0">
                                <select class="form-select form-control" aria-label="Default select example">
                                    <option selected>All Jobs</option>
                                    <option>Digital & Creative</option>
                                    <option>Accountancy</option>
                                    <option>Banking</option>
                                    <option>IT Contractor</option>
                                    <option>Graduate</option>
                                    <option>Estate Agency</option>
                                </select>
                            </div>
                        </div>
                        <!-- Categories -->

                        <!-- Posted -->
                        <div class="widget mb-4 pb-4 border-bottom">
                            <h5 class="widget-title">Date Posted</h5>
                            <div class="mt-4">
                                <div class="form-check mb-0">
                                    <input class="form-check-input" checked type="radio" name="flexRadioDefault" id="lasthour">
                                    <label class="form-check-label" for="lasthour">Last Hour</label>
                                </div>

                                <div class="form-check mb-0">
                                    <input class="form-check-input" checked type="radio" name="flexRadioDefault" id="oneday">
                                    <label class="form-check-label" for="oneday">Last 24 hours</label>
                                </div>

                                <div class="form-check mb-0">
                                    <input class="form-check-input" checked type="radio" name="flexRadioDefault" id="sevenday">
                                    <label class="form-check-label" for="sevenday">Last 7 days</label>
                                </div>

                                <div class="form-check mb-0">
                                    <input class="form-check-input" checked type="radio" name="flexRadioDefault" id="fourteenhour">
                                    <label class="form-check-label" for="fourteenhour">Last 14 days</label>
                                </div>

                                <div class="form-check mb-0">
                                    <input class="form-check-input" checked type="radio" name="flexRadioDefault" id="onemonth">
                                    <label class="form-check-label" for="onemonth">Last 30 days</label>
                                </div>
                            </div>
                        </div>
                        <!-- Posted -->

                        <!-- Job Type -->
                        <div class="widget mb-4 pb-4 border-bottom">
                            <h5 class="widget-title">Job Type</h5>
                            <div class="mt-4">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="contract">
                                    <label class="form-check-label" for="contract">Contract Base</label>
                                </div>

                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="freelancer">
                                    <label class="form-check-label" for="freelancer">Freelancer</label>
                                </div>

                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="fulltime">
                                    <label class="form-check-label" for="fulltime">Full Time</label>
                                </div>

                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="parttime">
                                    <label class="form-check-label" for="parttime">Part Time</label>
                                </div>

                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="remote">
                                    <label class="form-check-label" for="remote">Remote</label>
                                </div>

                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="intern">
                                    <label class="form-check-label" for="intern">Intership</label>
                                </div>
                            </div>
                        </div>
                        <!-- Job Type -->

                        <!-- Posted -->
                        <div class="widget">
                            <h5 class="widget-title">Experience</h5>
                            <div class="mt-4">
                                <div class="form-check mb-0">
                                    <input class="form-check-input" checked type="radio" name="flexRadioDefault2" id="fresher">
                                    <label class="form-check-label" for="fresher">Fresher</label>
                                </div>

                                <div class="form-check mb-0">
                                    <input class="form-check-input" checked type="radio" name="flexRadioDefault2" id="oneyear">
                                    <label class="form-check-label" for="oneyear">+1 Year</label>
                                </div>

                                <div class="form-check mb-0">
                                    <input class="form-check-input" checked type="radio" name="flexRadioDefault2" id="twoyear">
                                    <label class="form-check-label" for="twoyear">+2 Year</label>
                                </div>

                                <div class="form-check mb-0">
                                    <input class="form-check-input" checked type="radio" name="flexRadioDefault2" id="3year">
                                    <label class="form-check-label" for="3year">+3 Year</label>
                                </div>

                                <div class="form-check mb-0">
                                    <input class="form-check-input" checked type="radio" name="flexRadioDefault2" id="fiveyear">
                                    <label class="form-check-label" for="fiveyear">+5 Year</label>
                                </div>
                            </div>
                        </div>
                        <!-- Posted -->
                    </div>
                </div><!--end col-->

                <div class="col-lg-8 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div class="row">
                        <div class="col-lg-6 col-12 mb-4 pb-2">
                            <div class="card job-box job-primary rounded shadow border-0 overflow-hidden">
                                <div class="border-bottom">
                                    <div class="position-relative">
                                        <img src="assets/images/job/full.jpg" class="img-fluid" alt="">
                                    </div>
                                    <div class="mb-0 position"><a routerLink="/job-detail-one" class="title text-dark h5">Web Designer</a>
                                        <ul class="list-unstyled h6 mb-0 text-warning">
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                                        </ul>
                                    </div>
                                    <ul class="list-unstyled head mb-0">
                                        <li class="badge bg-success rounded-pill">Full Time</li>
                                    </ul>
                                </div>
                                
                                <div class="card-body content position-relative">
                                    <div class="firm-logo rounded-circle shadow bg-light text-center">
                                        <img src="assets/images/job/Circleci.svg" class="avatar avatar-md-sm" alt="">
                                    </div>
                                    <div class="company-detail text-center mt-3">
                                        <h5 class="mb-0"><a routerLink="/job-company" class="text-dark company-name">CircleCi</a></h5>
                                        <p class="text-muted"><a href="javascript:void(0)" class="text-muted"><i-feather name="map-pin" class="fea icon-sm"></i-feather> San Francisco</a></p>
                                    </div>
                                    <ul class="job-facts list-unstyled">
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> 2 Year Expirence</li>
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> Working Hours- 6hr</li>
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> Information strategy</li>
                                    </ul>
                                    <div class="d-grid">
                                        <a routerLink="/job-detail-one" class="btn btn-soft-primary">Apply Now</a>
                                    </div>
                                </div>
                            </div>
                        </div><!--end col-->
    
                        <div class="col-lg-6 col-12 mb-4 pb-2">
                            <div class="card job-box job-primary rounded shadow border-0 overflow-hidden">
                                <div class="border-bottom">
                                    <div class="position-relative">
                                        <img src="assets/images/job/full.jpg" class="img-fluid" alt="">
                                    </div>
                                    <div class="mb-0 position"><a routerLink="/job-detail-one" class="title text-dark h5">Web Developer</a>
                                        <ul class="list-unstyled h6 mb-0 text-warning">
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                                        </ul>
                                    </div>
                                    <ul class="list-unstyled head mb-0">
                                        <li class="badge bg-success rounded-pill">Full Time</li>
                                    </ul>
                                </div>
                                
                                <div class="card-body content position-relative">
                                    <div class="firm-logo rounded-circle shadow bg-light text-center">
                                        <img src="assets/images/job/Codepen.svg" class="avatar avatar-md-sm" alt="">
                                    </div>
                                    <div class="company-detail text-center mt-3">
                                        <h5 class="mb-0"><a routerLink="/job-company" class="text-dark company-name">Codepen</a></h5>
                                        <p class="text-muted"><a href="javascript:void(0)" class="text-muted"><i-feather name="map-pin" class="fea icon-sm"></i-feather> San Francisco</a></p>
                                    </div>
                                    <ul class="job-facts list-unstyled">
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> 2 Year Expirence</li>
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> Working Hours- 6hr</li>
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> Information strategy</li>
                                    </ul>
                                    <div class="d-grid">
                                        <a routerLink="/job-detail-one" class="btn btn-soft-primary">Apply Now</a>
                                    </div>
                                </div>
                            </div>
                        </div><!--end col-->
    
                        <div class="col-lg-6 col-12 mb-4 pb-2">
                            <div class="card job-box job-primary rounded shadow border-0 overflow-hidden">
                                <div class="border-bottom">
                                    <div class="position-relative">
                                        <img src="assets/images/job/part.jpg" class="img-fluid" alt="">
                                    </div>
                                    <div class="mb-0 position"><a routerLink="/job-detail-one" class="title text-dark h5">UX Designer</a>
                                        <ul class="list-unstyled h6 mb-0 text-warning">
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                                        </ul>
                                    </div>
                                    <ul class="list-unstyled head mb-0">
                                        <li class="badge bg-warning rounded-pill">Part Time</li>
                                    </ul>
                                </div>
                                
                                <div class="card-body content position-relative">
                                    <div class="firm-logo rounded-circle shadow bg-light text-center">
                                        <img src="assets/images/job/Discord.svg" class="avatar avatar-md-sm" alt="">
                                    </div>
                                    <div class="company-detail text-center mt-3">
                                        <h5 class="mb-0"><a routerLink="/job-company" class="text-dark company-name">Discord</a></h5>
                                        <p class="text-muted"><a href="javascript:void(0)" class="text-muted"><i-feather name="map-pin" class="fea icon-sm"></i-feather> San Francisco</a></p>
                                    </div>
                                    <ul class="job-facts list-unstyled">
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> 2 Year Expirence</li>
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> Working Hours- 6hr</li>
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> Information strategy</li>
                                    </ul>
                                    <div class="d-grid">
                                        <a routerLink="/job-detail-one" class="btn btn-soft-primary">Apply Now</a>
                                    </div>
                                </div>
                            </div>
                        </div><!--end col-->
    
                        <div class="col-lg-6 col-12 mb-4 pb-2">
                            <div class="card job-box job-primary rounded shadow border-0 overflow-hidden">
                                <div class="border-bottom">
                                    <div class="position-relative">
                                        <img src="assets/images/job/full.jpg" class="img-fluid" alt="">
                                    </div>
                                    <div class="mb-0 position"><a routerLink="/job-detail-one" class="title text-dark h5">UI Designer</a>
                                        <ul class="list-unstyled h6 mb-0 text-warning">
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                                        </ul>
                                    </div>
                                    <ul class="list-unstyled head mb-0">
                                        <li class="badge bg-success rounded-pill">Full Time</li>
                                    </ul>
                                </div>
                                
                                <div class="card-body content position-relative">
                                    <div class="firm-logo rounded-circle shadow bg-light text-center">
                                        <img src="assets/images/job/Eslint.svg" class="avatar avatar-md-sm" alt="">
                                    </div>
                                    <div class="company-detail text-center mt-3">
                                        <h5 class="mb-0"><a routerLink="/job-company" class="text-dark company-name">Eslint</a></h5>
                                        <p class="text-muted"><a href="javascript:void(0)" class="text-muted"><i-feather name="map-pin" class="fea icon-sm"></i-feather> San Francisco</a></p>
                                    </div>
                                    <ul class="job-facts list-unstyled">
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> 2 Year Expirence</li>
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> Working Hours- 6hr</li>
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> Information strategy</li>
                                    </ul>
                                    <div class="d-grid">
                                        <a routerLink="/job-detail-one" class="btn btn-soft-primary">Apply Now</a>
                                    </div>
                                </div>
                            </div>
                        </div><!--end col-->
    
                        <div class="col-lg-6 col-12 mb-4 pb-2">
                            <div class="card job-box job-primary rounded shadow border-0 overflow-hidden">
                                <div class="border-bottom">
                                    <div class="position-relative">
                                        <img src="assets/images/job/remote.jpg" class="img-fluid" alt="">
                                    </div>
                                    <div class="mb-0 position"><a routerLink="/job-detail-one" class="title text-dark h5">Back-End Developer</a>
                                        <ul class="list-unstyled h6 mb-0 text-warning">
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                                        </ul>
                                    </div>
                                    <ul class="list-unstyled head mb-0">
                                        <li class="badge bg-danger rounded-pill">Remote</li>
                                    </ul>
                                </div>
                                
                                <div class="card-body content position-relative">
                                    <div class="firm-logo rounded-circle shadow bg-light text-center">
                                        <img src="assets/images/job/Gitlab.svg" class="avatar avatar-md-sm" alt="">
                                    </div>
                                    <div class="company-detail text-center mt-3">
                                        <h5 class="mb-0"><a routerLink="/job-company" class="text-dark company-name">Gitlab</a></h5>
                                        <p class="text-muted"><a href="javascript:void(0)" class="text-muted"><i-feather name="map-pin" class="fea icon-sm"></i-feather> San Francisco</a></p>
                                    </div>
                                    <ul class="job-facts list-unstyled">
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> 2 Year Expirence</li>
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> Working Hours- 6hr</li>
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> Information strategy</li>
                                    </ul>
                                    <div class="d-grid">
                                        <a routerLink="/job-detail-one" class="btn btn-soft-primary">Apply Now</a>
                                    </div>
                                </div>
                            </div>
                        </div><!--end col-->
    
                        <div class="col-lg-6 col-12 mb-4 pb-2">
                            <div class="card job-box job-primary rounded shadow border-0 overflow-hidden">
                                <div class="border-bottom">
                                    <div class="position-relative">
                                        <img src="assets/images/job/part.jpg" class="img-fluid" alt="">
                                    </div>
                                    <div class="mb-0 position"><a routerLink="/job-detail-one" class="title text-dark h5">Photographer</a>
                                        <ul class="list-unstyled h6 mb-0 text-warning">
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                                        </ul>
                                    </div>
                                    <ul class="list-unstyled head mb-0">
                                        <li class="badge bg-warning rounded-pill">Part Time</li>
                                    </ul>
                                </div>
                                
                                <div class="card-body content position-relative">
                                    <div class="firm-logo rounded-circle shadow bg-light text-center">
                                        <img src="assets/images/job/Gradle.svg" class="avatar avatar-md-sm" alt="">
                                    </div>
                                    <div class="company-detail text-center mt-3">
                                        <h5 class="mb-0"><a routerLink="/job-company" class="text-dark company-name">Gradle</a></h5>
                                        <p class="text-muted"><a href="javascript:void(0)" class="text-muted"><i-feather name="map-pin" class="fea icon-sm"></i-feather> San Francisco</a></p>
                                    </div>
                                    <ul class="job-facts list-unstyled">
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> 2 Year Expirence</li>
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> Working Hours- 6hr</li>
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> Information strategy</li>
                                    </ul>
                                    <div class="d-grid">
                                        <a routerLink="/job-detail-one" class="btn btn-soft-primary">Apply Now</a>
                                    </div>
                                </div>
                            </div>
                        </div><!--end col-->
    
                        <div class="col-lg-6 col-12 mb-4 pb-2">
                            <div class="card job-box job-primary rounded shadow border-0 overflow-hidden">
                                <div class="border-bottom">
                                    <div class="position-relative">
                                        <img src="assets/images/job/full.jpg" class="img-fluid" alt="">
                                    </div>
                                    <div class="mb-0 position"><a routerLink="/job-detail-one" class="title text-dark h5">Mechanic</a>
                                        <ul class="list-unstyled h6 mb-0 text-warning">
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                                        </ul>
                                    </div>
                                    <ul class="list-unstyled head mb-0">
                                        <li class="badge bg-success rounded-pill">Full Time</li>
                                    </ul>
                                </div>
                                
                                <div class="card-body content position-relative">
                                    <div class="firm-logo rounded-circle shadow bg-light text-center">
                                        <img src="assets/images/job/Mg.svg" class="avatar avatar-md-sm" alt="">
                                    </div>
                                    <div class="company-detail text-center mt-3">
                                        <h5 class="mb-0"><a routerLink="/job-company" class="text-dark company-name">Mg</a></h5>
                                        <p class="text-muted"><a href="javascript:void(0)" class="text-muted"><i-feather name="map-pin" class="fea icon-sm"></i-feather> San Francisco</a></p>
                                    </div>
                                    <ul class="job-facts list-unstyled">
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> 2 Year Expirence</li>
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> Working Hours- 6hr</li>
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> Information strategy</li>
                                    </ul>
                                    <div class="d-grid">
                                        <a routerLink="/job-detail-one" class="btn btn-soft-primary">Apply Now</a>
                                    </div>
                                </div>
                            </div>
                        </div><!--end col-->
    
                        <div class="col-lg-6 col-12 mb-4 pb-2">
                            <div class="card job-box job-primary rounded shadow border-0 overflow-hidden">
                                <div class="border-bottom">
                                    <div class="position-relative">
                                        <img src="assets/images/job/part.jpg" class="img-fluid" alt="">
                                    </div>
                                    <div class="mb-0 position"><a routerLink="/job-detail-one" class="title text-dark h5">Frontend Developer</a>
                                        <ul class="list-unstyled h6 mb-0 text-warning">
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                                        </ul>
                                    </div>
                                    <ul class="list-unstyled head mb-0">
                                        <li class="badge bg-warning rounded-pill">Part Time</li>
                                    </ul>
                                </div>
                                
                                <div class="card-body content position-relative">
                                    <div class="firm-logo rounded-circle shadow bg-light text-center">
                                        <img src="assets/images/job/Sketch.svg" class="avatar avatar-md-sm" alt="">
                                    </div>
                                    <div class="company-detail text-center mt-3">
                                        <h5 class="mb-0"><a routerLink="/job-company" class="text-dark company-name">Sketch</a></h5>
                                        <p class="text-muted"><a href="javascript:void(0)" class="text-muted"><i-feather name="map-pin" class="fea icon-sm"></i-feather> San Francisco</a></p>
                                    </div>
                                    <ul class="job-facts list-unstyled">
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> 2 Year Expirence</li>
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> Working Hours- 6hr</li>
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> Information strategy</li>
                                    </ul>
                                    <div class="d-grid">
                                        <a routerLink="/job-detail-one" class="btn btn-soft-primary">Apply Now</a>
                                    </div>
                                </div>
                            </div>
                        </div><!--end col-->
    
                        <div class="col-lg-6 col-12 mb-4 pb-2">
                            <div class="card job-box job-primary rounded shadow border-0 overflow-hidden">
                                <div class="border-bottom">
                                    <div class="position-relative">
                                        <img src="assets/images/job/remote.jpg" class="img-fluid" alt="">
                                    </div>
                                    <div class="mb-0 position"><a routerLink="/job-detail-one" class="title text-dark h5">Web Designer</a>
                                        <ul class="list-unstyled h6 mb-0 text-warning">
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                                        </ul>
                                    </div>
                                    <ul class="list-unstyled head mb-0">
                                        <li class="badge bg-danger rounded-pill">Remote</li>
                                    </ul>
                                </div>
                                
                                <div class="card-body content position-relative">
                                    <div class="firm-logo rounded-circle shadow bg-light text-center">
                                        <img src="assets/images/job/Twilio.svg" class="avatar avatar-md-sm" alt="">
                                    </div>
                                    <div class="company-detail text-center mt-3">
                                        <h5 class="mb-0"><a routerLink="/job-company" class="text-dark company-name">Twilio</a></h5>
                                        <p class="text-muted"><a href="javascript:void(0)" class="text-muted"><i-feather name="map-pin" class="fea icon-sm"></i-feather> San Francisco</a></p>
                                    </div>
                                    <ul class="job-facts list-unstyled">
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> 2 Year Expirence</li>
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> Working Hours- 6hr</li>
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> Information strategy</li>
                                    </ul>
                                    <div class="d-grid">
                                        <a routerLink="/job-detail-one" class="btn btn-soft-primary">Apply Now</a>
                                    </div>
                                </div>
                            </div>
                        </div><!--end col-->
    
                        <div class="col-lg-6 col-12 mb-4 pb-2">
                            <div class="card job-box job-primary rounded shadow border-0 overflow-hidden">
                                <div class="border-bottom">
                                    <div class="position-relative">
                                        <img src="assets/images/job/part.jpg" class="img-fluid" alt="">
                                    </div>
                                    <div class="mb-0 position"><a routerLink="/job-detail-one" class="title text-dark h5">Php Developer</a>
                                        <ul class="list-unstyled h6 mb-0 text-warning">
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                                        </ul>
                                    </div>
                                    <ul class="list-unstyled head mb-0">
                                        <li class="badge bg-warning rounded-pill">Part Time</li>
                                    </ul>
                                </div>
                                
                                <div class="card-body content position-relative">
                                    <div class="firm-logo rounded-circle shadow bg-light text-center">
                                        <img src="assets/images/job/Udemy.svg" class="avatar avatar-md-sm" alt="">
                                    </div>
                                    <div class="company-detail text-center mt-3">
                                        <h5 class="mb-0"><a routerLink="/job-company" class="text-dark company-name">Udemy</a></h5>
                                        <p class="text-muted"><a href="javascript:void(0)" class="text-muted"><i-feather name="map-pin" class="fea icon-sm"></i-feather> San Francisco</a></p>
                                    </div>
                                    <ul class="job-facts list-unstyled">
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> 2 Year Expirence</li>
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> Working Hours- 6hr</li>
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> Information strategy</li>
                                    </ul>
                                    <div class="d-grid">
                                        <a routerLink="/job-detail-one" class="btn btn-soft-primary">Apply Now</a>
                                    </div>
                                </div>
                            </div>
                        </div><!--end col-->
    
                        <div class="col-lg-6 col-12 mb-4 pb-2">
                            <div class="card job-box job-primary rounded shadow border-0 overflow-hidden">
                                <div class="border-bottom">
                                    <div class="position-relative">
                                        <img src="assets/images/job/full.jpg" class="img-fluid" alt="">
                                    </div>
                                    <div class="mb-0 position"><a routerLink="/job-detail-one" class="title text-dark h5">Web Designer</a>
                                        <ul class="list-unstyled h6 mb-0 text-warning">
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                                        </ul>
                                    </div>
                                    <ul class="list-unstyled head mb-0">
                                        <li class="badge bg-success rounded-pill">Full Time</li>
                                    </ul>
                                </div>
                                
                                <div class="card-body content position-relative">
                                    <div class="firm-logo rounded-circle shadow bg-light text-center">
                                        <img src="assets/images/job/Webhooks.svg" class="avatar avatar-md-sm" alt="">
                                    </div>
                                    <div class="company-detail text-center mt-3">
                                        <h5 class="mb-0"><a routerLink="/job-company" class="text-dark company-name">Webhooks</a></h5>
                                        <p class="text-muted"><a href="javascript:void(0)" class="text-muted"><i-feather name="map-pin" class="fea icon-sm"></i-feather> San Francisco</a></p>
                                    </div>
                                    <ul class="job-facts list-unstyled">
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> 2 Year Expirence</li>
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> Working Hours- 6hr</li>
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> Information strategy</li>
                                    </ul>
                                    <div class="d-grid">
                                        <a routerLink="/job-detail-one" class="btn btn-soft-primary">Apply Now</a>
                                    </div>
                                </div>
                            </div>
                        </div><!--end col-->
    
                        <div class="col-lg-6 col-12 mb-4 pb-2">
                            <div class="card job-box job-primary rounded shadow border-0 overflow-hidden">
                                <div class="border-bottom">
                                    <div class="position-relative">
                                        <img src="assets/images/job/remote.jpg" class="img-fluid" alt="">
                                    </div>
                                    <div class="mb-0 position"><a routerLink="/job-detail-one" class="title text-dark h5">Python Developer</a>
                                        <ul class="list-unstyled h6 mb-0 text-warning">
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>                                    
                                            <li class="list-inline-item mb-0"><i class="mdi mdi-star"></i></li>
                                        </ul>
                                    </div>
                                    <ul class="list-unstyled head mb-0">
                                        <li class="badge bg-danger rounded-pill">Remote</li>
                                    </ul>
                                </div>
                                
                                <div class="card-body content position-relative">
                                    <div class="firm-logo rounded-circle shadow bg-light text-center">
                                        <img src="assets/images/job/Circleci.svg" class="avatar avatar-md-sm" alt="">
                                    </div>
                                    <div class="company-detail text-center mt-3">
                                        <h5 class="mb-0"><a routerLink="/job-company" class="text-dark company-name">CircleCi</a></h5>
                                        <p class="text-muted"><a href="javascript:void(0)" class="text-muted"><i-feather name="map-pin" class="fea icon-sm"></i-feather> San Francisco</a></p>
                                    </div>
                                    <ul class="job-facts list-unstyled">
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> 2 Year Expirence</li>
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> Working Hours- 6hr</li>
                                        <li class="list-inline-item text-muted"><i-feather name="check" class="fea icon-sm text-success me-1"></i-feather> Information strategy</li>
                                    </ul>
                                    <div class="d-grid">
                                        <a routerLink="/job-detail-one" class="btn btn-soft-primary">Apply Now</a>
                                    </div>
                                </div>
                            </div>
                        </div><!--end col-->
                    </div><!--end row-->

                    <div class="row">
                        <!-- PAGINATION START -->
                        <div class="col-12">
                            <ul class="pagination justify-content-center mb-0">
                                <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Previous">Prev</a></li>
                                <li class="page-item active"><a class="page-link" href="javascript:void(0)">1</a></li>
                                <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
                                <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                                <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Next">Next</a></li>
                            </ul>
                        </div><!--end col-->
                        <!-- PAGINATION END -->
                    </div><!--end row-->
                </div><!--end col-->
            </div><!--end row-->
        </div><!--end container-->
    </section><!--end section-->
    <!-- Job List End -->
    
<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
