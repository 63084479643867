<div class="row">
  <div class="col-lg-3 col-md-6 mt-4 pt-2" *ngFor="let data of memberData">
    <div
      class="text-center border-0"
      style="
        border: 1.8px solid white !important;
        padding: 15px;
        border-radius: 3px;
      "
    >
      <div class="position-relative">
        <img
          src="{{ data.profile }}"
          class="img-fluid avatar avatar-ex-large rounded-circle shadow"
          alt=""
        />
        <!-- <ul class="list-unstyled mb-0 team-icon">
            <li class="list-inline-item ms-1" *ngFor="let item of data.list"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon">
                <i-feather name="{{item}}" class="icons"></i-feather>
              </a></li>
          </ul> -->
        <!--end icon-->
      </div>
      <div class="card-body py-3 px-0 content">
        <h5 class="mb-0">
          <a href="javascript:void(0)" class="name text-light">{{
            data.name
          }}</a>
        </h5>
        <small class="designation text-muted">{{ data.designation }}</small>
      </div>
    </div>
  </div>
</div>
