<!-- Hero Start -->
<section class="bg-home d-flex align-items-center" style="height: auto;" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center mt-0 mt-md-5 pt-0 pt-md-5">
        <div class="title-heading margin-top-100">
          <h1 class="heading fw-bold mb-3">We Build A Design <br> For Email Inbox</h1>
          <p class="para-desc mx-auto text-muted">Launch your campaign and benefit from our expertise on designing and
            managing conversion centered bootstrap v5 html page.</p>
          <div class="mt-4 pt-2">
            <a routerLink="/index-email-inbox" class="btn btn-primary">Start Free Trial <i
                class="uil uil-angle-right-b"></i></a>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div class="home-dashboard">
              <img src="assets/images/email/home.png" alt="" class="img-fluid rounded-md shadow-lg" style="z-index: 0;">
            </div>
          </div>
        </div>
        <!--end row-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Partners start -->
<section class="section py-4 bg-light">
  <div class="container">
    <app-clients-logo  brand_class="col-lg-2 col-md-2 col-6 text-center py-4"></app-clients-logo>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Partners End -->

<!-- Start -->
<section class="section border-bottom">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h4 class="title mb-4">Our Features</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-4 col-md-6 mt-5">
        <div class="d-flex features feature-clean feature-primary">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-comment-verify d-block rounded h3 mb-0"></i>
          </div>
          <div class="flex-1 content ms-4">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark title">Instant Chat</a></h5>
            <p class="text-muted mb-0">The most well-known dummy text is the, which is said to have originated in the
              16th century.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-5">
        <div class="d-flex features feature-clean feature-primary">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-image d-block rounded h3 mb-0"></i>
          </div>
          <div class="flex-1 content ms-4">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark title">Media Messages</a></h5>
            <p class="text-muted mb-0">The most well-known dummy text is the, which is said to have originated in the
              16th century.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-5">
        <div class="d-flex features feature-clean feature-primary">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-video d-block rounded h3 mb-0"></i>
          </div>
          <div class="flex-1 content ms-4">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark title">Video Messages</a></h5>
            <p class="text-muted mb-0">The most well-known dummy text is the, which is said to have originated in the
              16th century.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-5">
        <div class="d-flex features feature-clean feature-primary">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-database-alt d-block rounded h3 mb-0"></i>
          </div>
          <div class="flex-1 content ms-4">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark title">Dedicated Server</a></h5>
            <p class="text-muted mb-0">The most well-known dummy text is the, which is said to have originated in the
              16th century.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-5">
        <div class="d-flex features feature-clean feature-primary">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-users-alt d-block rounded h3 mb-0"></i>
          </div>
          <div class="flex-1 content ms-4">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark title">Team Collaboration</a></h5>
            <p class="text-muted mb-0">The most well-known dummy text is the, which is said to have originated in the
              16th century.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-5">
        <div class="d-flex features feature-clean feature-primary">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-apps d-block rounded h3 mb-0"></i>
          </div>
          <div class="flex-1 content ms-4">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark title">Responsive App</a></h5>
            <p class="text-muted mb-0">The most well-known dummy text is the, which is said to have originated in the
              16th century.</p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class="col-12 mt-5">
        <div class="watch-video text-center">
          <a href="javascript:void(0)" class="btn btn-primary m-1 me-2">Get Started <i
              class="mdi mdi-chevron-right"></i></a>
          <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" data-type="youtube"
            data-id="yba7hPeTSjk" class="btn btn-icon btn-pills btn-primary m-1">
            <i-feather name="video" class="icons"></i-feather>
          </a><span class="fw-bold text-uppercase small align-middle ms-1">Watch Now</span>
          <ng-template #content let-modal>
            <div class="modal-header">
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <iframe src="https://player.vimeo.com/video/99025203" height="450" width="780" frameborder="0"
                allowfullscreen allow="autoplay; encrypted-media"></iframe>
            </div>
          </ng-template>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>

<section class="section">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-6">
        <img src="assets/images/saas/classic01.png" class="img-fluid" alt="">
      </div>
      <!--end col-->

      <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4">Make your website <br> growth with next level visitors</h4>
          <p class="text-muted">You can combine all the Landrick templates into a single one, you can take a component
            from the Application theme and use it in the Website.</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for Tomorrow</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Our Talented & Experienced Marketing Agency</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Create your own skin to match your brand</li>
          </ul>
          <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
              class="uil uil-angle-right-b"></i></a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-md-6 order-1 order-md-2">
        <img src="assets/images/illustrator/inbox-cleanup.svg" alt="">
      </div>
      <!--end col-->

      <div class="col-md-6 order-2 order-md-1">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4">Meet our business <br> partner who work behind the scene</h4>
          <p class="text-muted">You can combine all the Landrick templates into a single one, you can take a component
            from the Application theme and use it in the Website.</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for Tomorrow</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Our Talented & Experienced Marketing Agency</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Create your own skin to match your brand</li>
          </ul>
          <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
              class="uil uil-angle-right-b"></i></a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Whats Our Clients Said About <span class="text-primary">Landrick</span> Project</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class="col-lg-12 mt-4">
        <app-testimonial [testimonialData]="testimonialData"></app-testimonial>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Start Plan -->
<section class="section bg-light">
  <div class="container">
    <div class="row align-items-end mb-4 pb-2">
      <div class="">
        <div class="section-title text-md-left">
          <h4 class="title mb-4">Choose the best <br> one for you</h4>
          <p class="text-muted mb-0 para-desc">Start working with <span class="text-primary fw-bold">Landrick</span>
            that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <div class="text-center text-md-end">
      <ul ngbNav #nav="ngbNav"
        class="nav-pills rounded-lg justify-content-center d-inline-block shadow py-1 px-2 mb-0">
        <li ngbNavItem class="d-inline-block">
          <a ngbNavLink class="nav-link px-3 rounded-lg monthly" id="Monthly" role="tab" aria-controls="Month"
            aria-selected="true">Monthly</a>
          <ng-template ngbNavContent>
            <app-pricing [pricingData]="monthlyData"></app-pricing>
          </ng-template>
        </li>
        <li ngbNavItem class="d-inline-block">
          <a ngbNavLink class="nav-link px-3 rounded-lg monthly" id="Yearly" role="tab" aria-controls="Month"
            aria-selected="true">Yearly</a>
          <ng-template ngbNavContent>
            <app-pricing [pricingData]="yearlyData"></app-pricing>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </div>
    <!--end col-->
  </div>
  <!--end row-->
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-5 col-12">
        <img src="assets/images/illustrator/envelope.svg" class="img-fluid mx-auto d-block" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-7 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title">
          <div class="alert alert-light alert-pills" role="alert">
            <span class="badge bg-primary rounded-pill me-1">Apps</span>
            <span class="content">Download now <i class="uil uil-angle-right-b align-middle"></i></span>
          </div>
          <h4 class="title mb-4">Available for your <br> Smartphones</h4>
          <p class="text-muted para-desc mb-0">Start working with <span class="text-primary fw-bold">Landrick</span>
            that can provide everything you need to generate awareness, drive traffic, connect.</p>
          <div class="my-4">
            <a href="javascript:void(0)" class="btn btn-lg btn-dark mt-2 me-2"><i class="uil uil-apple"></i> App
              Store</a>
            <a href="javascript:void(0)" class="btn btn-lg btn-dark mt-2"><i class="uil uil-google-play"></i> Play
              Store</a>
          </div>
          <div class="d-inline-block">
            <div class="pt-4 d-flex align-items-center border-top">
              <i-feather name="smartphone" class="fea icon-md me-2 text-primary"></i-feather>
              <div class="content">
                <h6 class="mb-0">Install app now on your cellphones</h6>
                <a href="javascript:void(0)" class="text-primary h6">Learn More <i
                    class="uil uil-angle-right-b"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Plan -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->