<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h4 class="title mb-0"> Frequently Asked Questions </h4>
        </div>
      </div><!--end col-->
    </div><!--end row-->

    <div class="position-breadcrumb">
      <nav aria-label="breadcrumb" class="d-inline-block">
        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
          <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
          <li class="breadcrumb-item"><a href="javascript:void(0)">Help Center</a></li>
          <li class="breadcrumb-item active" aria-current="page">FAQs</li>
        </ul>
      </nav>
    </div>
  </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Start Section -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-5 col-12 d-none d-md-block">
        <div class="rounded shadow p-4 sticky-bar">
          <ul class="list-unstyled mb-0">
            <li class="navbar-item p-0"><a href="javascript: void(0);" [ngxScrollTo]="'#tech'"
                class="mouse-down h6 text-dark">Buying Questions</a></li>
            <li class="navbar-item mt-3 p-0"><a href="javascript: void(0);" [ngxScrollTo]="'#general'"
                class="h6 text-dark navbar-link">General Questions</a></li>
            <li class="navbar-item mt-3 p-0"><a href="javascript: void(0);" [ngxScrollTo]="'#payment'"
                class="h6 text-dark navbar-link">Payments Questions</a></li>
            <li class="navbar-item mt-3 p-0"><a href="javascript: void(0);" [ngxScrollTo]="'#support'"
                class="h6 text-dark navbar-link">Support Questions</a></li>
          </ul>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-8 col-md-7 col-12">
        <div class="section-title" id="tech">
          <h4>Buying Product</h4>
        </div>
        <div class="faq-content mt-4 pt-2">
          <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
            <ngb-panel id="ngb-panel-0" cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingone">
                  <h6 class="title mb-0"> How does it work ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="accordion-body text-muted">
                  There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                  alteration in some form.
                </div>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0"> Do I need a designer to use Landrick ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingthree">
                  <h6 class="title mb-0"> What do I need to do to start selling ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans"> There are many variations of passages of Lorem Ipsum available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingfour">
                  <h6 class="title mb-0"> What happens when I receive an order ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans"> There are many variations of passages of Lorem Ipsum available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>

        <div class="section-title mt-5" id="general">
          <h4>General Questions</h4>
        </div>

        <div class="faq-content mt-4 pt-3">

          <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panelfaq" closeOthers="true">
            <ngb-panel id="ngb-panelfaq" cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingone">
                  <h6 class="title mb-0"> How does it work ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans"> There are many variations of passages of Lorem Ipsum available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0"> Do I need a designer to use Landrick ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingthree">
                  <h6 class="title mb-0"> What do I need to do to start selling ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans"> There are many variations of passages of Lorem Ipsum available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingfour">
                  <h6 class="title mb-0"> What happens when I receive an order ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans"> There are many variations of passages of Lorem Ipsum available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>

        <div class="section-title mt-5" id="payment">
          <h4>Payments Questions</h4>
        </div>

        <div class="faq-content mt-4 pt-3">
          <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panelpayment" closeOthers="true">
            <ngb-panel id="ngb-panelpayment" cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingone">
                  <h6 class="title mb-0"> How does it work ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans"> There are many variations of passages of Lorem Ipsum available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0"> Do I need a designer to use Landrick ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingthree">
                  <h6 class="title mb-0"> What do I need to do to start selling ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingfour">
                  <h6 class="title mb-0"> What happens when I receive an order ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans"> There are many variations of passages of Lorem Ipsum available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>

        <div class="section-title mt-5" id="support">
          <h4>Support Questions</h4>
        </div>

        <div class="faq-content mt-4 pt-3">
          <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panelsupport" closeOthers="true">
            <ngb-panel id="ngb-panelsupport" cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingone">
                  <h6 class="title mb-0"> How does it work ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans"> There are many variations of passages of Lorem Ipsum available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0"> Do I need a designer to use Landrick ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans"> There are many variations of passages of Lorem Ipsum available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingthree">
                  <h6 class="title mb-0"> What do I need to do to start selling ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingfour">
                  <h6 class="title mb-0"> What happens when I receive an order ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans"> There are many variations of passages of Lorem Ipsum available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>

        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-12">
        <div class="d-flex align-items-center features feature-clean shadow rounded p-4">
          <div class="icons text-primary text-center">
            <i class="uil uil-envelope-check d-block rounded h3 mb-0"></i>
          </div>
          <div class="flex-1 content ms-4">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Get in Touch !</a></h5>
            <p class="text-muted mb-0">This is required when, for text is not yet available.</p>
            <div class="mt-2">
              <a href="javascript:void(0)" class="btn btn-sm btn-soft-primary">Submit a Request</a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="d-flex align-items-center features feature-clean shadow rounded p-4">
          <div class="icons text-primary text-center">
            <i class="uil uil-webcam d-block rounded h3 mb-0"></i>
          </div>
          <div class="flex-1 content ms-4">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Start a Meeting</a></h5>
            <p class="text-muted mb-0">This is required when, for text is not yet available.</p>
            <div class="mt-2">
              <a href="javascript:void(0)" class="btn btn-sm btn-soft-primary">Start Video Chat</a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Section -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->