<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0"> Explore Items </h4>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a routerLink="/index-nft">NFTs</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Explore</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- Hero End -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                <div class="card nft nft-item nft-primary rounded shadow overflow-hidden">
                    <div class="nft-image position-relative overflow-hidden">
                        <img src="assets/images/nft/1.jpg" class="img-fluid item-img" alt="">
                        <div class="overlay"></div>
                        <div class="bid-btn p-3 text-center">
                            <a routerLink="/nft-item-detail" class="btn btn-pills"><i class="mdi mdi-gavel fs-6 me-2"></i>
                                Bid</a>
                        </div>
                        <div class="position-absolute top-0 start-0 m-2">
                            <a href=""><img src="assets/images/client/01.jpg"
                                    class="avatar avatar-sm-sm rounded-pill shadow-md" alt=""></a>
                        </div>
                        <div class="position-absolute top-0 end-0 m-2">
                            <a href="" class="badge rounded-md bg-light shadow"><i
                                    class="mdi mdi-heart align-middle text-danger"></i> <span
                                    class="text-dark">231</span></a>
                        </div>
                    </div>

                    <div class="card-body p-3">
                        <a routerLink="/nft-item-detail" class="h5 title text-dark">#01 nft title</a>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <span class="text-muted">12 in stock</span>
                            <span class="text-dark">Price: <span class="link">2.333ETH</span></span>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="card nft nft-item nft-primary rounded shadow overflow-hidden">
                    <div class="nft-image position-relative overflow-hidden">
                        <img src="assets/images/nft/2.jpg" class="img-fluid item-img" alt="">
                        <div class="overlay"></div>
                        <div class="bid-btn p-3 text-center">
                            <a routerLink="/nft-item-detail" class="btn btn-pills"><i class="mdi mdi-gavel fs-6 me-2"></i>
                                Bid</a>
                        </div>
                        <div class="position-absolute top-0 start-0 m-2">
                            <a href=""><img src="assets/images/client/02.jpg"
                                    class="avatar avatar-sm-sm rounded-pill shadow-md" alt=""></a>
                        </div>
                        <div class="position-absolute top-0 end-0 m-2">
                            <a href="" class="badge rounded-md bg-light shadow"><i
                                    class="mdi mdi-heart align-middle text-danger"></i> <span
                                    class="text-dark">232</span></a>
                        </div>
                    </div>

                    <div class="card-body p-3">
                        <a routerLink="/nft-item-detail" class="h5 title text-dark">#02 nft title</a>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <span class="text-muted">12 in stock</span>
                            <span class="text-dark">Price: <span class="link">2.333ETH</span></span>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="card nft nft-item nft-primary rounded shadow overflow-hidden">
                    <div class="nft-image position-relative overflow-hidden">
                        <img src="assets/images/nft/3.jpg" class="img-fluid item-img" alt="">
                        <div class="overlay"></div>
                        <div class="bid-btn p-3 text-center">
                            <a routerLink="/nft-item-detail" class="btn btn-pills"><i class="mdi mdi-gavel fs-6 me-2"></i>
                                Bid</a>
                        </div>
                        <div class="position-absolute top-0 start-0 m-2">
                            <a href=""><img src="assets/images/client/03.jpg"
                                    class="avatar avatar-sm-sm rounded-pill shadow-md" alt=""></a>
                        </div>
                        <div class="position-absolute top-0 end-0 m-2">
                            <a href="" class="badge rounded-md bg-light shadow"><i
                                    class="mdi mdi-heart align-middle text-danger"></i> <span
                                    class="text-dark">233</span></a>
                        </div>
                    </div>

                    <div class="card-body p-3">
                        <a routerLink="/nft-item-detail" class="h5 title text-dark">#03 nft title</a>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <span class="text-muted">12 in stock</span>
                            <span class="text-dark">Price: <span class="link">2.333ETH</span></span>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2 mt-lg-0 pt-lg-0">
                <div class="card nft nft-item nft-primary rounded shadow overflow-hidden">
                    <div class="nft-image position-relative overflow-hidden">
                        <img src="assets/images/nft/4.jpg" class="img-fluid item-img" alt="">
                        <div class="overlay"></div>
                        <div class="bid-btn p-3 text-center">
                            <a routerLink="/nft-item-detail" class="btn btn-pills"><i class="mdi mdi-gavel fs-6 me-2"></i>
                                Bid</a>
                        </div>
                        <div class="position-absolute top-0 start-0 m-2">
                            <a href=""><img src="assets/images/client/04.jpg"
                                    class="avatar avatar-sm-sm rounded-pill shadow-md" alt=""></a>
                        </div>
                        <div class="position-absolute top-0 end-0 m-2">
                            <a href="" class="badge rounded-md bg-light shadow"><i
                                    class="mdi mdi-heart align-middle text-danger"></i> <span
                                    class="text-dark">234</span></a>
                        </div>
                    </div>

                    <div class="card-body p-3">
                        <a routerLink="/nft-item-detail" class="h5 title text-dark">#04 nft title</a>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <span class="text-muted">12 in stock</span>
                            <span class="text-dark">Price: <span class="link">2.333ETH</span></span>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2">
                <div class="card nft nft-item nft-primary rounded shadow overflow-hidden">
                    <div class="nft-image position-relative overflow-hidden">
                        <img src="assets/images/nft/5.jpg" class="img-fluid item-img" alt="">
                        <div class="overlay"></div>
                        <div class="bid-btn p-3 text-center">
                            <a routerLink="/nft-item-detail" class="btn btn-pills"><i class="mdi mdi-gavel fs-6 me-2"></i>
                                Bid</a>
                        </div>
                        <div class="position-absolute top-0 start-0 m-2">
                            <a href=""><img src="assets/images/client/05.jpg"
                                    class="avatar avatar-sm-sm rounded-pill shadow-md" alt=""></a>
                        </div>
                        <div class="position-absolute top-0 end-0 m-2">
                            <a href="" class="badge rounded-md bg-light shadow"><i
                                    class="mdi mdi-heart align-middle text-danger"></i> <span
                                    class="text-dark">235</span></a>
                        </div>
                    </div>

                    <div class="card-body p-3">
                        <a routerLink="/nft-item-detail" class="h5 title text-dark">#05 nft title</a>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <span class="text-muted">12 in stock</span>
                            <span class="text-dark">Price: <span class="link">2.333ETH</span></span>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2">
                <div class="card nft nft-item nft-primary rounded shadow overflow-hidden">
                    <div class="nft-image position-relative overflow-hidden">
                        <img src="assets/images/nft/6.jpg" class="img-fluid item-img" alt="">
                        <div class="overlay"></div>
                        <div class="bid-btn p-3 text-center">
                            <a routerLink="/nft-item-detail" class="btn btn-pills"><i class="mdi mdi-gavel fs-6 me-2"></i>
                                Bid</a>
                        </div>
                        <div class="position-absolute top-0 start-0 m-2">
                            <a href=""><img src="assets/images/client/06.jpg"
                                    class="avatar avatar-sm-sm rounded-pill shadow-md" alt=""></a>
                        </div>
                        <div class="position-absolute top-0 end-0 m-2">
                            <a href="" class="badge rounded-md bg-light shadow"><i
                                    class="mdi mdi-heart align-middle text-danger"></i> <span
                                    class="text-dark">236</span></a>
                        </div>
                    </div>

                    <div class="card-body p-3">
                        <a routerLink="/nft-item-detail" class="h5 title text-dark">#06 nft title</a>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <span class="text-muted">12 in stock</span>
                            <span class="text-dark">Price: <span class="link">2.333ETH</span></span>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2">
                <div class="card nft nft-item nft-primary rounded shadow overflow-hidden">
                    <div class="nft-image position-relative overflow-hidden">
                        <img src="assets/images/nft/7.jpg" class="img-fluid item-img" alt="">
                        <div class="overlay"></div>
                        <div class="bid-btn p-3 text-center">
                            <a routerLink="/nft-item-detail" class="btn btn-pills"><i class="mdi mdi-gavel fs-6 me-2"></i>
                                Bid</a>
                        </div>
                        <div class="position-absolute top-0 start-0 m-2">
                            <a href=""><img src="assets/images/client/07.jpg"
                                    class="avatar avatar-sm-sm rounded-pill shadow-md" alt=""></a>
                        </div>
                        <div class="position-absolute top-0 end-0 m-2">
                            <a href="" class="badge rounded-md bg-light shadow"><i
                                    class="mdi mdi-heart align-middle text-danger"></i> <span
                                    class="text-dark">237</span></a>
                        </div>
                    </div>

                    <div class="card-body p-3">
                        <a routerLink="/nft-item-detail" class="h5 title text-dark">#07 nft title</a>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <span class="text-muted">12 in stock</span>
                            <span class="text-dark">Price: <span class="link">2.333ETH</span></span>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2">
                <div class="card nft nft-item nft-primary rounded shadow overflow-hidden">
                    <div class="nft-image position-relative overflow-hidden">
                        <img src="assets/images/nft/8.jpg" class="img-fluid item-img" alt="">
                        <div class="overlay"></div>
                        <div class="bid-btn p-3 text-center">
                            <a routerLink="/nft-item-detail" class="btn btn-pills"><i class="mdi mdi-gavel fs-6 me-2"></i>
                                Bid</a>
                        </div>
                        <div class="position-absolute top-0 start-0 m-2">
                            <a href=""><img src="assets/images/client/08.jpg"
                                    class="avatar avatar-sm-sm rounded-pill shadow-md" alt=""></a>
                        </div>
                        <div class="position-absolute top-0 end-0 m-2">
                            <a href="" class="badge rounded-md bg-light shadow"><i
                                    class="mdi mdi-heart align-middle text-danger"></i> <span
                                    class="text-dark">238</span></a>
                        </div>
                    </div>

                    <div class="card-body p-3">
                        <a routerLink="/nft-item-detail" class="h5 title text-dark">#08 nft title</a>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <span class="text-muted">12 in stock</span>
                            <span class="text-dark">Price: <span class="link">2.333ETH</span></span>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2">
                <div class="card nft nft-item nft-primary rounded shadow overflow-hidden">
                    <div class="nft-image position-relative overflow-hidden">
                        <img src="assets/images/nft/9.jpg" class="img-fluid item-img" alt="">
                        <div class="overlay"></div>
                        <div class="bid-btn p-3 text-center">
                            <a routerLink="/nft-item-detail" class="btn btn-pills"><i class="mdi mdi-gavel fs-6 me-2"></i>
                                Bid</a>
                        </div>
                        <div class="position-absolute top-0 start-0 m-2">
                            <a href=""><img src="assets/images/client/05.jpg"
                                    class="avatar avatar-sm-sm rounded-pill shadow-md" alt=""></a>
                        </div>
                        <div class="position-absolute top-0 end-0 m-2">
                            <a href="" class="badge rounded-md bg-light shadow"><i
                                    class="mdi mdi-heart align-middle text-danger"></i> <span
                                    class="text-dark">239</span></a>
                        </div>
                    </div>

                    <div class="card-body p-3">
                        <a routerLink="/nft-item-detail" class="h5 title text-dark">#09 nft title</a>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <span class="text-muted">12 in stock</span>
                            <span class="text-dark">Price: <span class="link">2.333ETH</span></span>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2">
                <div class="card nft nft-item nft-primary rounded shadow overflow-hidden">
                    <div class="nft-image position-relative overflow-hidden">
                        <img src="assets/images/nft/10.jpg" class="img-fluid item-img" alt="">
                        <div class="overlay"></div>
                        <div class="bid-btn p-3 text-center">
                            <a routerLink="/nft-item-detail" class="btn btn-pills"><i class="mdi mdi-gavel fs-6 me-2"></i>
                                Bid</a>
                        </div>
                        <div class="position-absolute top-0 start-0 m-2">
                            <a href=""><img src="assets/images/client/06.jpg"
                                    class="avatar avatar-sm-sm rounded-pill shadow-md" alt=""></a>
                        </div>
                        <div class="position-absolute top-0 end-0 m-2">
                            <a href="" class="badge rounded-md bg-light shadow"><i
                                    class="mdi mdi-heart align-middle text-danger"></i> <span
                                    class="text-dark">236</span></a>
                        </div>
                    </div>

                    <div class="card-body p-3">
                        <a routerLink="/nft-item-detail" class="h5 title text-dark">#10 nft title</a>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <span class="text-muted">12 in stock</span>
                            <span class="text-dark">Price: <span class="link">2.333ETH</span></span>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2">
                <div class="card nft nft-item nft-primary rounded shadow overflow-hidden">
                    <div class="nft-image position-relative overflow-hidden">
                        <img src="assets/images/nft/11.jpg" class="img-fluid item-img" alt="">
                        <div class="overlay"></div>
                        <div class="bid-btn p-3 text-center">
                            <a routerLink="/nft-item-detail" class="btn btn-pills"><i class="mdi mdi-gavel fs-6 me-2"></i>
                                Bid</a>
                        </div>
                        <div class="position-absolute top-0 start-0 m-2">
                            <a href=""><img src="assets/images/client/07.jpg"
                                    class="avatar avatar-sm-sm rounded-pill shadow-md" alt=""></a>
                        </div>
                        <div class="position-absolute top-0 end-0 m-2">
                            <a href="" class="badge rounded-md bg-light shadow"><i
                                    class="mdi mdi-heart align-middle text-danger"></i> <span
                                    class="text-dark">237</span></a>
                        </div>
                    </div>

                    <div class="card-body p-3">
                        <a routerLink="/nft-item-detail" class="h5 title text-dark">#11 nft title</a>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <span class="text-muted">12 in stock</span>
                            <span class="text-dark">Price: <span class="link">2.333ETH</span></span>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2">
                <div class="card nft nft-item nft-primary rounded shadow overflow-hidden">
                    <div class="nft-image position-relative overflow-hidden">
                        <img src="assets/images/nft/12.jpg" class="img-fluid item-img" alt="">
                        <div class="overlay"></div>
                        <div class="bid-btn p-3 text-center">
                            <a routerLink="/nft-item-detail" class="btn btn-pills"><i class="mdi mdi-gavel fs-6 me-2"></i>
                                Bid</a>
                        </div>
                        <div class="position-absolute top-0 start-0 m-2">
                            <a href=""><img src="assets/images/client/08.jpg"
                                    class="avatar avatar-sm-sm rounded-pill shadow-md" alt=""></a>
                        </div>
                        <div class="position-absolute top-0 end-0 m-2">
                            <a href="" class="badge rounded-md bg-light shadow"><i
                                    class="mdi mdi-heart align-middle text-danger"></i> <span
                                    class="text-dark">238</span></a>
                        </div>
                    </div>

                    <div class="card-body p-3">
                        <a routerLink="/nft-item-detail" class="h5 title text-dark">#12 nft title</a>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <span class="text-muted">12 in stock</span>
                            <span class="text-dark">Price: <span class="link">2.333ETH</span></span>
                        </div>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="row">
            <div class="col-12 mt-4 pt-2">
                <ul class="pagination justify-content-center mb-0">
                    <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Previous">Prev</a>
                    </li>
                    <li class="page-item active"><a class="page-link" href="javascript:void(0)">1</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Next">Next</a></li>
                </ul>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->