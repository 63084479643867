import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-index-landing-one',
  templateUrl: './index-landing-one.component.html',
  styleUrls: ['./index-landing-one.component.css']
})

/***
 * Langing one Component
 */
export class IndexLandingOneComponent implements OnInit {

  /***
   * Nav light class add
   */
  navClass = 'nav-light';
  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true

  constructor(private modalService: NgbModal) { }

  /***
   * Testimonial Slider
   */
  customOptions: OwlOptions = {
    // loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    // autoplay: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      900: {
        items: 1
      }
    },
    nav: false
  };

  ngOnInit(): void {
  }

  /**
  * Open modal for show the video
  * @param content content of modal
  */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }
  openModal(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }
  
}
