import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-job-detail',
  templateUrl: './page-job-detail.component.html',
  styleUrls: ['./page-job-detail.component.css']
})

/**
 * Page Job-detail Component
 */
export class PageJobDetailComponent implements OnInit {
 
    // Set Topbar
    Settingicon = true
    Menuoption='center'
  constructor() { }

  ngOnInit(): void {
  }

}
