<!-- Hero Start -->
<section class="bg-half-170 bg-primary d-table w-100" style="background-image: url('assets/images/job/bg.png');" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h2 class="title text-white title-dark mb-0"> Jobs / Careers </h2>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a routerLink="/index-job">Careers</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Jobs</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<!-- Job List Start -->
<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-12">
                <div class="row align-items-center">
                    <div class="col-lg-9 col-md-8">
                        <div class="section-title">
                            <span class="text-muted me-3">Showing 1 - 15 out of 452</span>
                        </div>
                    </div><!--end col-->

                    <div class="col-lg-3 col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div class="form custom-form">
                            <select class="form-select form-control" aria-label="Default select example"
                                id="Sortbylist-job">
                                <option selected>All Jobs</option>
                                <option>Full Time</option>
                                <option>Part Time</option>
                                <option>Remote</option>
                                <option>Work From Home</option>
                            </select>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->

                <div class="row">
                    <div class="col-12 mt-4 pt-2">
                        <div
                            class="job-box job-primary d-md-flex align-items-center border-0 shadow rounded p-4 position-relative">
                            <img src="assets/images/job/Circleci.svg" class="avatar avatar-md-sm" alt="">

                            <div class="ms-md-4 mt-4 mt-sm-0">
                                <a routerLink="/job-detail-three" class="title text-dark h5">Web Developer</a>

                                <ul class="list-unstyled mb-0 mt-2">
                                    <li class="d-lg-inline text-muted h6 mb-0 me-lg-2"><i
                                            class="uil uil-map-marker icons"></i> <a href="javascript:void(0)"
                                            class="text-dark">CircleCI</a>, San Francisco</li>
                                    <li class="d-lg-inline text-muted h6 mb-0 me-lg-2"><i
                                            class="uil uil-clock icons"></i> 8hr/ Day</li>
                                    <li class="d-lg-inline text-muted h6 mb-0"><i class="uil uil-bill icons"></i>
                                        30k-35k</li>
                                </ul>

                                <div class="mt-2 d-flex gap-1">
                                    <span class="badge bg-soft">Fulltime</span>
                                    <span class="badge bg-soft-warning">Office</span>
                                </div>
                            </div>

                            <div class="position-absolute top-0 end-0 mt-3 me-3">
                                <a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft"><i
                                        class="uil uil-bookmark align-middle"></i></a>
                            </div>
                        </div>
                    </div><!--end col-->

                    <div class="col-12 mt-4 pt-2">
                        <div
                            class="job-box job-primary d-md-flex align-items-center border-0 shadow rounded p-4 position-relative">
                            <img src="assets/images/job/Mg.svg" class="avatar avatar-md-sm" alt="">

                            <div class="ms-md-4 mt-4 mt-sm-0">
                                <a routerLink="/job-detail-three" class="title text-dark h5">Web Designer</a>

                                <ul class="list-unstyled mb-0 mt-2">
                                    <li class="d-lg-inline text-muted h6 mb-0 me-lg-2"><i
                                            class="uil uil-map-marker icons"></i> <a href="javascript:void(0)"
                                            class="text-dark">MG</a>, San Francisco</li>
                                    <li class="d-lg-inline text-muted h6 mb-0 me-lg-2"><i
                                            class="uil uil-clock icons"></i> 8hr/ Day</li>
                                    <li class="d-lg-inline text-muted h6 mb-0"><i class="uil uil-bill icons"></i>
                                        30k-35k</li>
                                </ul>

                                <div class="mt-2 d-flex gap-1">
                                    <span class="badge bg-soft">Fulltime</span>
                                    <span class="badge bg-soft-warning">Office</span>
                                </div>
                            </div>

                            <div class="position-absolute top-0 end-0 mt-3 me-3">
                                <a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft"><i
                                        class="uil uil-bookmark align-middle"></i></a>
                            </div>
                        </div>
                    </div><!--end col-->

                    <div class="col-12 mt-4 pt-2">
                        <div
                            class="job-box job-primary d-md-flex align-items-center border-0 shadow rounded p-4 position-relative">
                            <img src="assets/images/job/Codepen.svg" class="avatar avatar-md-sm" alt="">

                            <div class="ms-md-4 mt-4 mt-sm-0">
                                <a routerLink="/job-detail-three" class="title text-dark h5">Web Developer</a>

                                <ul class="list-unstyled mb-0 mt-2">
                                    <li class="d-lg-inline text-muted h6 mb-0 me-lg-2"><i
                                            class="uil uil-map-marker icons"></i> <a href="javascript:void(0)"
                                            class="text-dark">Codepen</a>, San Francisco</li>
                                    <li class="d-lg-inline text-muted h6 mb-0 me-lg-2"><i
                                            class="uil uil-clock icons"></i> 8hr/ Day</li>
                                    <li class="d-lg-inline text-muted h6 mb-0"><i class="uil uil-bill icons"></i>
                                        30k-35k</li>
                                </ul>

                                <div class="mt-2 d-flex gap-1">
                                    <span class="badge bg-soft">Fulltime</span>
                                    <span class="badge bg-soft-warning">Office</span>
                                </div>
                            </div>

                            <div class="position-absolute top-0 end-0 mt-3 me-3">
                                <a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft"><i
                                        class="uil uil-bookmark align-middle"></i></a>
                            </div>
                        </div>
                    </div><!--end col-->

                    <div class="col-12 mt-4 pt-2">
                        <div
                            class="job-box job-primary d-md-flex align-items-center border-0 shadow rounded p-4 position-relative">
                            <img src="assets/images/job/Discord.svg" class="avatar avatar-md-sm" alt="">

                            <div class="ms-md-4 mt-4 mt-sm-0">
                                <a routerLink="/job-detail-three" class="title text-dark h5">Web Developer</a>

                                <ul class="list-unstyled mb-0 mt-2">
                                    <li class="d-lg-inline text-muted h6 mb-0 me-lg-2"><i
                                            class="uil uil-map-marker icons"></i> <a href="javascript:void(0)"
                                            class="text-dark">Discord</a>, San Francisco</li>
                                    <li class="d-lg-inline text-muted h6 mb-0 me-lg-2"><i
                                            class="uil uil-clock icons"></i> 8hr/ Day</li>
                                    <li class="d-lg-inline text-muted h6 mb-0"><i class="uil uil-bill icons"></i>
                                        30k-35k</li>
                                </ul>

                                <div class="mt-2 d-flex gap-1">
                                    <span class="badge bg-soft">Fulltime</span>
                                    <span class="badge bg-soft-warning">Office</span>
                                </div>
                            </div>

                            <div class="position-absolute top-0 end-0 mt-3 me-3">
                                <a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft"><i
                                        class="uil uil-bookmark align-middle"></i></a>
                            </div>
                        </div>
                    </div><!--end col-->

                    <div class="col-12 mt-4 pt-2">
                        <div
                            class="job-box job-primary d-md-flex align-items-center border-0 shadow rounded p-4 position-relative">
                            <img src="assets/images/job/Eslint.svg" class="avatar avatar-md-sm" alt="">

                            <div class="ms-md-4 mt-4 mt-sm-0">
                                <a routerLink="/job-detail-three" class="title text-dark h5">Web Developer</a>

                                <ul class="list-unstyled mb-0 mt-2">
                                    <li class="d-lg-inline text-muted h6 mb-0 me-lg-2"><i
                                            class="uil uil-map-marker icons"></i> <a href="javascript:void(0)"
                                            class="text-dark">Eslint</a>, San Francisco</li>
                                    <li class="d-lg-inline text-muted h6 mb-0 me-lg-2"><i
                                            class="uil uil-clock icons"></i> 8hr/ Day</li>
                                    <li class="d-lg-inline text-muted h6 mb-0"><i class="uil uil-bill icons"></i>
                                        30k-35k</li>
                                </ul>

                                <div class="mt-2 d-flex gap-1">
                                    <span class="badge bg-soft">Fulltime</span>
                                    <span class="badge bg-soft-warning">Office</span>
                                </div>
                            </div>

                            <div class="position-absolute top-0 end-0 mt-3 me-3">
                                <a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft"><i
                                        class="uil uil-bookmark align-middle"></i></a>
                            </div>
                        </div>
                    </div><!--end col-->

                    <div class="col-12 mt-4 pt-2">
                        <div
                            class="job-box job-primary d-md-flex align-items-center border-0 shadow rounded p-4 position-relative">
                            <img src="assets/images/job/Gitlab.svg" class="avatar avatar-md-sm" alt="">

                            <div class="ms-md-4 mt-4 mt-sm-0">
                                <a routerLink="/job-detail-three" class="title text-dark h5">Web Developer</a>

                                <ul class="list-unstyled mb-0 mt-2">
                                    <li class="d-lg-inline text-muted h6 mb-0 me-lg-2"><i
                                            class="uil uil-map-marker icons"></i> <a href="javascript:void(0)"
                                            class="text-dark">Gitlab</a>, San Francisco</li>
                                    <li class="d-lg-inline text-muted h6 mb-0 me-lg-2"><i
                                            class="uil uil-clock icons"></i> 8hr/ Day</li>
                                    <li class="d-lg-inline text-muted h6 mb-0"><i class="uil uil-bill icons"></i>
                                        30k-35k</li>
                                </ul>

                                <div class="mt-2 d-flex gap-1">
                                    <span class="badge bg-soft">Fulltime</span>
                                    <span class="badge bg-soft-warning">Office</span>
                                </div>
                            </div>

                            <div class="position-absolute top-0 end-0 mt-3 me-3">
                                <a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft"><i
                                        class="uil uil-bookmark align-middle"></i></a>
                            </div>
                        </div>
                    </div><!--end col-->

                    <div class="col-12 mt-4 pt-2">
                        <div
                            class="job-box job-primary d-md-flex align-items-center border-0 shadow rounded p-4 position-relative">
                            <img src="assets/images/job/Udemy.svg" class="avatar avatar-md-sm" alt="">

                            <div class="ms-md-4 mt-4 mt-sm-0">
                                <a routerLink="/job-detail-three" class="title text-dark h5">Web Developer</a>

                                <ul class="list-unstyled mb-0 mt-2">
                                    <li class="d-lg-inline text-muted h6 mb-0 me-lg-2"><i
                                            class="uil uil-map-marker icons"></i> <a href="javascript:void(0)"
                                            class="text-dark">Udemy</a>, San Francisco</li>
                                    <li class="d-lg-inline text-muted h6 mb-0 me-lg-2"><i
                                            class="uil uil-clock icons"></i> 8hr/ Day</li>
                                    <li class="d-lg-inline text-muted h6 mb-0"><i class="uil uil-bill icons"></i>
                                        30k-35k</li>
                                </ul>

                                <div class="mt-2 d-flex gap-1">
                                    <span class="badge bg-soft">Fulltime</span>
                                    <span class="badge bg-soft-warning">Office</span>
                                </div>
                            </div>

                            <div class="position-absolute top-0 end-0 mt-3 me-3">
                                <a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft"><i
                                        class="uil uil-bookmark align-middle"></i></a>
                            </div>
                        </div>
                    </div><!--end col-->

                    <div class="col-12 mt-4 pt-2">
                        <div
                            class="job-box job-primary d-md-flex align-items-center border-0 shadow rounded p-4 position-relative">
                            <img src="assets/images/job/Webhooks.svg" class="avatar avatar-md-sm" alt="">

                            <div class="ms-md-4 mt-4 mt-sm-0">
                                <a routerLink="/job-detail-three" class="title text-dark h5">Web Developer</a>

                                <ul class="list-unstyled mb-0 mt-2">
                                    <li class="d-lg-inline text-muted h6 mb-0 me-lg-2"><i
                                            class="uil uil-map-marker icons"></i> <a href="javascript:void(0)"
                                            class="text-dark">Webhooks</a>, San Francisco</li>
                                    <li class="d-lg-inline text-muted h6 mb-0 me-lg-2"><i
                                            class="uil uil-clock icons"></i> 8hr/ Day</li>
                                    <li class="d-lg-inline text-muted h6 mb-0"><i class="uil uil-bill icons"></i>
                                        30k-35k</li>
                                </ul>

                                <div class="mt-2 d-flex gap-1">
                                    <span class="badge bg-soft">Fulltime</span>
                                    <span class="badge bg-soft-warning">Office</span>
                                </div>
                            </div>

                            <div class="position-absolute top-0 end-0 mt-3 me-3">
                                <a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft"><i
                                        class="uil uil-bookmark align-middle"></i></a>
                            </div>
                        </div>
                    </div><!--end col-->

                    <div class="col-12 mt-4 pt-2">
                        <div
                            class="job-box job-primary d-md-flex align-items-center border-0 shadow rounded p-4 position-relative">
                            <img src="assets/images/job/Sketch.svg" class="avatar avatar-md-sm" alt="">

                            <div class="ms-md-4 mt-4 mt-sm-0">
                                <a routerLink="/job-detail-three" class="title text-dark h5">Web Developer</a>

                                <ul class="list-unstyled mb-0 mt-2">
                                    <li class="d-lg-inline text-muted h6 mb-0 me-lg-2"><i
                                            class="uil uil-map-marker icons"></i> <a href="javascript:void(0)"
                                            class="text-dark">Sketch</a>, San Francisco</li>
                                    <li class="d-lg-inline text-muted h6 mb-0 me-lg-2"><i
                                            class="uil uil-clock icons"></i> 8hr/ Day</li>
                                    <li class="d-lg-inline text-muted h6 mb-0"><i class="uil uil-bill icons"></i>
                                        30k-35k</li>
                                </ul>

                                <div class="mt-2 d-flex gap-1">
                                    <span class="badge bg-soft">Fulltime</span>
                                    <span class="badge bg-soft-warning">Office</span>
                                </div>
                            </div>

                            <div class="position-absolute top-0 end-0 mt-3 me-3">
                                <a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft"><i
                                        class="uil uil-bookmark align-middle"></i></a>
                            </div>
                        </div>
                    </div><!--end col-->

                    <div class="col-12 mt-4 pt-2">
                        <!-- PAGINATION START -->
                        <ul class="pagination justify-content-center mb-0">
                            <li class="page-item"><a class="page-link" href="javascript:void(0)"
                                    aria-label="Previous">Prev</a></li>
                            <li class="page-item active"><a class="page-link" href="javascript:void(0)">1</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:void(0)"
                                    aria-label="Next">Next</a></li>
                        </ul>
                        <!-- PAGINATION END -->
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->
<!-- Job List End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->