import { Component } from '@angular/core';

@Component({
  selector: 'app-portfolio-about',
  templateUrl: './portfolio-about.component.html',
  styleUrls: ['./portfolio-about.component.css']
})
export class PortfolioAboutComponent {
      // Set Topbar
      Settingicon = true
  Menuoption = 'portfolio'
  footerVariant = 'footer-four';
}
