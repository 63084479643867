import { Component } from '@angular/core';

@Component({
  selector: 'app-job-detail-three',
  templateUrl: './job-detail-three.component.html',
  styleUrls: ['./job-detail-three.component.css']
})
export class JobDetailThreeComponent {
   // Set Topbar Option
   Menuoption = 'job';
  Settingicon = true
  footerVariant = 'bg-light';
}
