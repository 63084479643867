<!-- Hero Start -->
<section class="section" id="home" style="padding-bottom: 0;">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="title-heading text-center mt-4">
          <div class="mt-4">
            <a routerLink="/page-job-apply" class="btn btn-primary mt-4"
              ><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-person-lines-fill" viewBox="0 0 16 16">
                <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z"/>
              </svg> <span class="hover" style="color:#FFC01D  ; margin-left: 8px;" ></span>Apply Now</a
            >
          </div>

          <h1 class="heading mb-3">
            <ngx-typed-js [strings]="['Full-Stack Developer','Web Developer', 'Designer', 'UX Developer', 
            'Mobile Developer','Freelancer']"
              [shuffle]="true" [typeSpeed]="80" [loop]="true">
              Job Vacancies for <span class="element text-primary typing"></span>
            </ngx-typed-js>
          </h1>
          <p class="para-desc mx-auto text-muted">Huge community of designers and developers ready for your project.</p>
          <form class="rounded p-4 mt-4">
            <div class="row text-start">
              <!-- <div class="col-lg-5 col-md-4">
                <div class="mb-0">
                  <input type="text" class="form-control" required placeholder="Keywords">
                </div>
              </div> -->
              <!--end col-->

              <!-- <div class="col-lg-7 col-md-8">
                <div class="row align-items-center">
                  <div class="col-md-4 mt-4 mt-sm-0">
                    <div class="mb-0">
                      <select class="form-control custom-select">
                        <option selected="">Location</option>
                        <option value="AF">Afghanistan</option>
                        <option value="AX">&Aring;land Islands</option>
                        <option value="AL">Albania</option>
                        <option value="DZ">Algeria</option>
                        <option value="AS">American Samoa</option>
                        <option value="AD">Andorra</option>
                        <option value="AO">Angola</option>
                        <option value="AI">Anguilla</option>
                        <option value="AQ">Antarctica</option>
                      </select>
                    </div>
                  </div>
                  <end col-->

                  <!-- <div class="col-md-4 mt-4 mt-sm-0">
                    <div class="mb-0">
                      <select class="form-control custom-select">
                        <option selected="">Job Types</option>
                        <option value="4">Accounting</option>
                        <option value="1">IT & Software</option>
                        <option value="3">Marketing</option>
                        <option value="5">Banking</option>
                      </select>
                    </div>
                  </div> -->
                  <!--end col-->
<!-- 
                  <div class="col-md-4 mt-4 mt-sm-0">
                    <div class="d-grid">
                      <input type="submit" id="search" name="search" class="searchbtn btn btn-primary" value="Search">
                    </div>
                  </div> -->

                </div>

              <!-- </div>  -->
            <!-- </div> -->
          </form>
          <!-- <p class="text-muted mb-0 mt-4"><span class="text-dark">Keywords :</span> Web Developer, Web Designer, Php
            Developer, IOS Developer, etc...</p> -->
        </div>
      </div>
      <!--end col-->

      <div class="col-12 mt-4 pt-3" style="width: 60%;">
        <img src="assets/images/job/home.png" class="img-fluid" alt="">
      </div>
      <hr style="color: whitesmoke;height: 4px;">
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<!-- <div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div> -->
<!--Shape End-->

<!-- Start -->
<section class="section" style="padding: 1px 0px 70px;">
  <!-- <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Popular Catagories</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
        <div class="card categories overflow-hidden rounded shadow border-0">
          <img src="assets/images/work/1.jpg" class="img-fluid" alt="">
          <div class="card-body">
            <ul class="list-unstyled d-flex justify-content-between mb-0">
              <li><a href="javascript:void(0)" class="title h6 text-dark">Communications</a></li>
              <li class="h6 mb-0 jobs">125 Jobs</li>
            </ul>
          </div>
        </div>
      </div>
 

      <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
        <div class="card categories overflow-hidden rounded shadow border-0">
          <img src="assets/images/work/2.jpg" class="img-fluid" alt="">
          <div class="card-body">
            <ul class="list-unstyled d-flex justify-content-between mb-0">
              <li><a href="javascript:void(0)" class="title h6 text-dark">Accounting</a></li>
              <li class="h6 mb-0 jobs">125 Jobs</li>
            </ul>
          </div>
        </div>
      </div>
  

      <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
        <div class="card categories overflow-hidden rounded shadow border-0">
          <img src="assets/images/work/3.jpg" class="img-fluid" alt="">
          <div class="card-body">
            <ul class="list-unstyled d-flex justify-content-between mb-0">
              <li><a href="javascript:void(0)" class="title h6 text-dark">Restaurant</a></li>
              <li class="h6 mb-0 jobs">125 Jobs</li>
            </ul>
          </div>
        </div>
      </div>
  

      <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
        <div class="card categories overflow-hidden rounded shadow border-0">
          <img src="assets/images/work/4.jpg" class="img-fluid" alt="">
          <div class="card-body">
            <ul class="list-unstyled d-flex justify-content-between mb-0">
              <li><a href="javascript:void(0)" class="title h6 text-dark">Health</a></li>
              <li class="h6 mb-0 jobs">125 Jobs</li>
            </ul>
          </div>
        </div>
      </div>


      <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
        <div class="card categories overflow-hidden rounded shadow border-0">
          <img src="assets/images/work/5.jpg" class="img-fluid" alt="">
          <div class="card-body">
            <ul class="list-unstyled d-flex justify-content-between mb-0">
              <li><a href="javascript:void(0)" class="title h6 text-dark">Finance</a></li>
              <li class="h6 mb-0 jobs">125 Jobs</li>
            </ul>
          </div>
        </div>
      </div>
      

      <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
        <div class="card categories overflow-hidden rounded shadow border-0">
          <img src="assets/images/work/6.jpg" class="img-fluid" alt="">
          <div class="card-body">
            <ul class="list-unstyled d-flex justify-content-between mb-0">
              <li><a href="javascript:void(0)" class="title h6 text-dark">Facilities</a></li>
              <li class="h6 mb-0 jobs">125 Jobs</li>
            </ul>
          </div>
        </div>
      </div>
 

      <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
        <div class="card categories overflow-hidden rounded shadow border-0">
          <img src="assets/images/work/7.jpg" class="img-fluid" alt="">
          <div class="card-body">
            <ul class="list-unstyled d-flex justify-content-between mb-0">
              <li><a href="javascript:void(0)" class="title h6 text-dark">HR</a></li>
              <li class="h6 mb-0 jobs">125 Jobs</li>
            </ul>
          </div>
        </div>
      </div>
 

      <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
        <div class="card categories overflow-hidden rounded shadow border-0">
          <img src="assets/images/work/8.jpg" class="img-fluid" alt="">
          <div class="card-body">
            <ul class="list-unstyled d-flex justify-content-between mb-0">
              <li><a href="javascript:void(0)" class="title h6 text-dark">Construction</a></li>
              <li class="h6 mb-0 jobs">125 Jobs</li>
            </ul>
          </div>
        </div>
      </div>
    
    </div>
  
  </div> -->


  <!-- Companies Start -->
  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Featured Jobs</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Tenzed</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <a routerLink="/page-job-detail">
          <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow">
            <img src="../../../../assets/images/Microsoft_.NET_logo.svg.png" class="avatar avatar-ex-sm" alt="">
            <div class="flex-1 ms-3">
              <h4 class="title mb-0 text-light">.Net Developer</h4>
              <p class="text-muted mb-0">Surat, India</p>
            </div>
          </div>
        </a>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <a routerLink="/page-job-detail">
          <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow">
            <img src="../../../../assets/images/icons8-angularjs.svg" class="avatar avatar-ex-sm" alt="">
            <div class="flex-1 ms-3">
              <h4 class="title mb-0 text-light">Angular Developer</h4>
              <p class="text-muted mb-0">Surat, India</p>
            </div>
          </div>
        </a>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <a routerLink="/page-job-detail">
          <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow">
            <img src="../../../../assets/images/icons8-react-native-48.png" class="avatar avatar-ex-sm" alt="">
            <div class="flex-1 ms-3">
              <h4 class="title mb-0 text-light">React Developer</h4>
              <p class="text-muted mb-0">Surat, India</p>
            </div>
          </div>
        </a>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <a routerLink="/page-job-detail">
          <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow">
            <img src="assets/images/job/Eslint.svg" class="avatar avatar-ex-sm" alt="">
            <div class="flex-1 ms-3">
              <h4 class="title mb-0 text-light">UI/UX Developer</h4>
              <p class="text-muted mb-0">Surat, India</p>
            </div>
          </div>
        </a>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <a routerLink="/page-job-detail">
          <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow">
            <img src="../../../../assets/images/icons8-flutter-48.png" class="avatar avatar-ex-sm" alt="">
            <div class="flex-1 ms-3">
              <h4 class="title mb-0 text-light">Flutter Developer</h4>
              <p class="text-muted mb-0">Surat, India</p>
            </div>
          </div>
        </a>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <a routerLink="/page-job-detail">
          <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow">
            <img src="../../../../assets/images/icons8-wordpress.svg" class="avatar avatar-ex-sm" alt="">
            <div class="flex-1 ms-3">
              <h4 class="title mb-0 text-light">WordPress Developer</h4>
              <p class="text-muted mb-0">Surat, India</p>
            </div>
          </div>
        </a>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <a routerLink="/page-job-detail">
          <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow">
            <img src="../../../../assets/images/icons8-shopify-64.png" class="avatar avatar-ex-sm" alt="">
            <div class="flex-1 ms-3">
              <h4 class="title mb-0 text-light">Shopify Developer</h4>
              <p class="text-muted mb-0">Surat, India</p>
            </div>
          </div>
        </a>
      </div>
      <!--end col-->
<!-- 
      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <a routerLink="/page-job-detail">
          <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow">
            <img src="assets/images/job/Twilio.svg" class="avatar avatar-ex-sm" alt="">
            <div class="flex-1 ms-3">
              <h4 class="title mb-0 text-light">Graphics Designer</h4>
              <p class="text-muted mb-0">Surat, India</p>
            </div>
          </div>
        </a>
      </div> -->
      <!--end col-->

      <!-- <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <a routerLink="/page-job-detail">
          <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow">
            <img src="assets/images/job/Udemy.svg" class="avatar avatar-ex-sm" alt="">
            <div class="flex-1 ms-3">
              <h4 class="title mb-0 text-light">Data Entry</h4>
              <p class="text-muted mb-0">Surat, India</p>
            </div>
          </div>
        </a>
      </div> -->
      <!--end col-->

      <!-- <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <a routerLink="/page-job-detail">
          <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow">
            <img src="assets/images/job/Webhooks.svg" class="avatar avatar-ex-sm" alt="">
            <div class="flex-1 ms-3">
              <h4 class="title mb-0 text-light">Sketch Designer</h4>
              <p class="text-muted mb-0">Surat, India</p>
            </div>
          </div>
        </a>
      </div> -->
      <!--end col-->

      <!-- <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <a routerLink="/page-job-detail">
          <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow">
            <img src="assets/images/job/Appdynamics.svg" class="avatar avatar-ex-sm" alt="">
            <div class="flex-1 ms-3">
              <h4 class="title mb-0 text-light">Web Developer</h4>
              <p class="text-muted mb-0">Surat, India</p>
            </div>
          </div>
        </a>
      </div> -->
      <!--end col-->

      <!-- <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <a routerLink="/page-job-detail">
          <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow">
            <img src="assets/images/job/Gradle.svg" class="avatar avatar-ex-sm" alt="">
            <div class="flex-1 ms-3">
              <h4 class="title mb-0 text-light">Game Developer</h4>
              <p class="text-muted mb-0">Surat, India</p>
            </div>
          </div>
        </a>
      </div> -->
      <!--end col-->

      <!-- <div class="col-12 text-center mt-4 pt-2">
        <a routerLink="/page-jobs" class="btn btn-primary">See All Jobs <i
            class="uil uil-angle-right-b align-middle"></i></a>
      </div> -->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!-- Companies End -->

  <!-- Candidates Start -->
         <h4 class="title mb-4">Expert Candidates</h4>
     <!-- <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
        <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Tenzed</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
    </div>
    <app-member [memberData]="memberData"></app-member>
  </div> -->
  <!--end container-->
  <!-- Candidates End -->

  <!-- Testi start -->
  <!-- <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Candidates Review</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Tenzed</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
  
    </div>


    <div class="row">
      <div class="col-12 mt-4 pt-2">
        <owl-carousel-o id="customer-testi" [options]="customOptions">
          <ng-template carouselSlide>
            <div class="card customer-testi border-0 text-center">
              <div class="card-body">
                <img src="assets/images/client/01.jpg"
                  class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" alt="">
                <p class="text-muted mt-4">" It seems that only fragments of the original text remain in the Lorem Ipsum
                  texts used today. "</p>
                <h6 class="text-primary">- Thomas Israel</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi border-0 text-center">
              <div class="card-body">
                <img src="assets/images/client/02.jpg"
                  class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" alt="">
                <p class="text-muted mt-4">" The most well-known dummy text is the 'Lorem Ipsum', which is said to have
                  originated in the 16th century. "</p>
                <h6 class="text-primary">- Carl Oliver</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi border-0 text-center">
              <div class="card-body">
                <img src="assets/images/client/03.jpg"
                  class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" alt="">
                <p class="text-muted mt-4">" One disadvantage of Lorum Ipsum is that in Latin certain letters appear
                  more frequently than others. "</p>
                <h6 class="text-primary">- Barbara McIntosh</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi border-0 text-center">
              <div class="card-body">
                <img src="assets/images/client/04.jpg"
                  class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" alt="">
                <p class="text-muted mt-4">" Thus, Lorem Ipsum has only limited suitability as a visual filler for
                  German texts. "</p>
                <h6 class="text-primary">- Jill Webb</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi border-0 text-center">
              <div class="card-body">
                <img src="assets/images/client/05.jpg"
                  class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" alt="">
                <p class="text-muted mt-4">" There is now an abundance of readable dummy texts. These are usually used
                  when a text is required. "</p>
                <h6 class="text-primary">- Dean Tolle</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi border-0 text-center">
              <div class="card-body">
                <img src="assets/images/client/06.jpg"
                  class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" alt="">
                <p class="text-muted mt-4">" According to most sources, Lorum Ipsum can be traced back to a text
                  composed by Cicero. "</p>
                <h6 class="text-primary">- Christa Smith</h6>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>

      </div>

    </div>

  </div> -->
  <!--end container-->
  <!-- <div class="container pt-5">
    <app-clients-logo brand_class="col-lg-2 col-md-2 col-6 text-center"></app-clients-logo>
  </div> -->
  <!--end container-->
  <!-- Testi end -->
</section>
<!--end section-->
<!-- End -->

<!-- Shape Start -->
<!-- <div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div> -->
<!--Shape End-->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->