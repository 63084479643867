import { Component } from '@angular/core';

@Component({
  selector: 'app-crypto-faqs',
  templateUrl: './crypto-faqs.component.html',
  styleUrls: ['./crypto-faqs.component.css']
})
export class CryptoFaqsComponent {
// Set Topbar
Menuoption = 'cryptocurrency'
footerVariant = 'footer-three';


}
