import { Component } from '@angular/core';

@Component({
  selector: 'app-shop-fullwidth-lists',
  templateUrl: './shop-fullwidth-lists.component.html',
  styleUrls: ['./shop-fullwidth-lists.component.css']
})
export class ShopFullwidthListsComponent {
// Set Topbar
  Menuoption = 'shop'
  footerVariant = 'footer-two';

/***
 * Header button list show
 */
shopPages = true;
}
