     <!-- Hero Start -->
     <section class="bg-half-170 bg-light d-table w-100" style="background: url('assets/images/shape02.png') top left;" id="home">
        <div class="container">
            <div class="row mt-5 justify-content-center">
                <div class="col-lg-12 text-center">
                    <div class="pages-heading">
                        <h4 class="title mb-0"> Services </h4>
                    </div>
                </div>  <!--end col-->
            </div><!--end row-->
            
            <div class="position-breadcrumb">
                <nav aria-label="breadcrumb" class="d-inline-block">
                    <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                        <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                        <li class="breadcrumb-item"><a routerLink="/index-crypto">Crypto</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Services</li>
                    </ul>
                </nav>
            </div>
        </div> <!--end container-->
    </section><!--end section-->
    <!-- Hero End -->

    <!-- Shape Start -->
    <div class="position-relative">
        <div class="shape overflow-hidden text-color-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!--Shape End-->

    <!-- Start -->
    <section class="section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="section-title text-center mb-4 pb-2">
                        <h4 class="title mb-3">Become a crypto trader in seconds</h4>
                        <p class="text-muted para-desc mx-auto mb-0">We've got everything you need to start trading.</p>
                    </div>
                </div><!--end col-->
            </div><!--end row-->

            <div class="row justify-content-center">
                <div class="col-lg-10">
                    <div class="row justify-content-center">
                        <div class="col-md-6 mt-4 pt-2">
                            <div class="feature feature-primary position-relative ps-4 py-4 pe-5 shadow rounded overflow-hidden">
                                <div class="me-md-5">
                                    <a href="javascript:void(0)" class="title h5 text-dark">Buy & Sell Crypto</a>
                                    <p class="text-muted mb-3 mt-4">Buy or sell Bitcoin, Ethereum, and other crypto assets with your credit card, debit card, or bank account.</p>
                                    <a href="javascript:void(0)" class="text-primary">Buy Bitcoin <i class="uil uil-arrow-right"></i></a>
                                </div>
                                <div class="position-absolute top-50 start-100 translate-middle">
                                    <img src="assets/images/crypto/cpu.svg" class="avatar avatar-large opacity-2" alt="">
                                </div>
                            </div>
                        </div><!--end col-->
                        
                        <div class="col-md-6 mt-4 pt-2">
                            <div class="feature feature-primary position-relative ps-4 py-4 pe-5 shadow rounded overflow-hidden">
                                <div class="me-md-5">
                                    <a href="javascript:void(0)" class="title h5 text-dark">Trade Assets</a>
                                    <p class="text-muted mb-3 mt-4">Discover new and innovative crypto assets with over 200 spot trading pairs and 25 margin trading pairs.</p>
                                    <a href="javascript:void(0)" class="text-primary">View Exchange <i class="uil uil-arrow-right"></i></a>
                                </div>
                                <div class="position-absolute top-50 start-100 translate-middle">
                                    <img src="assets/images/crypto/analytics.svg" class="avatar avatar-large opacity-2" alt="">
                                </div>
                            </div>
                        </div><!--end col-->
                        
                        <div class="col-md-6 mt-4 pt-2">
                            <div class="feature feature-primary position-relative ps-4 py-4 pe-5 shadow rounded overflow-hidden">
                                <div class="me-md-5">
                                    <a href="javascript:void(0)" class="title h5 text-dark">Hedge with Poloniex Futures</a>
                                    <p class="text-muted mb-3 mt-4">Trade Bitcoin, Ethereum, and other perpetual with up to 100x leverage on Poloniex Futures.</p>
                                    <a href="javascript:void(0)" class="text-primary">Trade Futures <i class="uil uil-arrow-right"></i></a>
                                </div>
                                <div class="position-absolute top-50 start-100 translate-middle">
                                    <img src="assets/images/crypto/exchange.svg" class="avatar avatar-large opacity-2" alt="">
                                </div>
                            </div>
                        </div><!--end col-->
                        
                        <div class="col-md-6 mt-4 pt-2">
                            <div class="feature feature-primary position-relative ps-4 py-4 pe-5 shadow rounded overflow-hidden">
                                <div class="me-md-5">
                                    <a href="javascript:void(0)" class="title h5 text-dark">Earn Rewards for Trading</a>
                                    <p class="text-muted mb-3 mt-4">Never miss an opportunity for a little competition. Join our latest campaigns to earn rewards.</p>
                                    <a href="javascript:void(0)" class="text-primary">Join Competition <i class="uil uil-arrow-right"></i></a>
                                </div>
                                <div class="position-absolute top-50 start-100 translate-middle">
                                    <img src="assets/images/crypto/money.svg" class="avatar avatar-large opacity-2" alt="">
                                </div>
                            </div>
                        </div><!--end col-->
                    </div><!--end row-->
                </div><!--end col-->
            </div><!--end row-->
        </div><!--end container-->

        <div class="container mt-100 mt-60">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="section-title text-center mb-4 pb-2">
                        <h4 class="title mb-3">Services</h4>
                        <p class="text-muted para-desc mx-auto mb-0">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                    </div>
                </div><!--end col-->
            </div><!--end row-->

            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow text-center">
                        <div class="icons text-center mx-auto">
                            <i class="uil uil-file-contract-dollar d-block rounded h3 mb-0"></i>
                        </div>
                        <div class="card-body p-0 mt-4">                                            
                            <a href="javascript:void(0)" class="title h5 text-dark">Support</a>
                            <p class="text-muted mt-2">Composed in a pseudo-Latin language which more or less corresponds.</p>
                            <a href="javascript:void(0)" class="read-more">Read More <i class="uil uil-angle-right-b"></i></a>
                            <i class="uil uil-file-contract-dollar full-img"></i>
                        </div>
                    </div>
                </div><!--end col-->
                
                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow text-center">
                        <div class="icons text-center mx-auto">
                            <i class="uil uil-analytics d-block rounded h3 mb-0"></i>
                        </div>
                        <div class="card-body p-0 mt-4">                                            
                            <a href="javascript:void(0)" class="title h5 text-dark">Service Level</a>
                            <p class="text-muted mt-2">Composed in a pseudo-Latin language which more or less corresponds.</p>
                            <a href="javascript:void(0)" class="read-more">Read More <i class="uil uil-angle-right-b"></i></a>
                            <i class="uil uil-analytics full-img"></i>
                        </div>
                    </div>
                </div><!--end col-->
                
                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow text-center">
                        <div class="icons text-center mx-auto">
                            <i class="uil uil-dashboard d-block rounded h3 mb-0"></i>
                        </div>
                        <div class="card-body p-0 mt-4">                                            
                            <a href="javascript:void(0)" class="title h5 text-dark">Take profit / Stoploss</a>
                            <p class="text-muted mt-2">Composed in a pseudo-Latin language which more or less corresponds.</p>
                            <a href="javascript:void(0)" class="read-more">Read More <i class="uil uil-angle-right-b"></i></a>
                            <i class="uil uil-dashboard full-img"></i>
                        </div>
                    </div>
                </div><!--end col-->
                
                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow text-center">
                        <div class="icons text-center mx-auto">
                            <i class="uil uil-swatchbook d-block rounded h3 mb-0"></i>
                        </div>
                        <div class="card-body p-0 mt-4">                                            
                            <a href="javascript:void(0)" class="title h5 text-dark">Trailing Stop</a>
                            <p class="text-muted mt-2">Composed in a pseudo-Latin language which more or less corresponds.</p>
                            <a href="javascript:void(0)" class="read-more">Read More <i class="uil uil-angle-right-b"></i></a>
                            <i class="uil uil-swatchbook full-img"></i>
                        </div>
                    </div>
                </div><!--end col-->
                
                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow text-center">
                        <div class="icons text-center mx-auto">
                            <i class="uil uil-exchange d-block rounded h3 mb-0"></i>
                        </div>
                        <div class="card-body p-0 mt-4">                                            
                            <a href="javascript:void(0)" class="title h5 text-dark">Pending Orders</a>
                            <p class="text-muted mt-2">Composed in a pseudo-Latin language which more or less corresponds.</p>
                            <a href="javascript:void(0)" class="read-more">Read More <i class="uil uil-angle-right-b"></i></a>
                            <i class="uil uil-exchange full-img"></i>
                        </div>
                    </div>
                </div><!--end col-->
                
                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow text-center">
                        <div class="icons text-center mx-auto">
                            <i class="uil uil-lock-alt d-block rounded h3 mb-0"></i>
                        </div>
                        <div class="card-body p-0 mt-4">                                            
                            <a href="javascript:void(0)" class="title h5 text-dark">Platform Fees</a>
                            <p class="text-muted mt-2">Composed in a pseudo-Latin language which more or less corresponds.</p>
                            <a href="javascript:void(0)" class="read-more">Read More <i class="uil uil-angle-right-b"></i></a>
                            <i class="uil uil-lock-alt full-img"></i>
                        </div>
                    </div>
                </div><!--end col-->
            </div><!--end row-->
        </div><!--end container-->

        <div class="container mt-100 mt-60">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="section-title text-center mb-4 pb-2">
                        <h6 class="text-primary">Our Vision</h6>
                        <h4 class="title mb-3 mt-2">Cryptocurrency in Every Wallet</h4>

                        <p class="text-muted para-desc mx-auto mb-0">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                    </div>
                </div><!--end col-->
            </div><!--end row-->

            <div class="row justify-content-center my-4 py-2">
                <div class="col-md-6">
                    <img src="assets/images/logo-icon.png" class="img-fluid d-block mx-auto" alt="">
                </div><!--end col-->
            </div><!--end row-->

            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6 mt-4 pt-2">
                    <div class="text-center">
                        <h5 class="text-muted mb-0">Founded in</h5>
                        <h2 class="mb-0 display-5 mt-4 fw-bold text-primary"><span class="counter-value" data-target="2021">2021</span></h2>
                    </div>
                </div><!--end col-->

                <div class="col-lg-3 col-md-6 mt-4 pt-2">
                    <div class="text-center">
                        <h5 class="text-muted mb-0">Team Member</h5>
                        <h2 class="mb-0 display-5 mt-4 fw-bold text-primary"><span class="counter-value" data-target="1000">1000</span>+</h2>
                    </div>
                </div><!--end col-->

                <div class="col-lg-3 col-md-6 mt-4 pt-2">
                    <div class="text-center">
                        <h5 class="text-muted mb-0">Users</h5>
                        <h2 class="mb-0 display-5 mt-4 fw-bold text-primary"><span class="counter-value" data-target="10">10</span>M</h2>
                    </div>
                </div><!--end col-->

                <div class="col-lg-3 col-md-6 mt-4 pt-2">
                    <div class="text-center">
                        <h5 class="text-muted mb-0">Insurance Coverage (USD)</h5>
                        <h2 class="mb-0 display-5 mt-4 fw-bold text-primary"><span class="counter-value" data-target="410">410</span>M</h2>
                    </div>
                </div><!--end col-->
            </div><!--end row-->
        </div><!--end container-->
    </section><!--end section-->
    <!-- Vision End -->

    <!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->