        <!-- Hero Start -->
        <section class="bg-home d-flex align-items-center" id="home">
            <div class="bg-overlay bg-gradient-primary"></div>
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-5 col-12">
                        <div class="title-heading">
                            <span class="badge bg-soft-success rounded-md">Sale tokens</span>
                            <h4 class="display-5 fw-bold text-white title-dark mt-3 mb-4">The token sale is live!</h4>
                            <p class="text-white-50 para-desc mx-auto mb-0">Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap v5 html page.</p>
                            <div class="mt-4">
                                <a href="#!" data-type="youtube" data-id="yba7hPeTSjk" class="btn btn-icon btn-pills btn-primary m-1 lightbox position-relative">
                                    <i-feather name="video" class="icons"></i-feather>
                                </a>
                                <small class="fw-medium text-white-50 align-middle ms-1">Watch Now</small>
                            </div>
                        </div>
                    </div><!--end col-->

                    <div class="col-lg-5 offset-lg-1 col-md-7 col-12 mt-4 pt-2">
                        <div class="card border-0 shadow rounded p-4 bg-light overflow-hidden ms-lg-4">
                            <img src="assets/images/logo-icon.png" class="avatar avatar-md-md d-block mx-auto" alt="">
                            <h5 class="text-center mt-3">Landrick Token Sale</h5>
                            <div class="row">
                                <div class="col-md-12 text-center">
                                    <div id="token-sale">
                                        <ul class="count-down list-unstyled m-0">
                                            <li id="days" class="count-number fw-medium mb-0 list-inline-item px-3"></li>
                                            <li id="hours" class="count-number fw-medium mb-0 list-inline-item px-3"></li>
                                            <li id="mins" class="count-number fw-medium mb-0 list-inline-item px-3"></li>
                                            <li id="secs" class="count-number fw-medium mb-0 list-inline-item px-3"></li>
                                            <li id="end" class="h1"></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            
                            <p class="text-muted text-center">Token sale ends <span class="text-dark fw-medium">August 25th 2021</span></p>

                            <div class="progress-box mt-4">
                                <div class="progress rounded-md" style="height: 10px;">
                                    <div class="progress-bar position-relative bg-primary rounded-md" style="width:60%;">
                                        <div class="progress-value d-block text-muted h6">Funded 60% = $ 600000</div>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between mt-2">
                                    <h6 class="title text-muted text-start mb-0">$ 1000</h6>
                                    <h6 class="title text-muted text-end mb-0">$ 1000000</h6>
                                </div>
                            </div><!--end process box-->

                            <ul class="list-unstyled mb-0 mt-4">
                                <li class="h6 mb-0"><i class="uil uil-arrow-circle-right text-primary align-middle fs-5 me-1"></i><span class="text-muted">Soft Cap:</span> &nbsp;10000 USD</li>
                                <li class="h6 mb-0"><i class="uil uil-arrow-circle-right text-primary align-middle fs-5 me-1"></i><span class="text-muted">Min. transaction Amount:</span> &nbsp;1000 USD</li>
                                <li class="h6 mb-0"><i class="uil uil-arrow-circle-right text-primary align-middle fs-5 me-1"></i><span class="text-muted">Target:</span> &nbsp;900000 USD</li>
                            </ul>

                            <div class="mt-4">
                                <a href="javascript:void(0)" class="btn btn-primary w-100">Join Now</a>
                            </div>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container--> 
        </section><!--end section-->
        <!-- Hero End -->

        <!-- Start -->
        <section class="section">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <div class="section-title text-center mb-4 pb-2">
                            <h4 class="title mb-3">It's easier than you think</h4>
                            <p class="text-muted para-desc mx-auto mb-0">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->

                <div class="row justify-content-center">
                    <div class="col-md-4 col-12 mt-4">
                        <div class="card border-0 text-center features feature-primary feature-clean p-4">
                            <div class="icons text-center mx-auto">
                                <i class="uil uil-user-check d-block rounded h3 mb-0"></i>
                            </div>

                            <div class="card-body p-0 mt-4">
                                <h5>Verify your identity</h5>
                                <p class="text-muted mb-0">Composed in a pseudo-Latin language which more or less pseudo-Latin language corresponds.</p>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-md-4 col-12 mt-4">
                        <div class="card border-0 text-center features feature-primary feature-clean p-4">
                            <div class="icons text-center mx-auto">
                                <i class="uil uil-qrcode-scan d-block rounded h3 mb-0"></i>
                            </div>

                            <div class="card-body p-0 mt-4">
                                <h5>Sale the Bitcoin</h5>
                                <p class="text-muted mb-0">Composed in a pseudo-Latin language which more or less pseudo-Latin language corresponds.</p>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-md-4 col-12 mt-4">
                        <div class="card border-0 text-center features feature-primary feature-clean p-4">
                            <div class="icons text-center mx-auto">
                                <i class="uil uil-university d-block rounded h3 mb-0"></i>
                            </div>

                            <div class="card-body p-0 mt-4">
                                <h5>Money in your bank account</h5>
                                <p class="text-muted mb-0">Composed in a pseudo-Latin language which more or less pseudo-Latin language corresponds.</p>
                            </div>
                        </div>
                    </div><!--end col-->
                </div><!--end container-->
            </div><!--end container-->
            
            <div class="container mt-100 mt-60">
                <div class="row">
                    <div class="col-12">
                        <div class="section-title text-center mb-4 pb-2">
                            <h4 class="title mb-4">Success Roadmap</h4>
                            <p class="text-muted para-desc mx-auto mb-0">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->

                <div class="row">
                    <div class="col-12 mt-4 pt-2">
                        <owl-carousel-o id="customer-testi" [options]="customOptions">
                            <ng-template carouselSlide>
                            <div class="tiny-slide text-center">
                                <div class="card features feature-primary pt-4 border-0 item-box">
                                    <div class="icon text-center text-primary rounded-pill mx-auto mb-3 fs-5">
                                        <i class="uil uil-pen"></i>
                                    </div>
                                    <p class="text-muted mb-0">January, 2021</p>
                                    <h6 class="mb-0 mt-1">Planning</h6>
                                </div>
                            </div>
                            </ng-template>

                            <ng-template carouselSlide>
                            <div class="tiny-slide text-center">
                                <div class="card features feature-primary pt-4 border-0 item-box">
                                    <div class="icon text-center text-primary rounded-pill mx-auto mb-3 fs-5">
                                        <i class="uil uil-bitcoin"></i>
                                    </div>
                                    <p class="text-muted mb-0">February, 2021</p>
                                    <h6 class="mb-0 mt-1">Blockchain Starts</h6>
                                </div>
                            </div>
                        </ng-template>

                        <ng-template carouselSlide>
                            <div class="tiny-slide text-center">
                                <div class="card features feature-primary pt-4 border-0 item-box">
                                    <div class="icon text-center text-primary rounded-pill mx-auto mb-3 fs-5">
                                        <i class="uil uil-clipboard-blank"></i>
                                    </div>
                                    <p class="text-muted mb-0">March, 2021</p>
                                    <h6 class="mb-0 mt-1">Expanded Licensing</h6>
                                </div>
                            </div>
                            </ng-template>

                            <ng-template carouselSlide>
                            <div class="tiny-slide text-center">
                                <div class="card features p-4 border-0 bg-primary item-box">
                                    <div class="icon text-center text-white title-dark rounded-pill mx-auto mb-3 fs-5">
                                        <i class="uil uil-money-insert"></i>
                                    </div>
                                    <p class="text-white-50 mb-0">April, 2021</p>
                                    <h6 class="text-white-50 mb-0 mt-1">Token Sale</h6>
                                </div>
                            </div>
                            </ng-template>

                            <ng-template carouselSlide>
                            <div class="tiny-slide text-center">
                                <div class="card features feature-primary pt-4 border-0 item-box">
                                    <div class="icon text-center text-primary rounded-pill mx-auto mb-3 fs-5">
                                        <i class="uil uil-airplay"></i>
                                    </div>
                                    <p class="text-muted mb-0">May, 2021</p>
                                    <h6 class="mb-0 mt-1">Project Launch</h6>
                                </div>
                            </div>
                            </ng-template>

                            <ng-template carouselSlide>
                            <div class="tiny-slide text-center">
                                <div class="card features feature-primary pt-4 border-0 item-box">
                                    <div class="icon text-center text-primary rounded-pill mx-auto mb-3 fs-5">
                                        <i class="uil uil-euro-circle"></i>
                                    </div>
                                    <p class="text-muted mb-0">June, 2021</p>
                                    <h6 class="mb-0 mt-1">Tier 5 sold</h6>
                                </div>
                            </div>
                            </ng-template>

                            <ng-template carouselSlide>
                            <div class="tiny-slide text-center">
                                <div class="card features feature-primary pt-4 border-0 item-box">
                                    <div class="icon text-center text-primary rounded-pill mx-auto mb-3 fs-5">
                                        <i class="uil uil-desktop"></i>
                                    </div>
                                    <p class="text-muted mb-0">December, 2021</p>
                                    <h6 class="mb-0 mt-1">Project Live</h6>
                                </div>
                            </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->

            <div class="container mt-100 mt-60">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <div class="section-title text-center mb-4 pb-2">
                            <h6 class="text-primary">Our Vision</h6>
                            <h4 class="title mb-3 mt-2">Cryptocurrency in Every Wallet</h4>

                            <p class="text-muted para-desc mx-auto mb-0">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->

                <div class="row justify-content-center my-4 py-2">
                    <div class="col-md-6">
                        <img src="assets/images/logo-icon.png" class="img-fluid d-block mx-auto" alt="">
                    </div><!--end col-->
                </div><!--end row-->

                <div class="row justify-content-center">
                    <div class="col-lg-3 col-md-6 mt-4 pt-2">
                        <div class="text-center">
                            <h5 class="text-muted mb-0">Founded in</h5>
                            <h2 class="mb-0 display-5 mt-4 fw-bold text-primary"><span class="counter-value" data-target="2021">2021</span></h2>
                        </div>
                    </div><!--end col-->

                    <div class="col-lg-3 col-md-6 mt-4 pt-2">
                        <div class="text-center">
                            <h5 class="text-muted mb-0">Team Member</h5>
                            <h2 class="mb-0 display-5 mt-4 fw-bold text-primary"><span class="counter-value" data-target="1000">1000</span>+</h2>
                        </div>
                    </div><!--end col-->

                    <div class="col-lg-3 col-md-6 mt-4 pt-2">
                        <div class="text-center">
                            <h5 class="text-muted mb-0">Users</h5>
                            <h2 class="mb-0 display-5 mt-4 fw-bold text-primary"><span class="counter-value" data-target="10">10</span>M</h2>
                        </div>
                    </div><!--end col-->

                    <div class="col-lg-3 col-md-6 mt-4 pt-2">
                        <div class="text-center">
                            <h5 class="text-muted mb-0">Insurance Coverage (USD)</h5>
                            <h2 class="mb-0 display-5 mt-4 fw-bold text-primary"><span class="counter-value" data-target="410">410</span>M</h2>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- Vision End -->

        <!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->
