<!-- Hero Start -->
<section class="bg-half-260 d-table w-100" style="background: url('assets/images/digital/home-bg.png') center center"
  id="home">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6">
        <div class="title-heading mt-4">
          <div class="alert alert-light alert-pills shadow" role="alert">
            <span class="badge rounded-pill bg-danger me-1">v4.2</span>
            <span class="content"> Build <span class="text-primary">anything</span> you want - Landrick.</span>
          </div>
          <h1 class="heading mb-3">A <span class="text-primary">Digital</span> Product <br> Marketing Agency</h1>
          <p class="para-desc text-muted">Launch your campaign and benefit from our expertise on designing and managing
            conversion centered bootstrap v5 html page.</p>
          <div class="mt-4 pt-2">
            <a href="javascript:void(0)" class="btn btn-pills btn-primary m-1">Get Started</a>
            <a (click)="openWindowmModal(contentModal)" href="javascript:void(0);" data-type="youtube"
              data-id="yba7hPeTSjk" class="btn btn-icon btn-pills btn-primary m-1">
              <i-feather name="video" class="icons"></i-feather>
            </a><span class="fw-bold text-uppercase small align-middle ms-1">Watch Now</span>
            <ng-template #contentModal let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <iframe src="https://player.vimeo.com/video/99025203" height="450" width="780" frameborder="0"
                  allowfullscreen allow="autoplay; encrypted-media"></iframe>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <img src="assets/images/digital/seo.gif" class="img-fluid" alt="">
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Partners start -->
<section class="py-5 border-bottom border-top">
  <div class="container">
    <app-clients-logo brand_class="col-lg-2 col-md-2 col-6 text-center"></app-clients-logo>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Partners End -->

<!-- Start -->
<section class="section overflow-hidden">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-12">
        <div class="card border-0 text-center features feature-primary feature-clean rounded">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-flip-v d-block rounded h3 mb-0"></i>
          </div>

          <div class="card-body p-0 mt-4">
            <a href="javascript:void(0)" class="title h5 text-dark">High-End Anaylizing</a>
            <p class="text-muted mt-3 mb-0">Composed in a pseudo-Latin language which more or less pseudo-Latin language
              corresponds.</p>
            <div class="mt-2">
              <a href="javascript:void(0)" class="text-primary">Read More <i-feather name="arrow-right"
                  class="fea icon-sm"></i-feather></a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 mt-sm-0 pt-4 pt-sm-0">
        <div class="card border-0 text-center features feature-primary feature-clean rounded">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-layer-group d-block rounded h3 mb-0"></i>
          </div>

          <div class="card-body p-0 mt-4">
            <a href="javascript:void(0)" class="title h5 text-dark">Excellence Track Record</a>
            <p class="text-muted mt-3 mb-0">Composed in a pseudo-Latin language which more or less pseudo-Latin language
              corresponds.</p>
            <div class="mt-2">
              <a href="javascript:void(0)" class="text-primary">Read More <i-feather name="arrow-right"
                  class="fea icon-sm"></i-feather></a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 mt-lg-0 pt-4 pt-lg-0">
        <div class="card border-0 text-center features feature-primary feature-clean rounded">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-object-group d-block rounded h3 mb-0"></i>
          </div>

          <div class="card-body p-0 mt-4">
            <a href="javascript:void(0)" class="title h5 text-dark">Our Dedicated Support</a>
            <p class="text-muted mt-3 mb-0">Composed in a pseudo-Latin language which more or less pseudo-Latin language
              corresponds.</p>
            <div class="mt-2">
              <a href="javascript:void(0)" class="text-primary">Read More <i-feather name="arrow-right"
                  class="fea icon-sm"></i-feather></a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-md-6">
        <img src="assets/images/digital/about.png" class="img-fluid" alt="">
      </div>
      <!--end col-->

      <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title">
          <h4 class="title mb-4">We provide best SEO service</h4>
          <p class="text-muted">Launch your campaign and benefit from our expertise on designing and managing conversion
            centered bootstrap v5 html page.</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for Tomorrow</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Our Talented &amp; Experienced Marketing Agency
            </li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Create your own skin to match your brand</li>
          </ul>
          <div class="mt-4 pt-2">
            <a href="javascript:void(0)" class="btn btn-primary m-1 me-2">Read More <i
                class="uil uil-angle-right-b"></i></a>
            <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" data-type="youtube"
              data-id="yba7hPeTSjk" class="btn btn-icon btn-pills btn-primary m-1">
              <i-feather name="video" class="icons"></i-feather>
            </a><span class="fw-bold text-uppercase small align-middle ms-1">Watch Now</span>
            <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <iframe src="https://player.vimeo.com/video/99025203" height="450" width="780" frameborder="0"
                  allowfullscreen allow="autoplay; encrypted-media"></iframe>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">

      <ul ngbNav #nav="ngbNav" class="nav-pills nav-justified flex-column rounded col-md-5 pr-0">
        <li ngbNavItem>
          <a ngbNavLink class="rounded">
            <div class="p-3 text-start">
              <h5>Management Tools</h5>
              <p class="text-muted tab-para mb-0">Dummy text is text that is used in the publishing industry or by
                web designers to occupy the space which will later be filled with 'real' content.</p>
            </div>
          </a>
          <ng-template ngbNavContent>
            <div class="saas-feature-shape-right position-relative">
              <img src="assets/images/digital/1.png" class="img-fluid mx-auto d-block" alt="">
            </div>
          </ng-template>
        </li>
        <li ngbNavItem>
          <a ngbNavLink class="rounded">
            <div class="p-3 text-start">
              <h5>Increase Effectiveness</h5>
              <p class="text-muted tab-para mb-0">Dummy text is text that is used in the publishing industry or by
                web designers to occupy the space which will later be filled with 'real' content.</p>
            </div>
          </a>
          <ng-template ngbNavContent>
            <img src="assets/images/digital/2.png" class="img-fluid mx-auto d-block" alt="">
          </ng-template>
        </li>
        <li ngbNavItem>
          <a ngbNavLink class="rounded">
            <div class="p-3 text-start">
              <h5>Data Analysis</h5>
              <p class="text-muted tab-para mb-0">Dummy text is text that is used in the publishing industry or by
                web designers to occupy the space which will later be filled with 'real' content.</p>
            </div>
          </a>
          <ng-template ngbNavContent>
            <img src="assets/images/digital/3.png" class="img-fluid mx-auto d-block" alt="">
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="col-md-7 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0"></div>

    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6 col-12 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <img src="assets/images/illustrator/app.svg" alt="">
      </div>
      <!--end col--->

      <div class="col-lg-6 col-md-6 col-12 order-1 order-md-2">
        <div class="section-title">
          <h4 class="title mb-4">A marketing agency that <br> helps you succeed</h4>
          <p class="text-muted">Due to its widespread use as filler text for layouts, non-readability is of great
            importance: human perception is tuned to recognize certain patterns and repetitions in texts. If the
            distribution of letters visual impact.</p>
          <a href="javascript:void(0)" class="btn btn-primary">Find More
            <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
          </a>
        </div>
      </div>
      <!--end col--->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Testi Start -->
<section class="section bg-light" style="background: url('assets/images/digital/testi.png') center center;">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <!-- <div id="single-owl" class="owl-carousel owl-theme"> -->
        <owl-carousel-o id="customer-testi" [options]="customOptions">
          <ng-template carouselSlide>
            <div class="tiny-slide">
              <div class="client-testi text-center">
                <p class="h5 text-muted fst-italic fw-normal">" The most well-known dummy text is the 'Lorem Ipsum',
                  which is said to have originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin
                  language which more or less corresponds to 'proper' Latin. It contains a series of real Latin words. "
                </p>
                <div class="commenter mt-5">
                  <img src="assets/images/client/01.jpg"
                    class="avatar avatar-medium rounded-circle d-block mx-auto shadow-lg" alt="">
                  <div class="content mt-4">
                    <ul class="list-unstyled h6 text-warning mb-0">
                      <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    </ul>
                    <h5 class="text-primary mb-0">Ronald Kinsey</h5>
                    <small class="text-muted">User</small>
                  </div>
                </div>
              </div>
              <!--end review content-->
            </div>
            <!--end review content-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="testi text-center">
              <p class="h5 text-muted fst-italic fw-normal">" The most well-known dummy text is the 'Lorem
                Ipsum', which is said to have originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin
                language which more or less corresponds to 'proper' Latin. It contains a series of real Latin words. "
              </p>
              <div class="commenter mt-5">
                <img src="assets/images/client/02.jpg"
                  class="avatar avatar-medium rounded-circle d-block mx-auto shadow-lg" alt="">
                <div class="content mt-4">
                  <ul class="list-unstyled h6 text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                  <h5 class="text-primary mb-0">Raquel Service</h5>
                  <small class="text-muted">App User</small>
                </div>
              </div>
            </div>
            <!--end review content-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="testi text-center">
              <p class="h5 text-muted fst-italic fw-normal">" The most well-known dummy text is the 'Lorem
                Ipsum', which is said to have originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin
                language which more or less corresponds to 'proper' Latin. It contains a series of real Latin words. "
              </p>
              <div class="commenter mt-5">
                <img src="assets/images/client/03.jpg"
                  class="avatar avatar-medium rounded-circle d-block mx-auto shadow-lg" alt="">
                <div class="content mt-4">
                  <ul class="list-unstyled h6 text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                  <h5 class="text-primary mb-0">Leona Moore</h5>
                  <small class="text-muted">Client</small>
                </div>
              </div>
            </div>
            <!--end review content-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="testi text-center">
              <p class="h5 text-muted fst-italic fw-normal">" The most well-known dummy text is the 'Lorem
                Ipsum', which is said to have originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin
                language which more or less corresponds to 'proper' Latin. It contains a series of real Latin words. "
              </p>
              <div class="commenter mt-5">
                <img src="assets/images/client/04.jpg"
                  class="avatar avatar-medium rounded-circle d-block mx-auto shadow-lg" alt="">
                <div class="content mt-4">
                  <ul class="list-unstyled h6 text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                  <h5 class="text-primary mb-0">Mark Rose</h5>
                  <small class="text-muted">CEO</small>
                </div>
              </div>
            </div>
            <!--end review content-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="testi text-center">
              <p class="h5 text-muted fst-italic fw-normal">" The most well-known dummy text is the 'Lorem
                Ipsum', which is said to have originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin
                language which more or less corresponds to 'proper' Latin. It contains a series of real Latin words. "
              </p>
              <div class="commenter mt-5">
                <img src="assets/images/client/05.jpg"
                  class="avatar avatar-medium rounded-circle d-block mx-auto shadow-lg" alt="">
                <div class="content mt-4">
                  <ul class="list-unstyled h6 text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                  <h5 class="text-primary mb-0">Marsha Donoho</h5>
                  <small class="text-muted">User</small>
                </div>
              </div>
            </div>
            <!--end review content-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="testi text-center">
              <p class="h5 text-muted fst-italic fw-normal">" The most well-known dummy text is the 'Lorem
                Ipsum', which is said to have originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin
                language which more or less corresponds to 'proper' Latin. It contains a series of real Latin words. "
              </p>
              <div class="commenter mt-5">
                <img src="assets/images/client/06.jpg"
                  class="avatar avatar-medium rounded-circle d-block mx-auto shadow-lg" alt="">
                <div class="content mt-4">
                  <ul class="list-unstyled h6 text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                  <h5 class="text-primary mb-0">Xavier Giancola</h5>
                  <small class="text-muted">App User</small>
                </div>
              </div>
            </div>
            <!--end review content-->
          </ng-template>
        </owl-carousel-o>
        <!-- </div> -->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Testi End -->

<!-- Start -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title mb-4 pb-2 text-center">
          <h4 class="title mb-4">Our Pricing Plans</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class="col-12 mt-4 pt-2">
        <div class="text-center">
          <ul ngbNav #navPrice="ngbNav" class="nav-pills rounded-pill justify-content-center d-inline-block border">
            <li ngbNavItem class="d-inline-block">
              <a ngbNavLink class="px-3 py-2 rounded-pill">
                Monthly
              </a>
              <ng-template ngbNavContent>
                <div class="row align-items-center">
                  <div class="col-md-6 col-12 mt-4 pt-4 ps-md-0 pe-md-0">
                    <div class="card pricing pricing-primary starter-plan text-center shadow rounded border-0">
                      <div class="card-body py-5">
                        <h6 class="title fw-bold text-uppercase text-primary mb-4">Starter</h6>
                        <div class="d-flex justify-content-center mb-4">
                          <span class="h4 mb-0 mt-2">$</span>
                          <span class="price h1 mb-0">0</span>
                          <span class="h4 align-self-end mb-1">/mo</span>
                        </div>

                        <ul class="list-unstyled mb-0 ps-0">
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                class="uil uil-check-circle align-middle"></i></span>Full Access</li>
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                class="uil uil-check-circle align-middle"></i></span>Source Files</li>
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                class="uil uil-check-circle align-middle"></i></span>Free Appointments</li>
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                class="uil uil-check-circle align-middle"></i></span>Enhanced Security</li>
                        </ul>
                        <a href="javascript:void(0)" class="btn btn-primary mt-4">Get Started</a>
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-md-6 col-12 mt-4 pt-2 pt-md-4 ps-md-0 pe-md-0">
                    <div class="card pricing-rates bg-light text-center shadow rounded border-0">
                      <div class="card-body py-5">
                        <h6 class="title fw-bold text-uppercase text-primary mb-4">Professional</h6>
                        <div class="d-flex justify-content-center mb-4">
                          <span class="h4 mb-0 mt-2">$</span>
                          <span class="price h1 mb-0">59</span>
                          <span class="h4 align-self-end mb-1">/mo</span>
                        </div>

                        <ul class="list-unstyled mb-0 ps-0">
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                class="uil uil-check-circle align-middle"></i></span>Full Access</li>
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                class="uil uil-check-circle align-middle"></i></span>Source Files</li>
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                class="uil uil-check-circle align-middle"></i></span>Free Appointments</li>
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                class="uil uil-check-circle align-middle"></i></span>Enhanced Security</li>
                        </ul>
                        <a href="javascript:void(0)" class="btn btn-primary mt-4">Try it now</a>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </ng-template>
            </li>
            <li ngbNavItem class="d-inline-block">
              <a ngbNavLink class="px-3 py-2 rounded-pill">
                Yearly<span class="badge rounded-pill bg-danger ms-1">10% Off </span>
              </a>
              <ng-template ngbNavContent>
                <div class="row align-items-center">
                  <div class="col-md-6 col-12 mt-4 pt-4 ps-md-0 pe-md-0">
                    <div class="card pricing-rates starter-plan text-center shadow rounded border-0">
                      <div class="card-body py-5">
                        <h6 class="title fw-bold text-uppercase text-primary mb-4">Starter</h6>
                        <div class="d-flex justify-content-center mb-4">
                          <span class="h4 mb-0 mt-2">$</span>
                          <span class="price h1 mb-0">9</span>
                          <span class="h4 align-self-end mb-1">/mo</span>
                        </div>

                        <ul class="list-unstyled mb-0 ps-0">
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                class="uil uil-check-circle align-middle"></i></span>Full Access</li>
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                class="uil uil-check-circle align-middle"></i></span>Source Files</li>
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                class="uil uil-check-circle align-middle"></i></span>Free Appointments</li>
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                class="uil uil-check-circle align-middle"></i></span>Enhanced Security</li>
                        </ul>
                        <a href="javascript:void(0)" class="btn btn-primary mt-4">Get Started</a>
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-md-6 col-12 mt-4 pt-2 pt-md-4 ps-md-0 pe-md-0">
                    <div class="card pricing-rates bg-light text-center shadow rounded border-0">
                      <div class="card-body py-5">
                        <h6 class="title fw-bold text-uppercase text-primary mb-4">Professional</h6>
                        <div class="d-flex justify-content-center mb-4">
                          <span class="h4 mb-0 mt-2">$</span>
                          <span class="price h1 mb-0">49</span>
                          <span class="h4 align-self-end mb-1">/mo</span>
                        </div>

                        <ul class="list-unstyled mb-0 ps-0">
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                class="uil uil-check-circle align-middle"></i></span>Full Access</li>
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                class="uil uil-check-circle align-middle"></i></span>Source Files</li>
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                class="uil uil-check-circle align-middle"></i></span>Free Appointments</li>
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                class="uil uil-check-circle align-middle"></i></span>Enhanced Security</li>
                        </ul>
                        <a href="javascript:void(0)" class="btn btn-primary mt-4">Try it now</a>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="navPrice" class="col-lg-7 col-md-12 text-center offset-md-2"></div>
        </div>
      </div>
      <!--end col-->

    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title mb-4 pb-2 text-center">
          <h4 class="title mb-4">Frequently Asked Questions</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex">
          <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0">How our <span class="text-primary">Landrick</span> work ?</h5>
            <p class="answer text-muted mb-0">Due to its widespread use as filler text for layouts, non-readability is
              of great importance: human perception is tuned to recognize certain patterns and repetitions in texts.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex">
          <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0"> What is the main process open account ?</h5>
            <p class="answer text-muted mb-0">If the distribution of letters and 'words' is random, the reader will not
              be distracted from making a neutral judgement on the visual impact</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex">
          <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0"> How to make unlimited data entry ?</h5>
            <p class="answer text-muted mb-0">Furthermore, it is advantageous when the dummy text is relatively
              realistic so that the layout impression of the final publication is not compromised.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex">
          <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0"> Is <span class="text-primary">Landrick</span> safer to use with my account ?</h5>
            <p class="answer text-muted mb-0">The most well-known dummy text is the 'Lorem Ipsum', which is said to have
              originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin language which more or less
              corresponds to 'proper' Latin.</p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row my-md-5 pt-md-3 my-4 pt-2 pb-lg-4 justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">Have Question ? Get in touch!</h4>
          <p class="text-muted para-desc mx-auto">Start working with <span class="text-primary fw-bold">Landrick</span>
            that can provide everything you need to generate awareness, drive traffic, connect.</p>
          <a routerLink="/page-contact-two" class="btn btn-primary mt-4"><i class="uil uil-phone"></i> Contact us</a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- End -->

<section class="section border-bottom bg-light">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-5 col-12 order-1 order-md-2">
        <img src="assets/images/illustrator/user_interface.svg" class="img-fluid mx-auto d-block" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-7 col-12 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title">
          <h4 class="title mb-4">Available for your <br> Smartphones</h4>
          <p class="text-muted para-desc mb-0">Start working with <span class="text-primary fw-bold">Landrick</span>
            that can provide everything you need to generate awareness, drive traffic, connect.</p>
          <div class="my-4">
            <a href="javascript:void(0)" class="btn btn-lg btn-dark mt-2 me-2"><i class="uil uil-apple"></i> App
              Store</a>
            <a href="javascript:void(0)" class="btn btn-lg btn-dark mt-2"><i class="uil uil-google-play"></i> Play
              Store</a>
          </div>

          <div class="d-inline-block">
            <div class="pt-4 d-flex align-items-center border-top">
              <i-feather name="smartphone" class="fea icon-md me-2 text-primary"></i-feather>
              <div class="content">
                <h6 class="mb-0">Install app now on your cellphones</h6>
                <a href="javascript:void(0)" class="text-primary">Learn More <i
                    class="uil uil-angle-right-b align-middle"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->