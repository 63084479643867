<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0"> New Trends in SEO </h4>
                    <ul class="list-unstyled mt-4">
                        <li class="list-inline-item h6 user text-muted me-2"> <span class="text-dark">Client :</span>
                            Calvin Carlo</li>
                        <li class="list-inline-item h6 date text-muted"> <span class="text-dark">Date :</span> 23th Sep,
                            2019</li>
                    </ul>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Pages</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Work</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Work Detail</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<!-- Start Work Detail -->
<section class="section">
    <div class="container">
        <div class="row">
            <!-- WORK START -->
            <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="sticky-bar">
                    <div class="row me-lg-4">
                        <div class="col-lg-12">
                            <div class="work-details">
                                <h4 class="title mb-3 border-bottom pb-3">Project Name :</h4>
                                <p class="text-muted">Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit
                                    totam atque dignissimos porro, exercitationem, neque alias ea aliquid quibusdam
                                    voluptates impedit maxime aut asperiores consequatur iste. Corporis fuga ducimus
                                    dignissimos. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci non
                                    dolorem consequatur vitae hic.</p>
                                <p class="text-muted mb-0">Suscipit totam atque dignissimos porro, exercitationem, neque
                                    alias ea aliquid quibusdam voluptates impedit maxime aut asperiores consequatur
                                    iste. Corporis fuga ducimus dignissimos.</p>
                            </div>
                        </div>
                        <!--end col-->

                        <div class="col-lg-7 mt-4 pt-2">
                            <div class="card work-details rounded bg-light border-0">
                                <div class="card-body">
                                    <h5 class="card-title border-bottom pb-3 mb-3">Project Info :</h5>
                                    <dl class="row mb-0">
                                        <dt class="col-md-4 col-5">Client :</dt>
                                        <dd class="col-md-8 col-7 text-muted">Calvin Carlo</dd>

                                        <dt class="col-md-4 col-5">Category :</dt>
                                        <dd class="col-md-8 col-7 text-muted">Web Design</dd>

                                        <dt class="col-md-4 col-5">Date :</dt>
                                        <dd class="col-md-8 col-7 text-muted">23rd Sep, 2019</dd>

                                        <dt class="col-md-4 col-5">Website :</dt>
                                        <dd class="col-md-8 col-7 text-muted">www.yourdomain.com</dd>

                                        <dt class="col-md-4 col-5">Location :</dt>
                                        <dd class="col-md-8 col-7 text-muted">3/2/64 Mongus Street, UK</dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                        <!--end col-->
                    </div>
                    <!--end row-->
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-5 col-md-6 order-1 order-md-2">
                <div class="port-images sticky-sidebar">
                    <img src="assets/images/work/1.jpg" class="img-fluid mx-auto d-block rounded" alt="img">
                    <img src="assets/images/work/2.jpg" class="img-fluid mx-auto d-block rounded mt-4" alt="img">
                    <img src="assets/images/work/3.jpg" class="img-fluid mx-auto d-block rounded mt-4" alt="img">
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <!-- PAGINATION START -->
            <div class="col-12 mt-4 pt-2">
                <ul class="pagination justify-content-center mb-0">
                    <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Previous">Prev</a>
                    </li>
                    <li class="page-item active"><a class="page-link" href="javascript:void(0)">1</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Next">Next</a></li>
                </ul>
            </div>
            <!--end col-->
            <!-- PAGINATION END -->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-3">Latest News &amp; Blog</h4>
                    <p class="text-muted mx-auto para-desc mb-0">Obviously I'm a Web Designer. Experienced with all
                        stages of the development cycle for dynamic web projects.</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
        <app-blog [blogData]="blogData"></app-blog>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Work Detail -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->