import { Component } from '@angular/core';

@Component({
  selector: 'app-crypto-about',
  templateUrl: './crypto-about.component.html',
  styleUrls: ['./crypto-about.component.css']
})
export class CryptoAboutComponent {
// Set Topbar
  Menuoption = 'cryptocurrency'
  footerVariant = 'footer-three';


}
