<!-- Hero Start -->
<section class="bg-half-170 bg-primary d-table w-100" style="background-image: url('assets/images/job/bg.png');" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title text-white title-dark mb-0"> Candidates </h4>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a routerLink="/index-job">Careers</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Candidates</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- Hero End -->


<section class="section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-9 col-md-8">
                <div class="section-title">
                    <h6 class="mb-0">Showing 1 – 8 of 10 results</h6>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="form custom-form">
                    <div class="mb-0">
                        <select class="form-select form-control" aria-label="Default select example"
                            id="Sortbylist-job">
                            <option selected>Default</option>
                            <option>Newest</option>
                            <option>Oldest</option>
                            <option>Random</option>
                        </select>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="row">
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="job-box job-primary candidate-list card rounded border-0 shadow">
                    <div class="card-body">
                        <ul class="list-unstyled align-items-center">
                            <li class="list-inline-item float-end"><a href="javascript:void(0)"
                                    class="text-muted like"><i class="mdi mdi-heart h5 mb-0"></i></a></li>
                            <li class="list-inline-item"><span
                                    class="badge rounded-pill bg-soft-success">Featured</span></li>
                        </ul>

                        <div class="content text-center">
                            <img src="assets/images/client/01.jpg" class="avatar avatar-md-md shadow-md rounded-circle"
                                alt="">
                            <ul class="list-unstyled mb-1 mt-2">
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                            </ul>
                            <a routerLink="/job-candidate" class="text-dark h5 name">Calvin Carlo</a>
                            <p class="text-muted my-1">Front-end Developer</p>

                            <span class="text-muted"><i class="uil uil-graduation-cap h4 mb-0 me-2 icons"></i>Experience
                                <span class="text-success">3+ years</span></span>

                            <ul class="list-unstyled mt-3">
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">PHP</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">WordPress</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">Web Design</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">CSS</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">JS</a></li>
                            </ul>
                            <div class="d-grid">
                                <a routerLink="/job-candidate" class="btn btn-soft-primary">Read More <i
                                        class="uil uil-angle-right-b align-middle"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="job-box job-primary candidate-list card rounded border-0 shadow">
                    <div class="card-body">
                        <ul class="list-unstyled align-items-center">
                            <li class="list-inline-item float-end"><a href="javascript:void(0)"
                                    class="text-muted like"><i class="mdi mdi-heart h5 mb-0"></i></a></li>
                            <li class="list-inline-item"><span
                                    class="badge rounded-pill bg-soft-success">Featured</span></li>
                        </ul>

                        <div class="content text-center">
                            <img src="assets/images/client/02.jpg" class="avatar avatar-md-md shadow-md rounded-circle"
                                alt="">
                            <ul class="list-unstyled mb-1 mt-2">
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                            </ul>
                            <a routerLink="/job-candidate" class="text-dark h5 name">Martha Griffin</a>
                            <p class="text-muted my-1">WordPress Developer</p>

                            <span class="text-muted"><i class="uil uil-graduation-cap h4 mb-0 me-2 icons"></i>Experience
                                <span class="text-success">3+ years</span></span>

                            <ul class="list-unstyled mt-3">
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">PHP</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">WordPress</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">Web Design</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">CSS</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">JS</a></li>
                            </ul>
                            <div class="d-grid">
                                <a routerLink="/job-candidate" class="btn btn-soft-primary">Read More <i
                                        class="uil uil-angle-right-b align-middle"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="job-box job-primary candidate-list card rounded border-0 shadow">
                    <div class="card-body">
                        <ul class="list-unstyled align-items-center">
                            <li class="list-inline-item float-end"><a href="javascript:void(0)"
                                    class="text-muted like"><i class="mdi mdi-heart h5 mb-0"></i></a></li>
                            <li class="list-inline-item"><span
                                    class="badge rounded-pill bg-soft-success">Featured</span></li>
                        </ul>

                        <div class="content text-center">
                            <img src="assets/images/client/03.jpg" class="avatar avatar-md-md shadow-md rounded-circle"
                                alt="">
                            <ul class="list-unstyled mb-1 mt-2">
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                            </ul>
                            <a routerLink="/job-candidate" class="text-dark h5 name">Ashley Jen</a>
                            <p class="text-muted my-1">Back-end Developer</p>

                            <span class="text-muted"><i class="uil uil-graduation-cap h4 mb-0 me-2 icons"></i>Experience
                                <span class="text-success">3+ years</span></span>

                            <ul class="list-unstyled mt-3">
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">PHP</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">WordPress</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">Web Design</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">CSS</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">JS</a></li>
                            </ul>
                            <div class="d-grid">
                                <a routerLink="/job-candidate" class="btn btn-soft-primary">Read More <i
                                        class="uil uil-angle-right-b align-middle"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="job-box job-primary candidate-list card rounded border-0 shadow">
                    <div class="card-body">
                        <ul class="list-unstyled align-items-center">
                            <li class="list-inline-item float-end"><a href="javascript:void(0)"
                                    class="text-muted like"><i class="mdi mdi-heart h5 mb-0"></i></a></li>
                            <li class="list-inline-item"><span
                                    class="badge rounded-pill bg-soft-success">Featured</span></li>
                        </ul>

                        <div class="content text-center">
                            <img src="assets/images/client/04.jpg" class="avatar avatar-md-md shadow-md rounded-circle"
                                alt="">
                            <ul class="list-unstyled mb-1 mt-2">
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                            </ul>
                            <a routerLink="/job-candidate" class="text-dark h5 name">Nicole Alan</a>
                            <p class="text-muted my-1">UX Designer</p>

                            <span class="text-muted"><i class="uil uil-graduation-cap h4 mb-0 me-2 icons"></i>Experience
                                <span class="text-success">3+ years</span></span>

                            <ul class="list-unstyled mt-3">
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">PHP</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">WordPress</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">Web Design</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">CSS</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">JS</a></li>
                            </ul>
                            <div class="d-grid">
                                <a routerLink="/job-candidate" class="btn btn-soft-primary">Read More <i
                                        class="uil uil-angle-right-b align-middle"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="job-box job-primary candidate-list card rounded border-0 shadow">
                    <div class="card-body">
                        <ul class="list-unstyled align-items-center">
                            <li class="list-inline-item float-end"><a href="javascript:void(0)"
                                    class="text-muted like"><i class="mdi mdi-heart h5 mb-0"></i></a></li>
                            <li class="list-inline-item"><span
                                    class="badge rounded-pill bg-soft-success">Featured</span></li>
                        </ul>

                        <div class="content text-center">
                            <img src="assets/images/client/05.jpg" class="avatar avatar-md-md shadow-md rounded-circle"
                                alt="">
                            <ul class="list-unstyled mb-1 mt-2">
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                            </ul>
                            <a routerLink="/job-candidate" class="text-dark h5 name">Jennifer Pham</a>
                            <p class="text-muted my-1">Web Designer</p>

                            <span class="text-muted"><i class="uil uil-graduation-cap h4 mb-0 me-2 icons"></i>Experience
                                <span class="text-success">3+ years</span></span>

                            <ul class="list-unstyled mt-3">
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">PHP</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">WordPress</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">Web Design</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">CSS</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">JS</a></li>
                            </ul>
                            <div class="d-grid">
                                <a routerLink="/job-candidate" class="btn btn-soft-primary">Read More <i
                                        class="uil uil-angle-right-b align-middle"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="job-box job-primary candidate-list card rounded border-0 shadow">
                    <div class="card-body">
                        <ul class="list-unstyled align-items-center">
                            <li class="list-inline-item float-end"><a href="javascript:void(0)"
                                    class="text-muted like"><i class="mdi mdi-heart h5 mb-0"></i></a></li>
                            <li class="list-inline-item"><span
                                    class="badge rounded-pill bg-soft-success">Featured</span></li>
                        </ul>

                        <div class="content text-center">
                            <img src="assets/images/client/06.jpg" class="avatar avatar-md-md shadow-md rounded-circle"
                                alt="">
                            <ul class="list-unstyled mb-1 mt-2">
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                            </ul>
                            <a routerLink="/job-candidate" class="text-dark h5 name">Alex Tom</a>
                            <p class="text-muted my-1">UI Designer</p>

                            <span class="text-muted"><i class="uil uil-graduation-cap h4 mb-0 me-2 icons"></i>Experience
                                <span class="text-success">3+ years</span></span>

                            <ul class="list-unstyled mt-3">
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">PHP</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">WordPress</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">Web Design</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">CSS</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">JS</a></li>
                            </ul>
                            <div class="d-grid">
                                <a routerLink="/job-candidate" class="btn btn-soft-primary">Read More <i
                                        class="uil uil-angle-right-b align-middle"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="job-box job-primary candidate-list card rounded border-0 shadow">
                    <div class="card-body">
                        <ul class="list-unstyled align-items-center">
                            <li class="list-inline-item float-end"><a href="javascript:void(0)"
                                    class="text-muted like"><i class="mdi mdi-heart h5 mb-0"></i></a></li>
                            <li class="list-inline-item"><span
                                    class="badge rounded-pill bg-soft-success">Featured</span></li>
                        </ul>

                        <div class="content text-center">
                            <img src="assets/images/client/07.jpg" class="avatar avatar-md-md shadow-md rounded-circle"
                                alt="">
                            <ul class="list-unstyled mb-1 mt-2">
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                            </ul>
                            <a routerLink="/job-candidate" class="text-dark h5 name">Cristino Murphy</a>
                            <p class="text-muted my-1">PHP Developer</p>

                            <span class="text-muted"><i class="uil uil-graduation-cap h4 mb-0 me-2 icons"></i>Experience
                                <span class="text-success">3+ years</span></span>

                            <ul class="list-unstyled mt-3">
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">PHP</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">WordPress</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">Web Design</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">CSS</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">JS</a></li>
                            </ul>
                            <div class="d-grid">
                                <a routerLink="/job-candidate" class="btn btn-soft-primary">Read More <i
                                        class="uil uil-angle-right-b align-middle"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="job-box job-primary candidate-list card rounded border-0 shadow">
                    <div class="card-body">
                        <ul class="list-unstyled align-items-center">
                            <li class="list-inline-item float-end"><a href="javascript:void(0)"
                                    class="text-muted like"><i class="mdi mdi-heart h5 mb-0"></i></a></li>
                            <li class="list-inline-item"><span
                                    class="badge rounded-pill bg-soft-success">Featured</span></li>
                        </ul>

                        <div class="content text-center">
                            <img src="assets/images/client/08.jpg" class="avatar avatar-md-md shadow-md rounded-circle"
                                alt="">
                            <ul class="list-unstyled mb-1 mt-2">
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                            </ul>
                            <a routerLink="/job-candidate" class="text-dark h5 name">Arlo Sons</a>
                            <p class="text-muted my-1">React Developer</p>

                            <span class="text-muted"><i class="uil uil-graduation-cap h4 mb-0 me-2 icons"></i>Experience
                                <span class="text-success">3+ years</span></span>

                            <ul class="list-unstyled mt-3">
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">PHP</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">WordPress</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">Web Design</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">CSS</a></li>
                                <li class="list-inline-item m-1"><a href="jvascript:void(0)"
                                        class="rounded bg-light py-1 px-2 text-muted small">JS</a></li>
                            </ul>
                            <div class="d-grid">
                                <a routerLink="/job-candidate" class="btn btn-soft-primary">Read More <i
                                        class="uil uil-angle-right-b align-middle"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <!-- PAGINATION START -->
            <div class="col-12 mt-4 pt-2">
                <ul class="pagination justify-content-center mb-0">
                    <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Previous">Prev</a>
                    </li>
                    <li class="page-item active"><a class="page-link" href="javascript:void(0)">1</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Next">Next</a></li>
                </ul>
            </div><!--end col-->
            <!-- PAGINATION END -->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->