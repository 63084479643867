import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { first } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "../../services/user.service";

@Component({
  selector: "app-page-erp",
  templateUrl: "./page-erp.component.html",
  styleUrls: ["./page-erp.component.css"],
})

/**
 * Page Contact-Two Component
 */
export class PageERPComponent implements OnInit {
  // Set Topbar Option
  Menuoption = "center";

  constructor(
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {

  }
 

  mapView(content) {
    this.modalService.open(content, {
      windowClass: "dark-modal",
      size: "lg",
      centered: true,
    });
  }
}
