<!-- Hero Start -->
<section class="bg-half-170 pb-0 bg-primary d-table w-100" id="home"
  style="background: url('assets/images/bg2.png') center center;">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-6">
        <div class="title-heading mb-md-5 pb-md-5">
          <h4 class="text-white-50">We are creative</h4>
          <h4 class="heading text-white mb-3 title-dark"> Best IT Solution <br> Company </h4>
          <p class="para-desc text-white-50">Launch your campaign and benefit from our expertise on designing and
            managing conversion centered bootstrap v5 html page.</p>
          <div class="mt-4 pt-2">
            <a href="javascript:void(0)" class="btn btn-light">Get Started</a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 col-md-6 mt-5 mt-sm-0">
        <img src="assets/images/hero1.png" class="img-fluid" alt="">
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Partners start -->
<section class="py-4 bg-light">
  <div class="container">
    <app-clients-logo  brand_class="col-lg-2 col-md-2 col-6 text-center py-4"></app-clients-logo>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Partners End -->

<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">What we do ?</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-chart-line"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Hign Performance</h5>
            <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-crosshairs"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Best Securities</h5>
            <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-airplay"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Trusted Service</h5>
            <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-rocket"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Info Technology</h5>
            <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-clock"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>24/7 Support</h5>
            <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-users-alt"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>IT Management</h5>
            <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-file-alt"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Certified Company</h5>
            <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-search"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Data Analytics</h5>
            <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-12 text-center col-md-4 mt-4 pt-2">
        <a href="javascript:void(0)" class="btn btn-primary">See more
          <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
        </a>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <!-- About Start -->
  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6">
        <div class="row align-items-center">
          <div class="col-lg-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
            <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
              <div class="card-body p-0">
                <img src="assets/images/course/online/ab01.jpg" class="img-fluid" alt="work-image">
                <div class="overlay-work bg-dark"></div>
                <div class="content">
                  <a href="javascript:void(0)" class="title text-white d-block fw-bold">Web Development</a>
                  <small class="text-light">IT & Software</small>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-6 col-6">
            <div class="row">
              <div class="col-lg-12 col-md-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                  <div class="card-body p-0">
                    <img src="assets/images/course/online/ab02.jpg" class="img-fluid" alt="work-image">
                    <div class="overlay-work bg-dark"></div>
                    <div class="content">
                      <a href="javascript:void(0)" class="title text-white d-block fw-bold">Michanical
                        Engineer</a>
                      <small class="text-light">Engineering</small>
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-lg-12 col-md-12 mt-4 pt-2">
                <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                  <div class="card-body p-0">
                    <img src="assets/images/course/online/ab03.jpg" class="img-fluid" alt="work-image">
                    <div class="overlay-work bg-dark"></div>
                    <div class="content">
                      <a href="javascript:void(0)" class="title text-white d-block fw-bold">Chartered
                        Accountant</a>
                      <small class="text-light">C.A.</small>
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-6 mt-4 mt-lg-0 pt- pt-lg-0">
        <div class="ms-lg-4">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">About Our Story</h4>
            <p class="text-muted para-desc">Start working with <span class="text-primary fw-bold">Landrick</span> that
              can provide everything you need to generate
              awareness, drive traffic, connect.</p>
            <p class="text-muted para-desc mb-0">The most well-known dummy text is the 'Lorem Ipsum', which is said to
              have originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin language which more or less
              corresponds to 'proper' Latin. It contains a series of real Latin words.</p>
          </div>

          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h4 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Fully Responsive</li>
            <li class="mb-0"><span class="text-primary h4 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Multiple Layouts</li>
            <li class="mb-0"><span class="text-primary h4 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Suits Your Style</li>
          </ul>

          <div class="watch-video mt-4 pt-2">
            <a href="javascript:void(0)" target="_blank" class="btn btn-primary m-1 me-2">Read More
              <i-feather name="chevron-right" class="fea icon-sm"></i-feather>
            </a>
            <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" data-type="youtube"
              data-id="yba7hPeTSjk" class="btn btn-icon btn-pills btn-primary m-1">
              <i-feather name="video" class="icons"></i-feather>
            </a><span class="fw-bold text-uppercase small align-middle ms-1">Watch Now</span>
            <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <youtube-player [videoId]="'yba7hPeTSjk'" height="450" width="780">
                </youtube-player>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->

<!-- Start -->
<section class="section pt-0">
  <div class="container">
    <div class="p-4 rounded shadow bg-primary position-relative" style="z-index: 1;">
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="progress-box">
            <h6 class="title text-white">Web Designing</h6>
            <div class="progress title-bg-dark" style="height: 10px; padding: 3px">
              <div class="progress-bar position-relative bg-black" style="width:84%;">
                <div class="progress-value d-block text-white-50 h6">84%</div>
              </div>
            </div>
          </div>
          <!--end process box-->
          <div class="progress-box mt-4">
            <h6 class="title text-white">Web Development</h6>
            <div class="progress title-bg-dark" style="height: 10px; padding: 3px">
              <div class="progress-bar position-relative bg-black" style="width:75%;">
                <div class="progress-value d-block text-white-50 h6">75%</div>
              </div>
            </div>
          </div>
          <!--end process box-->
          <div class="progress-box mt-4">
            <h6 class="title text-white">Game Development</h6>
            <div class="progress title-bg-dark" style="height: 10px; padding: 3px">
              <div class="progress-bar position-relative bg-black" style="width:79%;">
                <div class="progress-value d-block text-white-50 h6">79%</div>
              </div>
            </div>
          </div>
          <!--end process box-->
        </div>
        <!--end col-->

        <div class="col-md-6 col-12">
          <div class="progress-box mt-4 mt-sm-0">
            <h6 class="title text-white">App Development</h6>
            <div class="progress title-bg-dark" style="height: 10px; padding: 3px">
              <div class="progress-bar position-relative bg-black" style="width:84%;">
                <div class="progress-value d-block text-white-50 h6">84%</div>
              </div>
            </div>
          </div>
          <!--end process box-->
          <div class="progress-box mt-4">
            <h6 class="title text-white">Digital Marketing</h6>
            <div class="progress title-bg-dark" style="height: 10px; padding: 3px">
              <div class="progress-bar position-relative bg-black" style="width:75%;">
                <div class="progress-value d-block text-white-50 h6">75%</div>
              </div>
            </div>
          </div>
          <!--end process box-->
          <div class="progress-box mt-4">
            <h6 class="title text-white">Full stack Development</h6>
            <div class="progress title-bg-dark" style="height: 10px; padding: 3px">
              <div class="progress-bar position-relative bg-black" style="width:79%;">
                <div class="progress-value d-block text-white-50 h6">79%</div>
              </div>
            </div>
          </div>
          <!--end process box-->
        </div>
        <!--end col-->
      </div>
      <!--end row -->
    </div>

    <div class="row mt-4 pt-2 position-relative" id="counter" style="z-index: 1;">
      <div class="col-md-3 col-6 mt-4 pt-2">
        <div class="counter-box text-center">
          <img src="assets/images/illustrator/Asset190.svg" class="avatar avatar-small" alt="">
          <h2 class="mb-0 mt-4"><span class="counter-value" [CountTo]="97">3</span>%</h2>
          <h6 class="counter-head text-muted">Happy Client</h6>
        </div>
        <!--end counter box-->
      </div>

      <div class="col-md-3 col-6 mt-4 pt-2">
        <div class="counter-box text-center">
          <img src="assets/images/illustrator/Asset189.svg" class="avatar avatar-small" alt="">
          <h2 class="mb-0 mt-4"><span class="counter-value" [CountTo]="15" [from]="1" [duration]="4"></span>+</h2>
          <h6 class="counter-head text-muted">Awards</h6>
        </div>
        <!--end counter box-->
      </div>

      <div class="col-md-3 col-6 mt-4 pt-2">
        <div class="counter-box text-center">
          <img src="assets/images/illustrator/Asset192.svg" class="avatar avatar-small" alt="">
          <h2 class="mb-0 mt-4"><span class="counter-value" [CountTo]="2" [from]="0" [duration]="4"></span>K</h2>
          <h6 class="counter-head text-muted">Job Placement</h6>
        </div>
        <!--end counter box-->
      </div>

      <div class="col-md-3 col-6 mt-4 pt-2">
        <div class="counter-box text-center">
          <img src="assets/images/illustrator/Asset187.svg" class="avatar avatar-small" alt="">
          <h2 class="mb-0 mt-4"><span class="counter-value" [CountTo]="98" [from]="3" [duration]="4"></span>%</h2>
          <h6 class="counter-head text-muted">Project Complete</h6>
        </div>
        <!--end counter box-->
      </div>
    </div>
    <!--end row-->
    <div class="feature-posts-placeholder bg-light"></div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End -->

<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h6 class="text-primary">Work Process</h6>
          <h4 class="title mb-4">How do we works ?</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-md-4 mt-4 pt-2">
        <div class="card features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-presentation-edit d-block rounded h3 mb-0"></i>
          </div>

          <div class="card-body">
            <h5 class="text-dark">Discussion</h5>
            <p class="text-muted mb-0">The most well-known dummy text is the 'Lorem Ipsum', which is said to have
              originated</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
        <div class="card features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-airplay d-block rounded h3 mb-0"></i>
          </div>

          <div class="card-body">
            <h5 class="text-dark">Strategy & Testing</h5>
            <p class="text-muted mb-0">Generators convallis odio, vel pharetra quam malesuada vel. Nam porttitor
              malesuada.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
        <div class="card features feature-primary feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-image-check d-block rounded h3 mb-0"></i>
          </div>

          <div class="card-body">
            <h5 class="text-dark">Reporting</h5>
            <p class="text-muted mb-0">Internet Proin tempus odio, vel pharetra quam malesuada vel. Nam porttitor
              malesuada.</p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h4 class="title mb-4">Our Mind Power</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <app-member [memberData]="memberData"></app-member>
  </div>
  <!--end container-->
</section>
<!--end section-->

<section class="section bg-light">
  <div class="container">
    <div class="row align-items-center mb-4 pb-2">
      <div class="col-lg-6">
        <div class="section-title text-center text-lg-start">
          <h6 class="text-primary">Blog</h6>
          <h4 class="title mb-4 mb-lg-0">Reads Our Latest <br> News & Blog</h4>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6">
        <div class="section-title text-center text-lg-start">
          <p class="text-muted mb-0 mx-auto para-desc">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <app-blog [blogData]="blogData"></app-blog>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->