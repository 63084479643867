        <!-- Hero Start -->
        <section class="bg-half-170 bg-primary d-table w-100 " style="background-image: url('assets/images/job/bg.png');" id="home">
            <div class="container">
                <div class="row mt-5 justify-content-center">
                    <div class="col-lg-12 text-center">
                        <div class="pages-heading">
                            <h4 class="title text-white title-dark mb-0"> About us</h4>
                        </div>
                    </div>  <!--end col-->
                </div><!--end row-->
                
                <div class="position-breadcrumb">
                    <nav aria-label="breadcrumb" class="d-inline-block">
                        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                            <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                            <li class="breadcrumb-item"><a routerLink="/index-job">Careers</a></li>
                            <li class="breadcrumb-item active" aria-current="page">About us</li>
                        </ul>
                    </nav>
                </div>
            </div> <!--end container-->
        </section><!--end section-->
        <!-- Hero End -->

        <!-- Shape Start -->
        <div class="position-relative">
            <div class="shape overflow-hidden text-color-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <!--Shape End-->
        
        <!-- Start -->
        <section class="section">
            <div class="container">
                <div class="row align-items-center" id="counter">
                    <div class="col-md-6">
                        <img src="assets/images/job/resume-folder.svg" class="img-fluid" alt="">
                    </div><!--end col-->

                    <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div class="ms-lg-4">
                            <div class="d-flex mb-4">
                                <span class="text-primary h1 mb-0"><span class="counter-value display-1 fw-bold" data-target="15">15</span>+</span>
                                <span class="h6 align-self-end ms-2">Years <br> Experience</span>
                            </div>
                            <div class="section-title">
                                <h4 class="title mb-4">Who we are ?</h4>
                                <p class="text-muted">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect. Dummy text is text that is used in the publishing industry or by web designers to occupy the space which will later be filled with 'real' content. This is required when, for example, the final text is not yet available. Dummy texts have been in use by typesetters since the 16th century.</p>
                                <a href="javascript:void(0)" class="btn btn-primary mt-3">Contact us</a>
                            </div>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
            
            <div class="container mt-4">
                <div class="row justify-content-center">
                    <div class="col-lg-2 col-md-2 col-6 text-center pt-4">
                        <img src="assets/images/client/amazon.svg" class="avatar avatar-ex-sm" alt="">
                    </div><!--end col-->

                    <div class="col-lg-2 col-md-2 col-6 text-center pt-4">
                        <img src="assets/images/client/google.svg" class="avatar avatar-ex-sm" alt="">
                    </div><!--end col-->
                    
                    <div class="col-lg-2 col-md-2 col-6 text-center pt-4">
                        <img src="assets/images/client/lenovo.svg" class="avatar avatar-ex-sm" alt="">
                    </div><!--end col-->
                    
                    <div class="col-lg-2 col-md-2 col-6 text-center pt-4">
                        <img src="assets/images/client/paypal.svg" class="avatar avatar-ex-sm" alt="">
                    </div><!--end col-->
                    
                    <div class="col-lg-2 col-md-2 col-6 text-center pt-4">
                        <img src="assets/images/client/shopify.svg" class="avatar avatar-ex-sm" alt="">
                    </div><!--end col-->
                    
                    <div class="col-lg-2 col-md-2 col-6 text-center pt-4">
                        <img src="assets/images/client/spotify.svg" class="avatar avatar-ex-sm" alt="">
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
            
            <div class="container mt-100 mt-60">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <div class="section-title text-center mb-4 pb-2">
                            <h6 class="text-primary">Work Process</h6>
                            <h4 class="title mb-4">How do we works ?</h4>
                            <p class="text-muted para-desc mx-auto mb-0">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->

                <div class="row">
                    <div class="col-md-4 mt-4 pt-2">
                        <div class="card features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
                            <div class="icons text-center mx-auto">
                                <i class="uil uil-presentation-edit d-block rounded h3 mb-0"></i>
                            </div>

                            <div class="card-body">
                                <h5 class="text-dark">Resume</h5>
                                <p class="text-muted mb-0">The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated</p>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
                        <div class="card features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
                            <div class="icons text-center mx-auto">
                                <i class="uil uil-airplay d-block rounded h3 mb-0"></i>
                            </div>

                            <div class="card-body">
                                <h5 class="text-dark">Interviews</h5>
                                <p class="text-muted mb-0">Generators convallis odio, vel pharetra quam malesuada vel. Nam porttitor malesuada.</p>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
                        <div class="card features feature-primary feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
                            <div class="icons text-center mx-auto">
                                <i class="uil uil-image-check d-block rounded h3 mb-0"></i>
                            </div>

                            <div class="card-body">
                                <h5 class="text-dark">Job Approval</h5>
                                <p class="text-muted mb-0">Internet Proin tempus odio, vel pharetra quam malesuada vel. Nam porttitor malesuada.</p>
                            </div>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
        </section><!--end section-->

        <!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->
