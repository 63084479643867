import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { first } from "rxjs";
import { UserService } from "../../services/user.service";

@Component({
  selector: "app-page-job-apply",
  templateUrl: "./page-job-apply.component.html",
  styleUrls: ["./page-job-apply.component.css"],
})

/**
 * Page Job-Apply Component
 */
export class PageJobApplyComponent implements OnInit {
  // Set Topbar
  Settingicon = true;
  Menuoption = "center";
  jobApplyForm: FormGroup;
  successmsg = false;
  submitted = false;
  hasError: boolean;
  error: "";

  constructor(
    private userService: UserService,
    private fb: FormBuilder
    ) {}

  ngOnInit(): void {
    this.jobApplyForm = this.fb.group({
      firstName: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      phoneno: ["", Validators.required],
      title: ["", Validators.required],
      jobType: ["", Validators.required],
      description: [""],
      resumeURL: [""],
      address: [""],
    });
  }
  get f() { return this.jobApplyForm.controls; }

  submit() {
    this.hasError = false;
    this.submitted = true;
    const data = {
      firstName: this.jobApplyForm.value?.firstName,
      email: this.jobApplyForm.value?.email,
      phoneno: this.jobApplyForm.value?.phoneno,
      title: this.jobApplyForm.value?.title,
      jobType: this.jobApplyForm.value?.jobType,
      description: this.jobApplyForm.value?.description,
      resumeURL: this.jobApplyForm.value?.resumeURL,
      address: this.jobApplyForm.value?.address,

    }
    if (this.jobApplyForm.invalid) {
      return;
    } else {
      {
        this.userService.jobApply(data).subscribe(
          (response: any) => {
            console.log("jobApply ::::", response);
            this.successmsg = true;
            this.jobApplyForm.reset();
          },
          (error) => {
            this.error = error ? error : "";
          }
        );
      }
    }
  }
}
