        <!-- Start -->
        <section class="bg-half-170 d-table w-100" id="home">
            <div class="bg-overlay bg-gradient-primary"></div>
            <div class="container">
                <div class="row align-items-center mt-5">
                    <div class="col-lg-6">
                        <div class="pages-heading title-heading">
                            <h4 class="title mb-4 text-white title-dark">Reliable VPS Hosting <br> Built for Enterprise</h4>
                            <p class="text-white-50 para-desc mb-0">Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap v5 html page.</p>
                        
                            <div class="mt-4">
                                <a href="javascript:void(0)" class="btn btn-primary">Get Started</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <img src="assets/images/hosting/vps.png" class="img-fluid" alt="">
                    </div>
                </div>
            </div><!--end container-->
        </section><!--end section-->
        <!-- End -->

        <!-- Start -->
        <section class="section">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <div class="section-title text-center mb-4 pb-2">
                            <h4 class="title mb-3">Why choose VPS Hosting</h4>
                            <p class="text-muted para-desc mx-auto mb-0">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->

                <div class="row">
                    <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
                        <div class="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
                            <span class="h1 icon-color">
                                <i class="uil uil-server-alt"></i>
                            </span>
                            <div class="card-body p-0 content">
                                <h5>SSD storage</h5>
                                <p class="para text-muted mb-0">If the distribution of letters and 'words' is random, the reader from making a neutral judgement</p>
                            </div>
                            <div class="position-absolute top-0 end-0">
                                <i class="uil uil-server-alt display-1 opacity-05"></i>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
                        <div class="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
                            <span class="h1 icon-color">
                                <i class="uil uil-data-sharing"></i>
                            </span>
                            <div class="card-body p-0 content">
                                <h5>Easy upgrade</h5>
                                <p class="para text-muted mb-0">If the distribution of letters and 'words' is random, the reader from making a neutral judgement</p>
                            </div>
                            <div class="position-absolute top-0 end-0">
                                <i class="uil uil-data-sharing display-1 opacity-05"></i>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
                        <div class="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
                            <span class="h1 icon-color">
                                <i class="uil uil-cloud-database-tree"></i>
                            </span>
                            <div class="card-body p-0 content">
                                <h5>Multiple Linux Distros</h5>
                                <p class="para text-muted mb-0">If the distribution of letters and 'words' is random, the reader from making a neutral judgement</p>
                            </div>
                            <div class="position-absolute top-0 end-0">
                                <i class="uil uil-cloud-database-tree display-1 opacity-05"></i>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
                        <div class="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
                            <span class="h1 icon-color">
                                <i class="uil uil-user-check"></i>
                            </span>
                            <div class="card-body p-0 content">
                                <h5>Admin Support</h5>
                                <p class="para text-muted mb-0">If the distribution of letters and 'words' is random, the reader from making a neutral judgement</p>
                            </div>
                            <div class="position-absolute top-0 end-0">
                                <i class="uil uil-user-check display-1 opacity-05"></i>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
                        <div class="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
                            <span class="h1 icon-color">
                                <i class="uil uil-create-dashboard"></i>
                            </span>
                            <div class="card-body p-0 content">
                                <h5>Guaranteed Resources</h5>
                                <p class="para text-muted mb-0">If the distribution of letters and 'words' is random, the reader from making a neutral judgement</p>
                            </div>
                            <div class="position-absolute top-0 end-0">
                                <i class="uil uil-create-dashboard display-1 opacity-05"></i>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
                        <div class="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
                            <span class="h1 icon-color">
                                <i class="uil uil-desktop"></i>
                            </span>
                            <div class="card-body p-0 content">
                                <h5>Full Root Access</h5>
                                <p class="para text-muted mb-0">If the distribution of letters and 'words' is random, the reader from making a neutral judgement</p>
                            </div>
                            <div class="position-absolute top-0 end-0">
                                <i class="uil uil-desktop display-1 opacity-05"></i>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
                        <div class="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
                            <span class="h1 icon-color">
                                <i class="uil uil-shield-check"></i>
                            </span>
                            <div class="card-body p-0 content">
                                <h5>Quick Provisioning</h5>
                                <p class="para text-muted mb-0">If the distribution of letters and 'words' is random, the reader from making a neutral judgement</p>
                            </div>
                            <div class="position-absolute top-0 end-0">
                                <i class="uil uil-shield-check display-1 opacity-05"></i>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
                        <div class="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
                            <span class="h1 icon-color">
                                <i class="uil uil-monitor-heart-rate"></i>
                            </span>
                            <div class="card-body p-0 content">
                                <h5>Intuitive Dashboard</h5>
                                <p class="para text-muted mb-0">If the distribution of letters and 'words' is random, the reader from making a neutral judgement</p>
                            </div>
                            <div class="position-absolute top-0 end-0">
                                <i class="uil uil-monitor-heart-rate display-1 opacity-05"></i>
                            </div>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->

            <div class="container mt-100 mt-60">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <div class="section-title text-center mb-4 pb-2">
                            <h4 class="title mb-3">VPS Hosting Plans</h4>
                            <p class="text-muted para-desc mx-auto mb-0">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->

                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-5 mt-4 pt-2 text-center">
                        <ul class="nav nav-pills nav-justified flex-sm-row rounded px-0" id="pills-tab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link rounded" id="pills-one-tab" data-bs-toggle="pill" href="#pills-one" role="tab" aria-controls="pills-one" aria-selected="false">
                                    <div class="text-center py-2">
                                        <img src="assets/images/hosting/india.svg" class="avatar avatar-md-sm" alt="">
                                        <h5 class="title fw-normal mt-3 mb-0">India</h5>
                                    </div>
                                </a><!--end nav link-->
                            </li><!--end nav item-->
                            
                            <li class="nav-item">
                                <a class="nav-link rounded active" id="pills-two-tab" data-bs-toggle="pill" href="#pills-two" role="tab" aria-controls="pills-two" aria-selected="false">
                                    <div class="text-center py-2">
                                        <img src="assets/images/hosting/usa.svg" class="avatar avatar-md-sm" alt="">
                                        <h5 class="title fw-normal mt-3 mb-0">USA</h5>
                                    </div>
                                </a><!--end nav link-->
                            </li><!--end nav item-->
                        </ul>
                    </div><!--end col-->
                </div><!--end row-->

                <div class="row">
                    <div class="col-12 mt-4 pt-2">
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade" id="pills-one" role="tabpanel" aria-labelledby="pills-one-tab">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="table-responsive bg-white shadow rounded">
                                            <table class="table mb-0 table-center">
                                                <thead>
                                                    <tr>
                                                        <th class="border-bottom py-4 px-3" style="min-width: 200px;">PLANS</th>
                                                        <th class="border-bottom text-center py-4 px-3" style="min-width: 160px;">PROCESSOR</th>
                                                        <th class="border-bottom text-center py-4 px-3" style="min-width: 160px;">RAM</th>
                                                        <th class="border-bottom text-center py-4 px-3" style="min-width: 160px;">DISK</th>
                                                        <th class="border-bottom text-center py-4 px-3" style="min-width: 160px;">BANDWIDTH </th>
                                                        <th class="border-bottom text-center py-4 px-3" style="min-width: 180px;">PRICE</th>
                                                        <th class="border-bottom text-end py-4 px-3" style="min-width: 50px;">#</th>
                                                    </tr>
                                                </thead>
                
                                                <tbody>
                                                    <tr>
                                                        <td class="h6 p-3">Basic</td>
                                                        <td class="text-center p-3">Intel E3</td>
                                                        <td class="text-center p-3">4 GB DDR3</td>
                                                        <td class="text-center p-3">1000 GB HDD</td>
                                                        <td class="text-center p-3">5 TB</td>
                                                        <td class="text-center p-3">$ 5.99/month</td>
                                                        <td class="text-end p-3"><a href="javascript:void(0)" class="btn btn-icon btn-soft-warning"><i-feather name="shopping-cart" class="icons"></i-feather></a></td>
                                                    </tr>
                                                    
                                                    <tr>
                                                        <td class="h6 p-3">Standard</td>
                                                        <td class="text-center p-3">Intel E3</td>
                                                        <td class="text-center p-3">4 GB DDR3</td>
                                                        <td class="text-center p-3">1000 GB HDD</td>
                                                        <td class="text-center p-3">5 TB</td>
                                                        <td class="text-center p-3">$ 5.99/month</td>
                                                        <td class="text-end p-3"><a href="javascript:void(0)" class="btn btn-icon btn-soft-warning"><i-feather name="shopping-cart" class="icons"></i-feather></a></td>
                                                    </tr>
                                                    
                                                    <tr>
                                                        <td class="h6 p-3">Elite</td>
                                                        <td class="text-center p-3">Intel E3</td>
                                                        <td class="text-center p-3">8 GB DDR3</td>
                                                        <td class="text-center p-3">1000 GB HDD</td>
                                                        <td class="text-center p-3">5 TB</td>
                                                        <td class="text-center p-3">$ 5.99/month</td>
                                                        <td class="text-end p-3"><a href="javascript:void(0)" class="btn btn-icon btn-soft-warning"><i-feather name="shopping-cart" class="icons"></i-feather></a></td>
                                                    </tr>
                                                    
                                                    <tr>
                                                        <td class="h6 p-3">Professional</td>
                                                        <td class="text-center p-3">Intel E3</td>
                                                        <td class="text-center p-3">16 GB DDR3</td>
                                                        <td class="text-center p-3">1000 GB HDD</td>
                                                        <td class="text-center p-3">10 TB</td>
                                                        <td class="text-center p-3">$ 5.99/month</td>
                                                        <td class="text-end p-3"><a href="javascript:void(0)" class="btn btn-icon btn-soft-warning"><i-feather name="shopping-cart" class="icons"></i-feather></a></td>
                                                    </tr>
                                                    
                                                    <tr>
                                                        <td class="h6 p-3">Advanced</td>
                                                        <td class="text-center p-3">Intel Xeon D</td>
                                                        <td class="text-center p-3">16 GB DDR4</td>
                                                        <td class="text-center p-3">1000 GB HDD</td>
                                                        <td class="text-center p-3">15 TB</td>
                                                        <td class="text-center p-3">$ 5.99/month</td>
                                                        <td class="text-end p-3"><a href="javascript:void(0)" class="btn btn-icon btn-soft-warning"><i-feather name="shopping-cart" class="icons"></i-feather></a></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div><!--end row-->
                            </div><!--end teb pane-->
                            
                            <div class="tab-pane fade show active" id="pills-two" role="tabpanel" aria-labelledby="pills-two-tab">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="table-responsive bg-white shadow rounded">
                                            <table class="table mb-0 table-center">
                                                <thead>
                                                    <tr>
                                                        <th class="border-bottom py-4 px-3" style="min-width: 200px;">PLANS</th>
                                                        <th class="border-bottom text-center py-4 px-3" style="min-width: 160px;">PROCESSOR</th>
                                                        <th class="border-bottom text-center py-4 px-3" style="min-width: 160px;">RAM</th>
                                                        <th class="border-bottom text-center py-4 px-3" style="min-width: 160px;">DISK</th>
                                                        <th class="border-bottom text-center py-4 px-3" style="min-width: 160px;">BANDWIDTH </th>
                                                        <th class="border-bottom text-center py-4 px-3" style="min-width: 180px;">PRICE</th>
                                                        <th class="border-bottom text-end py-4 px-3" style="min-width: 50px;">#</th>
                                                    </tr>
                                                </thead>
                
                                                <tbody>
                                                    <tr>
                                                        <td class="h6 p-3">Basic</td>
                                                        <td class="text-center p-3">Intel E3</td>
                                                        <td class="text-center p-3">4 GB DDR3</td>
                                                        <td class="text-center p-3">1000 GB HDD</td>
                                                        <td class="text-center p-3">5 TB</td>
                                                        <td class="text-center p-3">$ 5.99/month</td>
                                                        <td class="text-end p-3"><a href="javascript:void(0)" class="btn btn-icon btn-soft-warning"><i-feather name="shopping-cart" class="icons"></i-feather></a></td>
                                                    </tr>
                                                    
                                                    <tr>
                                                        <td class="h6 p-3">Standard</td>
                                                        <td class="text-center p-3">Intel E3</td>
                                                        <td class="text-center p-3">4 GB DDR3</td>
                                                        <td class="text-center p-3">1000 GB HDD</td>
                                                        <td class="text-center p-3">5 TB</td>
                                                        <td class="text-center p-3">$ 5.99/month</td>
                                                        <td class="text-end p-3"><a href="javascript:void(0)" class="btn btn-icon btn-soft-warning"><i-feather name="shopping-cart" class="icons"></i-feather></a></td>
                                                    </tr>
                                                    
                                                    <tr>
                                                        <td class="h6 p-3">Elite</td>
                                                        <td class="text-center p-3">Intel E3</td>
                                                        <td class="text-center p-3">8 GB DDR3</td>
                                                        <td class="text-center p-3">1000 GB HDD</td>
                                                        <td class="text-center p-3">5 TB</td>
                                                        <td class="text-center p-3">$ 5.99/month</td>
                                                        <td class="text-end p-3"><a href="javascript:void(0)" class="btn btn-icon btn-soft-warning"><i-feather name="shopping-cart" class="icons"></i-feather></a></td>
                                                    </tr>
                                                    
                                                    <tr>
                                                        <td class="h6 p-3">Professional</td>
                                                        <td class="text-center p-3">Intel E3</td>
                                                        <td class="text-center p-3">16 GB DDR3</td>
                                                        <td class="text-center p-3">1000 GB HDD</td>
                                                        <td class="text-center p-3">10 TB</td>
                                                        <td class="text-center p-3">$ 5.99/month</td>
                                                        <td class="text-end p-3"><a href="javascript:void(0)" class="btn btn-icon btn-soft-warning"><i-feather name="shopping-cart" class="icons"></i-feather></a></td>
                                                    </tr>
                                                    
                                                    <tr>
                                                        <td class="h6 p-3">Advanced</td>
                                                        <td class="text-center p-3">Intel Xeon D</td>
                                                        <td class="text-center p-3">16 GB DDR4</td>
                                                        <td class="text-center p-3">1000 GB HDD</td>
                                                        <td class="text-center p-3">15 TB</td>
                                                        <td class="text-center p-3">$ 5.99/month</td>
                                                        <td class="text-end p-3"><a href="javascript:void(0)" class="btn btn-icon btn-soft-warning"><i-feather name="shopping-cart" class="icons"></i-feather></a></td>
                                                    </tr>
                                                    
                                                    <tr>
                                                        <td class="h6 p-3">Advanced <span class="text-primary">ST</span></td>
                                                        <td class="text-center p-3">Intel Xeon D</td>
                                                        <td class="text-center p-3">28 GB DDR4</td>
                                                        <td class="text-center p-3">1000 GB HDD</td>
                                                        <td class="text-center p-3">10 TB</td>
                                                        <td class="text-center p-3">$ 5.99/month</td>
                                                        <td class="text-end p-3"><a href="javascript:void(0)" class="btn btn-icon btn-soft-warning"><i-feather name="shopping-cart" class="icons"></i-feather></a></td>
                                                    </tr>
                                                    
                                                    <tr>
                                                        <td class="h6 p-3">Advanced <span class="text-success">ELITE</span></td>
                                                        <td class="text-center p-3">Intel Xeon D</td>
                                                        <td class="text-center p-3">28 GB DDR4</td>
                                                        <td class="text-center p-3">1000 GB HDD</td>
                                                        <td class="text-center p-3">10 TB</td>
                                                        <td class="text-center p-3">$ 5.99/month</td>
                                                        <td class="text-end p-3"><a href="javascript:void(0)" class="btn btn-icon btn-soft-warning"><i-feather name="shopping-cart" class="icons"></i-feather></a></td>
                                                    </tr>
                                                    
                                                    <tr>
                                                        <td class="h6 p-3">Advanced <span class="text-info">BUSINESS</span></td>
                                                        <td class="text-center p-3">Intel Xeon D</td>
                                                        <td class="text-center p-3">60 GB DDR4</td>
                                                        <td class="text-center p-3">1000 GB HDD</td>
                                                        <td class="text-center p-3">15 TB</td>
                                                        <td class="text-center p-3">$ 5.99/month</td>
                                                        <td class="text-end p-3"><a href="javascript:void(0)" class="btn btn-icon btn-soft-warning"><i-feather name="shopping-cart" class="icons"></i-feather></a></td>
                                                    </tr>
                                                    
                                                    <tr>
                                                        <td class="h6 p-3">Advanced <span class="text-warning">PRO</span></td>
                                                        <td class="text-center p-3">Intel Xeon D</td>
                                                        <td class="text-center p-3">60 GB DDR4</td>
                                                        <td class="text-center p-3">1000 GB HDD</td>
                                                        <td class="text-center p-3">15 TB</td>
                                                        <td class="text-center p-3">$ 5.99/month</td>
                                                        <td class="text-end p-3"><a href="javascript:void(0)" class="btn btn-icon btn-soft-warning"><i-feather name="shopping-cart" class="icons"></i-feather></a></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div><!--end row-->                    
                            </div><!--end teb pane-->
                            
                            <div class="text-center pt-4">
                                <p class="text-muted mb-0">Couldn’t find what you’re looking for ? <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#additional-requirement" class="h6 text-primary">Let us know <i class="mdi mdi-arrow-right"></i></a></p>
                            </div>
                        </div><!--end col-->
                    </div>
                </div><!--end row-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- End -->

        <!-- FAQs Start -->
        <section class="section bg-primary">
            <div class="container">
                <div class="row mb-md-4 pb-md-2 justify-content-center">
                    <div class="col-12">
                        <div class="section-title text-center mb-md-4 pb-md-2 pb-5 pb-sm-0">
                            <h4 class="title text-white  title-dark mb-3">Frequently asked questions</h4>
                            <p class="text-white-50 para-desc mx-auto mb-0">Start working with Landrick that can provide everything you need to generate awareness, drive traffic, connect.</p>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
        </section><!--end section-->
        <section class="section features-absolute" id="question">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-12 mt-3 mt-sm-0">
                        <div class="me-lg-4">
                            <h4 class="text-white title-dark text-md-start text-center">Technical Questions</h4>

                            <div class="accordion mt-3" id="accordionExample">
                                <div class="accordion-item rounded shadow">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button border-0 bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                            aria-expanded="true" aria-controls="collapseOne">
                                            Features
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse border-0 collapse show" aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body text-muted">
                                            <ul class="list-unstyled mb-0">
                                                <li class="text-muted my-2"><i-feather name="arrow-right-circle" style="width:20px;" class="fea icon-ex-md text-primary me-2"></i-feather>CentOS 6/CentOS 7/Ubuntu 16.04/Fedora 23/Debian 8</li>
                                                <li class="text-muted my-2"><i-feather name="arrow-right-circle" style="width:20px;" class="fea icon-ex-md text-primary me-2"></i-feather>Multiple locations</li>
                                                <li class="text-muted my-2"><i-feather name="arrow-right-circle" style="width:20px;" class="fea icon-ex-md text-primary me-2"></i-feather>Private Network</li>
                                                <li class="text-muted my-2"><i-feather name="arrow-right-circle" style="width:20px;" class="fea icon-ex-md text-primary me-2"></i-feather>Quick VNC access</li>
                                                <li class="text-muted my-2"><i-feather name="arrow-right-circle" style="width:20px;" class="fea icon-ex-md text-primary me-2"></i-feather>rDNS support</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item rounded shadow mt-2">
                                    <h2 class="accordion-header" id="headingTwo">
                                        <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Performance
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse border-0 collapse" aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body text-muted">
                                            <ul class="list-unstyled mb-0">
                                                <li class="text-muted my-2"><i-feather name="arrow-right-circle" style="width:20px;" class="fea icon-ex-md text-primary me-2"></i-feather>Top notch network built with Tier 1 ISPs</li>
                                                <li class="text-muted my-2"><i-feather name="arrow-right-circle" style="width:20px;" class="fea icon-ex-md text-primary me-2"></i-feather>SSD Storage</li>
                                                <li class="text-muted my-2"><i-feather name="arrow-right-circle" style="width:20px;" class="fea icon-ex-md text-primary me-2"></i-feather>Tier IV Center< name/li>
                                                <li class="text-muted my-2"><i-feather name="arrow-right-circle" style="width:20px;" class="fea icon-ex-md text-primary me-2"></i-feather>Neustar’s DDoS protection</li>
                                                <li class="text-muted my-2"><i-feather name="arrow-right-circle" style="width:20px;" class="fea icon-ex-md text-primary me-2"></i-feather>Backed by Juniper Networks</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item rounded shadow mt-2">
                                    <h2 class="accordion-header" id="headingThree">
                                        <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Boosters
                                        </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse border-0 collapse" aria-labelledby="headingThree"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body text-muted">
                                            <ul class="list-unstyled mb-0">
                                                <li class="text-muted my-2"><i-feather name="arrow-right-circle" style="width:20px;" class="fea icon-ex-md text-primary me-2"></i-feather>cPanel (only with CentOS 7)</li>
                                                <li class="text-muted my-2"><i-feather name="arrow-right-circle" style="width:20px;" class="fea icon-ex-md text-primary me-2"></i-feather>Plesk Onyx</li>
                                                <li class="text-muted my-2"><i-feather name="arrow-right-circle" style="width:20px;" class="fea icon-ex-md text-primary me-2"></i-feather>WHMCS</li>
                                                <li class="text-muted my-2"><i-feather name="arrow-right-circle" style="width:20px;" class="fea icon-ex-md text-primary me-2"></i-feather>SAN Storage</li>
                                                <li class="text-muted my-2"><i-feather name="arrow-right-circle" style="width:20px;" class="fea icon-ex-md text-primary me-2"></i-feather>CDN</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item rounded shadow mt-2">
                                    <h2 class="accordion-header" id="headingFour">
                                        <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            Semi-Managed Support
                                        </button>
                                    </h2>
                                    <div id="collapseFour" class="accordion-collapse border-0 collapse" aria-labelledby="headingFour"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body text-muted">
                                            <ul class="list-unstyled mb-0">
                                                <li class="text-muted my-2"><i-feather name="arrow-right-circle" style="width:20px;" class="fea icon-ex-md text-primary me-2"></i-feather>Free cPanel Migrations</li>
                                                <li class="text-muted my-2"><i-feather name="arrow-right-circle" style="width:20px;" class="fea icon-ex-md text-primary me-2"></i-feather>Plesk Migrations</li>
                                                <li class="text-muted my-2"><i-feather name="arrow-right-circle" style="width:20px;" class="fea icon-ex-md text-primary me-2"></i-feather>Boot, Login, Network, Hardware, Rebuild</li>
                                                <li class="text-muted my-2"><i-feather name="arrow-right-circle" style="width:20px;" class="fea icon-ex-md text-primary me-2"></i-feather>Basic server optimisation, patching & hardening</li>
                                                <li class="text-muted my-2"><i-feather name="arrow-right-circle" style="width:20px;" class="fea icon-ex-md text-primary me-2"></i-feather>Basic setup of Applications & Firewall</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->

                    <div class="col-lg-6 col-md-6 col-12 mt-5 mt-sm-0">
                        <div class="ms-lg-4">
                            <h4 class="text-white title-dark d-none d-md-block">General Questions</h4>
                            <h4 class="d-md-none d-block text-md-start text-center">General Questions</h4>

                            <div class="accordion mt-3" id="accordionExampletwo">
                                <div class="accordion-item rounded shadow">
                                    <h2 class="accordion-header" id="headingfive">
                                        <button class="accordion-button border-0 bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive"
                                            aria-expanded="true" aria-controls="collapsefive">
                                            How our Landrick work ?
                                        </button>
                                    </h2>
                                    <div id="collapsefive" class="accordion-collapse border-0 collapse show" aria-labelledby="headingfive"
                                        data-bs-parent="#accordionExampletwo">
                                        <div class="accordion-body text-muted">
                                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item rounded shadow mt-2">
                                    <h2 class="accordion-header" id="headingsix">
                                        <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                                            What is the main process open account ?
                                        </button>
                                    </h2>
                                    <div id="collapsesix" class="accordion-collapse border-0 collapse" aria-labelledby="headingsix"
                                        data-bs-parent="#accordionExampletwo">
                                        <div class="accordion-body text-muted">
                                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item rounded shadow mt-2">
                                    <h2 class="accordion-header" id="headingseven">
                                        <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven">
                                            How to make unlimited data entry ?
                                        </button>
                                    </h2>
                                    <div id="collapseseven" class="accordion-collapse border-0 collapse" aria-labelledby="headingseven"
                                        data-bs-parent="#accordionExampletwo">
                                        <div class="accordion-body text-muted">
                                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item rounded shadow mt-2">
                                    <h2 class="accordion-header" id="headingeight">
                                        <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseeight" aria-expanded="false" aria-controls="collapseeight">
                                            Is Landrick safer to use with my account ?
                                        </button>
                                    </h2>
                                    <div id="collapseeight" class="accordion-collapse border-0 collapse" aria-labelledby="headingeight"
                                        data-bs-parent="#accordionExampletwo">
                                        <div class="accordion-body text-muted">
                                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item rounded shadow mt-2">
                                    <h2 class="accordion-header" id="headingnine">
                                        <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapsenine" aria-expanded="false" aria-controls="collapsenine">
                                            How can i contact your technical team ?
                                        </button>
                                    </h2>
                                    <div id="collapsenine" class="accordion-collapse border-0 collapse" aria-labelledby="headingnine"
                                        data-bs-parent="#accordionExampletwo">
                                        <div class="accordion-body text-muted">
                                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- FAQs End -->

        <!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->