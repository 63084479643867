<!-- Hero Start -->
<section class="home-slider position-relative" id="home">
  <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <owl-carousel-o id="customer-testi" [options]="customOptions">
        <ng-template carouselSlide>
          <div class="bg-home d-flex align-items-center"
            style="background: url('assets/images/coworking/bg01.jpg') center center;">
            <div class="bg-overlay"></div>
            <div class="row justify-content-center">
              <div class="col-lg-12 text-center">
                <div class="title-heading mt-4">
                  <h6 class="text-white-50 para-dark animated fadeInDownBig animated.delay-2s">Private office and
                    Co-working space for <span class="text-success">$19</span></h6>
                  <h1 class="heading mb-3 text-white title-dark animated fadeInUpBig animation-delay-3">Coworking Space
                    For a Success</h1>
                  <p class="para-desc mx-auto text-white-50 para-dark animated fadeInUpBig animated.delay-4s">Launch your
                    campaign and benefit from our expertise on designing and managing conversion centered bootstrap v5
                    html page.</p>
                  <div class="mt-4 pt-2 animated fadeInUpBig animation-delay-11">
                    <a href="javascript:void(0)" class="btn btn-primary mt-2"><i class="uil uil-envelope"></i> Get
                      Started</a>
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
          </div>
          <!--end slide-->
        </ng-template>
        <ng-template carouselSlide>
          <div class="bg-home d-flex align-items-center"
            style="background: url('assets/images/coworking/bg02.jpg') center center;">
            <div class="bg-overlay"></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-12 text-center">
                  <div class="title-heading mt-4">
                    <h6 class="text-white-50 para-dark animated fadeInDownBig animated.delay-2s">The Best Coworking in The
                      City</h6>
                    <h1 class="heading mb-3 text-white title-dark animated fadeInUpBig animation-delay-3">Office Space
                      in Vietnam</h1>
                    <p class="para-desc mx-auto text-white-50 para-dark animated fadeInUpBig animated.delay-4s">Launch your
                      campaign and benefit from our expertise on designing and managing conversion centered bootstrap v5
                      html page.</p>

                    <div class="text-center subcribe-form mt-4 pt-2 animated fadeInUpBig animation-delay-11">
                      <form>
                        <input type="email" id="email" name="email" class="rounded" placeholder="E-mail">
                        <button type="submit" class="btn btn-primary">Book Space</button>
                      </form>
                      <!--end form-->
                    </div>
                  </div>
                </div>
                <!--end col-->
              </div>
            </div>
          </div>
          <!--end slide-->
        </ng-template>
        <ng-template carouselSlide>
          <div class="bg-home d-flex align-items-center"
            style="background: url('assets/images/coworking/bg03.jpg') center center;">
            <div class="bg-overlay"></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-12 text-center">
                  <div class="title-heading mt-4">
                    <h6 class="text-white-50 para-dark animated fadeInDownBig animated.delay-2s">Our Space for You</h6>
                    <h1 class="heading mb-3 text-white title-dark animated fadeInUpBig animation-delay-3">Own Your
                      Office For A Day</h1>
                    <p class="para-desc mx-auto text-white-50 para-dark animated fadeInUpBig animated.delay-4s">Launch your
                      campaign and benefit from our expertise on designing and managing conversion centered bootstrap v5
                      html page.</p>
                    <div class="mt-4 pt-2 animated fadeInUpBig animation-delay-11">
                      <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" data-type="youtube"
                        data-id="yba7hPeTSjk" class="btn btn-icon btn-pills btn-primary m-1">
                        <i-feather name="video" class="icons"></i-feather>
                      </a><span class="fw-bold text-uppercase small text-light title-dark align-middle ms-1">Watch
                        Now</span>
                      <ng-template #content let-modal>
                        <div class="modal-header">
                          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <youtube-player [videoId]="'jNTZpfXYJa4'" height="450" width="780">
                          </youtube-player>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </div>
          </div>
          <!--end slide-->
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</section>
<!--end section-->
<!-- Hero End -->

<!-- Partners start -->
<section class="py-5 border-bottom border-top">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <h5>Trusted client by over 10,000+ of the world’s</h5>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <div class="mt-5">
      <app-clients-logo brand_class="col-lg-2 col-md-2 col-6 text-center"></app-clients-logo>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Partners End -->

<!-- About Start -->
<section class="section">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 col-12">
        <img src="assets/images/coworking/about.jpg" class="img-fluid rounded" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title ms-lg-4">
          <h4 class="title mb-4">About Our Community</h4>
          <p class="text-muted">Start working with <span class="text-primary fw-bold">Landrick Space</span> that can
            provide everything you need to generate awareness, drive traffic, connect. Dummy text is text that is used
            in the publishing industry or by web designers to occupy the space which will later be filled with 'real'
            content. This is required when, for example, the final text is not yet available. Dummy texts have been in
            use by typesetters since the 16th century.</p>
          <a href="javascript:void(0)" class="btn btn-primary mt-3">Join now</a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--enc container-->
</section>
<!--end section-->
<!-- About End -->

<!-- Services Start -->
<section class="section bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Your Comfort is Our Priority</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-3 col-md-4 col-6 mt-4 pt-2">
        <div class="features text-center pt-3 pb-3">
          <i class="uil uil-wifi h1 text-primary"></i>
          <div class="content mt-3">
            <h5>Fast Internet</h5>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 col-6 mt-4 pt-2">
        <div class="features text-center pt-3 pb-3">
          <i class="uil uil-print h1 text-primary"></i>
          <div class="content mt-3">
            <h5>Printer & Fax</h5>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 col-6 mt-4 pt-2">
        <div class="features text-center pt-3 pb-3">
          <i class="uil uil-restaurant h1 text-primary"></i>
          <div class="content mt-3">
            <h5>Modern Kitchen</h5>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 col-6 mt-4 pt-2">
        <div class="features text-center pt-3 pb-3">
          <i class="uil uil-clock h1 text-primary"></i>
          <div class="content mt-3">
            <h5>24 Hr Access</h5>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 col-6 mt-4 pt-2">
        <div class="features text-center pt-3 pb-3">
          <i class="uil uil-envelope-alt h1 text-primary"></i>
          <div class="content mt-3">
            <h5>Mail Service</h5>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 col-6 mt-4 pt-2">
        <div class="features text-center pt-3 pb-3">
          <i class="uil uil-calendar-alt h1 text-primary"></i>
          <div class="content mt-3">
            <h5>Events Space</h5>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 col-6 mt-4 pt-2">
        <div class="features text-center pt-3 pb-3">
          <i class="uil uil-user-arrows h1 text-primary"></i>
          <div class="content mt-3">
            <h5>Conference Rooms</h5>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 col-6 mt-4 pt-2">
        <div class="features text-center pt-3 pb-3">
          <i class="uil uil-coffee h1 text-primary"></i>
          <div class="content mt-3">
            <h5>Organic Tea & Coffee</h5>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Services End -->

<!-- CTA START -->
<section class="section">
  <div class="container">
    <div class="card bg-light rounded border-0 overflow-hidden">
      <div class="row no-gutters align-items-center">
        <div class="col-lg-6">
          <img src="assets/images/coworking/cta.jpg" class="img-fluid" alt="">
        </div>
        <!--end col-->

        <div class="col-lg-6 text-center">
          <div class="card-body section-title p-md-5">
            <a (click)="openWindowCustomClass(content)" href="javascript:void(0);"
              class="play-btn mt-0 border border-light text-center video-play-icon">
              <i-feather name="play" class="fea icon-ex-md text-dark"></i-feather>
            </a>
            <h4 class="title mb-4 mt-4">We Are Creative Dreamers and Co-workers</h4>
            <p class="text-muted mx-auto para-desc mb-0">Start working with Landrick that can provide everything you
              need to generate awareness, drive traffic, connect.</p>
            <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <youtube-player [videoId]="'yba7hPeTSjk'" height="450" width="780">
                </youtube-player>
              </div>
            </ng-template>
          </div>
        </div>
        <!--end col-->
      </div>
    </div>
  </div>
  <!--end container-->

  <!-- Start Price -->
  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Choose The Pricing Plan</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row align-items-center">
      <div class="col-md-3 col-12 mt-4 pt-2">
        <div class="card pricing-rates best-plan rounded bg-light text-center border-0">
          <div class="card-body py-5">
            <img src="assets/images/icon/lightbulb.svg" class="mb-4" height="50" alt="">
            <h6 class="title text-uppercase mb-4">Member</h6>
            <div class="d-flex justify-content-center mb-4">
              <span class="h4 mb-0 mt-2">$</span>
              <span class="price h1 mb-0">19</span>
              <span class="h4 align-self-end mb-1">/mo</span>
            </div>

            <p class="text-muted">Start working with Landrick that can provide everything you need to generate
              awareness, drive traffic, connect.</p>
            <a href="javascript:void(0)" class="btn btn-primary btn-sm mt-4">Buy Now</a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-3 col-12 mt-4 pt-2">
        <div class="card pricing-rates best-plan rounded shadow text-center border-0">
          <div class="ribbon ribbon-right ribbon-warning overflow-hidden"><span
              class="text-center d-block shadow small h6">Best</span></div>
          <div class="card-body py-5">
            <img src="assets/images/icon/table.svg" class="mb-4" height="50" alt="">
            <h6 class="title text-uppercase mb-4">Desk</h6>
            <div class="d-flex justify-content-center mb-4">
              <span class="h4 mb-0 mt-2">$</span>
              <span class="price h1 mb-0">39</span>
              <span class="h4 align-self-end mb-1">/mo</span>
            </div>

            <p class="text-muted">Start working with Landrick that can provide everything you need to generate
              awareness, drive traffic, connect.</p>
            <a href="javascript:void(0)" class="btn btn-primary btn-sm mt-4">Get Started</a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-3 col-12 mt-4 pt-2">
        <div class="card pricing-rates best-plan rounded bg-light text-center border-0">
          <div class="card-body py-5">
            <img src="assets/images/icon/star.svg" class="mb-4" height="50" alt="">
            <h6 class="title text-uppercase mb-4">Dedicated</h6>
            <div class="d-flex justify-content-center mb-4">
              <span class="h4 mb-0 mt-2">$</span>
              <span class="price h1 mb-0">49</span>
              <span class="h4 align-self-end mb-1">/mo</span>
            </div>

            <p class="text-muted">Start working with Landrick that can provide everything you need to generate
              awareness, drive traffic, connect.</p>
            <a href="javascript:void(0)" class="btn btn-primary btn-sm mt-4">Get Started</a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-3 col-12 mt-4 pt-2">
        <div class="card pricing-rates best-plan rounded bg-light text-center border-0">
          <div class="card-body py-5">
            <img src="assets/images/icon/start-up.svg" class="mb-4" height="50" alt="">
            <h6 class="title text-uppercase mb-4">Office</h6>
            <div class="d-flex justify-content-center mb-4">
              <span class="h4 mb-0 mt-2">$</span>
              <span class="price h1 mb-0">59</span>
              <span class="h4 align-self-end mb-1">/mo</span>
            </div>

            <p class="text-muted">Start working with Landrick that can provide everything you need to generate
              awareness, drive traffic, connect.</p>
            <a href="javascript:void(0)" class="btn btn-primary btn-sm mt-4">Try It Now</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!-- End Price -->
</section>
<!--end section-->
<!-- CTA END -->

<!-- Start Client And Blog -->
<section class="section bg-light">
  <!-- Start Client -->
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Trusted Co-workers</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class="col-lg-12 mt-4">
        <app-testimonial [testimonialData]="testimonialData"></app-testimonial>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!-- End Client -->

  <!-- Start Blog -->
  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Latest News & Events</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <app-blog [blogData]="blogData"></app-blog>
  </div>
  <!--end container-->
  <!-- End Blog -->
</section>
<!--end section-->
<!-- End Client And Blog -->

<!-- Start Form -->
<section class="section" style="background: url('assets/images/coworking/bg04.jpg') center center;">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-8 col-md-7">
        <div class="section-title me-lg-4">
          <h4 class="title title-dark text-light mb-4">We are Built for Business – Explore Us Today !</h4>
          <p class="text-white-50 para-dark para-desc mb-0">Start working with <span
              class="text-success font-weight-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
          <div class="watch-video mt-4 pt-2">
            <a href="javascript:void(0)" class="btn btn-primary me-2">Install Now</a>
            <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" data-type="youtube"
              data-id="yba7hPeTSjk" class="btn btn-icon btn-pills btn-primary m-1">
              <i-feather name="video" class="icons"></i-feather>
            </a><span class="fw-bold text-uppercase small text-light title-dark align-middle ms-1">Watch Now</span>
            <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <youtube-player [videoId]="'jNTZpfXYJa4'" height="450" width="780">
                </youtube-player>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="card rounded shadow border-0">
          <div class="card-body">
            <h5 class="text-center">Become A Member</h5>

            <form>
              <div class="row mt-4">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Your Name : <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="user" class="fea icon-sm icons"></i-feather>
                      <input name="name" id="name3" type="text" class="form-control ps-5" placeholder="Name"
                        required="">
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Your Email : <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                      <input type="email" id="email3" class="form-control ps-5" placeholder="Email" name="email"
                        required="">
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label">Your Phone no.: <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="phone" class="fea icon-sm icons"></i-feather>
                      <input name="number" id="number" type="number" class="form-control ps-5" placeholder="Phone no. :"
                        required="">
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-lg-12 mb-0">
                  <div class="d-grid">
                    <button class="btn btn-primary">Started now</button>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </form>
            <!--end form-->
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Form -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->