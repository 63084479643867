<section class="bg-half-170 d-table w-100 bg-dark" id="home">
  <div class="container">
    <div class="row mt-5 align-items-center">
      <div class="col-lg-7 col-md-7">
        <div class="title-heading me-lg-4">
          <h1 class="heading mb-3 text-white">Experience The Power Of Our<span class="text-primary"> Elite Team </span>
          </h1>
          <p class="para-desc text-muted">For companies of all sizes and types, including start-ups, small businesses,
            and large enterprises, we offer specialised solutions.</p>
          <div class="mt-4">
            <a routerLink="/page-contact-two" class="btn btn-primary mt-4"><i class="mdi mdi-phone"></i> Contact us</a>
            <!-- <a routerLink="/documentation" class="btn btn-outline-primary mt-2"><i class="uil uil-book-alt"></i>
              Documentation</a> -->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <img src="assets/images/illustrator/Startup_SVG.svg" alt="">
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<section class="section pt-0 bg-dark ">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="video-solution-cta position-relative" style="z-index: 1;">
          <div class="position-relative py-5 rounded" style="background: url('assets/images/it/cta.jpg') top;">
            <div class="bg-overlay rounded bg-primary bg-gradient" style="opacity: 0.8; background: #3c4858!important;box-shadow: 0.8px 0.8px 6px #ed193f;"></div>

            <div class="row">
              <div class="col-lg-3 col-6 my-4">
                <div class="counter-box text-center px-lg-4">
                  <i class="uil uil-users-alt text-white-50 h2"></i>
                  <h1 class="mb-0 text-white"><span class="counter-value" [CountTo]="100" [from]="10"
                      [duration]="2"></span>+</h1>
                  <h6 class="counter-head text-white-50">Happy Client</h6>
                </div>
                <!--end counter box-->
              </div>
              <!--end col-->

              <div class="col-lg-3 col-6 my-4">
                <div class="counter-box text-center px-lg-4">
                  <i class="uil uil-schedule text-white-50 h2"></i>
                  <h1 class="mb-0 text-white"><span class="counter-value" [CountTo]="7" [from]="1"
                      [duration]="2"></span>+</h1>
                  <h6 class="counter-head text-white-50">Years Experience</h6>
                </div>
                <!--end counter box-->
              </div>
              <!--end col-->

              <div class="col-lg-3 col-6 my-4">
                <div class="counter-box text-center px-lg-4">
                  <i class="uil uil-file-check-alt text-white-50 h2"></i>
                  <h1 class="mb-0 text-white"><span class="counter-value" [CountTo]="11" [from]="1"
                      [duration]="2"></span>+</h1>
                  <h6 class="counter-head text-white-50">Project Complete</h6>
                </div>
                <!--end counter box-->
              </div>
              <!--end col-->

              <div class="col-lg-3 col-6 my-4">
                <div class="counter-box text-center px-lg-4">
                  <i class="uil uil-hard-hat text-white-50 h2"></i>
                  <h1 class="mb-0 text-white"><span class="counter-value" [CountTo]="98" [from]="3"
                      [duration]="2"></span>+</h1>
                  <h6 class="counter-head text-white-50">Expert Engineers</h6>
                </div>
                <!--end counter box-->
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row -->
    <div class="feature-posts-placeholder bg-dark" style="background-color: #2B3949!important;"></div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!--end section-->
<!-- Hero End -->

<!-- Partners start -->
<!-- <section class="py-4 border-bottom border-top">
  <div class="container">
    <app-clients-logo brand_class="col-lg-2 col-md-2 col-6 text-center py-4"></app-clients-logo>
  </div>
</section> -->
<!--end container-->
<!--end section-->
<!-- Partners End -->

<!-- How It Work Start -->
<section class="section bg-dark">
  <!-- <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">How It Work ?</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span class="text-primary fw-bold"></span>
            Project planning is preference to determining the overall goals and objectives of the business analysis. The
            most important stage in the entire project without analysis and research project success of any company.</p>
        </div>
      </div>

    </div>
 

    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 mt-4 pt-2">
        <img src="assets/images/illustrator/SEO_SVG.svg" alt="">
      </div>


      <div class="col-lg-7 col-md-6 mt-4 pt-2">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4">Steps to the success</h4>
          <p class="text-muted">You can combine all the Tenzed templates into a single one, you can take a component
            from the Application theme and use it in the Website.</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for Tomorrow</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Our Talented & Experienced Marketing Agency</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Create your own skin to match your brand</li>
          </ul>
          <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
              class="uil uil-angle-right-b align-middle"></i></a>
        </div>
      </div>
   
    </div>
 
  </div> -->

  <div class="container mt-00 mt-60">
    <div class="row">
      <div class="d-flex">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active"
          class="nav nav-pills flex-column rounded shadow p-3 mb-0 col-md-4" orientation="vertical"
          style="background: #2B3949!important; border:1.8px solid white">
          <li ngbNavItem="top" class="nav-item">
            <a ngbNavLink>
              <div class="text-start py-1 px-2 ">
                <h6 class="mb-0 text-white">Software Development</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="tab-pane fade p-4 rounded shadow active show" id="dental" role="tabpanel"
                aria-labelledby="dentalcare" style="height: 100% !important; ">
                <!-- <img src="assets/images/medical/dental.jpg" class="img-fluid rounded shadow" alt=""> -->
                <div class="mt-4">
                  <h5 class="text-white">Software Development</h5>
                  <p class="text-muted my-3">We offer a wide range of custom software development services and
                    frameworks, whether you need help creating a website for your company, an information hub, or
                    backend services to enable your apps and Internet of Things devices. In order to provide custom web
                    solutions, our licenced developers have vast experience working with the most recent web
                    technologies.
                  </p>
                  <!-- <a href="javascript:void(0)" class="text-primary">Learn More <i-feather name="arrow-right"
                      class="fea icon-sm"></i-feather></a> -->
                </div>
              </div>
            </ng-template>
          </li>
          <li ngbNavItem="middle" class="nav-item mt-2">
            <a ngbNavLink>
              <div class="text-start py-1 px-2">
                <h6 class="mb-0 text-white">App Development</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="tab-pane fade p-4 rounded shadow active show" id="dental" role="tabpanel"
                aria-labelledby="dentalcare">
                <!-- <img src="assets/images/medical/cardio.jpg" class="img-fluid rounded shadow" alt=""> -->
                <div class="mt-4">
                  <h5 class="text-white">App Development</h5>
                  <p class="text-muted my-3">Are you a business that provides services on demand? Do you have a concept
                    for an on-demand mobile application? We create apps that are precisely suited to your requirements.
                    Utilise our skills in on-demand bespoke app development to add cutting-edge features and improve
                    user experience for your customers while staying within your budget and timetable requirements.</p>
                  <!-- <a href="javascript:void(0)" class="text-primary">Learn More <i-feather name="arrow-right"
                      class="fea icon-sm"></i-feather></a> -->
                </div>
              </div>
            </ng-template>
          </li>
          <li ngbNavItem="bottom" class="nav-item mt-2">
            <a ngbNavLink>
              <div class="text-start py-1 px-2">
                <h6 class="mb-0 text-white">Web Development</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="tab-pane fade p-4 rounded shadow active show" id="dental" role="tabpanel"
                aria-labelledby="dentalcare">
                <!-- <img src="assets/images/medical/eyecare.jpg" class="img-fluid rounded shadow" alt=""> -->
                <div class="mt-4">
                  <h5 class="text-white">Web Development</h5>
                  <p class="text-muted my-3">With extensive experience and a strong team, we create user-friendly and
                    contemporary websites, online portals, and other web products that modernise business operations,
                    promote digital transformation, and expand corporate workflows. You can develop one-of-a-kind,
                    quickly loading web development services by working with our staff. Businesses can utilise Tenzed to
                    grow their user bases more quickly, create new digital channels, boost conversion, draw in more
                    customers, gain their loyalty, and accomplish other important business objectives.</p>
                  <!-- <a href="javascript:void(0)" class="text-primary">Learn More <i-feather name="arrow-right"
                      class="fea icon-sm"></i-feather></a> -->
                </div>
              </div>
            </ng-template>
          </li>
          <li ngbNavItem="general" class="nav-item mt-2">
            <a ngbNavLink>
              <div class="text-start py-1 px-2">
                <h6 class="mb-0 text-white">Product Development</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="tab-pane fade p-4 rounded shadow active show" id="dental" role="tabpanel"
                aria-labelledby="dentalcare">
                <!-- <img src="assets/images/medical/general.jpg" class="img-fluid rounded shadow" alt=""> -->
                <div class="mt-4">
                  <h5 class="text-white">Product Development</h5>
                  <p class="text-muted my-3">We transform your ideas into a stable, fully operational product. We
                    provide support to organisations of all sizes and developmental stages around the world as they
                    implement agile solutions. We essentially merge into your business by helping you to explore, think
                    through the experience, and iterate. We develop a tested, advance-paying product based on your
                    concept. We are specialists in creating connected gadgets, among other things.</p>
                  <!-- <a href="javascript:void(0)" class="text-primary">Learn More <i-feather name="arrow-right"
                      class="fea icon-sm"></i-feather></a> -->
                </div>
              </div>
            </ng-template>
          </li>
          <li ngbNavItem="medicine" class="nav-item mt-2">
            <a ngbNavLink>
              <div class="text-start py-1 px-2">
                <h6 class="mb-0 text-white">Mobile App Development</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="tab-pane fade p-4 rounded shadow active show" id="dental" role="tabpanel"
                aria-labelledby="dentalcare">
                <!-- <img src="assets/images/medical/medicine.jpg" class="img-fluid rounded shadow" alt=""> -->
                <div class="mt-4">
                  <h5 class="text-white">Mobile App Development</h5>
                  <p class="text-muted my-3">Mobile app development services have seen a major change recently and are
                    now essential to all businesses. As one of the top providers of mobile application development
                    services, we create reliable, adaptable mobile apps for a variety of businesses. with a focus on
                    developing a futuristic strategy for producing customer-centric solutions that are effective,
                    imaginative, and economical.</p>
                  <!-- <a href="javascript:void(0)" class="text-primary">Learn More <i-feather name="arrow-right"
                      class="fea icon-sm"></i-feather></a> -->
                </div>
              </div>
            </ng-template>
          </li>
          <li ngbNavItem="orthopadic" class="nav-item mt-2">
            <a ngbNavLink>
              <div class="text-start py-1 px-2">
                <h6 class="mb-0 text-white">Cloud Computing</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="tab-pane fade p-4 rounded shadow active show" id="dental" role="tabpanel"
                aria-labelledby="dentalcare">
                <!-- <img src="assets/images/medical/orthopedic.jpg" class="img-fluid rounded shadow" alt=""> -->
                <div class="mt-4">
                  <h5 class="text-white">Cloud Computing</h5>
                  <p class="text-muted my-3">Moving to the cloud is quickly overtaking other cost-effective IT
                    infrastructure reinforcement strategies as more businesses look for them. Because they incorporate
                    processing, storage, data, analytics, and machine learning into a single platform, Google Cloud,
                    AWS, and Azure are also referred to as "all-in-one cloud platforms."</p>
                  <!-- <a href="javascript:void(0)" class="text-primary">Learn More <i-feather name="arrow-right"
                      class="fea icon-sm"></i-feather></a> -->
                </div>
              </div>
            </ng-template>
          </li>
          <li ngbNavItem="orthopadic1" class="nav-item mt-2">
            <a ngbNavLink>
              <div class="text-start py-1 px-2">
                <h6 class="mb-0 text-white">eCommerce Development</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="tab-pane fade p-4 rounded shadow active show" id="dental" role="tabpanel"
                aria-labelledby="dentalcare">
                <!-- <img src="assets/images/medical/orthopedic.jpg" class="img-fluid rounded shadow" alt=""> -->
                <div class="mt-4">
                  <h5 class="text-white">eCommerce Development</h5>
                  <p class="text-muted my-3">We only occasionally meet the same tech setup or integration needs, despite
                    having a vast customer base. We take pride in providing exact Business & System Architecture
                    solutions across a range of platforms that address all circumstances and endpoints. We are
                    certified, partners, and experts on several of the world's leading eCommerce systems, including
                    Magento by Adobe, Shopify Plus, and Big Commerce.</p>
                  <!-- <a href="javascript:void(0)" class="text-primary">Learn More <i-feather name="arrow-right"
                      class="fea icon-sm"></i-feather></a> -->
                </div>
              </div>
            </ng-template>
          </li>
          <li ngbNavItem="orthopadic2" class="nav-item mt-2">
            <a ngbNavLink>
              <div class="text-start py-1 px-2">
                <h6 class="mb-0 text-white">Big Data & Analytics</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="tab-pane fade p-4 rounded shadow active show" id="dental" role="tabpanel"
                aria-labelledby="dentalcare">
                <!-- <img src="assets/images/medical/orthopedic.jpg" class="img-fluid rounded shadow" alt=""> -->
                <div class="mt-4">
                  <h5 class="text-white">Big Data & Analytics</h5>
                  <p class="text-muted my-3">Moving to the cloud is quickly overtaking other cost-effective IT
                    infrastructure reinforcement strategies as more businesses look for them. Because they incorporate
                    processing, storage, data, analytics, and machine learning into a single platform, Google Cloud,
                    AWS, and Azure are also referred to as "all-in-one cloud platforms."</p>
                  <!-- <a href="javascript:void(0)" class="text-primary">Learn More <i-feather name="arrow-right"
                      class="fea icon-sm"></i-feather></a> -->
                </div>
              </div>
            </ng-template>
          </li>
          <li ngbNavItem="orthopadic3" class="nav-item mt-2">
            <a ngbNavLink>
              <div class="text-start py-1 px-2">
                <h6 class="mb-0 text-white">Testing & QA</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="tab-pane fade p-4 rounded shadow active show" id="dental" role="tabpanel"
                aria-labelledby="dentalcare">
                <!-- <img src="assets/images/medical/orthopedic.jpg" class="img-fluid rounded shadow" alt=""> -->
                <div class="mt-4">
                  <h5 class="text-white">Testing & QA</h5>
                  <p class="text-muted my-3">Business organisations are reexamining traditional quality assurance and
                    testing services and practises as they strive for excellence in their software applications. They
                    seek out offerings that guarantee user experience consistency and application stability. The goal is
                    to have software solutions that give the company the ability to adapt to shifting market conditions.
                    Our strategies shorten the time to market, boost return on investment, and help customers get more
                    out of our testing efforts.</p>
                  <!-- <a href="javascript:void(0)" class="text-primary">Learn More <i-feather name="arrow-right"
                      class="fea icon-sm"></i-feather></a> -->
                </div>
              </div>
            </ng-template>
          </li>
          <li ngbNavItem="orthopadic4" class="nav-item mt-2">
            <a ngbNavLink>
              <div class="text-start py-1 px-2">
                <h6 class="mb-0 text-white">SAP Consulting</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="tab-pane fade p-4 rounded shadow active show" id="dental" role="tabpanel"
                aria-labelledby="dentalcare">
                <!-- <img src="assets/images/medical/orthopedic.jpg" class="img-fluid rounded shadow" alt=""> -->
                <div class="mt-4">
                  <h5 class="text-white">SAP Consulting</h5>
                  <p class="text-muted my-3">Moving to the cloud is quickly overtaking other cost-effective IT
                    infrastructure reinforcement strategies as more businesses look for them. Because they incorporate
                    processing, storage, data, analytics, and machine learning into a single platform, Google Cloud,
                    AWS, and Azure are also referred to as "all-in-one cloud platforms."</p>
                  <!-- <a href="javascript:void(0)" class="text-primary">Learn More <i-feather name="arrow-right"
                      class="fea icon-sm"></i-feather></a> -->
                </div>
              </div>
            </ng-template>
          </li>
          <li ngbNavItem="orthopadic5" class="nav-item mt-2">
            <a ngbNavLink>
              <div class="text-start py-1 px-2">
                <h6 class="mb-0 text-white">UI/UX Design</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="tab-pane fade p-4 rounded shadow active show" id="dental" role="tabpanel"
                aria-labelledby="dentalcare">
                <!-- <img src="assets/images/medical/orthopedic.jpg" class="img-fluid rounded shadow" alt=""> -->
                <div class="mt-4">
                  <h5 class="text-white">UI/UX Design</h5>
                  <p class="text-muted my-3">As in chess, you must plan out several moves in advance. Tenzed offers a
                    best-in-class user experience by analysing consumer behaviour using data to identify the variety of
                    personas and online goals and by applying best-in-class digital capabilities. Based on our in-depth
                    knowledge of digital commerce, our UI/UX designers investigate how basic operations and service
                    delivery are digitised as well as how value is created at each technological and marketing function.
                  </p>
                  <!-- <a href="javascript:void(0)" class="text-primary">Learn More <i-feather name="arrow-right"
                      class="fea icon-sm"></i-feather></a> -->
                </div>
              </div>
            </ng-template>
          </li>
        </ul>

        <div [ngbNavOutlet]="nav" class="ms-4 col-md-8"></div>
      </div>
    </div>
  </div>
  <!--end container-->
</section>
<section class="section p-2 bg-dark" id="showhide2">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h6 class="text-primary">Work Process</h6>
          <h4 class="title mb-4 text-white">How do we works ?</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Tenzed</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-md-4 mt-4 pt-2">
        <div
          class="card features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-presentation-edit d-block rounded h3 mb-0"></i>
            <div class="process1"></div>

          </div>

          <div class="card-body">
            <h5 class="text-white">Learn</h5>
            <p class="text-muted mb-0">We carefully take advantage of the rapidly evolving tech industry. Our team of
              professionals keeps up with emerging technologies and adjusts to them to provide the finest solutions for
              our clients. We make sure to keep up with the quick-moving technology industry by following the procedures
              of cutting-edge technologies and learning new tools. Tenzed is your ideal partner for software development
              services since we value lifelong learning.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
        <div
          class="card features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-airplay d-block rounded h3 mb-0"></i>
            <div class="process1"></div>

          </div>

          <div class="card-body">
            <h5 class="text-white">Plan</h5>
            <p class="text-muted mb-0">Before beginning a project, we always develop a strategic plan. Tenzed is a
              well-known software development firm, thus it is aware of what its customers want. Our team investigates
              all available options in-depth before developing a strategy. For definite commercial successes and ensured
              results, we strive to provide our clients with the greatest results.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
        <div
          class="card features feature-primary feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-image-check d-block rounded h3 mb-0"></i>
            <div class="process1"></div>

          </div>

          <div class="card-body">
            <h5 class="text-white">Design</h5>
            <p class="text-muted mb-0">Without an interesting, responsive, and high-quality design, no website,
              programme, or app performs successfully. Only a skilled UI/UX designer can offer first-rate designs that
              also characterise your company. Tenzed has a talented and highly trained pool of UI/UX designers who
              provide intuitive and eye-catching designs. Whether your design requirements are basic or complex, our
              designers will always offer remarkable solutions.</p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <div class="arrow">
      <div class="row">
        <div class="col-md-4 mt-4 pt-2">
          <div
            class="card features feature-primary feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
            <div class="icons text-primary text-center mx-auto">
              <i class="uil uil-presentation-edit d-block rounded h3 mb-0"></i>
              <div class="process"></div>
              <div class="process1"></div>

            </div>

            <div class="card-body">
              <h5 class="text-white">Support</h5>
              <p class="text-muted mb-0">delivering premium services to our clients using cutting-edge technology. Our
                support and maintenance team works tirelessly to provide you with the best services possible. Our
                support team offers suitable solutions for successful outcomes, from upgrades to the maintenance of the
                app and website. To maintain a consistent flow of work, we even resolve your problems after the project
                is delivered.</p>
            </div>
          </div>
        </div>
        <!--end col-->

        <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
          <div
            class="card features feature-primary feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
            <div class="icons text-primary text-center mx-auto">
              <i class="uil uil-airplay d-block rounded h3 mb-0"></i>
              <div class="process"></div>
              <div class="process1"></div>


            </div>

            <div class="card-body">
              <h5 class="text-white">Launch</h5>
              <p class="text-muted mb-0">Online business launches can be intimidating. Tenzed never leaves your side
                because of this. Once you hire us for your project, we promise to support you all the way through. Your
                app or website is launched by our committed team after several testing at each level. To guarantee that
                the audience sees a flawlessly developed website or app with no bugs, this is done.</p>
            </div>
          </div>
        </div>
        <!--end col-->

        <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
          <div
            class="card features feature-primary feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
            <div class="icons text-primary text-center mx-auto">
              <i class="uil uil-image-check d-block rounded h3 mb-0"></i>

            </div>

            <div class="card-body">
              <h5 class="text-white">Build</h5>
              <p class="text-muted mb-0">Our programme is created with the utmost accuracy and honesty. We devote all of
                our energy to producing the best results during the constructing stage, which is a prominent one. Tenzed
                builds your programme using the most up-to-date technologies under the guiding principle of offering
                perfection. Our programmers are skilled experts with several years of experience. Our area of expertise
                is the development of excellent software, which we have been doing for decades.</p>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!--end container-->
</section>
<section class="section p-2 bg-dark" id="showhide">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h6 class="text-primary">Work Process</h6>
          <h4 class="title mb-4 text-white">How do we works ?</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Tenzed</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-md-4 mt-4 pt-2">
        <div
          class="card features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-presentation-edit d-block rounded h3 mb-0"></i>
            <div class="process1"></div>

          </div>

          <div class="card-body">
            <h5 class="text-white">Learn</h5>
            <p class="text-muted mb-0">We carefully take advantage of the rapidly evolving tech industry. Our team of
              professionals keeps up with emerging technologies and adjusts to them to provide the finest solutions for
              our clients. We make sure to keep up with the quick-moving technology industry by following the procedures
              of cutting-edge technologies and learning new tools. Tenzed is your ideal partner for software development
              services since we value lifelong learning.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
        <div
          class="card features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-airplay d-block rounded h3 mb-0"></i>
            <div class="process1"></div>

          </div>

          <div class="card-body">
            <h5 class="text-white">Plan</h5>
            <p class="text-muted mb-0">Before beginning a project, we always develop a strategic plan. Tenzed is a
              well-known software development firm, thus it is aware of what its customers want. Our team investigates
              all available options in-depth before developing a strategy. For definite commercial successes and ensured
              results, we strive to provide our clients with the greatest results.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
        <div
          class="card features feature-primary feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-image-check d-block rounded h3 mb-0"></i>
            <div class="process1"></div>

          </div>

          <div class="card-body">
            <h5 class="text-white">Design</h5>
            <p class="text-muted mb-0">Without an interesting, responsive, and high-quality design, no website,
              programme, or app performs successfully. Only a skilled UI/UX designer can offer first-rate designs that
              also characterise your company. Tenzed has a talented and highly trained pool of UI/UX designers who
              provide intuitive and eye-catching designs. Whether your design requirements are basic or complex, our
              designers will always offer remarkable solutions.</p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <div class="arrow">
      <div class="row">




        <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
          <div
            class="card features feature-primary feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
            <div class="icons text-primary text-center mx-auto">
              <i class="uil uil-image-check d-block rounded h3 mb-0"></i>
              <div class="process"></div>
              <div class="process1"></div>
            </div>

            <div class="card-body">
              <h5 class="text-white">Build</h5>
              <p class="text-muted mb-0">Our programme is created with the utmost accuracy and honesty. We devote all of
                our energy to producing the best results during the constructing stage, which is a prominent one. Tenzed
                builds your programme using the most up-to-date technologies under the guiding principle of offering
                perfection. Our programmers are skilled experts with several years of experience. Our area of expertise
                is the development of excellent software, which we have been doing for decades.</p>
            </div>
          </div>
        </div>
        <!--end col-->
        <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
          <div
            class="card features feature-primary feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
            <div class="icons text-primary text-center mx-auto">
              <i class="uil uil-airplay d-block rounded h3 mb-0"></i>
              <div class="process"></div>
              <div class="process1"></div>


            </div>

            <div class="card-body">
              <h5 class="text-white">Launch</h5>
              <p class="text-muted mb-0">Online business launches can be intimidating. Tenzed never leaves your side
                because of this. Once you hire us for your project, we promise to support you all the way through. Your
                app or website is launched by our committed team after several testing at each level. To guarantee that
                the audience sees a flawlessly developed website or app with no bugs, this is done.</p>
            </div>
          </div>
        </div>
        <!--end col-->
        <div class="col-md-4 mt-4 pt-2">
          <div
            class="card features feature-primary feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
            <div class="icons text-primary text-center mx-auto">
              <i class="uil uil-presentation-edit d-block rounded h3 mb-0"></i>

            </div>

            <div class="card-body">
              <h5 class="text-white">Support</h5>
              <p class="text-muted mb-0">delivering premium services to our clients using cutting-edge technology. Our
                support and maintenance team works tirelessly to provide you with the best services possible. Our
                support team offers suitable solutions for successful outcomes, from upgrades to the maintenance of the
                app and website. To maintain a consistent flow of work, we even resolve your problems after the project
                is delivered.</p>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!--end container-->
</section>
<!--end section-->
<!-- How It Work End -->
<section class="section p-2 bg-dark">
  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <h3 class="title mb-4 text-white" style="font-size: 32px;">Services</h3>
      </div>
      <!--end col-->
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow" style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="abx" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/website.svg" alt="" style="width: 100%;">
          </div>
          <div class="flex-1">
            <h6 class="title mb-0 text-white service-title">Website</h6>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow" style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/software.svg" alt="" style="width: 100%;">
          </div>
          <div class="flex-1">
            <h6 class="title mb-0 text-white service-title">Software</h6>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow" style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/development.svg" alt="" style="width: 100%;">
          </div>
          <div class="flex-1">
            <h6 class="title mb-0 text-white service-title">Develop Based On Your Requirement</h6>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow" style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/software1.svg" alt="" style="width: 100%;">
          </div>
          <div class="flex-1">
            <h6 class="title mb-0 text-white service-title">UI-UX / Logo / Design card</h6>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow" style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/email.svg" alt="" style="width: 100%;">
          </div>
          <div class="flex-1">
            <h6 class="title mb-0 text-white service-title">Email Setting</h6>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow"style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/database.svg" alt="" style="width: 100%;">
          </div>
          <div class="flex-1">
            <h6 class="title mb-0 text-white service-title">Database Management</h6>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow"style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/document.svg" alt="" style="width: 100%;">
          </div>
          <div class="flex-1">
            <h6 class="title mb-0 text-white service-title">Document Management</h6>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow"style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/business.svg" alt="" style="width: 90%;">
          </div>
          <div class="flex-1">
            <h6 class="title mb-0 text-white service-title">Digitize Your Business</h6>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow"style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/report.svg" alt="" style="width: 100%;">
          </div>
          <div class="flex-1">
            <h6 class="title mb-0 text-white service-title">Data Report Wise Management</h6>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow"style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/support.svg" alt="" style="width: 100%;">
          </div>
          <div class="flex-1">
            <h6 class="title mb-0 text-white service-title">Personalized Business Support Tool</h6>

          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow"style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/project.svg" alt="" style="width: 100%;">
          </div>
          <div class="flex-1">
            <h6 class="title mb-0 text-white service-title">Project/Domain/DNS Management</h6>

          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow"style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/excel.svg" alt="" style="width: 100%;">
          </div>
          <div class="flex-1">
            <h6 class="title mb-0 text-white service-title">Excel Management</h6>

          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow"style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/integration.svg" alt="" style="width: 90%;">
          </div>
          <div class="flex-1">
            <h6 class="title mb-0 text-white service-title">Effortless Integration With Third-Party APIs and Tools</h6>

          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow"style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/maintenance.svg" alt="" style="width: 100%;">
          </div>
          <div class="flex-1">
            <h6 class="title mb-0 text-white service-title">Maintenance</h6>

          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow"style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/cloud.svg" alt="" style="width: 100%;">
          </div>
          <div class="flex-1">
            <h6 class="title mb-0 text-white service-title">Cloud/Server Management</h6>

          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow"style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/crm.svg" alt="" style="width: 100%;">
          </div>
          <div class="flex-1">
            <h6 class="title mb-0 text-white service-title">CRM Software</h6>

          </div>
        </div>
      </div>
      <!--end col-->

      <!--end col-->
    </div>
  </div>
</section>
<section class="section p-2 bg-dark">
  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <h3 class="title mb-4 text-white" style="font-size: 32px;">Technologies</h3>
      </div>
      <!--end col-->
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow" style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="abx" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/microsoft-azure.svg" alt="" style="width: 100%;">
          </div>
          <div class="flex-1">
            <h4 class="title mb-0 text-white">Microsoft Azure</h4>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow" style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/icons8-microsoft-sql-server.svg" alt="" style="width: 100%;">
          </div>
          <div class="flex-1">
            <h4 class="title mb-0 text-white">Sql Server</h4>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow" style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/icons8-mysql-logo.svg" alt="" style="width: 100%;">
          </div>
          <div class="flex-1">
            <h4 class="title mb-0 text-white">My Sql</h4>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow" style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/mongodb.svg" alt="" style="width: 100%;">
          </div>
          <div class="flex-1">
            <h4 class="title mb-0 text-white">MongoDb</h4>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow" style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/icons8-javascript.svg" alt="" style="width: 100%;">
          </div>
          <div class="flex-1">
            <h4 class="title mb-0 text-white">JavaScript</h4>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow"style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/jquery.svg" alt="" style="width: 100%;">
          </div>
          <div class="flex-1">
            <h4 class="title mb-0 text-white">Jquery</h4>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow"style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/Microsoft_.NET_logo.svg.png" alt="" style="width: 100%;">
          </div>
          <div class="flex-1">
            <h4 class="title mb-0 text-white">.Net</h4>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow"style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/icons8-react-native-48.png" alt="" style="width: 90%;">
          </div>
          <div class="flex-1">
            <h4 class="title mb-0 text-white">React</h4>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow"style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/icons8-html-5.svg" alt="" style="width: 100%;">
          </div>
          <div class="flex-1">
            <h4 class="title mb-0 text-white">HTML5</h4>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow"style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/icons8-css3.svg" alt="" style="width: 100%;">
          </div>
          <div class="flex-1">
            <h4 class="title mb-0 text-white">CSS3</h4>

          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow"style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/icons8-wordpress.svg" alt="" style="width: 100%;">
          </div>
          <div class="flex-1">
            <h4 class="title mb-0 text-white">WordPress</h4>

          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow"style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/icons8-angularjs.svg" alt="" style="width: 100%;">
          </div>
          <div class="flex-1">
            <h4 class="title mb-0 text-white">Angular</h4>

          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow"style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/bootstrap.svg" alt="" style="width: 90%;">
          </div>
          <div class="flex-1">
            <h4 class="title mb-0 text-white">Bootstrap</h4>

          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow"style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/adobe-photoshop.svg" alt="" style="width: 100%;">
          </div>
          <div class="flex-1">
            <h4 class="title mb-0 text-white">Photoshop</h4>

          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow"style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/adobe-xd.svg" alt="" style="width: 100%;">
          </div>
          <div class="flex-1">
            <h4 class="title mb-0 text-white">Adobe XD</h4>

          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mt-4 pt-4">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow"style="background: #2B3949 !important;
        border: 1.8px solid white;">
          <div class="icon text-center rounded-circle me-3" style="width: 10%;">
            <!-- <i-feather name="" class="fea icon-ex-md"></i-feather> -->
            <img src="../../../../assets/images/csharp.svg" alt="" style="width: 100%;">
          </div>
          <div class="flex-1">
            <h4 class="title mb-0 text-white">CSharp</h4>

          </div>
        </div>
      </div>
      <!--end col-->

      <!--end col-->
    </div>
  </div>
</section>
<section class="section bg-dark">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4 text-white">What we do ?</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Tenzed</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0"style="background: #2B3949 !important;
          border: 1.8px solid white !important;">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-chart-line"></i>
          </span>
          <div class="card-body p-0 content">
            <h5 class="text-white">Hign Performance</h5>
            <!-- <p class="para text-muted mb-0">It is a long established fact that a reader.</p> -->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0" style="background: #2B3949 !important;
          border: 1.8px solid white !important;">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-crosshairs"></i>
          </span>
          <div class="card-body p-0 content">
            <h5 class="text-white">Best Securities</h5>
            <!-- <p class="para text-muted mb-0">It is a long established fact that a reader.</p> -->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0" style="background: #2B3949 !important;
          border: 1.8px solid white !important;">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-airplay"></i>
          </span>
          <div class="card-body p-0 content">
            <h5 class="text-white">Trusted Service</h5>
            <!-- <p class="para text-muted mb-0">It is a long established fact that a reader.</p> -->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0" style="background: #2B3949 !important;
          border: 1.8px solid white !important;">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-rocket"></i>
          </span>
          <div class="card-body p-0 content">
            <h5 class="text-white">Info Technology</h5>
            <!-- <p class="para text-muted mb-0">It is a long established fact that a reader.</p> -->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0" style="background: #2B3949 !important;
          border: 1.8px solid white !important;">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-clock"></i>
          </span>
          <div class="card-body p-0 content">
            <h5 class="text-white">24/7 Support</h5>
            <!-- <p class="para text-muted mb-0">It is a long established fact that a reader.</p> -->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0" style="background: #2B3949 !important;
          border: 1.8px solid white !important;">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-users-alt"></i>
          </span>
          <div class="card-body p-0 content">
            <h5 class="text-white">IT Management</h5>
            <!-- <p class="para text-muted mb-0">It is a long established fact that a reader.</p> -->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0" style="background: #2B3949 !important;
          border: 1.8px solid white !important;">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-file-alt"></i>
          </span>
          <div class="card-body p-0 content">
            <h5 class="text-white">Certified Company</h5>
            <!-- <p class="para text-muted mb-0">It is a long established fact that a reader.</p> -->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0" style="background: #2B3949 !important;
          border: 1.8px solid white !important;">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-search"></i>
          </span>
          <div class="card-body p-0 content">
            <h5 class="text-white">Data Analytics</h5>
            <!-- <p class="para text-muted mb-0">It is a long established fact that a reader.</p> -->
          </div>
        </div>
      </div>
      <!--end col-->

      <!-- <div class="col-lg-12 text-center col-md-4 mt-4 pt-2">
        <a href="javascript:void(0)" class="btn btn-primary">See more
          <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
        </a>
      </div> -->
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <!-- About Start -->
  <!--end container-->
</section>
<!-- Testi Start -->
<!-- <section class="section pb-0 bg-dark">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h6>We believe in building each other and hence</h6>
          <h4 class="title mb-4">Work with some amazing partners</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Tenzed</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>

    </div>
 

    <div class="row">
      <div class="col-12 mt-4 pt-2">
        <owl-carousel-o id="customer-testi" [options]="customOptions">
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body">
                <img src="assets/images/client/amazon.svg" class="img-fluid avatar avatar-ex-sm mx-auto" alt="">
                <p class="text-muted mt-4">" It seems that only fragments of the original text remain in the Lorem
                  Ipsum
                  texts used today. "</p>
                <h6 class="text-primary">- Thomas Israel</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body">
                <img src="assets/images/client/google.svg" class="img-fluid avatar avatar-ex-sm mx-auto" alt="">
                <p class="text-muted mt-4">" The most well-known dummy text is the 'Lorem Ipsum', which is said to have
                  originated in the 16th century. "</p>
                <h6 class="text-primary">- Carl Oliver</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body">
                <img src="assets/images/client/lenovo.svg" class="img-fluid avatar avatar-ex-sm mx-auto" alt="">
                <p class="text-muted mt-4">" One disadvantage of Lorum Ipsum is that in Latin certain letters appear
                  more
                  frequently than others. "</p>
                <h6 class="text-primary">- Barbara McIntosh</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body">
                <img src="assets/images/client/paypal.svg" class="img-fluid avatar avatar-ex-sm mx-auto" alt="">
                <p class="text-muted mt-4">" Thus, Lorem Ipsum has only limited suitability as a visual filler for
                  German
                  texts. "</p>
                <h6 class="text-primary">- Jill Webb</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body">
                <img src="assets/images/client/shopify.svg" class="img-fluid avatar avatar-ex-sm mx-auto" alt="">
                <p class="text-muted mt-4">" There is now an abundance of readable dummy texts. These are usually used
                  when a text is required. "</p>
                <h6 class="text-primary">- Dean Tolle</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body">
                <img src="assets/images/client/spotify.svg" class="img-fluid avatar avatar-ex-sm mx-auto" alt="">
                <p class="text-muted mt-4">" According to most sources, Lorum Ipsum can be traced back to a text
                  composed
                  by Cicero. "</p>
                <h6 class="text-primary">- Christa Smith</h6>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
  
    </div>
 
  </div>

</section> -->
<!--end section-->
<!-- Testi End -->

<!-- Pricing Start -->
<!-- <section class="section">
  <div class="container">
    <div class="row mt-lg-4 align-items-center"> -->
<!-- <div class="col-lg-5 col-md-12 text-center text-lg-start">
        <div class="section-title mb-4 mb-lg-0 pb-2 pb-lg-0">
          <h4 class="title mb-4">Our Comfortable Rates</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Tenzed</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
          <a href="https://1.envato.market/4n73n" target="_blank" class="btn btn-primary mt-4">Buy Now <span
              class="badge rounded-pill bg-danger ms-2">v4.2</span></a>
        </div>
      </div> -->
<!--end col-->

<!-- <div class="col-lg-7 col-md-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
        <div class="ms-lg-5">
          <div class="row align-items-center">
            <div class="col-md-6 col-12 px-md-0">
              <div class="card pricing pricing-primary starter-plan shadow rounded border-0">
                <div class="card-body py-5">
                  <h6 class="title fw-bold text-uppercase text-primary mb-4">Starter</h6>
                  <div class="d-flex mb-4">
                    <span class="h4 mb-0 mt-2">$</span>
                    <span class="price h1 mb-0">39</span>
                    <span class="h4 align-self-end mb-1">/mo</span>
                  </div>

                  <ul class="list-unstyled mb-0 ps-0">
                    <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                          class="uil uil-check-circle align-middle"></i></span>Full Access</li>
                    <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                          class="uil uil-check-circle align-middle"></i></span>Source Files</li>
                    <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                          class="uil uil-check-circle align-middle"></i></span>Free Appointments</li>
                    <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                          class="uil uil-check-circle align-middle"></i></span>Enhanced Security</li>
                  </ul>
                  <a href="javascript:void(0)" class="btn btn-primary mt-4">Get Started</a>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12 mt-4 pt-2 pt-sm-0 mt-sm-0 px-md-0">
              <div class="card pricing pricing-primary bg-light shadow rounded border-0">
                <div class="card-body py-5">
                  <h6 class="title fw-bold text-uppercase text-primary mb-4">Professional</h6>
                  <div class="d-flex mb-4">
                    <span class="h4 mb-0 mt-2">$</span>
                    <span class="price h1 mb-0">59</span>
                    <span class="h4 align-self-end mb-1">/mo</span>
                  </div>

                  <ul class="list-unstyled mb-0 ps-0">
                    <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                          class="uil uil-check-circle align-middle"></i></span>Full Access</li>
                    <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                          class="uil uil-check-circle align-middle"></i></span>Source Files</li>
                    <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                          class="uil uil-check-circle align-middle"></i></span>Free Appointments</li>
                    <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                          class="uil uil-check-circle align-middle"></i></span>Enhanced Security</li>
                  </ul>
                  <a href="javascript:void(0)" class="btn btn-primary mt-4">Try it now</a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div> -->

<!-- </div> -->

<!-- </div> -->
<!--end container-->
<!-- </section> -->
<!--end section-->
<!-- <div class="position-relative bg-dark">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div> -->
<!-- Pricing End -->

<!-- FAQ n Contact Start -->
<section class="bg-dark">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h4 class="title mb-4 text-white">Why Choose <span style="color: #FFC01D;">Tenzed</span> For Your Business?
          </h4>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-6 col-md-6 mt-4 pt-3 pb-3">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow" style="background: #2B3949 !important;
        border: 1.8px solid white !important; height: 100%;">
          <div class="card-body p-0">
            <h2 class=" mb-3 mt-3" style="font-size: 26px; color: #FFC01D;">Client Satisfaction is the key</h2>
            <p class="text-muted" style="color:#c9cfd7 !important;">To build a system that can provide the most sophisticated and seamless user
              experience, we first try to completely know the user's expectations.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 mt-4 pt-3 pb-3">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow" style="background: #2B3949 !important;
        border: 1.8px solid white !important;height: 100%;">
          <div class="card-body p-0">
            <h2 class=" mb-3 mt-3" style="font-size: 26px; color: #FFC01D;">Keep learning, keep improving</h2>
            <p class="text-muted" style="color:#c9cfd7 !important; ">Even though we occasionally believe everything is wonderful, we are aware that there
              is always room for improvement, and we work hard to do so at every opportunity.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 mt-4 pt-3 pb-3">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow" style="background: #2B3949 !important;
        border: 1.8px solid white !important;height: 100%;">
          <div class="card-body p-0">
            <h2 class=" mb-3 mt-3" style="font-size: 26px; color: #FFC01D;">Walk the extra mile</h2>
            <p class="text-muted" style="color:#c9cfd7!important;">When generating sound solutions at Tenzed, we consider our experience, skills, your
              needs, and real data, providing you a distinct advantage over the competition.
            <p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 mt-4 pt-3 pb-3">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow" style="background: #2B3949 !important;
        border: 1.8px solid white !important;height: 100%;">
          <div class="card-body p-0">
            <h2 class=" mb-3 mt-3" style="font-size: 26px; color: #FFC01D;">Importance of transparency</h2>
            <p class="text-muted" style="color:#c9cfd7!important;">Being somewhat traditional in this regard, we prefer to be open and honest with our
              clients. Trust that what you see is what you get when working with Tenzed.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 mt-4 pt-3 pb-3">
        <div class="d-flex features feature-primary key-feature align-items-center p-3 bg-body rounded shadow" style="background: #2B3949 !important;
        border: 1.8px solid white !important;height: 100%;">
          <div class="card-body p-0">
            <h2 class=" mb-3 mt-3" style="font-size: 26px; color: #FFC01D;">Embracing evolving technology</h2>
            <p class="text-muted" style="color:#c9cfd7!important;">Unlike most IT services providers, we don't hesitate to take risks and actually go
              right into testing out emerging technologies.</p>
          </div>
        </div>
      </div>

    </div>
    <!--end row-->
  </div>
</section>
<section class="section bg-dark">
  <div class="container">
    <!-- <div class="row"> -->
    <!-- <div class="col-md-6 col-12">
        <div class="d-flex">
          <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0">How our <span class="text-primary">Tenzed</span> work ?</h5>
            <p class="answer text-muted mb-0">Due to its widespread use as filler text for layouts, non-readability is
              of great importance: human perception is tuned to recognize certain patterns and repetitions in texts.</p>
          </div>
        </div>
      </div> -->
    <!--end col-->

    <!-- <div class="col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="d-flex">
          <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0"> What is the main process open account ?</h5>
            <p class="answer text-muted mb-0">If the distribution of letters and 'words' is random, the reader will not
              be distracted from making a neutral judgement on the visual impact</p>
          </div>
        </div>
      </div> -->
    <!--end col-->

    <!-- <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex">
          <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0"> How to make unlimited data entry ?</h5>
            <p class="answer text-muted mb-0">Furthermore, it is advantageous when the dummy text is relatively
              realistic so that the layout impression of the final publication is not compromised.</p>
          </div>
        </div>
      </div> -->
    <!--end col-->

    <!-- <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex">
          <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0"> Is <span class="text-primary">Tenzed</span> safer to use with my account ?</h5>
            <p class="answer text-muted mb-0">The most well-known dummy text is the 'Lorem Ipsum', which is said to have
              originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin language which more or less
              corresponds to 'proper' Latin.</p>
          </div>
        </div>
      </div> -->
    <!--end col-->
    <!-- </div> -->
    <!--end row-->

    <div class="row my-md-5 pt-md-3 my-4 pt-2 pb-lg-4 justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4 text-white">Have Question ? Get in touch!</h4>
          <p class="text-muted para-desc mx-auto">Start working with <span class="text-primary fw-bold">Tenzed</span>
            that can provide everything you need to generate awareness, drive traffic, connect.</p>
          <a routerLink="page-contact-two" class="btn btn-primary mt-4" 
          
          ><i class="mdi mdi-phone"></i> Contact us</a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- <div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div> -->
<!-- FAQ n Contact End -->


<!-- Back to top -->
<a href="javascript:void(0)" [ngxScrollTo]="'#home'" id="back-to-top" class="btn btn-icon btn-primary back-to-top" style="background:  #FFC01D !important; border-color: #FFC01D !important;">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->