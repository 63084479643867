 <!-- Hero Start -->
 <section class="bg-half-170 bg-light d-table w-100" style="background: url('assets/images/shape02.png') top left;" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0"> About us </h4>
                </div>
            </div>  <!--end col-->
        </div><!--end row-->
        
        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a routerLink="/index-crypto">Crypto</a></li>
                    <li class="breadcrumb-item active" aria-current="page">About Us</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<!-- Start About -->
<section class="section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="position-relative me-lg-5">
                    <img src="assets/images/crypto/revenue.svg" class="img-fluid" alt="">
                </div>
            </div><!--end col-->

            <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="section-title">
                    <span class="badge bg-soft-primary rounded">About</span>
                    <h4 class="title mb-3 mt-2">Our Story</h4>

                    <p class="text-muted">We are building the cryptoeconomy - a more fair, accessible, efficient, and transparent financial system enabled by crypto.</p>

                    <p class="text-muted mb-0">We started in 2021 with the radical idea that anyone, anywhere, should be able to easily and securely send and receive Bitcoin. Today, we offer a trusted and easy-to-use platform for accessing the broader cryptoeconomy.</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-7 order-2 order-md-1">
                <div class="section-title me-lg-5">
                    <h4 class="title mb-3">Build the future of technology</h4>
                    <p class="text-muted">What's the secret to Landrick's success? The answer is obvious: Our people. We are proud to have one of the most talented, hardworking and passionate teams the world has to offer. Interested in joining our team?</p>
                
                    <ul class="list-unstyled text-muted mb-0">
                        <li class="mb-1"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span><span class="fw-medium">Landrick Wallet.</span>  Your Keys, Your Crypto.</li>
                        <li class="mb-1"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span><span class="fw-medium">Landrick Earn.</span>  No lock-up period and stable returns.</li>
                        <li class="mb-1"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span><span class="fw-medium">Landrick Swap.</span>  Swap Landrick coins and earn Triple Yield.</li>
                    </ul>

                    <div class="mt-4">
                        <a href="javascript:void(0)" class="btn btn-soft-primary"><i class="uil uil-wallet"></i> Go to wallet</a>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-5 order-1 order-md-2">
                <img src="assets/images/crypto/bills.svg" class="img-fluid" alt="">
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-7 order-1 order-md-2">
                <div class="section-title ms-lg-5">
                    <h4 class="title mb-3">Join us</h4>
                    <p class="text-muted mb-0">We’re currently hiring. If our company mission means something to you, you’re invited to join the Bitcoin.com team.</p>

                    <div class="mt-4">
                        <a href="javascript:void(0)" class="btn btn-soft-primary"><i class="uil uil-arrow-growth"></i> See Positions</a>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-5 order-2 order-md-1">
                <img src="assets/images/crypto/profit.svg" class="img-fluid" alt="">
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
                <div class="d-flex align-items-center features feature-primary feature-clean shadow rounded p-4">
                    <div class="icons text-center">
                        <i class="uil uil-envelope-check d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="flex-1 content ms-4">
                        <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Get in Touch !</a></h5>
                        <p class="text-muted mb-0">This is required when, for text is not yet available.</p>
                        <div class="mt-2">
                            <a href="javascript:void(0)" class="btn btn-sm btn-soft-primary">Submit a Request</a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->
            
            <div class="col-lg-6 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="d-flex align-items-center features feature-primary feature-clean shadow rounded p-4">
                    <div class="icons text-center">
                        <i class="uil uil-webcam d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="flex-1 content ms-4">
                        <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Start a Meeting</a></h5>
                        <p class="text-muted mb-0">This is required when, for text is not yet available.</p>
                        <div class="mt-2">
                            <a href="javascript:void(0)" class="btn btn-sm btn-soft-primary">Start Video Chat</a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->
<!-- End About -->
<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->


