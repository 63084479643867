<!-- Start -->
<section class="bg-half-170 d-table bg-primary w-100"
    style="background-image: url('assets/images/hosting/dedicated.png');" id="home">
    <div class="bg-overlay opacity-4"></div>
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-12">
                <div class="pages-heading title-heading text-center">
                    <h4 class="title mb-3 text-white title-dark">Dedicated Servers</h4>
                    <p class="text-white-50 para-desc mx-auto mb-0">Launch your campaign and benefit from our expertise
                        on designing and managing conversion centered bootstrap v5 html page.</p>

                    <div class="mt-4">
                        <a href="javascript:void(0)" class="btn btn-primary">Request Call</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- Hero End -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-6">
                <div
                    class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                    <div class="icons text-center">
                        <i class="uil uil-server-alt d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">
                        <a href="javascript:void(0)" class="title h5 text-dark">SSD storage</a>
                        <p class="text-muted mt-2">Composed in a pseudo-Latin language which more or less corresponds.
                        </p>
                        <a href="javascript:void(0)" class="read-more">Read More <i
                                class="uil uil-angle-right-b"></i></a>
                        <i class="uil uil-server-alt full-img"></i>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div
                    class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                    <div class="icons text-center">
                        <i class="uil uil-data-sharing d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">
                        <a href="javascript:void(0)" class="title h5 text-dark">Easy upgrade</a>
                        <p class="text-muted mt-2">Composed in a pseudo-Latin language which more or less corresponds.
                        </p>
                        <a href="javascript:void(0)" class="read-more">Read More <i
                                class="uil uil-angle-right-b"></i></a>
                        <i class="uil uil-data-sharing full-img"></i>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div
                    class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                    <div class="icons text-center">
                        <i class="uil uil-cloud-database-tree d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">
                        <a href="javascript:void(0)" class="title h5 text-dark">Multiple Linux Distros</a>
                        <p class="text-muted mt-2">Composed in a pseudo-Latin language which more or less corresponds.
                        </p>
                        <a href="javascript:void(0)" class="read-more">Read More <i
                                class="uil uil-angle-right-b"></i></a>
                        <i class="uil uil-cloud-database-tree full-img"></i>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div
                    class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                    <div class="icons text-center">
                        <i class="uil uil-user-check d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">
                        <a href="javascript:void(0)" class="title h5 text-dark">Admin Support</a>
                        <p class="text-muted mt-2">Composed in a pseudo-Latin language which more or less corresponds.
                        </p>
                        <a href="javascript:void(0)" class="read-more">Read More <i
                                class="uil uil-angle-right-b"></i></a>
                        <i class="uil uil-user-check full-img"></i>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div
                    class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                    <div class="icons text-center">
                        <i class="uil uil-create-dashboard d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">
                        <a href="javascript:void(0)" class="title h5 text-dark">Guaranteed Resources</a>
                        <p class="text-muted mt-2">Composed in a pseudo-Latin language which more or less corresponds.
                        </p>
                        <a href="javascript:void(0)" class="read-more">Read More <i
                                class="uil uil-angle-right-b"></i></a>
                        <i class="uil uil-create-dashboard full-img"></i>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div
                    class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                    <div class="icons text-center">
                        <i class="uil uil-desktop d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">
                        <a href="javascript:void(0)" class="title h5 text-dark">Full Root Access</a>
                        <p class="text-muted mt-2">Composed in a pseudo-Latin language which more or less corresponds.
                        </p>
                        <a href="javascript:void(0)" class="read-more">Read More <i
                                class="uil uil-angle-right-b"></i></a>
                        <i class="uil uil-desktop full-img"></i>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div
                    class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                    <div class="icons text-center">
                        <i class="uil uil-shield-check d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">
                        <a href="javascript:void(0)" class="title h5 text-dark">Quick Provisioning</a>
                        <p class="text-muted mt-2">Composed in a pseudo-Latin language which more or less corresponds.
                        </p>
                        <a href="javascript:void(0)" class="read-more">Read More <i
                                class="uil uil-angle-right-b"></i></a>
                        <i class="uil uil-shield-check full-img"></i>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div
                    class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                    <div class="icons text-center">
                        <i class="uil uil-monitor-heart-rate d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">
                        <a href="javascript:void(0)" class="title h5 text-dark">Intuitive Dashboard</a>
                        <p class="text-muted mt-2">Composed in a pseudo-Latin language which more or less corresponds.
                        </p>
                        <a href="javascript:void(0)" class="read-more">Read More <i
                                class="uil uil-angle-right-b"></i></a>
                        <i class="uil uil-monitor-heart-rate full-img"></i>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->

    <div class="container-fluid mt-100 mt-60">
        <div class="rounded-md bg-primary py-5 px-4">
            <div class="row py-md-5 py-4">
                <div class="container">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="section-title">
                                <h6 class="text-white title-dark mb-3">Powered by SSD</h6>
                                <h2 class="text-white title-dark mb-0">100% Faster Solid State Drive Server</h2>
                            </div>
                        </div><!--end col-->

                        <div class="col-lg-6 col-md-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <ul class="list-unstyled mb-0 ms-lg-5">
                                <li class="text-white-50 my-2"><i-feather name="arrow-right-circle"
                                        class="fea icon-ex-md me-1"></i-feather> Ultrafast Data Read/Write Speeds</li>
                                <li class="text-white-50 my-2"><i-feather name="arrow-right-circle"
                                        class="fea icon-ex-md me-1"></i-feather> Enterprise Grade Hardware</li>
                                <li class="text-white-50 my-2"><i-feather name="arrow-right-circle"
                                        class="fea icon-ex-md me-1"></i-feather> Highest Data Protection Reliability</li>
                            </ul>
                        </div><!--end col-->
                    </div><!--end row-->
                </div><!--end container-->
            </div><!--end row-->
        </div><!--end div-->
    </div><!--end container fluid-->

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h4 class="title mb-3">Pricing Plans</h4>
                    <p class="text-muted para-desc mx-auto mb-0">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="row mt-4 pt-2">
            <div class="col-12">
                <div class="table-responsive bg-white shadow rounded">
                    <table class="table mb-0 table-center">
                        <thead>
                            <tr>
                                <th class="border-bottom">&nbsp;</th>
                                <th class="text-center p-4 border-bottom" style="min-width: 200px; max-width: 200px;">
                                    <div class="h4">Starter</div>
                                    <p class="text-muted fw-normal mt-3 mb-0">We offers a <strong>free month</strong> of
                                        service for new customers.</p>
                                    <div class="d-flex justify-content-center mt-3">
                                        <span class="h6 text-muted mb-0 mt-2">$</span>
                                        <span class="price text-primary h1 mb-0">4.99</span>
                                        <span class="h6 text-muted align-self-end mb-1">/mo</span>
                                    </div>
                                </th>
                                <th class="text-center p-4 border-bottom" style="min-width: 200px; max-width: 200px;">
                                    <div class="h4">Business</div>
                                    <p class="text-muted fw-normal mt-3 mb-0">We offers a <strong>free 14 days</strong>
                                        of service for new customers.</p>
                                    <div class="d-flex justify-content-center mt-3">
                                        <span class="h6 text-muted mb-0 mt-2">$</span>
                                        <span class="price text-primary h1 mb-0">9.99</span>
                                        <span class="h6 text-muted align-self-end mb-1">/mo</span>
                                    </div>
                                </th>
                                <th class="text-center p-4 border-bottom" style="min-width: 200px; max-width: 200px;">
                                    <div class="h4">Enterprise</div>
                                    <p class="text-muted fw-normal mt-3 mb-0">We offers a <strong>free 7 days</strong>
                                        of service for new customers.</p>
                                    <div class="d-flex justify-content-center mt-3">
                                        <span class="h6 text-muted mb-0 mt-2">$</span>
                                        <span class="price text-primary h1 mb-0">14.99</span>
                                        <span class="h6 text-muted align-self-end mb-1">/mo</span>
                                    </div>
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td class="h6 p-3">Space</td>
                                <td class="text-center p-3">1 GB</td>
                                <td class="text-center p-3">5 GB</td>
                                <td class="text-center p-3">20 GB</td>
                            </tr>
                            <tr>
                                <td class="h6 p-3">Bandwidth</td>
                                <td class="text-center p-3">10 GB</td>
                                <td class="text-center p-3">100 GB</td>
                                <td class="text-center p-3">500 GB</td>
                            </tr>
                            <tr>
                                <td class="h6 p-3">Email Accounts</td>
                                <td class="text-center p-3">100</td>
                                <td class="text-center p-3">1000</td>
                                <td class="text-center p-3">5000</td>
                            </tr>
                            <tr>
                                <td class="h6 p-3">MySQL Accounts</td>
                                <td class="text-center p-3">100</td>
                                <td class="text-center p-3">1000</td>
                                <td class="text-center p-3">5000</td>
                            </tr>
                            <tr>
                                <td class="h6 p-3">Processor</td>
                                <td class="text-center p-3">3Core</td>
                                <td class="text-center p-3">5Core</td>
                                <td class="text-center p-3">7Core</td>
                            </tr>
                            <tr>
                                <td class="h6 p-3">CPUs</td>
                                <td class="text-center p-3">3.2GHz CPU</td>
                                <td class="text-center p-3">2x3.2GHz CPU</td>
                                <td class="text-center p-3">4x3.2GHz CPU</td>
                            </tr>
                            <tr>
                                <td class="h6 p-3">Cpanel</td>
                                <td class="text-center p-3">WHM</td>
                                <td class="text-center p-3">WHM</td>
                                <td class="text-center p-3">WHM</td>
                            </tr>
                            <tr>
                                <td class="h6 p-3">Support</td>
                                <td class="text-center p-3">Free Setup</td>
                                <td class="text-center p-3">24/7 Hour</td>
                                <td class="text-center p-3">24/7 Hour</td>
                            </tr>
                            <tr>
                                <td class="h6 p-3">SSH Access</td>
                                <td class="text-center p-3"><i-feather name="x" class="fea icon-sm text-danger"></i-feather>
                                </td>
                                <td class="text-center p-3"><i-feather name="x" class="fea icon-sm text-danger"></i-feather>
                                </td>
                                <td class="text-center p-3"><i-feather name="check"
                                        class="fea icon-sm text-success"></i-feather></td>
                            </tr>
                            <tr>
                                <td class="h6 p-3">SMTP Management</td>
                                <td class="text-center p-3"><i-feather name="x" class="fea icon-sm text-danger"></i-feather>
                                </td>
                                <td class="text-center p-3"><i-feather name="x" class="fea icon-sm text-danger"></i-feather>
                                </td>
                                <td class="text-center p-3"><i-feather name="check"
                                        class="fea icon-sm text-success"></i-feather></td>
                            </tr>
                            <tr>
                                <td class="h6 p-3">FTP Access</td>
                                <td class="text-center p-3"><i-feather name="x" class="fea icon-sm text-danger"></i-feather>
                                </td>
                                <td class="text-center p-3"><i-feather name="check"
                                        class="fea icon-sm text-success"></i-feather></td>
                                <td class="text-center p-3"><i-feather name="check"
                                        class="fea icon-sm text-success"></i-feather></td>
                            </tr>
                            <tr>
                                <td class="h6 p-3">Google Adwords Credit</td>
                                <td class="text-center p-3"><i-feather name="x" class="fea icon-sm text-danger"></i-feather>
                                </td>
                                <td class="text-center p-3"><i-feather name="check"
                                        class="fea icon-sm text-success"></i-feather></td>
                                <td class="text-center p-3"><i-feather name="check"
                                        class="fea icon-sm text-success"></i-feather></td>
                            </tr>
                            <tr>
                                <td class="h6 p-3">&nbsp;</td>
                                <td class="text-center p-3"><a href="javascript:void(0)" class="btn btn-primary">Start Now</a></td>
                                <td class="text-center p-3"><a href="javascript:void(0)" class="btn btn-primary">Buy Now</a></td>
                                <td class="text-center p-3"><a href="javascript:void(0)" class="btn btn-primary">Buy Now</a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h4 class="title mb-3">Questions regarding Dedicated Server ?</h4>
                    <p class="text-muted para-desc mx-auto mb-0">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="row align-items-center">
            <div class="col-lg-6 col-12 mt-4 pt-2">
                <img src="assets/images/hosting/faqs.svg" class="img-fluid" alt="">
            </div><!--end col-->

            <div class="col-lg-6 col-12 mt-4 pt-2">
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item rounded shadow">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button border-0 bg-light" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                What is Dedicated Server?
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse border-0 collapse show"
                            aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body text-muted">
                                There are many variations of passages of Lorem Ipsum available, but the majority have
                                suffered alteration in some form.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded shadow mt-2">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button border-0 bg-light collapsed" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
                                aria-controls="collapseTwo">
                                Do I need a Dedicated Server?
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse border-0 collapse" aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body text-muted">
                                There are many variations of passages of Lorem Ipsum available, but the majority have
                                suffered alteration in some form.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded shadow mt-2">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button border-0 bg-light collapsed" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false"
                                aria-controls="collapseThree">
                                How can I get my website transferred for free?
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse border-0 collapse"
                            aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div class="accordion-body text-muted">
                                There are many variations of passages of Lorem Ipsum available, but the majority have
                                suffered alteration in some form.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded shadow mt-2">
                        <h2 class="accordion-header" id="headingFour">
                            <button class="accordion-button border-0 bg-light collapsed" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false"
                                aria-controls="collapseFour">
                                What level of access do I get with my Dedicated Server?
                            </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse border-0 collapse"
                            aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                            <div class="accordion-body text-muted">
                                There are many variations of passages of Lorem Ipsum available, but the majority have
                                suffered alteration in some form.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded shadow mt-2">
                        <h2 class="accordion-header" id="headingFive">
                            <button class="accordion-button border-0 bg-light collapsed" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false"
                                aria-controls="collapseFive">
                                Which control panels are provided?
                            </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse border-0 collapse"
                            aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                            <div class="accordion-body text-muted">
                                There are many variations of passages of Lorem Ipsum available, but the majority have
                                suffered alteration in some form.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded shadow mt-2">
                        <h2 class="accordion-header" id="headingSix">
                            <button class="accordion-button border-0 bg-light collapsed" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false"
                                aria-controls="collapseSix">
                                Do you offer any billing panels?
                            </button>
                        </h2>
                        <div id="collapseSix" class="accordion-collapse border-0 collapse" aria-labelledby="headingSix"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body text-muted">
                                There are many variations of passages of Lorem Ipsum available, but the majority have
                                suffered alteration in some form.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded shadow mt-2">
                        <h2 class="accordion-header" id="headingSeven">
                            <button class="accordion-button border-0 bg-light collapsed" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false"
                                aria-controls="collapseSeven">
                                How long will it take for my server to be ready for use?
                            </button>
                        </h2>
                        <div id="collapseSeven" class="accordion-collapse border-0 collapse"
                            aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                            <div class="accordion-body text-muted">
                                There are many variations of passages of Lorem Ipsum available, but the majority have
                                suffered alteration in some form.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded shadow mt-2">
                        <h2 class="accordion-header" id="headingEight">
                            <button class="accordion-button border-0 bg-light collapsed" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false"
                                aria-controls="collapseEight">
                                How do I access my Dedicated Server?
                            </button>
                        </h2>
                        <div id="collapseEight" class="accordion-collapse border-0 collapse"
                            aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                            <div class="accordion-body text-muted">
                                There are many variations of passages of Lorem Ipsum available, but the majority have
                                suffered alteration in some form.
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->