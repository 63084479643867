import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-master-page',
  templateUrl: './master-page.component.html',
  styleUrls: ['./master-page.component.css']
})

export class MasterPageComponent implements OnInit {

  addclass: string ="";
  buttonShow: boolean =false;
  TopbarShow: boolean=false;
  footerClass: string="";
  developerPage: boolean=false;
  hideFooter: boolean=false;
  shopPages: boolean=false;
  Settingicon: boolean=false;
  appicons: boolean=false;
  Nfticons: boolean=false;
  Menuoption: string ="";

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Router activation
   */
  onActivate(componentReference: any) {
    this.addclass = componentReference.navClass;
    this.buttonShow = componentReference.buttonList;
    this.TopbarShow = componentReference.sliderTopbar;
    this.footerClass = componentReference.footerVariant;
    this.developerPage = componentReference.isdeveloper;
    this.hideFooter = componentReference.hideFooter;
    this.shopPages = componentReference.shopPages;
    this.Settingicon = componentReference.Settingicon;
    this.appicons = componentReference.appicons;
    this.Nfticons = componentReference.Nfticons;
    this.Menuoption = componentReference.Menuoption;
  }
}
