import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { JobApply, User } from '../models/user.model';
import { apiConfig } from 'src/environments/api-config';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    saveContact(user: User) {
        const url = apiConfig.save_contact;
        return this.http.post(url, user);
    }
    jobApply(jobApply: JobApply) {
        const url = apiConfig.job_apply;
        return this.http.post(url, jobApply);
    }
}