  <!-- Hero Start -->
  <section class="bg-half d-table w-100" id="home">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="card border-0 shadow rounded p-4 sticky-bar">
                    <img src="assets/images/job/Gradle.svg" class="avatar avatar-medium p-4 bg-white shadow-md rounded-pill" alt="">
                    
                    <div class="mt-4">
                        <h4 class="title mb-3"> Back-End Developer </h4>
                        <p class="para-desc text-muted">Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap v5 html page.</p>
                        <ul class="list-unstyled mb-0">
                            <li class="list-inline-item mb-0 text-primary me-3"><i class="mdi mdi-map-marker text-warning me-2"></i>Beijing, China</li>
                            <li class="list-inline-item mb-0 text-primary"><i class="mdi mdi-office-building text-warning me-2"></i>Gradle</li>
                        </ul>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-8">
                <div class="sidebar border-0">
                    <h5 class="mb-0">Job Information</h5>

                    <ul class="list-unstyled mb-0 mt-4">                                
                        <li class="list-inline-item px-3 py-2 shadow rounded text-start">
                            <div class="d-flex widget align-items-center">
                                <i-feather name="user-check" class="fea icon-ex-md me-3"></i-feather>
                                <div class="flex-1">
                                    <h6 class="widget-title mb-0">Employee Type:</h6>
                                    <small class="text-primary mb-0">Full Time</small>
                                </div>
                            </div>
                        </li>

                        <li class="list-inline-item px-3 py-2 shadow rounded text-start">
                            <div class="d-flex widget align-items-center">
                                <i-feather name="map-pin" class="fea icon-ex-md me-3"></i-feather>
                                <div class="flex-1">
                                    <h6 class="widget-title mb-0">Location:</h6>
                                    <small class="text-primary mb-0">Beijing, China</small>
                                </div>
                            </div>
                        </li>

                        <li class="list-inline-item px-3 py-2 shadow rounded text-start">
                            <div class="d-flex widget align-items-center">
                                <i-feather name="clock" class="fea icon-ex-md me-3"></i-feather>
                                <div class="flex-1">
                                    <h6 class="widget-title mb-0">Date posted:</h6>
                                    <small class="text-primary mb-0 mb-0">5th Sep, 2021</small>
                                </div>
                            </div>
                        </li>

                        <li class="list-inline-item px-3 py-2 shadow rounded text-start">
                            <div class="d-flex widget align-items-center">
                                <i-feather name="monitor" class="fea icon-ex-md me-3"></i-feather>
                                <div class="flex-1">
                                    <h6 class="widget-title mb-0">Job Type:</h6>
                                    <small class="text-primary mb-0">Back-end Developer</small>
                                </div>
                            </div>
                        </li>

                        <li class="list-inline-item px-3 py-2 shadow rounded text-start">
                            <div class="d-flex widget align-items-center">
                                <i-feather name="briefcase" class="fea icon-ex-md me-3"></i-feather>
                                <div class="flex-1">
                                    <h6 class="widget-title mb-0">Experience:</h6>
                                    <small class="text-primary mb-0">+2 Year</small>
                                </div>
                            </div>
                        </li>

                        <li class="list-inline-item px-3 py-2 shadow rounded text-start">
                            <div class="d-flex widget align-items-center">
                                <i-feather name="book" class="fea icon-ex-md me-3"></i-feather>
                                <div class="flex-1">
                                    <h6 class="widget-title mb-0">Qualifications:</h6>
                                    <small class="text-primary mb-0">MSCIT</small>
                                </div>
                            </div>
                        </li>

                        <li class="list-inline-item px-3 py-2 shadow rounded text-start">
                            <div class="d-flex widget align-items-center">
                                <i-feather name="dollar-sign" class="fea icon-ex-md me-3"></i-feather>
                                <div class="flex-1">
                                    <h6 class="widget-title mb-0">Salary:</h6>
                                    <small class="text-primary mb-0">+50k to 70k</small>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="mt-4">
                    <h5>Job Description: </h5>
                    <p class="text-muted">One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others - which creates a distinct visual impression. Moreover, in Latin only words at the beginning of sentences are capitalized.</p>
                    <p class="text-muted">This means that Lorem Ipsum cannot accurately represent, for example, German, in which all nouns are capitalized. Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts. If the fill text is intended to illustrate the characteristics of different typefaces.</p>
                    <p class="text-muted">It sometimes makes sense to select texts containing the various letters and symbols specific to the output language.</p>
                    
                    <h5 class="mt-4">Responsibilities and Duties: </h5>
                    <p class="text-muted">It sometimes makes sense to select texts containing the various letters and symbols specific to the output language.</p>
                    <ul class="list-unstyled">
                        <li class="text-muted"><i-feather name="arrow-right" class="fea icon-sm text-success me-2"></i-feather>Participate in requirements analysis</li>
                        <li class="text-muted"><i-feather name="arrow-right" class="fea icon-sm text-success me-2"></i-feather>Write clean, scalable code using C# and .NET frameworks</li>
                        <li class="text-muted"><i-feather name="arrow-right" class="fea icon-sm text-success me-2"></i-feather>Test and deploy applications and systems</li>
                        <li class="text-muted"><i-feather name="arrow-right" class="fea icon-sm text-success me-2"></i-feather>Revise, update, refactor and debug code</li>
                        <li class="text-muted"><i-feather name="arrow-right" class="fea icon-sm text-success me-2"></i-feather>Improve existing software</li>
                        <li class="text-muted"><i-feather name="arrow-right" class="fea icon-sm text-success me-2"></i-feather>Develop documentation throughout the software development life cycle (SDLC)</li>
                        <li class="text-muted"><i-feather name="arrow-right" class="fea icon-sm text-success me-2"></i-feather>Serve as an expert on applications and provide technical support</li>
                    </ul>

                    <h5 class="mt-4">Required Experience, Skills and Qualifications: </h5>
                    <p class="text-muted">It sometimes makes sense to select texts containing the various letters and symbols specific to the output language.</p>
                    <ul class="list-unstyled">
                        <li class="text-muted"><i-feather name="arrow-right" class="fea icon-sm text-success me-2"></i-feather>Proven experience as a .NET Developer or Application Developer</li>
                        <li class="text-muted"><i-feather name="arrow-right" class="fea icon-sm text-success me-2"></i-feather>good understanding of SQL and Relational Databases, specifically Microsoft SQL Server.</li>
                        <li class="text-muted"><i-feather name="arrow-right" class="fea icon-sm text-success me-2"></i-feather>Experience designing, developing and creating RESTful web services and APIs</li>
                        <li class="text-muted"><i-feather name="arrow-right" class="fea icon-sm text-success me-2"></i-feather>Basic know how of Agile process and practices</li>
                        <li class="text-muted"><i-feather name="arrow-right" class="fea icon-sm text-success me-2"></i-feather>Good understanding of object-oriented programming.</li>
                        <li class="text-muted"><i-feather name="arrow-right" class="fea icon-sm text-success me-2"></i-feather>Good understanding of concurrent programming.</li>
                        <li class="text-muted"><i-feather name="arrow-right" class="fea icon-sm text-success me-2"></i-feather>Sound knowledge of application architecture and design.</li>
                        <li class="text-muted"><i-feather name="arrow-right" class="fea icon-sm text-success me-2"></i-feather>Excellent problem solving and analytical skills</li>
                    </ul>

                    <div class="mt-4">
                        <a routerLink="/job-apply" class="btn btn-outline-primary">Apply Now <i class="mdi mdi-send"></i></a>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->
<!-- Job Detail End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
