import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-index-job',
  templateUrl: './index-job.component.html',
  styleUrls: ['./index-job.component.css']
})

/**
 * Job Component
 */
export class IndexJobComponent implements OnInit {

    // Set Topbar Option
    Menuoption = 'center';
    Settingicon = true;
  /***
   * Footer bg color set
   */


  /**
  * Member Data
  */
  memberData = [
    {
      profile: "assets/images/computer.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Himanshu T.",
      designation: "Full Stack"
    },
    {
      profile: "assets/images/computer.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Jaydip T.",
      designation: ".Net | SQL"
    },
    {
      profile: "assets/images/computer.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Ladlesh T.",
      designation: "Angular | UI/UX"
    },
    {
      profile: "assets/images/computer.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Harikrushna T.",
      designation: "Sr.Angular"
    }
  ];

  constructor() { }

  /**
   * Testimonial Slider
   */
  customOptions: OwlOptions = {
    // loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    // autoplay: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      900: {
        items: 3
      }
    },
    nav: false
  };

  ngOnInit(): void {
  }

}
