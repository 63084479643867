<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" style="background: url('assets/images/corporate/pages.jpg') top;" id="home">
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading title-heading">
                    <h2 class="text-white title-dark"> Services </h2>
                    <p class="text-white-50 para-desc mb-0 mx-auto">Start working with Landrick that can provide
                        everything you need to generate awareness, drive traffic, connect.</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a routerLink="/index-corporate">Business</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Services</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- Hero End -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-6">
                <div
                    class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                    <div class="icons text-center">
                        <i class="uil uil-airplay d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">
                        <a href="javascript:void(0)" class="title h5 text-dark">Consulting Services</a>
                        <p class="text-muted mt-2">Composed in a pseudo-Latin language which more or less corresponds.
                        </p>
                        <a href="javascript:void(0)" class="read-more">Read More <i
                                class="uil uil-angle-right-b"></i></a>
                        <i class="uil uil-airplay full-img"></i>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div
                    class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                    <div class="icons text-center">
                        <i class="uil uil-heart d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">
                        <a href="javascript:void(0)" class="title h5 text-dark">Business Setup</a>
                        <p class="text-muted mt-2">Composed in a pseudo-Latin language which more or less corresponds.
                        </p>
                        <a href="javascript:void(0)" class="read-more">Read More <i
                                class="uil uil-angle-right-b"></i></a>
                        <i class="uil uil-heart full-img"></i>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div
                    class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                    <div class="icons text-center">
                        <i class="uil uil-eye d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">
                        <a href="javascript:void(0)" class="title h5 text-dark">Investment</a>
                        <p class="text-muted mt-2">Composed in a pseudo-Latin language which more or less corresponds.
                        </p>
                        <a href="javascript:void(0)" class="read-more">Read More <i
                                class="uil uil-angle-right-b"></i></a>
                        <i class="uil uil-eye full-img"></i>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div
                    class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                    <div class="icons text-center">
                        <i class="uil uil-expand-arrows d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">
                        <a href="javascript:void(0)" class="title h5 text-dark">Management</a>
                        <p class="text-muted mt-2">Composed in a pseudo-Latin language which more or less corresponds.
                        </p>
                        <a href="javascript:void(0)" class="read-more">Read More <i
                                class="uil uil-angle-right-b"></i></a>
                        <i class="uil uil-expand-arrows full-img"></i>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div
                    class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                    <div class="icons text-center">
                        <i class="uil uil-layer-group d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">
                        <a href="javascript:void(0)" class="title h5 text-dark">Acquisitions Consulting</a>
                        <p class="text-muted mt-2">Composed in a pseudo-Latin language which more or less corresponds.
                        </p>
                        <a href="javascript:void(0)" class="read-more">Read More <i
                                class="uil uil-angle-right-b"></i></a>
                        <i class="uil uil-layer-group full-img"></i>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div
                    class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                    <div class="icons text-center">
                        <i class="uil uil-airplay d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">
                        <a href="javascript:void(0)" class="title h5 text-dark">Company Management</a>
                        <p class="text-muted mt-2">Composed in a pseudo-Latin language which more or less corresponds.
                        </p>
                        <a href="javascript:void(0)" class="read-more">Read More <i
                                class="uil uil-angle-right-b"></i></a>
                        <i class="uil uil-airplay full-img"></i>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div
                    class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                    <div class="icons text-center">
                        <i class="uil uil-focus-target d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">
                        <a href="javascript:void(0)" class="title h5 text-dark">Placement Consulting</a>
                        <p class="text-muted mt-2">Composed in a pseudo-Latin language which more or less corresponds.
                        </p>
                        <a href="javascript:void(0)" class="read-more">Read More <i
                                class="uil uil-angle-right-b"></i></a>
                        <i class="uil uil-focus-target full-img"></i>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div
                    class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                    <div class="icons text-center">
                        <i class="uil uil-analytics d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">
                        <a href="javascript:void(0)" class="title h5 text-dark">Corporate services</a>
                        <p class="text-muted mt-2">Composed in a pseudo-Latin language which more or less corresponds.
                        </p>
                        <a href="javascript:void(0)" class="read-more">Read More <i
                                class="uil uil-angle-right-b"></i></a>
                        <i class="uil uil-analytics full-img"></i>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h6 class="text-primary">Work Process</h6>
                    <h4 class="title mb-4">How do we works ?</h4>
                    <p class="text-muted para-desc mx-auto mb-0">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="row">
            <div class="col-md-4 mt-4 pt-2">
                <div
                    class="card features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
                    <div class="icons text-center mx-auto">
                        <i class="uil uil-presentation-edit d-block rounded h3 mb-0"></i>
                    </div>

                    <div class="card-body">
                        <h5 class="text-dark">Discussion</h5>
                        <p class="text-muted mb-0">The most well-known dummy text is the 'Lorem Ipsum', which is said to
                            have originated</p>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
                <div
                    class="card features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
                    <div class="icons text-center mx-auto">
                        <i class="uil uil-airplay d-block rounded h3 mb-0"></i>
                    </div>

                    <div class="card-body">
                        <h5 class="text-dark">Strategy & Testing</h5>
                        <p class="text-muted mb-0">Generators convallis odio, vel pharetra quam malesuada vel. Nam
                            porttitor malesuada.</p>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
                <div
                    class="card features feature-primary feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
                    <div class="icons text-center mx-auto">
                        <i class="uil uil-image-check d-block rounded h3 mb-0"></i>
                    </div>

                    <div class="card-body">
                        <h5 class="text-dark">Reporting</h5>
                        <p class="text-muted mb-0">Internet Proin tempus odio, vel pharetra quam malesuada vel. Nam
                            porttitor malesuada.</p>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->

    <div class="container-fluid mt-100 mt-60">
        <div class="bg-cta shadow rounded card overflow-hidden"
            style="background: url('assets/images/2.jpg') center center;" id="cta">
            <div class="bg-overlay"></div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <div class="section-title">
                            <h4 class="title title-dark text-white mb-4">We Are Creative Dreamers and Innovators</h4>
                            <p class="text-white-50 para-dark para-desc mx-auto">Start working with Landrick that can
                                provide everything you need to generate awareness, drive traffic, connect.</p>
                            <a href="javascript:void(0);" class="play-btn mt-4 video-play-icon">
                                <i-feather name="play" class="fea icon-ex-md text-white title-dark"></i-feather>
                            </a>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
        </div>
    </div><!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row align-items-end mb-4 pb-4">
            <div class="col-md-8">
                <div class="section-title text-center text-md-start">
                    <h6 class="text-primary">Services</h6>
                    <h4 class="title mb-4">What we do ?</h4>
                    <p class="text-muted mb-0 para-desc">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div><!--end col-->

            <div class="col-md-4 mt-4 mt-sm-0">
                <div class="text-center text-md-end">
                    <a href="javascript:void(0)" class="text-primary h6">See More <i
                            class="uil uil-angle-right-b align-middle"></i></a>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="row">
            <div class="col-md-4 mt-4 pt-2">
                <ul class="nav nav-pills nav-justified flex-column rounded shadow p-3 mb-0 sticky-bar" id="pills-tab"
                    role="tablist">
                    <li class="nav-item">
                        <a class="nav-link rounded active" id="webdeveloping" data-bs-toggle="pill" href="#developing"
                            role="tab" aria-controls="developing" aria-selected="false">
                            <div class="text-center py-1">
                                <h6 class="mb-0">Web Developing</h6>
                            </div>
                        </a><!--end nav link-->
                    </li><!--end nav item-->

                    <li class="nav-item mt-2">
                        <a class="nav-link rounded" id="database" data-bs-toggle="pill" href="#data-analise" role="tab"
                            aria-controls="data-analise" aria-selected="false">
                            <div class="text-center py-1">
                                <h6 class="mb-0">Database Analysis</h6>
                            </div>
                        </a><!--end nav link-->
                    </li><!--end nav item-->

                    <li class="nav-item mt-2">
                        <a class="nav-link rounded" id="server" data-bs-toggle="pill" href="#security" role="tab"
                            aria-controls="security" aria-selected="false">
                            <div class="text-center py-1">
                                <h6 class="mb-0">Server Security</h6>
                            </div>
                        </a><!--end nav link-->
                    </li><!--end nav item-->

                    <li class="nav-item mt-2">
                        <a class="nav-link rounded" id="webdesigning" data-bs-toggle="pill" href="#designing" role="tab"
                            aria-controls="designing" aria-selected="false">
                            <div class="text-center py-1">
                                <h6 class="mb-0">Web Designing</h6>
                            </div>
                        </a><!--end nav link-->
                    </li><!--end nav item-->
                </ul><!--end nav pills-->
            </div><!--end col-->

            <div class="col-md-8 col-12 mt-4 pt-2">
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active p-4 rounded shadow" id="developing" role="tabpanel"
                        aria-labelledby="webdeveloping">
                        <img src="assets/images/work/7.jpg" class="img-fluid rounded shadow" alt="">
                        <div class="mt-4">
                            <p class="text-muted">This is required when, for example, the final text is not yet
                                available. Dummy text is also known as 'fill text'. It is said that song composers of
                                the past used dummy texts as lyrics.</p>
                            <a href="javascript:void(0)" class="text-primary">See More <i
                                    class="uil uil-angle-right-b align-middle"></i></a>
                        </div>
                    </div><!--end teb pane-->

                    <div class="tab-pane fade p-4 rounded shadow" id="data-analise" role="tabpanel"
                        aria-labelledby="database">
                        <img src="assets/images/work/8.jpg" class="img-fluid rounded shadow" alt="">
                        <div class="mt-4">
                            <p class="text-muted">This is required when, for example, the final text is not yet
                                available. Dummy text is also known as 'fill text'. It is said that song composers of
                                the past used dummy texts as lyrics.</p>
                            <a href="javascript:void(0)" class="text-primary">See More <i
                                    class="uil uil-angle-right-b align-middle"></i></a>
                        </div>
                    </div><!--end teb pane-->

                    <div class="tab-pane fade p-4 rounded shadow" id="security" role="tabpanel"
                        aria-labelledby="server">
                        <img src="assets/images/work/9.jpg" class="img-fluid rounded shadow" alt="">
                        <div class="mt-4">
                            <p class="text-muted">This is required when, for example, the final text is not yet
                                available. Dummy text is also known as 'fill text'. It is said that song composers of
                                the past used dummy texts as lyrics.</p>
                            <a href="javascript:void(0)" class="text-primary">See More <i
                                    class="uil uil-angle-right-b align-middle"></i></a>
                        </div>
                    </div><!--end teb pane-->

                    <div class="tab-pane fade p-4 rounded shadow" id="designing" role="tabpanel"
                        aria-labelledby="webdesigning">
                        <img src="assets/images/work/12.jpg" class="img-fluid rounded shadow" alt="">
                        <div class="mt-4">
                            <p class="text-muted">This is required when, for example, the final text is not yet
                                available. Dummy text is also known as 'fill text'. It is said that song composers of
                                the past used dummy texts as lyrics.</p>
                            <a href="javascript:void(0)" class="text-primary">See More <i
                                    class="uil uil-angle-right-b align-middle"></i></a>
                        </div>
                    </div><!--end teb pane-->
                </div><!--end tab content-->
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>