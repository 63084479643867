import { Component } from '@angular/core';

@Component({
  selector: 'app-nft-creators',
  templateUrl: './nft-creators.component.html',
  styleUrls: ['./nft-creators.component.css']
})
export class NftCreatorsComponent {
 // Set Topbar Option
 Menuoption = 'nft';
 Nfticons = true
  footerVariant = 'footer-three';
}
