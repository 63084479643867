<!-- Hero Start -->
<section class="bg-half-260 bg-primary d-table w-100" style="background: url('assets/images/bg.png') center;" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="title-heading">
          <h1 class="heading text-white title-dark mb-4">Powerfull analytics <br> tools for your business</h1>
          <p class="para-desc mx-auto text-white-50">Launch your campaign and benefit from our expertise on designing
            and managing conversion centered bootstrap v5 html page.</p>
          <div class="mt-4 pt-2">
            <a href="javascript:void(0)" class="btn btn-light"><i class="uil uil-envelope align-middle"></i> Get
              Started</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<!-- FEATURES START -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="features-absolute">
          <div class="row">
            <div class="col-md-6">
              <div class="d-flex features feature-clean feature-primary p-4 shadow rounded">
                <div class="icons text-primary d-block text-center rounded">
                  <i class="uil uil-comment-verify d-block rounded h3 mb-0"></i>
                </div>
                <div class="flex-1 content ms-4">
                  <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Instant Chat</a></h5>
                  <p class="text-muted mb-0">The most well-known dummy text is the, which is said to have originated in
                    the 16th century.</p>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div class="d-flex features feature-clean feature-primary p-4 shadow rounded">
                <div class="icons text-primary d-block text-center rounded">
                  <i class="uil uil-image d-block rounded h3 mb-0"></i>
                </div>
                <div class="flex-1 content ms-4">
                  <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Media Messages</a></h5>
                  <p class="text-muted mb-0">The most well-known dummy text is the, which is said to have originated in
                    the 16th century.</p>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 pt-2">
              <div class="d-flex features feature-clean feature-primary p-4 shadow rounded">
                <div class="icons text-primary d-block text-center rounded">
                  <i class="uil uil-video d-block rounded h3 mb-0"></i>
                </div>
                <div class="flex-1 content ms-4">
                  <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Video Messages</a></h5>
                  <p class="text-muted mb-0">The most well-known dummy text is the, which is said to have originated in
                    the 16th century.</p>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 pt-2">
              <div class="d-flex features feature-clean feature-primary p-4 shadow rounded">
                <div class="icons text-primary d-block text-center rounded">
                  <i class="uil uil-database-alt d-block rounded h3 mb-0"></i>
                </div>
                <div class="flex-1 content ms-4">
                  <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Dedicated Server</a></h5>
                  <p class="text-muted mb-0">The most well-known dummy text is the, which is said to have originated in
                    the 16th century.</p>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-12 mt-4 pt-2 text-center">
              <a href="javascript:void(0)" class="text-primary h6">Explore features <i-feather name="arrow-right"
                  class="fea icon-sm"></i-feather></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <!-- About Start -->
  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6">
        <div class="row align-items-center">
          <div class="col-lg-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
            <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
              <div class="card-body p-0">
                <img src="assets/images/course/online/ab01.jpg" class="img-fluid" alt="work-image">
                <div class="overlay-work bg-dark"></div>
                <div class="content">
                  <a href="javascript:void(0)" class="title text-white d-block fw-bold">Web Development</a>
                  <small class="text-light">IT & Software</small>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-6 col-6">
            <div class="row">
              <div class="col-lg-12 col-md-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                  <div class="card-body p-0">
                    <img src="assets/images/course/online/ab02.jpg" class="img-fluid" alt="work-image">
                    <div class="overlay-work bg-dark"></div>
                    <div class="content">
                      <a href="javascript:void(0)" class="title text-white d-block fw-bold">Michanical Engineer</a>
                      <small class="text-light">Engineering</small>
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-lg-12 col-md-12 mt-4 pt-2">
                <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                  <div class="card-body p-0">
                    <img src="assets/images/course/online/ab03.jpg" class="img-fluid" alt="work-image">
                    <div class="overlay-work bg-dark"></div>
                    <div class="content">
                      <a href="javascript:void(0)" class="title text-white d-block fw-bold">Chartered Accountant</a>
                      <small class="text-light">C.A.</small>
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-6 mt-4 mt-lg-0 pt- pt-lg-0">
        <div class="ms-lg-4">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">About Our Story</h4>
            <p class="text-muted para-desc">Start working with <span class="text-primary fw-bold">Landrick</span> that
              can provide everything you need to generate awareness, drive traffic, connect.</p>
            <p class="text-muted para-desc mb-0">The most well-known dummy text is the 'Lorem Ipsum', which is said to
              have originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin language which more or less
              corresponds to 'proper' Latin. It contains a series of real Latin words.</p>
          </div>

          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h4 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Fully Responsive</li>
            <li class="mb-0"><span class="text-primary h4 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Multiple Layouts</li>
            <li class="mb-0"><span class="text-primary h4 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Suits Your Style</li>
          </ul>

          <div class="mt-4 pt-2">
            <a href="https://1.envato.market/4n73n" target="_blank" class="btn btn-primary m-1 me-2">Read More <i
                class="uil uil-angle-right-b align-middle"></i></a>
            <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" data-type="youtube"
              data-id="yba7hPeTSjk" class="btn btn-icon btn-pills btn-primary m-1">
              <i-feather name="video" class="icons"></i-feather>
            </a><span class="fw-bold text-uppercase small align-middle ms-1">Watch Now</span>
            <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <youtube-player [videoId]="'jNTZpfXYJa4'" height="450" width="780">
                </youtube-player>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 order-1 order-md-2">
        <img src="assets/images/landing/3.jpg" class="img-fluid rounded-md shadow-lg" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="section-title text-md-start text-center">
          <h4 class="title mb-4">Collaborate with your <br> team anytime and anywhare.</h4>
          <p class="text-muted mb-0 para-desc">Start working with <span class="text-primary fw-bold">Landrick</span>
            that can provide everything you need to generate awareness, drive traffic, connect.</p>

          <div class="d-flex align-items-center text-start mt-4 pt-2">
            <div class="text-primary h4 mb-0 me-3 p-3 rounded-md shadow">
              <i class="uil uil-capture"></i>
            </div>
            <div class="flex-1">
              <a href="javascript:void(0)" class="text-dark h6">Find Better Leads</a>
            </div>
          </div>

          <div class="d-flex align-items-center text-start mt-4">
            <div class="text-primary h4 mb-0 me-3 p-3 rounded-md shadow">
              <i class="uil uil-file"></i>
            </div>
            <div class="flex-1">
              <a href="javascript:void(0)" class="text-dark h6">Set Instant Metting</a>
            </div>
          </div>

          <div class="d-flex align-items-center text-start mt-4">
            <div class="text-primary h4 mb-0 me-3 p-3 rounded-md shadow">
              <i class="uil uil-credit-card-search"></i>
            </div>
            <div class="flex-1">
              <a href="javascript:void(0)" class="text-dark h6">Get Paid Seemlessly</a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->

<section class="section bg-light">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="section-title text-lg-left">
          <h6 class="text-primary">Partners</h6>
          <h4 class="title mb-4 mb-lg-0">Manage your client <br> smartly with instappoint</h4>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6">
        <div class="section-title text-lg-left">
          <p class="text-muted mb-0 mx-auto para-desc">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <app-clients-logo brand_class="col-lg-2 col-md-2 col-6 text-center pt-5"></app-clients-logo>
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-md-6">
        <div class="section-title text-md-left">
          <h4 class="title mb-4">You have any questions ?</h4>
          <p class="text-muted mb-0 para-desc">Start working with <span class="text-primary fw-bold">Landrick</span>
            that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
          <ul class="list-unstyled text-muted mt-4 mb-0">
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for Tomorrow</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Our Talented & Experienced Marketing Agency</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Create your own skin to match your brand</li>
          </ul>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="faq-content">
          <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingone">
                  <h6 class="title mb-0"> How does it work ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0"> Do I need a designer to use Landrick ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingthree">
                  <h6 class="title mb-0"> What do I need to do to start selling ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingfour">
                  <h6 class="title mb-0"> What happens when I receive an order ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>

        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row mt-5 pt-md-4 justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">Have Question ? Get in touch!</h4>
          <p class="text-muted para-desc mx-auto">Start working with <span class="text-primary fw-bold">Landrick</span>
            that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
          <a href="page-contact-two" class="btn btn-primary mt-4"><i class="mdi mdi-phone"></i> Contact us</a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->