<!-- Hero Start -->
<section class="bg-home bg-primary d-flex align-items-center"
    style="background: url('assets/images/integration/bg.png') center center; height: auto;" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center mt-0 mt-md-5 pt-0 pt-md-5">
                <div class="title-heading">
                    <span class="badge rounded-pill bg-success">Integration</span>
                    <h4 class="heading text-white title-dark my-3">The future is too <br> <b>Interesting</b> and to be
                        <b>Predictable</b>.
                    </h4>
                    <p class="para-desc mx-auto text-white-50">Explore and learn more about everything from machine
                        learning and global payments to scaling your team.</p>
                </div>

                <div class="text-center subcribe-form mt-4 pt-2">
                    <form>
                        <input type="url" id="url" class="border bg-white rounded-lg" style="opacity: 0.85;" required
                            placeholder="https://themesbrand.com">
                        <button type="submit" class="btn btn-pills btn-primary">Get Started</button>
                    </form>
                    <!--end form-->
                </div>

                <div class="row justify-content-center">
                    <div class="col-lg-8 col-md-10">
                        <div class="home-dashboard">
                            <img src="assets/images/integration/hero.png" alt="" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
    <div class="shape integration-hero overflow-hidden text-light"></div>
</div>
<!-- Hero End -->

<!-- Partners start -->
<section class="mt-5 pt-md-5">
    <div class="container">
        <app-clients-logo brand_class="col-lg-2 col-md-2 col-6 text-center py-4 py-sm-0"></app-clients-logo>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Partners End -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10">
                <div class="row">
                    <div class="col-md-6">
                        <div class="d-flex features feature-clean feature-primary core-feature rounded shadow p-4">
                            <div class="icons text-primary text-center mx-auto">
                                <i class="uil uil-shield d-block rounded h3 mb-0"></i>
                            </div>
                            <div class="flex-1 ms-4">
                                <a href="javascript:void(0)" class="title text-dark h5">Security</a>
                                <p class="text-muted mt-2 mb-0">Composed in a pseudo-Latin language which more or less
                                    pseudo-Latin language corresponds.</p>
                            </div>
                        </div>
                    </div>
                    <!--end col-->

                    <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div class="d-flex features feature-clean feature-primary core-feature rounded shadow p-4">
                            <div class="icons text-primary text-center mx-auto">
                                <i class="uil uil-atom d-block rounded h3 mb-0"></i>
                            </div>
                            <div class="flex-1 ms-4">
                                <a href="javascript:void(0)" class="title text-dark h5">Reliability</a>
                                <p class="text-muted mt-2 mb-0">Composed in a pseudo-Latin language which more or less
                                    pseudo-Latin language corresponds.</p>
                            </div>
                        </div>
                    </div>
                    <!--end col-->

                    <div class="col-md-6 mt-4 pt-2">
                        <div class="d-flex features feature-clean feature-primary core-feature rounded shadow p-4">
                            <div class="icons text-primary text-center mx-auto">
                                <i class="uil uil-medal d-block rounded h3 mb-0"></i>
                            </div>
                            <div class="flex-1 ms-4">
                                <a href="javascript:void(0)" class="title text-dark h5">Compliance</a>
                                <p class="text-muted mt-2 mb-0">Composed in a pseudo-Latin language which more or less
                                    pseudo-Latin language corresponds.</p>
                            </div>
                        </div>
                    </div>
                    <!--end col-->

                    <div class="col-md-6 mt-4 pt-2">
                        <div class="d-flex features feature-clean feature-primary core-feature rounded shadow p-4">
                            <div class="icons text-primary text-center mx-auto">
                                <i class="uil uil-padlock d-block rounded h3 mb-0"></i>
                            </div>
                            <div class="flex-1 ms-4">
                                <a href="javascript:void(0)" class="title text-dark h5">Privacy</a>
                                <p class="text-muted mt-2 mb-0">Composed in a pseudo-Latin language which more or less
                                    pseudo-Latin language corresponds.</p>
                            </div>
                        </div>
                    </div>
                    <!--end col-->

                    <div class="col-12 mt-4 pt-2 text-center">
                        <a href="javascript:void(0)" class="text-primary h6">Explore features <i-feather
                                name="arrow-right" class="fea icon-sm"></i-feather></a>
                    </div>
                </div>
                <!--end row-->
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-6">
                <img src="assets/images/integration/interactiondesign-amico.svg" class="img-fluid" alt="">
            </div>
            <!--end col-->

            <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="section-title ms-lg-5">
                    <h4 class="title mb-4">Integrate with monitoring & alerting tools</h4>

                    <h5><i class="uil uil-arrow-circle-right text-primary me-1"></i> Monitoring tool integrations</h5>
                    <p class="text-muted para-desc ms-4 ps-2">Integrate Statuspage with DataDog, New Relic, Librato or
                        Pingdom to update your page as soon as an issue is detected.</p>
                    <h5><i class="uil uil-arrow-circle-right text-primary me-1"></i> Alerting tool integrations</h5>
                    <p class="text-muted para-desc ms-4 ps-2">Integrate Statuspage with Opsgenie, PagerDuty, VictorOps,
                        or xMatters to update status directly from your alerting app.</p>
                    <h5><i class="uil uil-arrow-circle-right text-primary me-1"></i> Automation via API or email</h5>
                    <p class="text-muted para-desc ms-4 ps-2">Use our REST API to programmatically update your status
                        page by writing your own integration, or automatically update the status of components by
                        triggering en email with a subject line containing the words “UP” or “DOWN”.</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-12">
                <a href="javascript:void(0)">
                    <div class="d-flex key-feature align-items-center p-3 rounded shadow">
                        <img src="assets/images/job/Circleci.svg" class="avatar avatar-ex-sm" alt="">
                        <div class="flex-1 ms-3">
                            <h4 class="title mb-0 text-dark">CircleCi</h4>
                            <p class="text-muted mb-0">Api Integration</p>
                        </div>
                    </div>
                </a>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <a href="javascript:void(0)">
                    <div class="d-flex key-feature align-items-center p-3 rounded shadow">
                        <img src="assets/images/job/Codepen.svg" class="avatar avatar-ex-sm" alt="">
                        <div class="flex-1 ms-3">
                            <h4 class="title mb-0 text-dark">Codepen</h4>
                            <p class="text-muted mb-0">Api Integration</p>
                        </div>
                    </div>
                </a>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <a href="javascript:void(0)">
                    <div class="d-flex key-feature align-items-center p-3 rounded shadow">
                        <img src="assets/images/job/Discord.svg" class="avatar avatar-ex-sm" alt="">
                        <div class="flex-1 ms-3">
                            <h4 class="title mb-0 text-dark">Discard</h4>
                            <p class="text-muted mb-0">Api Integration</p>
                        </div>
                    </div>
                </a>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <a href="javascript:void(0)">
                    <div class="d-flex key-feature align-items-center p-3 rounded shadow">
                        <img src="assets/images/job/Eslint.svg" class="avatar avatar-ex-sm" alt="">
                        <div class="flex-1 ms-3">
                            <h4 class="title mb-0 text-dark">Eslint</h4>
                            <p class="text-muted mb-0">Api Integration</p>
                        </div>
                    </div>
                </a>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-6 order-1 order-md-2">
                <img src="assets/images/integration/1.png" class="img-fluid" alt="">
            </div>
            <!--end col-->

            <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="section-title me-lg-5">
                    <h4 class="title mb-4">Deliver your websites <br> faster, and better.</h4>
                    <p class="text-muted">You can combine all the Landrick templates into a single one, you can take a
                        component from the Application theme and use it in the Website.</p>
                    <ul class="list-unstyled text-muted">
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                                    class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for
                            Tomorrow</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                                    class="uil uil-check-circle align-middle"></i></span>Our Talented & Experienced
                            Marketing Agency</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                                    class="uil uil-check-circle align-middle"></i></span>Create your own skin to match
                            your brand</li>
                    </ul>
                    <div class="mt-4">
                        <a href="javascript:void(0)" class="btn btn-primary">Read More <i-feather name="arrow-right"
                                class="fea icon-sm"></i-feather></a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Start -->
<div class="container-fluid px-0">
    <div class="py-5 bg-footer">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-7">
                    <div class="section-title">
                        <div class="d-flex">
                            <i class="uil uil-mobile-android display-4 text-white title-dark"></i>
                            <div class="flex-1 ms-md-4 ms-3">
                                <h4 class="fw-bold text-white mb-1">Download our app now !</h4>
                                <p class="text-white-50 mb-0">Build modern looking websites fast and easy using
                                    Landflow.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-5 mt-4 mt-sm-0">
                    <div class="text-md-end ms-5 ms-sm-0">
                        <a href="javascript:void(0)" class="btn btn-primary me-2 me-lg-2 me-md-0 my-2"><i
                                class="uil uil-apple"></i> App Store</a>
                        <a href="javascript:void(0)" class="btn btn-soft-primary my-2"><i
                                class="uil uil-google-play"></i> Play Store</a>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </div>
    <!--end div-->
</div>
<!--end container-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->