        <!-- Start -->
        <section class="bg-half-170 d-table w-100" style="background-image: url('assets/images/hosting/reseller.png');" id="home">
            <div class="bg-overlay bg-gradient-primary opacity-9"></div>
            <div class="container">
                <div class="row mt-5">
                    <div class="col">
                        <div class="pages-heading title-heading">
                            <h4 class="title mb-4 fw-bold text-white title-dark">Reseller Hosting</h4>
                            <p class="text-white-50 para-desc mb-0">Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap v5 html page.</p>
                        
                            <div class="mt-4">
                                <a href="javascript:void(0)" class="btn btn-warning">Get Started</a>
                            </div>
                            <h6 class="mb-0 mt-2 text-white-50"><i-feather name="check-circle" class="fea icon-sm text-white title-dark me-1"></i-feather> 30-day money-back guarantee</h6>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- End -->

        <!-- Start -->
        <section class="section">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-5 col-md-12 text-center text-lg-start">
                        <div class="section-title mb-4 mb-lg-0 pb-2 pb-lg-0">
                            <h4 class="title mb-3">Hosting Plans</h4>
                            <p class="text-muted para-desc mx-auto mb-0">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                            <a href="https://1.envato.market/landrick" target="_blank" class="btn btn-primary mt-4">Buy Now <span class="badge rounded-pill bg-danger ms-2">v4.2</span></a>
                        </div>
                    </div><!--end col-->

                    <div class="col-lg-7 col-md-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                        <div class="ms-lg-5">
                            <div class="row align-items-center">
                                <div class="col-md-6 col-12 px-md-0">
                                    <div class="card pricing pricing-primary starter-plan shadow rounded border-0">
                                        <div class="card-body py-5">
                                            <h6 class="title name fw-bold text-uppercase mb-4">Starter</h6>
                                            <div class="d-flex mb-4">
                                                <span class="h4 mb-0 mt-2">$</span>
                                                <span class="price h1 mb-0">39</span>
                                                <span class="h4 align-self-end mb-1">/mo</span>
                                            </div>
    
                                            <ul class="list-unstyled mb-0 ps-0">
                                                <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Full Access</li>
                                                <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Source Files</li>
                                                <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Free Appointments</li>
                                                <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Enhanced Security</li>
                                            </ul>
                                            <a href="javascript:void(0)" class="btn btn-primary mt-4">Get Started</a>
                                        </div>
                                    </div>
                                </div><!--end col-->
    
                                <div class="col-md-6 col-12 mt-4 pt-2 pt-sm-0 mt-sm-0 px-md-0">
                                    <div class="card pricing pricing-primary bg-light shadow rounded border-0">
                                        <div class="card-body py-5">
                                            <h6 class="title name fw-bold text-uppercase mb-4">Professional</h6>
                                            <div class="d-flex mb-4">
                                                <span class="h4 mb-0 mt-2">$</span>
                                                <span class="price h1 mb-0">59</span>
                                                <span class="h4 align-self-end mb-1">/mo</span>
                                            </div>
    
                                            <ul class="list-unstyled mb-0 ps-0">
                                                <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Full Access</li>
                                                <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Source Files</li>
                                                <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Free Appointments</li>
                                                <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Enhanced Security</li>
                                            </ul>
                                            <a href="javascript:void(0)" class="btn btn-primary mt-4">Try it now</a>
                                        </div>
                                    </div>
                                </div><!--end col-->
                            </div><!--end row-->
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->

            <div class="container mt-100 mt-60">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6 col-12 order-2 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div class="section-title">
                            <small class="text-primary"><strong>Next generation hosting platform</strong></small>
                            <h4 class="title mt-2 mb-4">High quality website hosting</h4>
                            <p class="text-muted para-desc mb-0">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                            
                            <div class="mt-4">
                                <a href="javascript:void(0)" class="btn btn-primary">Learn More <i-feather name="arrow-right" class="fea icon-sm"></i-feather></a>
                            </div>
                        </div>
                    </div><!--end col-->

                    <div class="col-lg-6 col-md-6 col-12 order-1">
                        <div class="me-lg-5">
                            <img src="assets/images/hosting/1.png" class="img-fluid" alt="">
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- End -->

        <!-- Start -->
        <section class="section">
            <div class="bg-overlay bg-gradient-primary opacity-9"></div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <div class="section-title text-center pb-5">
                            <h4 class="title text-white title-dark mb-3">All the power and performance you'll ever need</h4>
                            <p class="text-white-50 para-desc mx-auto mb-0">Start working with Landrick that can provide everything you need to generate awareness, drive traffic, connect.</p>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
        </section><!--end section-->
        
        <section class="section features-absolute">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                        <div class="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
                            <span class="h1 icon-color">
                                <i class="uil uil-tachometer-fast-alt"></i>
                            </span>
                            <div class="card-body p-0 content">
                                <h5>Speedy</h5>
                                <p class="para text-muted mb-0">It is a long established fact that a reader will be of at its layout.</p>
                            </div>

                            <div class="position-absolute top-0 end-0">
                                <i class="uil uil-tachometer-fast-alt display-1 opacity-05"></i>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                        <div class="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
                            <span class="h1 icon-color">
                                <i class="uil uil-compact-disc"></i>
                            </span>
                            <div class="card-body p-0 content">
                                <h5>Reliable</h5>
                                <p class="para text-muted mb-0">It is a long established fact that a reader will be of at its layout.</p>
                            </div>

                            <div class="position-absolute top-0 end-0">
                                <i class="uil uil-compact-disc display-1 opacity-05"></i>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                        <div class="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
                            <span class="h1 icon-color">
                                <i class="uil uil-weight"></i>
                            </span>
                            <div class="card-body p-0 content">
                                <h5>Scalable</h5>
                                <p class="para text-muted mb-0">It is a long established fact that a reader will be of at its layout.</p>
                            </div>

                            <div class="position-absolute top-0 end-0">
                                <i class="uil uil-weight display-1 opacity-05"></i>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                        <div class="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
                            <span class="h1 icon-color">
                                <i class="uil uil-envelope-question"></i>
                            </span>
                            <div class="card-body p-0 content">
                                <h5>Supported</h5>
                                <p class="para text-muted mb-0">It is a long established fact that a reader will be of at its layout.</p>
                            </div>

                            <div class="position-absolute top-0 end-0">
                                <i class="uil uil-envelope-question display-1 opacity-05"></i>
                            </div>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->

            <!-- FAQs Start -->
            <div class="container mt-100 mt-60">
                <div class="row mb-md-4 pb-md-2 justify-content-center">
                    <div class="col-12">
                        <div class="section-title text-center">
                            <h4 class="title mb-3">Frequently asked questions</h4>
                            <p class="text-muted para-desc mx-auto mb-0">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->

                <div class="row justify-content-center">
                    <div class="col-lg-10 mt-4 pt-2">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item rounded shadow">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button border-0 bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                        aria-expanded="true" aria-controls="collapseOne">
                                        What is Dedicated Server?
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse border-0 collapse show" aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body text-muted">
                                        There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item rounded shadow mt-2">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Do I need a Dedicated Server?
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse border-0 collapse" aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body text-muted">
                                        There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item rounded shadow mt-2">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        How can I get my website transferred for free?
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse border-0 collapse" aria-labelledby="headingThree"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body text-muted">
                                        There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item rounded shadow mt-2">
                                <h2 class="accordion-header" id="headingFour">
                                    <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        What level of access do I get with my Dedicated Server?
                                    </button>
                                </h2>
                                <div id="collapseFour" class="accordion-collapse border-0 collapse" aria-labelledby="headingFour"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body text-muted">
                                        There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item rounded shadow mt-2">
                                <h2 class="accordion-header" id="headingFive">
                                    <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        Which control panels are provided?
                                    </button>
                                </h2>
                                <div id="collapseFive" class="accordion-collapse border-0 collapse" aria-labelledby="headingFive"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body text-muted">
                                        There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item rounded shadow mt-2">
                                <h2 class="accordion-header" id="headingSix">
                                    <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                        Do you offer any billing panels?
                                    </button>
                                </h2>
                                <div id="collapseSix" class="accordion-collapse border-0 collapse" aria-labelledby="headingSix"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body text-muted">
                                        There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item rounded shadow mt-2">
                                <h2 class="accordion-header" id="headingSeven">
                                    <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                        How long will it take for my server to be ready for use?
                                    </button>
                                </h2>
                                <div id="collapseSeven" class="accordion-collapse border-0 collapse" aria-labelledby="headingSeven"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body text-muted">
                                        There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item rounded shadow mt-2">
                                <h2 class="accordion-header" id="headingEight">
                                    <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                        How do I access my Dedicated Server?
                                    </button>
                                </h2>
                                <div id="collapseEight" class="accordion-collapse border-0 collapse" aria-labelledby="headingEight"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body text-muted">
                                        There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end container-->
            <!-- FAQs End -->
        </section><!--end section-->
        <!-- End -->

        <section class="section py-5">
            <div class="bg-overlay bg-gradient-primary opacity-9"></div>
            <div class="container">
                <div class="row justify-content-center align-items-center">
                    <div class="col-lg-7 col-md-8 col-12">
                        <div class="section-title text-center text-md-start">
                            <h4 class="title text-white title-dark mb-3">Powerful Reseller <br> Hosting to fuel your business!</h4>
                            <p class="text-white-50 para-desc mb-0">Start working with Landrick that can provide everything you need to generate awareness, drive traffic, connect.</p>
                        </div>
                    </div><!--end col-->

                    <div class="col-lg-5 col-md-4 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div class="text-center text-md-end">
                            <a href="javascript:void(0)" class="btn btn-warning">Get Started</a>
                        </div>
                    </div>
                </div><!--end row-->
            </div><!--end container-->
        </section>

        <!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
