<!-- <app-header [navClass]="addclass" [buttonList]="buttonShow" [sliderTopbar]="TopbarShow" 
[isdeveloper]="developerPage" [shopPages]="shopPages" [appicons]="appicons" [Nfticons]="Nfticons" [Menuoption]="Menuoption" [Settingicon]="Settingicon"></app-header> -->

<app-header-tzd [navClass]="addclass" [buttonList]="buttonShow" [sliderTopbar]="TopbarShow" 
[isdeveloper]="developerPage" [shopPages]="shopPages" [appicons]="appicons" [Nfticons]="Nfticons" [Menuoption]="Menuoption" [Settingicon]="Settingicon"></app-header-tzd>

<router-outlet (activate)="onActivate($event)"></router-outlet>

<!-- <app-footer [footerVariant]="footerClass" [hideFooter]="hideFooter"></app-footer>  -->
<app-footer-tzd [footerVariant]="footerClass" [hideFooter]="hideFooter"></app-footer-tzd> 

