import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-index-it-solution-tzd',
  templateUrl: './index-it-solution-tzd.component.html',
  styleUrls: ['./index-it-solution-tzd.component.css']
})
export class IndexItSolutionTzdComponent {

/***
   * Nav Bg Class Add
   */
navClass = 'nav-light';
// Set Topbar Option
Menuoption = 'center';
Settingicon = true

/**
* Blog Data
*/
blogData = [
 {
   image: "assets/images/blog/01.jpg",
   title: "Design your apps in your own way",
   like: "33",
   message: "08",
   name: "Calvin Carlo",
   date: "13th August, 2019"
 },
 {
   image: "assets/images/blog/02.jpg",
   title: "How apps is changing the IT world",
   like: "33",
   message: "08",
   name: "Calvin Carlo",
   date: "13th August, 2019"
 },
 {
   image: "assets/images/blog/03.jpg",
   title: "Smartest Applications for Business",
   like: "33",
   message: "08",
   name: "Calvin Carlo",
   date: "13th August, 2019"
 }
];

/**
* Member Data
*/
memberData = [
 {
   profile: "assets/images/client/01.jpg",
   list: ["facebook", "instagram", "twitter", "linkedin"],
   name: "Ronny Jofra",
   designation: "C.E.O"
 },
 {
   profile: "assets/images/client/04.jpg",
   list: ["facebook", "instagram", "twitter", "linkedin"],
   name: "Micheal Carlo",
   designation: "Director"
 },
 {
   profile: "assets/images/client/02.jpg",
   list: ["facebook", "instagram", "twitter", "linkedin"],
   name: "Aliana Rosy",
   designation: "Manager"
 },
 {
   profile: "assets/images/client/03.jpg",
   list: ["facebook", "instagram", "twitter", "linkedin"],
   name: "Sofia Razaq",
   designation: "Developer"
 }
];

constructor(private modalService: NgbModal) { }

ngOnInit(): void {
}

/**
* Open modal for show the video
* @param content content of modal
*/
openWindowCustomClass(content) {
 this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
}
}

