<!-- Hero Start -->
<section class="section" id="home" style="padding-bottom: 0 !important">
  <div class="container">
    <div class="mt-4">
      <a
        href="javascript:window.history.back();"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="white"
          class="bi bi-arrow-left-square"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm11.5 5.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
          /></svg
      ></a>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h3 class="title text-light">Job Apply Form</h3>
          <!-- <div class="page-next">
                        <nav aria-label="breadcrumb" class="d-inline-block">
                            <ul class="breadcrumb bg-white rounded shadow mb-0">
                                <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                                <li class="breadcrumb-item"><a href="javascript:void(0)">Page</a></li>
                                <li class="breadcrumb-item"><a href="javascript:void(0)">Careers</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Job Apply</li>
                            </ul>
                        </nav>
                    </div> -->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<!-- <div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div> -->
<!--Shape End-->

<!-- Job apply form Start -->
<section class="section pt-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10 col-md-7">
        <div class="card custom-form border-0" style="background: #2b3949">
          <div
            class="card-body"
            style="
              border: 1.8px solid white;
              border-radius: 7px;
              color: white !important;
            "
          >
            <form class="rounded shadow p-4" [formGroup]="jobApplyForm">
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label"
                      >Your Name :<span class="text-danger">*</span></label
                    >
                    <div class="form-icon position-relative">
                      <i-feather
                        name="user"
                        class="fea icon-sm icons"
                      ></i-feather>
                      <input
                        name="name"
                        id="name"
                        type="text"
                        class="form-control ps-5"
                        formControlName="firstName"
                        placeholder="First Name :"
                        autocomplete="off"
                        style="
                          background: #2b3949;
                          border: 1.8px solid white;
                          color: white !important;
                        "
                        [ngClass]="{
                          'is-invalid': submitted && f.firstName.errors
                        }"
                      />
                      <div
                        *ngIf="submitted && f.firstName.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.firstName.errors.required">
                          Name is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end col-->
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label"
                      >Your Email :<span class="text-danger">*</span></label
                    >
                    <div class="form-icon position-relative">
                      <i-feather
                        name="mail"
                        class="fea icon-sm icons"
                      ></i-feather>
                      <input
                        name="email"
                        id="email"
                        type="email"
                        class="form-control ps-5"
                        formControlName="email"
                        placeholder="Your email :"
                        autocomplete="off"
                        style="
                          background: #2b3949;
                          border: 1.8px solid white;
                          color: white !important;
                        "
                        [ngClass]="{
                          'is-invalid': submitted && f.email.errors
                        }"
                      />
                      <div
                        *ngIf="submitted && f.email.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.email.errors.required">
                          Email is required
                        </div>
                        <div *ngIf="f.email.errors.email">
                          Email must be a valid email address
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end col-->
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label"
                      >Your Phone no. :<span class="text-danger">*</span></label
                    >
                    <div class="form-icon position-relative">
                      <i-feather
                        name="phone"
                        class="fea icon-sm icons"
                      ></i-feather>
                      <input
                        name="number"
                        id="number"
                        type="tel"
                        formControlName="phoneno"
                        class="form-control ps-5"
                        placeholder="Your phone no. :"
                        autocomplete="off"
                        style="
                          background: #2b3949;
                          border: 1.8px solid white;
                          color: white !important;
                        "
                        [ngClass]="{
                          'is-invalid': submitted && f.phoneno.errors
                        }"
                      />
                      <div
                        *ngIf="submitted && f.phoneno.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.phoneno.errors.required">
                          Phone is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end col-->
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Job Title :</label>
                    <div class="form-icon position-relative">
                      <i-feather
                        name="book"
                        class="fea icon-sm icons"
                      ></i-feather>
                      <input
                        name="subject"
                        id="subject"
                        class="form-control ps-5"
                        formControlName="title"
                        placeholder="Title :"
                        autocomplete="off"
                        style="
                          background: #2b3949;
                          border: 1.8px solid white;
                          color: white !important;
                        "
                        [ngClass]="{
                          'is-invalid': submitted && f.title.errors
                        }"
                      />
                      <div
                        *ngIf="submitted && f.title.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.title.errors.required">
                          Title is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end col-->
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Types of jobs :</label>
                    <select
                      class="form-control custom-select"
                      id="Sortbylist-Shop"
                      formControlName="jobType"
                    >

                      <option value="" selected >All Jobs</option>
                      <option>Full Time</option>
                      <option>Half Time</option>
                      <option>Remote</option>
                      <option>In Office</option>
                    </select>
                  </div>
                </div>
                <!--end col-->
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label">Description :</label>
                    <div class="form-icon position-relative">
                      <i-feather
                        name="message-circle"
                        class="fea icon-sm icons"
                      ></i-feather>
                      <textarea
                        name="comments"
                        id="comments"
                        rows="3"
                        formControlName="description"
                        class="form-control ps-5"
                        placeholder="Describe the job :"
                        autocomplete="off"
                        style="
                          background: #2b3949;
                          border: 1.8px solid white;
                          color: white !important;
                        "
                      ></textarea>
                    </div>
                  </div>
                </div>
                <!--end col-->
                <div class="col-md-12">
                  <div class="mb-3">
                    <label for="formFile" class="form-label"
                      >Upload Your Cv / Resume :</label
                    >
                    <input
                      class="form-control"
                      type="file"
                      id="formFile"
                      formControlName="resumeURL"
                      style="
                        background: #2b3949;
                        border: 1.8px solid white;
                        color: white !important;
                      "
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label">Address :</label>
                    <div class="form-icon position-relative">
                      <i-feather
                        name="message-circle"
                        class="fea icon-sm icons"
                      ></i-feather>
                      <textarea
                        name="comments"
                        id="comments"
                        rows="3"
                        formControlName="address"
                        class="form-control ps-5"
                        placeholder="Enter Address:"
                        autocomplete="off"
                        style="
                          background: #2b3949;
                          border: 1.8px solid white;
                          color: white !important;
                        "
                      ></textarea>
                    </div>
                  </div>
                </div>
                <!--end col-->
                <div class="col-md-12">
                  <div class="mb-3">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label class="form-check-label" for="flexCheckDefault"
                        >I Accept
                        <a href="javascript:void(0)" class="text-primary"
                          >Terms And Condition</a
                        ></label
                      >
                    </div>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
              <div class="row justify-content-center">
                <div class="col-4">
                  <div class="d-grid">
                    <button
                      type="submit"
                      class="btn btn-primary"
                      (click)="submit()"
                    >
                      Apply Now
                    </button>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </form>
            <!--end form-->
          </div>
        </div>
        <!--end custom-form-->
      </div>
    </div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Job apply form End -->

<!-- Back to top -->
<a
  href="javascript: void(0);"
  class="btn btn-icon btn-primary back-to-top"
  id="back-to-top"
  [ngxScrollTo]="'#home'"
>
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
