<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0"> Creators List </h4>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a routerLink="/index-nft">NFTs</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Creator</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- Hero End -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                <div class="card nft nft-primary nft-creator border-0 rounded-md shadow">
                    <div class="card-body p-3">
                        <div class="pb-3 border-bottom">
                            <div class="d-flex justify-content-between align-items-center">
                                <span class="badge bg-soft rounded-pill">No. #1</span>

                                <a routerLink="/nft-explore" class="text-dark h5 mb-0 read-more"><i
                                        class="uil uil-arrow-circle-right"></i></a>
                            </div>
                        </div>

                        <div class="content mt-3">
                            <div class="position-relative text-center">
                                <img src="assets/images/client/01.jpg" class="avatar avatar-small rounded-pill shadow"
                                    alt="">

                                <div class="author mt-2">
                                    <a routerLink="/account-profile" class="text-dark h6 name">Jordan Joo</a>
                                    <small class="d-block fw-bold mt-1">0.75<span class="text-muted">ETH</span></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="card nft nft-primary nft-creator border-0 rounded-md shadow">
                    <div class="card-body p-3">
                        <div class="pb-3 border-bottom">
                            <div class="d-flex justify-content-between align-items-center">
                                <span class="badge bg-soft rounded-pill">No. #2</span>

                                <a routerLink="/nft-explore" class="text-dark h5 mb-0 read-more"><i
                                        class="uil uil-arrow-circle-right"></i></a>
                            </div>
                        </div>

                        <div class="content mt-3">
                            <div class="position-relative text-center">
                                <img src="assets/images/client/02.jpg" class="avatar avatar-small rounded-pill shadow"
                                    alt="">

                                <div class="author mt-2">
                                    <a routerLink="/account-profile" class="text-dark h6 name">Sofia Malik</a>
                                    <small class="d-block fw-bold mt-1">0.75<span class="text-muted">ETH</span></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="card nft nft-primary nft-creator border-0 rounded-md shadow">
                    <div class="card-body p-3">
                        <div class="pb-3 border-bottom">
                            <div class="d-flex justify-content-between align-items-center">
                                <span class="badge bg-soft rounded-pill">No. #3</span>

                                <a routerLink="/nft-explore" class="text-dark h5 mb-0 read-more"><i
                                        class="uil uil-arrow-circle-right"></i></a>
                            </div>
                        </div>

                        <div class="content mt-3">
                            <div class="position-relative text-center">
                                <img src="assets/images/client/03.jpg" class="avatar avatar-small rounded-pill shadow"
                                    alt="">

                                <div class="author mt-2">
                                    <a routerLink="/account-profile" class="text-dark h6 name">Cristina Murphy</a>
                                    <small class="d-block fw-bold mt-1">0.75<span class="text-muted">ETH</span></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2 mt-lg-0 pt-lg-0">
                <div class="card nft nft-primary nft-creator border-0 rounded-md shadow">
                    <div class="card-body p-3">
                        <div class="pb-3 border-bottom">
                            <div class="d-flex justify-content-between align-items-center">
                                <span class="badge bg-soft rounded-pill">No. #4</span>

                                <a routerLink="/nft-explore" class="text-dark h5 mb-0 read-more"><i
                                        class="uil uil-arrow-circle-right"></i></a>
                            </div>
                        </div>

                        <div class="content mt-3">
                            <div class="position-relative text-center">
                                <img src="assets/images/client/04.jpg" class="avatar avatar-small rounded-pill shadow"
                                    alt="">

                                <div class="author mt-2">
                                    <a routerLink="/account-profile" class="text-dark h6 name">Jack Lili</a>
                                    <small class="d-block fw-bold mt-1">0.75<span class="text-muted">ETH</span></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2">
                <div class="card nft nft-primary nft-creator border-0 rounded-md shadow">
                    <div class="card-body p-3">
                        <div class="pb-3 border-bottom">
                            <div class="d-flex justify-content-between align-items-center">
                                <span class="badge bg-soft rounded-pill">No. #5</span>

                                <a routerLink="/nft-explore" class="text-dark h5 mb-0 read-more"><i
                                        class="uil uil-arrow-circle-right"></i></a>
                            </div>
                        </div>

                        <div class="content mt-3">
                            <div class="position-relative text-center">
                                <img src="assets/images/client/05.jpg" class="avatar avatar-small rounded-pill shadow"
                                    alt="">

                                <div class="author mt-2">
                                    <a routerLink="/account-profile" class="text-dark h6 name">Lilia Moris</a>
                                    <small class="d-block fw-bold mt-1">0.75<span class="text-muted">ETH</span></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2">
                <div class="card nft nft-primary nft-creator border-0 rounded-md shadow">
                    <div class="card-body p-3">
                        <div class="pb-3 border-bottom">
                            <div class="d-flex justify-content-between align-items-center">
                                <span class="badge bg-soft rounded-pill">No. #6</span>

                                <a routerLink="/nft-explore" class="text-dark h5 mb-0 read-more"><i
                                        class="uil uil-arrow-circle-right"></i></a>
                            </div>
                        </div>

                        <div class="content mt-3">
                            <div class="position-relative text-center">
                                <img src="assets/images/client/06.jpg" class="avatar avatar-small rounded-pill shadow"
                                    alt="">

                                <div class="author mt-2">
                                    <a routerLink="/account-profile" class="text-dark h6 name">Anderson</a>
                                    <small class="d-block fw-bold mt-1">0.75<span class="text-muted">ETH</span></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2">
                <div class="card nft nft-primary nft-creator border-0 rounded-md shadow">
                    <div class="card-body p-3">
                        <div class="pb-3 border-bottom">
                            <div class="d-flex justify-content-between align-items-center">
                                <span class="badge bg-soft rounded-pill">No. #7</span>

                                <a routerLink="/nft-explore" class="text-dark h5 mb-0 read-more"><i
                                        class="uil uil-arrow-circle-right"></i></a>
                            </div>
                        </div>

                        <div class="content mt-3">
                            <div class="position-relative text-center">
                                <img src="assets/images/client/07.jpg" class="avatar avatar-small rounded-pill shadow"
                                    alt="">

                                <div class="author mt-2">
                                    <a routerLink="/account-profile" class="text-dark h6 name">Joe Moris</a>
                                    <small class="d-block fw-bold mt-1">0.75<span class="text-muted">ETH</span></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2">
                <div class="card nft nft-primary nft-creator border-0 rounded-md shadow">
                    <div class="card-body p-3">
                        <div class="pb-3 border-bottom">
                            <div class="d-flex justify-content-between align-items-center">
                                <span class="badge bg-soft rounded-pill">No. #8</span>

                                <a routerLink="/nft-explore" class="text-dark h5 mb-0 read-more"><i
                                        class="uil uil-arrow-circle-right"></i></a>
                            </div>
                        </div>

                        <div class="content mt-3">
                            <div class="position-relative text-center">
                                <img src="assets/images/client/08.jpg" class="avatar avatar-small rounded-pill shadow"
                                    alt="">

                                <div class="author mt-2">
                                    <a routerLink="/account-profile" class="text-dark h6 name">John Carter</a>
                                    <small class="d-block fw-bold mt-1">0.75<span class="text-muted">ETH</span></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2">
                <div class="card nft nft-primary nft-creator border-0 rounded-md shadow">
                    <div class="card-body p-3">
                        <div class="pb-3 border-bottom">
                            <div class="d-flex justify-content-between align-items-center">
                                <span class="badge bg-soft rounded-pill">No. #9</span>

                                <a routerLink="/nft-explore" class="text-dark h5 mb-0 read-more"><i
                                        class="uil uil-arrow-circle-right"></i></a>
                            </div>
                        </div>

                        <div class="content mt-3">
                            <div class="position-relative text-center">
                                <img src="assets/images/client/01.jpg" class="avatar avatar-small rounded-pill shadow"
                                    alt="">

                                <div class="author mt-2">
                                    <a routerLink="/account-profile" class="text-dark h6 name">Jordan Joo</a>
                                    <small class="d-block fw-bold mt-1">0.75<span class="text-muted">ETH</span></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2">
                <div class="card nft nft-primary nft-creator border-0 rounded-md shadow">
                    <div class="card-body p-3">
                        <div class="pb-3 border-bottom">
                            <div class="d-flex justify-content-between align-items-center">
                                <span class="badge bg-soft rounded-pill">No. #10</span>

                                <a routerLink="/nft-explore" class="text-dark h5 mb-0 read-more"><i
                                        class="uil uil-arrow-circle-right"></i></a>
                            </div>
                        </div>

                        <div class="content mt-3">
                            <div class="position-relative text-center">
                                <img src="assets/images/client/02.jpg" class="avatar avatar-small rounded-pill shadow"
                                    alt="">

                                <div class="author mt-2">
                                    <a routerLink="/account-profile" class="text-dark h6 name">Sofia Malik</a>
                                    <small class="d-block fw-bold mt-1">0.75<span class="text-muted">ETH</span></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2">
                <div class="card nft nft-primary nft-creator border-0 rounded-md shadow">
                    <div class="card-body p-3">
                        <div class="pb-3 border-bottom">
                            <div class="d-flex justify-content-between align-items-center">
                                <span class="badge bg-soft rounded-pill">No. #11</span>

                                <a routerLink="/nft-explore" class="text-dark h5 mb-0 read-more"><i
                                        class="uil uil-arrow-circle-right"></i></a>
                            </div>
                        </div>

                        <div class="content mt-3">
                            <div class="position-relative text-center">
                                <img src="assets/images/client/03.jpg" class="avatar avatar-small rounded-pill shadow"
                                    alt="">

                                <div class="author mt-2">
                                    <a routerLink="/account-profile" class="text-dark h6 name">Cristina Murphy</a>
                                    <small class="d-block fw-bold mt-1">0.75<span class="text-muted">ETH</span></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2">
                <div class="card nft nft-primary nft-creator border-0 rounded-md shadow">
                    <div class="card-body p-3">
                        <div class="pb-3 border-bottom">
                            <div class="d-flex justify-content-between align-items-center">
                                <span class="badge bg-soft rounded-pill">No. #12</span>

                                <a routerLink="/nft-explore" class="text-dark h5 mb-0 read-more"><i
                                        class="uil uil-arrow-circle-right"></i></a>
                            </div>
                        </div>

                        <div class="content mt-3">
                            <div class="position-relative text-center">
                                <img src="assets/images/client/04.jpg" class="avatar avatar-small rounded-pill shadow"
                                    alt="">

                                <div class="author mt-2">
                                    <a routerLink="/account-profile" class="text-dark h6 name">Jack Lili</a>
                                    <small class="d-block fw-bold mt-1">0.75<span class="text-muted">ETH</span></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2">
                <div class="card nft nft-primary nft-creator border-0 rounded-md shadow">
                    <div class="card-body p-3">
                        <div class="pb-3 border-bottom">
                            <div class="d-flex justify-content-between align-items-center">
                                <span class="badge bg-soft rounded-pill">No. #13</span>

                                <a routerLink="/nft-explore" class="text-dark h5 mb-0 read-more"><i
                                        class="uil uil-arrow-circle-right"></i></a>
                            </div>
                        </div>

                        <div class="content mt-3">
                            <div class="position-relative text-center">
                                <img src="assets/images/client/05.jpg" class="avatar avatar-small rounded-pill shadow"
                                    alt="">

                                <div class="author mt-2">
                                    <a routerLink="/account-profile" class="text-dark h6 name">Lilia Moris</a>
                                    <small class="d-block fw-bold mt-1">0.75<span class="text-muted">ETH</span></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2">
                <div class="card nft nft-primary nft-creator border-0 rounded-md shadow">
                    <div class="card-body p-3">
                        <div class="pb-3 border-bottom">
                            <div class="d-flex justify-content-between align-items-center">
                                <span class="badge bg-soft rounded-pill">No. #14</span>

                                <a routerLink="/nft-explore" class="text-dark h5 mb-0 read-more"><i
                                        class="uil uil-arrow-circle-right"></i></a>
                            </div>
                        </div>

                        <div class="content mt-3">
                            <div class="position-relative text-center">
                                <img src="assets/images/client/06.jpg" class="avatar avatar-small rounded-pill shadow"
                                    alt="">

                                <div class="author mt-2">
                                    <a routerLink="/account-profile" class="text-dark h6 name">Anderson</a>
                                    <small class="d-block fw-bold mt-1">0.75<span class="text-muted">ETH</span></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2">
                <div class="card nft nft-primary nft-creator border-0 rounded-md shadow">
                    <div class="card-body p-3">
                        <div class="pb-3 border-bottom">
                            <div class="d-flex justify-content-between align-items-center">
                                <span class="badge bg-soft rounded-pill">No. #15</span>

                                <a routerLink="/nft-explore" class="text-dark h5 mb-0 read-more"><i
                                        class="uil uil-arrow-circle-right"></i></a>
                            </div>
                        </div>

                        <div class="content mt-3">
                            <div class="position-relative text-center">
                                <img src="assets/images/client/07.jpg" class="avatar avatar-small rounded-pill shadow"
                                    alt="">

                                <div class="author mt-2">
                                    <a routerLink="/account-profile" class="text-dark h6 name">Joe Moris</a>
                                    <small class="d-block fw-bold mt-1">0.75<span class="text-muted">ETH</span></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2">
                <div class="card nft nft-primary nft-creator border-0 rounded-md shadow">
                    <div class="card-body p-3">
                        <div class="pb-3 border-bottom">
                            <div class="d-flex justify-content-between align-items-center">
                                <span class="badge bg-soft rounded-pill">No. #16</span>

                                <a routerLink="/nft-explore" class="text-dark h5 mb-0 read-more"><i
                                        class="uil uil-arrow-circle-right"></i></a>
                            </div>
                        </div>

                        <div class="content mt-3">
                            <div class="position-relative text-center">
                                <img src="assets/images/client/08.jpg" class="avatar avatar-small rounded-pill shadow"
                                    alt="">

                                <div class="author mt-2">
                                    <a routerLink="/account-profile" class="text-dark h6 name">John Carter</a>
                                    <small class="d-block fw-bold mt-1">0.75<span class="text-muted">ETH</span></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="row">
            <div class="col-12 mt-4 pt-2">
                <ul class="pagination justify-content-center mb-0">
                    <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Previous">Prev</a>
                    </li>
                    <li class="page-item active"><a class="page-link" href="javascript:void(0)">1</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Next">Next</a></li>
                </ul>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->