    <!-- Start -->
    <section class="bg-half" id="home">
        <div class="container">
            <div class="row">
                <div class="col-lg-10">
                    <div class="pages-heading">
                        <h4 class="title mb-0">We Are Landrick. <br>Switzerland based art & design studio</h4>
                    </div>
                </div><!--end col-->
            </div><!--end row-->
        </div><!--end container-->

        <div class="container mt-100 mt-60">
            <div id="grid" class="row mt-5">
                <div class="col-md-3 col-4 spacing picture-item">
                    <div class="card border-0 position-relative d-block shadow overflow-hidden rounded">
                        <div class="card-body p-0">
                            <img src="assets/images/work/about/1.jpg" class="img-fluid" alt="work-image">
                        </div>
                    </div>
                </div><!--end col-->
                
                <div class="col-md-3 col-4 spacing picture-item">
                    <div class="card border-0 position-relative d-block shadow overflow-hidden rounded">
                        <div class="card-body p-0">
                            <img src="assets/images/work/about/2.jpg" class="img-fluid" alt="work-image">
                        </div>
                    </div>
                </div><!--end col-->
                
                <div class="col-md-3 col-4 spacing picture-item">
                    <div class="card border-0 position-relative d-block shadow overflow-hidden rounded">
                        <div class="card-body p-0">
                            <img src="assets/images/work/about/3.jpg" class="img-fluid" alt="work-image">
                        </div>
                    </div>
                </div><!--end col-->
                
                <div class="col-md-3 col-4 spacing picture-item">
                    <div class="card border-0 position-relative d-block shadow overflow-hidden rounded">
                        <div class="card-body p-0">
                            <img src="assets/images/work/about/4.jpg" class="img-fluid" alt="work-image">
                        </div>
                    </div>
                </div><!--end col-->
                
                <div class="col-md-3 col-4 spacing picture-item">
                    <div class="card border-0 position-relative d-block shadow overflow-hidden rounded">
                        <div class="card-body p-0">
                            <img src="assets/images/work/about/8.jpg" class="img-fluid" alt="work-image">
                        </div>
                    </div>
                </div><!--end col-->
                
                <div class="col-md-3 col-4 spacing picture-item">
                    <div class="card border-0 position-relative d-block shadow overflow-hidden rounded">
                        <div class="card-body p-0">
                            <img src="assets/images/work/about/6.jpg" class="img-fluid" alt="work-image">
                        </div>
                    </div>
                </div><!--end col-->
                
                <div class="col-md-3 col-4 spacing picture-item">
                    <div class="card border-0 position-relative d-block shadow overflow-hidden rounded">
                        <div class="card-body p-0">
                            <img src="assets/images/work/about/5.jpg" class="img-fluid" alt="work-image">
                        </div>
                    </div>
                </div><!--end col-->
                
                <div class="col-md-3 col-4 spacing picture-item">
                    <div class="card border-0 position-relative d-block shadow overflow-hidden rounded">
                        <div class="card-body p-0">
                            <img src="assets/images/work/about/7.jpg" class="img-fluid" alt="work-image">
                        </div>
                    </div>
                </div><!--end col-->
            </div><!--end row-->
        </div><!--end container-->

        <div class="container mt-100 mt-60">
            <div class="row justify-content-center">
                <div class="col-12 text-center">
                    <div class="section-title mb-4 pb-2">
                        <h4 class="title mb-4">Key Features</h4>
                        <p class="text-muted para-desc mx-auto mb-0">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                    </div>
                </div><!--end col-->
            </div><!--end row-->

            <div class="row">
                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                        <div class="icon text-center rounded-circle me-3">
                            <i-feather name="monitor" class="fea icon-ex-md"></i-feather>
                        </div>
                        <div class="flex-1">
                            <h4 class="title mb-0">Fully Responsive</h4>
                        </div>
                    </div>
                </div><!--end col-->
                
                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                        <div class="icon text-center rounded-circle me-3">
                            <i-feather name="heart" class="fea icon-ex-md"></i-feather>
                        </div>
                        <div class="flex-1">
                            <h4 class="title mb-0">Browser Compatibility</h4>
                        </div>
                    </div>
                </div><!--end col-->
                
                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                        <div class="icon text-center rounded-circle me-3">
                            <i-feather name="eye" class="fea icon-ex-md"></i-feather>
                        </div>
                        <div class="flex-1">
                            <h4 class="title mb-0">Retina Ready</h4>
                        </div>
                    </div>
                </div><!--end col-->
                
                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                        <div class="icon text-center rounded-circle me-3">
                            <i-feather name="bold" class="fea icon-ex-md"></i-feather>
                        </div>
                        <div class="flex-1">
                            <h4 class="title mb-0">Based On Bootstrap 5</h4>
                        </div>
                    </div>
                </div><!--end col-->
                
                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                        <div class="icon text-center rounded-circle me-3">
                            <i-feather name="feather" class="fea icon-ex-md"></i-feather>
                        </div>
                        <div class="flex-1">
                            <h4 class="title mb-0">Feather Icons</h4>
                        </div>
                    </div>
                </div><!--end col-->
                
                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                        <div class="icon text-center rounded-circle me-3">
                            <i-feather name="code" class="fea icon-ex-md"></i-feather>
                        </div>
                        <div class="flex-1">
                            <h4 class="title mb-0">Built With SASS</h4>
                        </div>
                    </div>
                </div><!--end col-->
                
                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                        <div class="icon text-center rounded-circle me-3">
                            <i-feather name="user-check" class="fea icon-ex-md"></i-feather>
                        </div>
                        <div class="flex-1">
                            <h4 class="title mb-0">W3c Valid Code</h4>
                        </div>
                    </div>
                </div><!--end col-->
                
                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                        <div class="icon text-center rounded-circle me-3">
                            <i-feather name="git-merge" class="fea icon-ex-md"></i-feather>
                        </div>
                        <div class="flex-1">
                            <h4 class="title mb-0">Flaticon Icons</h4>
                        </div>
                    </div>
                </div><!--end col-->
                
                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                        <div class="icon text-center rounded-circle me-3">
                            <i-feather name="settings" class="fea icon-ex-md"></i-feather>
                        </div>
                        <div class="flex-1">
                            <h4 class="title mb-0">Easy to customize</h4>
                        </div>
                    </div>
                </div><!--end col-->
                
                <div class="col-12 mt-4 pt-2 text-center">
                    <a href="javascript:void(0)" class="btn btn-primary">See More <i class="mdi mdi-arrow-right"></i></a>
                </div><!--end col-->
            </div><!--end row-->
        </div><!--end container-->

        <div class="container mt-100 mt-60">
            <div class="row justify-content-center">
                <div class="col-12 text-center">
                    <div class="section-title mb-4 pb-2">
                        <h4 class="title mb-4">Our Team</h4>
                        <p class="text-muted para-desc mx-auto mb-0">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                    </div>
                </div><!--end col-->
            </div><!--end row-->

            <div class="row">
                <div class="col-lg-3 col-md-6 mt-4 pt-2">
                    <div class="card team team-primary text-center border-0">
                        <div class="position-relative">
                            <img src="assets/images/client/01.jpg" class="img-fluid rounded shadow" alt="">
                            <ul class="list-unstyled mb-0 team-icon">
                                <li class="list-inline-item"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="facebook" class="icons"></i-feather></a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="instagram" class="icons"></i-feather></a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="twitter" class="icons"></i-feather></a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="linkedin" class="icons"></i-feather></a></li>
                            </ul><!--end icon-->
                        </div>
                        <div class="card-body py-3 px-0 content">
                            <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Ronny Jofra</a></h5>
                            <small class="designation text-muted">C.E.O</small>
                        </div>
                    </div>
                </div><!--end col-->

                <div class="col-lg-3 col-md-6 mt-4 pt-2">
                    <div class="card team team-primary text-center border-0">
                        <div class="position-relative">
                            <img src="assets/images/client/04.jpg" class="img-fluid rounded shadow" alt="">
                            <ul class="list-unstyled mb-0 team-icon">
                                <li class="list-inline-item"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="facebook" class="icons"></i-feather></a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="instagram" class="icons"></i-feather></a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="twitter" class="icons"></i-feather></a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="linkedin" class="icons"></i-feather></a></li>
                            </ul><!--end icon-->
                        </div>
                        <div class="card-body py-3 px-0 content">
                            <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Micheal Carlo</a></h5>
                            <small class="designation text-muted">Director</small>
                        </div>
                    </div>
                </div><!--end col-->
                
                <div class="col-lg-3 col-md-6 mt-4 pt-2">
                    <div class="card team team-primary text-center border-0">
                        <div class="position-relative">
                            <img src="assets/images/client/02.jpg" class="img-fluid rounded shadow" alt="">
                            <ul class="list-unstyled mb-0 team-icon">
                                <li class="list-inline-item"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="facebook" class="icons"></i-feather></a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="instagram" class="icons"></i-feather></a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="twitter" class="icons"></i-feather></a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="linkedin" class="icons"></i-feather></a></li>
                            </ul><!--end icon-->
                        </div>
                        <div class="card-body py-3 px-0 content">
                            <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Aliana Rosy</a></h5>
                            <small class="designation text-muted">Manager</small>
                        </div>
                    </div>
                </div><!--end col-->
                
                <div class="col-lg-3 col-md-6 mt-4 pt-2">
                    <div class="card team team-primary text-center border-0">
                        <div class="position-relative">
                            <img src="assets/images/client/03.jpg" class="img-fluid rounded shadow" alt="">
                            <ul class="list-unstyled mb-0 team-icon">
                                <li class="list-inline-item"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="facebook" class="icons"></i-feather></a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="instagram" class="icons"></i-feather></a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="twitter" class="icons"></i-feather></a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="linkedin" class="icons"></i-feather></a></li>
                            </ul><!--end icon-->
                        </div>
                        <div class="card-body py-3 px-0 content">
                            <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Sofia Razaq</a></h5>
                            <small class="designation text-muted">Developer</small>
                        </div>
                    </div>
                </div><!--end col-->
            </div><!--end row-->
        </div><!--end container-->

        <div class="container mt-100 mt-60">
            <div class="row justify-content-center">
                <div class="col-12 text-center">
                    <div class="section-title">
                        <h4 class="title mb-4">Let's talk about your portfolio</h4>
                        <p class="text-muted para-desc mx-auto">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                    
                        <div class="mt-4 pt-2">
                            <a href="javascript:void(0)" class="btn btn-primary">Get Started Now</a>
                        </div>
                    </div>
                </div><!--end col-->
            </div><!--end row-->
        </div><!--end container-->
    </section><!--end section-->
    <!-- End -->

    <!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
