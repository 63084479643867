<!-- Hero Start -->
<section class="bg-home d-flex align-items-center background-effect bg-soft-primary" id="home">
    <div class="container z-index-1">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="title-heading">
                    <h4 class="display-4 fw-bold text-dark mb-4">Discover, <br> find and sell <br> monster NFTs</h4>
                    <p class="para-desc mx-auto text-muted mb-0">Launch your campaign and benefit from our expertise on
                        designing and managing conversion centered bootstrap v5 html page.</p>

                    <div class="mt-4">
                        <a href="" class="btn btn-primary"><i class="uil uil-bitcoin"></i> Mint NFT</a>
                        <a href="" class="btn btn-soft-primary ms-1"><i class="uil uil-file-bookmark-alt"></i>
                            Explore</a>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <img src="assets/images/nft/home.png" class="img-fluid" alt="">
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->

    <ul class="circles p-0 mb-0">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
    </ul>
</section><!--end section-->
<!-- Hero End -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title mb-4 pb-2 text-center">
                    <h4 class="title mb-4">Browse by Categories</h4>
                    <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="row">
            <div class="col-lg-2 col-md-4 col-6 mt-4 pt-2">
                <div class="card features feature-primary explore-feature shadow rounded text-center">
                    <div class="card-body">
                        <div class="icons rounded-circle shadow-lg d-inline-block">
                            <img src="assets/images/icon/paint-bucket.png" class="avatar avatar-md-sm" alt="">
                        </div>
                        <div class="content mt-3">
                            <h6 class="mb-0"><a href="javascript:void(0)" class="title text-dark">Arts</a></h6>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-2 col-md-4 col-6 mt-4 pt-2">
                <div class="card features feature-primary explore-feature shadow rounded text-center">
                    <div class="card-body">
                        <div class="icons rounded-circle shadow-lg d-inline-block">
                            <img src="assets/images/icon/wave.png" class="avatar avatar-md-sm" alt="">
                        </div>
                        <div class="content mt-3">
                            <h6 class="mb-0"><a href="javascript:void(0)" class="title text-dark">Musics</a></h6>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-2 col-md-4 col-6 mt-4 pt-2">
                <div class="card features feature-primary explore-feature shadow rounded text-center">
                    <div class="card-body">
                        <div class="icons rounded-circle shadow-lg d-inline-block">
                            <img src="assets/images/icon/domain.png" class="avatar avatar-md-sm" alt="">
                        </div>
                        <div class="content mt-3">
                            <h6 class="mb-0"><a href="javascript:void(0)" class="title text-dark">Domain</a></h6>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-2 col-md-4 col-6 mt-4 pt-2">
                <div class="card features feature-primary explore-feature shadow rounded text-center">
                    <div class="card-body">
                        <div class="icons rounded-circle shadow-lg d-inline-block">
                            <img src="assets/images/icon/chatbot.png" class="avatar avatar-md-sm" alt="">
                        </div>
                        <div class="content mt-3">
                            <h6 class="mb-0"><a href="javascript:void(0)" class="title text-dark">Virtual World</a></h6>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-2 col-md-4 col-6 mt-4 pt-2">
                <div class="card features feature-primary explore-feature shadow rounded text-center">
                    <div class="card-body">
                        <div class="icons rounded-circle shadow-lg d-inline-block">
                            <img src="assets/images/icon/mail.png" class="avatar avatar-md-sm" alt="">
                        </div>
                        <div class="content mt-3">
                            <h6 class="mb-0"><a href="javascript:void(0)" class="title text-dark">Trading Cards</a></h6>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-2 col-md-4 col-6 mt-4 pt-2">
                <div class="card features feature-primary explore-feature shadow rounded text-center">
                    <div class="card-body">
                        <div class="icons rounded-circle shadow-lg d-inline-block">
                            <img src="assets/images/icon/cubes.png" class="avatar avatar-md-sm" alt="">
                        </div>
                        <div class="content mt-3">
                            <h6 class="mb-0"><a href="javascript:void(0)" class="title text-dark">Collectibles</a></h6>
                        </div>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title mb-4 pb-2 text-center">
                    <h4 class="title mb-4">Explore Hot Products</h4>
                    <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2">
                <div class="card nft nft-item nft-primary rounded shadow overflow-hidden">
                    <div class="nft-image position-relative overflow-hidden">
                        <img src="assets/images/nft/1.jpg" class="img-fluid item-img" alt="">
                        <div class="overlay"></div>
                        <div class="bid-btn p-3 text-center">
                            <a routerLink="/nft-item-detail" class="btn btn-pills"><i class="mdi mdi-gavel fs-6 me-2"></i>
                                Bid</a>
                        </div>
                        <div class="position-absolute top-0 start-0 m-2">
                            <a href=""><img src="assets/images/client/01.jpg"
                                    class="avatar avatar-sm-sm rounded-pill shadow-md" alt=""></a>
                        </div>
                        <div class="position-absolute top-0 end-0 m-2">
                            <a href="" class="badge rounded-md bg-light shadow"><i
                                    class="mdi mdi-heart align-middle text-danger"></i> <span
                                    class="text-dark">231</span></a>
                        </div>
                    </div>

                    <div class="card-body p-3">
                        <a routerLink="/nft-item-detail" class="h5 title text-dark">#01 nft title</a>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <span class="text-muted">12 in stock</span>
                            <span class="text-dark">Price: <span class="link">2.333ETH</span></span>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2">
                <div class="card nft nft-item nft-primary rounded shadow overflow-hidden">
                    <div class="nft-image position-relative overflow-hidden">
                        <img src="assets/images/nft/2.jpg" class="img-fluid item-img" alt="">
                        <div class="overlay"></div>
                        <div class="bid-btn p-3 text-center">
                            <a routerLink="/nft-item-detail" class="btn btn-pills"><i class="mdi mdi-gavel fs-6 me-2"></i>
                                Bid</a>
                        </div>
                        <div class="position-absolute top-0 start-0 m-2">
                            <a href=""><img src="assets/images/client/02.jpg"
                                    class="avatar avatar-sm-sm rounded-pill shadow-md" alt=""></a>
                        </div>
                        <div class="position-absolute top-0 end-0 m-2">
                            <a href="" class="badge rounded-md bg-light shadow"><i
                                    class="mdi mdi-heart align-middle text-danger"></i> <span
                                    class="text-dark">232</span></a>
                        </div>
                    </div>

                    <div class="card-body p-3">
                        <a routerLink="/nft-item-detail" class="h5 title text-dark">#02 nft title</a>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <span class="text-muted">12 in stock</span>
                            <span class="text-dark">Price: <span class="link">2.333ETH</span></span>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2">
                <div class="card nft nft-item nft-primary rounded shadow overflow-hidden">
                    <div class="nft-image position-relative overflow-hidden">
                        <img src="assets/images/nft/3.jpg" class="img-fluid item-img" alt="">
                        <div class="overlay"></div>
                        <div class="bid-btn p-3 text-center">
                            <a routerLink="/nft-item-detail" class="btn btn-pills"><i class="mdi mdi-gavel fs-6 me-2"></i>
                                Bid</a>
                        </div>
                        <div class="position-absolute top-0 start-0 m-2">
                            <a href=""><img src="assets/images/client/03.jpg"
                                    class="avatar avatar-sm-sm rounded-pill shadow-md" alt=""></a>
                        </div>
                        <div class="position-absolute top-0 end-0 m-2">
                            <a href="" class="badge rounded-md bg-light shadow"><i
                                    class="mdi mdi-heart align-middle text-danger"></i> <span
                                    class="text-dark">233</span></a>
                        </div>
                    </div>

                    <div class="card-body p-3">
                        <a routerLink="/nft-item-detail" class="h5 title text-dark">#03 nft title</a>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <span class="text-muted">12 in stock</span>
                            <span class="text-dark">Price: <span class="link">2.333ETH</span></span>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2">
                <div class="card nft nft-item nft-primary rounded shadow overflow-hidden">
                    <div class="nft-image position-relative overflow-hidden">
                        <img src="assets/images/nft/4.jpg" class="img-fluid item-img" alt="">
                        <div class="overlay"></div>
                        <div class="bid-btn p-3 text-center">
                            <a routerLink="/nft-item-detail" class="btn btn-pills"><i class="mdi mdi-gavel fs-6 me-2"></i>
                                Bid</a>
                        </div>
                        <div class="position-absolute top-0 start-0 m-2">
                            <a href=""><img src="assets/images/client/04.jpg"
                                    class="avatar avatar-sm-sm rounded-pill shadow-md" alt=""></a>
                        </div>
                        <div class="position-absolute top-0 end-0 m-2">
                            <a href="" class="badge rounded-md bg-light shadow"><i
                                    class="mdi mdi-heart align-middle text-danger"></i> <span
                                    class="text-dark">234</span></a>
                        </div>
                    </div>

                    <div class="card-body p-3">
                        <a routerLink="/nft-item-detail" class="h5 title text-dark">#04 nft title</a>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <span class="text-muted">12 in stock</span>
                            <span class="text-dark">Price: <span class="link">2.333ETH</span></span>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2">
                <div class="card nft nft-item nft-primary rounded shadow overflow-hidden">
                    <div class="nft-image position-relative overflow-hidden">
                        <img src="assets/images/nft/5.jpg" class="img-fluid item-img" alt="">
                        <div class="overlay"></div>
                        <div class="bid-btn p-3 text-center">
                            <a routerLink="/nft-item-detail" class="btn btn-pills"><i class="mdi mdi-gavel fs-6 me-2"></i>
                                Bid</a>
                        </div>
                        <div class="position-absolute top-0 start-0 m-2">
                            <a href=""><img src="assets/images/client/05.jpg"
                                    class="avatar avatar-sm-sm rounded-pill shadow-md" alt=""></a>
                        </div>
                        <div class="position-absolute top-0 end-0 m-2">
                            <a href="" class="badge rounded-md bg-light shadow"><i
                                    class="mdi mdi-heart align-middle text-danger"></i> <span
                                    class="text-dark">235</span></a>
                        </div>
                    </div>

                    <div class="card-body p-3">
                        <a routerLink="/nft-item-detail" class="h5 title text-dark">#05 nft title</a>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <span class="text-muted">12 in stock</span>
                            <span class="text-dark">Price: <span class="link">2.333ETH</span></span>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2">
                <div class="card nft nft-item nft-primary rounded shadow overflow-hidden">
                    <div class="nft-image position-relative overflow-hidden">
                        <img src="assets/images/nft/6.jpg" class="img-fluid item-img" alt="">
                        <div class="overlay"></div>
                        <div class="bid-btn p-3 text-center">
                            <a routerLink="/nft-item-detail" class="btn btn-pills"><i class="mdi mdi-gavel fs-6 me-2"></i>
                                Bid</a>
                        </div>
                        <div class="position-absolute top-0 start-0 m-2">
                            <a href=""><img src="assets/images/client/06.jpg"
                                    class="avatar avatar-sm-sm rounded-pill shadow-md" alt=""></a>
                        </div>
                        <div class="position-absolute top-0 end-0 m-2">
                            <a href="" class="badge rounded-md bg-light shadow"><i
                                    class="mdi mdi-heart align-middle text-danger"></i> <span
                                    class="text-dark">236</span></a>
                        </div>
                    </div>

                    <div class="card-body p-3">
                        <a routerLink="/nft-item-detail" class="h5 title text-dark">#06 nft title</a>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <span class="text-muted">12 in stock</span>
                            <span class="text-dark">Price: <span class="link">2.333ETH</span></span>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2">
                <div class="card nft nft-item nft-primary rounded shadow overflow-hidden">
                    <div class="nft-image position-relative overflow-hidden">
                        <img src="assets/images/nft/7.jpg" class="img-fluid item-img" alt="">
                        <div class="overlay"></div>
                        <div class="bid-btn p-3 text-center">
                            <a routerLink="/nft-item-detail" class="btn btn-pills"><i class="mdi mdi-gavel fs-6 me-2"></i>
                                Bid</a>
                        </div>
                        <div class="position-absolute top-0 start-0 m-2">
                            <a href=""><img src="assets/images/client/07.jpg"
                                    class="avatar avatar-sm-sm rounded-pill shadow-md" alt=""></a>
                        </div>
                        <div class="position-absolute top-0 end-0 m-2">
                            <a href="" class="badge rounded-md bg-light shadow"><i
                                    class="mdi mdi-heart align-middle text-danger"></i> <span
                                    class="text-dark">237</span></a>
                        </div>
                    </div>

                    <div class="card-body p-3">
                        <a routerLink="/nft-item-detail" class="h5 title text-dark">#07 nft title</a>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <span class="text-muted">12 in stock</span>
                            <span class="text-dark">Price: <span class="link">2.333ETH</span></span>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2">
                <div class="card nft nft-item nft-primary rounded shadow overflow-hidden">
                    <div class="nft-image position-relative overflow-hidden">
                        <img src="assets/images/nft/8.jpg" class="img-fluid item-img" alt="">
                        <div class="overlay"></div>
                        <div class="bid-btn p-3 text-center">
                            <a routerLink="/nft-item-detail" class="btn btn-pills"><i class="mdi mdi-gavel fs-6 me-2"></i>
                                Bid</a>
                        </div>
                        <div class="position-absolute top-0 start-0 m-2">
                            <a href=""><img src="assets/images/client/08.jpg"
                                    class="avatar avatar-sm-sm rounded-pill shadow-md" alt=""></a>
                        </div>
                        <div class="position-absolute top-0 end-0 m-2">
                            <a href="" class="badge rounded-md bg-light shadow"><i
                                    class="mdi mdi-heart align-middle text-danger"></i> <span
                                    class="text-dark">238</span></a>
                        </div>
                    </div>

                    <div class="card-body p-3">
                        <a routerLink="/nft-item-detail" class="h5 title text-dark">#08 nft title</a>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <span class="text-muted">12 in stock</span>
                            <span class="text-dark">Price: <span class="link">2.333ETH</span></span>
                        </div>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title mb-4 pb-2 text-center">
                    <h4 class="title mb-4">Best Creators</h4>
                    <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="row">
            <div class="col-12 mt-4 pt-2">
                <swiper [config]="config">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <div class="card nft nft-primary nft-creator border-0 rounded-md shadow m-2">
                                <div class="card-body p-3">
                                    <div class="pb-3 border-bottom">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <span class="badge bg-soft rounded-pill">No. #1</span>

                                            <a routerLink="/nft-explore" class="text-dark h5 mb-0 read-more"><i
                                                    class="uil uil-arrow-circle-right"></i></a>
                                        </div>
                                    </div>

                                    <div class="content mt-3">
                                        <div class="position-relative text-center">
                                            <img src="assets/images/client/01.jpg"
                                                class="avatar avatar-small rounded-pill shadow" alt="">

                                            <div class="author mt-2">
                                                <a routerLink="/account-profile" class="text-dark h6 name">Jordan Joo</a>
                                                <small class="d-block fw-bold mt-1">0.75<span
                                                        class="text-muted">ETH</span></small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div><!--end swiper-slide-->

                        <div class="swiper-slide">
                            <div class="card nft nft-primary nft-creator border-0 rounded-md shadow m-2">
                                <div class="card-body p-3">
                                    <div class="pb-3 border-bottom">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <span class="badge bg-soft rounded-pill">No. #2</span>

                                            <a routerLink="/nft-explore" class="text-dark h5 mb-0 read-more"><i
                                                    class="uil uil-arrow-circle-right"></i></a>
                                        </div>
                                    </div>

                                    <div class="content mt-3">
                                        <div class="position-relative text-center">
                                            <img src="assets/images/client/02.jpg"
                                                class="avatar avatar-small rounded-pill shadow" alt="">

                                            <div class="author mt-2">
                                                <a routerLink="/account-profile" class="text-dark h6 name">Sofia Malik</a>
                                                <small class="d-block fw-bold mt-1">0.75<span
                                                        class="text-muted">ETH</span></small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div><!--end swiper-slide-->

                        <div class="swiper-slide">
                            <div class="card nft nft-primary nft-creator border-0 rounded-md shadow m-2">
                                <div class="card-body p-3">
                                    <div class="pb-3 border-bottom">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <span class="badge bg-soft rounded-pill">No. #3</span>

                                            <a routerLink="/nft-explore" class="text-dark h5 mb-0 read-more"><i
                                                    class="uil uil-arrow-circle-right"></i></a>
                                        </div>
                                    </div>

                                    <div class="content mt-3">
                                        <div class="position-relative text-center">
                                            <img src="assets/images/client/03.jpg"
                                                class="avatar avatar-small rounded-pill shadow" alt="">

                                            <div class="author mt-2">
                                                <a routerLink="/account-profile" class="text-dark h6 name">Cristina
                                                    Murphy</a>
                                                <small class="d-block fw-bold mt-1">0.75<span
                                                        class="text-muted">ETH</span></small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div><!--end swiper-slide-->

                        <div class="swiper-slide">
                            <div class="card nft nft-primary nft-creator border-0 rounded-md shadow m-2">
                                <div class="card-body p-3">
                                    <div class="pb-3 border-bottom">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <span class="badge bg-soft rounded-pill">No. #4</span>

                                            <a routerLink="/nft-explore" class="text-dark h5 mb-0 read-more"><i
                                                    class="uil uil-arrow-circle-right"></i></a>
                                        </div>
                                    </div>

                                    <div class="content mt-3">
                                        <div class="position-relative text-center">
                                            <img src="assets/images/client/04.jpg"
                                                class="avatar avatar-small rounded-pill shadow" alt="">

                                            <div class="author mt-2">
                                                <a routerLink="/account-profile" class="text-dark h6 name">Jack Lili</a>
                                                <small class="d-block fw-bold mt-1">0.75<span
                                                        class="text-muted">ETH</span></small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div><!--end swiper-slide-->

                        <div class="swiper-slide">
                            <div class="card nft nft-primary nft-creator border-0 rounded-md shadow m-2">
                                <div class="card-body p-3">
                                    <div class="pb-3 border-bottom">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <span class="badge bg-soft rounded-pill">No. #5</span>

                                            <a routerLink="/nft-explore" class="text-dark h5 mb-0 read-more"><i
                                                    class="uil uil-arrow-circle-right"></i></a>
                                        </div>
                                    </div>

                                    <div class="content mt-3">
                                        <div class="position-relative text-center">
                                            <img src="assets/images/client/05.jpg"
                                                class="avatar avatar-small rounded-pill shadow" alt="">

                                            <div class="author mt-2">
                                                <a routerLink="/account-profile" class="text-dark h6 name">Lilia Moris</a>
                                                <small class="d-block fw-bold mt-1">0.75<span
                                                        class="text-muted">ETH</span></small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div><!--end swiper-slide-->

                        <div class="swiper-slide">
                            <div class="card nft nft-primary nft-creator border-0 rounded-md shadow m-2">
                                <div class="card-body p-3">
                                    <div class="pb-3 border-bottom">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <span class="badge bg-soft rounded-pill">No. #6</span>

                                            <a routerLink="/nft-explore" class="text-dark h5 mb-0 read-more"><i
                                                    class="uil uil-arrow-circle-right"></i></a>
                                        </div>
                                    </div>

                                    <div class="content mt-3">
                                        <div class="position-relative text-center">
                                            <img src="assets/images/client/06.jpg"
                                                class="avatar avatar-small rounded-pill shadow" alt="">

                                            <div class="author mt-2">
                                                <a routerLink="/account-profile" class="text-dark h6 name">Anderson</a>
                                                <small class="d-block fw-bold mt-1">0.75<span
                                                        class="text-muted">ETH</span></small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div><!--end swiper-slide-->

                        <div class="swiper-slide">
                            <div class="card nft nft-primary nft-creator border-0 rounded-md shadow m-2">
                                <div class="card-body p-3">
                                    <div class="pb-3 border-bottom">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <span class="badge bg-soft rounded-pill">No. #7</span>

                                            <a routerLink="/nft-explore" class="text-dark h5 mb-0 read-more"><i
                                                    class="uil uil-arrow-circle-right"></i></a>
                                        </div>
                                    </div>

                                    <div class="content mt-3">
                                        <div class="position-relative text-center">
                                            <img src="assets/images/client/07.jpg"
                                                class="avatar avatar-small rounded-pill shadow" alt="">

                                            <div class="author mt-2">
                                                <a routerLink="/account-profile" class="text-dark h6 name">Joe Moris</a>
                                                <small class="d-block fw-bold mt-1">0.75<span
                                                        class="text-muted">ETH</span></small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div><!--end swiper-slide-->

                        <div class="swiper-slide">
                            <div class="card nft nft-primary nft-creator border-0 rounded-md shadow m-2">
                                <div class="card-body p-3">
                                    <div class="pb-3 border-bottom">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <span class="badge bg-soft rounded-pill">No. #8</span>

                                            <a routerLink="/nft-explore" class="text-dark h5 mb-0 read-more"><i
                                                    class="uil uil-arrow-circle-right"></i></a>
                                        </div>
                                    </div>

                                    <div class="content mt-3">
                                        <div class="position-relative text-center">
                                            <img src="assets/images/client/08.jpg"
                                                class="avatar avatar-small rounded-pill shadow" alt="">

                                            <div class="author mt-2">
                                                <a routerLink="/account-profile" class="text-dark h6 name">John Carter</a>
                                                <small class="d-block fw-bold mt-1">0.75<span
                                                        class="text-muted">ETH</span></small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div><!--end swiper-slide-->
                    </div>
                </swiper>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->
<!-- End -->

<!-- Start -->
<section class="section" style="background: url('assets/images/nft/bg.jpg') top center;">
    <div class="bg-overlay bg-linear-gradient-2"></div>
    <div class="container">
        <div class="row align-items-end">
            <div class="col-lg-8 col-md-6 col-12">
                <div class="section-title">
                    <h4 class="title text-white title-dark mb-4">Start your own collection today</h4>
                    <p class="text-white-50 para-desc mb-0">Start working with Landrick that can provide everything you
                        need to generate awareness, drive traffic, connect.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12">
                <div class="text-md-end">
                    <a href="" class="btn btn-primary"><i class="uil uil-bookmark"></i> Creat your collection</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End -->

    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"
      [ngxScrollTo]="'#home'">
      <i-feather name="arrow-up" class="icons"></i-feather>
    </a>
    <!-- Back to top -->