<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="title-heading text-center mt-5 pt-3">
          <div class="alert alert-light alert-pills" role="alert" data-aos="fade-up" data-aos-duration="1000">
            <span class="badge rounded-pill bg-success me-1">Support</span>
            <span class="content"> Customer Support 24/7</span>
          </div>
          <h1 class="heading mb-3" data-aos="fade-up" data-aos-duration="1400">How Can We Help You ?</h1>
          <p class="para-desc mx-auto text-muted" data-aos="fade-up" data-aos-duration="1800">Launch your campaign and
            benefit from our expertise on designing and managing conversion centered bootstrap v5 html page.</p>
        </div>
        <div class="subcribe-form mt-4 pt-2" data-aos="fade-up" data-aos-duration="2200">
          <form>
            <div class="mb-0">
              <input type="text" id="help" name="name" class="form-control border rounded-pill" required=""
                placeholder="Search the help center">
              <button type="submit" class="btn btn-pills btn-primary">Search</button>
            </div>
          </form>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Communiaction Priority Start -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <span class="badge rounded-pill bg-success" data-aos="fade-up" data-aos-duration="1000">Features</span>
          <h4 class="title my-4" data-aos="fade-up" data-aos-duration="1400">Communication Resources</h4>
          <p class="text-muted para-desc mx-auto mb-0" data-aos="fade-up" data-aos-duration="1800">Start working with
            <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to
            generate awareness, drive traffic, connect.
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card catagories text-center rounded border-0" data-aos="fade-up" data-aos-duration="1000">
          <div class="card-body">
            <img src="assets/images/icon/chat.svg" class="avatar avatar-small mb-3" alt="">
            <h5><a href="javascript:void(0)" class="text-dark">Live Chat</a></h5>
            <p class="text-muted mb-0 mt-3">If the distribution of letters and 'words' is random, the reader will not
              be distracted from making</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card catagories text-center rounded border-0" data-aos="fade-up" data-aos-duration="1300">
          <div class="card-body">
            <img src="assets/images/icon/customer-service.svg" class="avatar avatar-small mb-3" alt="">
            <h5><a href="javascript:void(0)" class="text-dark">Social</a></h5>
            <p class="text-muted mb-0 mt-3">If the distribution of letters and 'words' is random, the reader will not
              be distracted from making</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card catagories text-center rounded border-0" data-aos="fade-up" data-aos-duration="1600">
          <div class="card-body">
            <img src="assets/images/icon/user.svg" class="avatar avatar-small mb-3" alt="">
            <h5><a href="javascript:void(0)" class="text-dark">Support Portal</a></h5>
            <p class="text-muted mb-0 mt-3">If the distribution of letters and 'words' is random, the reader will not
              be distracted from making</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card catagories text-center rounded border-0" data-aos="fade-up" data-aos-duration="1900">
          <div class="card-body">
            <img src="assets/images/icon/call.svg" class="avatar avatar-small mb-3" alt="">
            <h5><a href="javascript:void(0)" class="text-dark">Phone Supports</a></h5>
            <p class="text-muted mb-0 mt-3">If the distribution of letters and 'words' is random, the reader will not
              be distracted from making</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card catagories text-center rounded border-0" data-aos="fade-up" data-aos-duration="2200">
          <div class="card-body">
            <img src="assets/images/icon/writing.svg" class="avatar avatar-small mb-3" alt="">
            <h5><a href="javascript:void(0)" class="text-dark">Tickets</a></h5>
            <p class="text-muted mb-0 mt-3">If the distribution of letters and 'words' is random, the reader will not
              be distracted from making</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card catagories text-center rounded border-0" data-aos="fade-up" data-aos-duration="2500">
          <div class="card-body">
            <img src="assets/images/icon/mail.svg" class="avatar avatar-small mb-3" alt="">
            <h5><a href="javascript:void(0)" class="text-dark">FAQ's</a></h5>
            <p class="text-muted mb-0 mt-3">If the distribution of letters and 'words' is random, the reader will not
              be distracted from making</p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4" data-aos="fade-up" data-aos-duration="1000">Need to CRM Support</h4>
          <p class="text-muted para-desc mb-0 mx-auto" data-aos="fade-up" data-aos-duration="1400">Start working with
            <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to
            generate awareness, drive traffic, connect.
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class=" mt-4 pt-2 text-center">
        <ul ngbNav #nav="ngbNav" class="nav-pills nav-justified flex-sm-row mb-0 col-lg-8 col-md-12 offset-md-2">
          <li ngbNavItem>
            <a ngbNavLink class="rounded">
              <div class="text-center pt-1 pb-1">
                <h6 class="mb-0">Community</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="row align-items-center">
                <div class="col-md-6">
                  <img src="assets/images/saas/classic04.png" data-aos="fade-up" data-aos-duration="1400"
                    class="img-fluid mx-auto d-block shadow rounded" alt="">
                </div>
                <!--end col-->

                <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <div class="section-title ms-md-4 text-md-start">
                    <h4 class="title mb-4" data-aos="fade-up" data-aos-duration="1000">User Community</h4>
                    <p class="text-muted" data-aos="fade-up" data-aos-duration="1400">CRM systems start by collecting a
                      customer's website, email, telephone, social media data, and more, across multiple sources and
                      channels. It may also automatically pull in other information, such as recent news about the
                      company's activity, and it can store personal details, such as a client's personal preferences on
                      communications.</p>
                    <div class="mt-4" data-aos="fade-up" data-aos-duration="1800">
                      <a href="javascript:void(0)" class="text-primary p-1 px-2 shadow rounded me-3">Read More <i
                          class="uil uil-angle-right-b"></i></a>
                      <a href="javascript:void(0)" class="text-warning p-1 px-2 shadow rounded">Blogs</a>
                    </div>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </ng-template>
          </li>
          <li ngbNavItem>
            <a ngbNavLink class="rounded">
              <div class="text-center pt-1 pb-1">
                <h6 class="mb-0">Self Service</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="row align-items-center">
                <div class="col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <div class="section-title me-md-4 text-md-start">
                    <h4 class="title mb-4" data-aos="fade-up" data-aos-duration="1000">Self-service Portal</h4>
                    <p class="text-muted" data-aos="fade-up" data-aos-duration="1400">CRM systems start by collecting a
                      customer's website, email, telephone, social media data, and more, across multiple sources and
                      channels. It may also automatically pull in other information, such as recent news about the
                      company's activity, and it can store personal details, such as a client's personal preferences on
                      communications.</p>
                    <div class="mt-4" data-aos="fade-up" data-aos-duration="1800">
                      <a href="javascript:void(0)" class="text-primary p-1 px-2 shadow rounded me-3">Read More <i
                          class="uil uil-angle-right-b"></i></a>
                      <a href="javascript:void(0)" class="text-warning p-1 px-2 shadow rounded">Blogs</a>
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-md-6 order-1 order-md-2">
                  <img src="assets/images/saas/classic02.png" data-aos="fade-up" data-aos-duration="1400"
                    class="img-fluid mx-auto d-block shadow rounded" alt="">
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </ng-template>
          </li>
          <li ngbNavItem>
            <a ngbNavLink class="rounded">
              <div class="text-center pt-1 pb-1">
                <h6 class="mb-0">Teamwork</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="row align-items-center">
                <div class="col-md-6">
                  <img src="assets/images/saas/classic03.png" data-aos="fade-up" data-aos-duration="1400"
                    class="img-fluid mx-auto d-block shadow rounded" alt="">
                </div>
                <!--end col-->

                <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <div class="section-title ms-md-4 text-md-start">
                    <h4 class="title mb-4" data-aos="fade-up" data-aos-duration="1000">Proper Teamwork</h4>
                    <p class="text-muted" data-aos="fade-up" data-aos-duration="1400">CRM systems start by collecting a
                      customer's website, email, telephone, social media data, and more, across multiple sources and
                      channels. It may also automatically pull in other information, such as recent news about the
                      company's activity, and it can store personal details, such as a client's personal preferences on
                      communications.</p>
                    <div class="mt-4" data-aos="fade-up" data-aos-duration="1800">
                      <a href="javascript:void(0)" class="text-primary p-1 px-2 shadow rounded me-3">Read More <i
                          class="uil uil-angle-right-b"></i></a>
                      <a href="javascript:void(0)" class="text-warning p-1 px-2 shadow rounded">Blogs</a>
                    </div>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </ng-template>
          </li>
        </ul>
        <div class="row">
          <div [ngbNavOutlet]="nav" class="col-12 mt-4 pt-2"></div>
        </div>
      </div>
    </div>
  </div>
  <!--end container-->

  <!-- Testi Start -->
  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4" data-aos="fade-up" data-aos-duration="1000">Customer's Review</h4>
          <p class="text-muted para-desc mb-0 mx-auto" data-aos="fade-up" data-aos-duration="1400">Start working with
            <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to
            generate awareness, drive traffic, connect.
          </p>
        </div>
      </div>
    </div>
    <!--end row-->

    <app-testimonial [testimonialData]="testimonialData"></app-testimonial>
  </div>
  <!--end container-->
  <!-- Testi end -->

  <!-- Submit Ticket Start -->
  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4" data-aos="fade-up" data-aos-duration="1000">Submit A Ticket</h4>
          <p class="text-muted para-desc mb-0 mx-auto" data-aos="fade-up" data-aos-duration="1400">Start working with
            <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to
            generate awareness, drive traffic, connect.
          </p>
        </div>
      </div>
    </div>
    <!--end row-->

    <div class="row align-items-center">
      <div class="col-lg-6 col-md-5 col-12 mt-4 pt-2">
        <img src="assets/images/customer/customer.svg" data-aos="fade-up" data-aos-duration="1400" class="img-fluid"
          alt="">
      </div>

      <div class="col-lg-6 col-md-7 col-12 mt-4 pt-2">
        <div class="card rounded shadow border-0 ms-lg-4" data-aos="fade-up" data-aos-duration="1400">
          <div class="card-body p-4">
            <form>
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Your Name :</label>
                    <div class="form-icon position-relative">
                      <i-feather name="user" class="fea icon-sm icons"></i-feather>
                      <input name="name" id="name" type="text" class="form-control ps-5" placeholder="First Name :">
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Your Email :</label>
                    <div class="form-icon position-relative">
                      <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                      <input name="email" id="email" type="email" class="form-control ps-5" placeholder="Your email :">
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label">Reasons / Categories :</label>
                    <select class="form-control custom-select" id="Sortbylist-Shop">
                      <option>Categories</option>
                      <option>General Question</option>
                      <option>Bugs</option>
                      <option>Remote</option>
                    </select>
                  </div>
                </div>
                <!--end col-->

                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Subject :</label>
                    <div class="form-icon position-relative">
                      <i-feather name="book" class="fea icon-sm icons"></i-feather>
                      <input name="subject" id="subject" class="form-control ps-5" placeholder="Your subject :">
                    </div>
                  </div>
                </div>
                <!--end col-->
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Url :</label>
                    <div class="form-icon position-relative">
                      <i-feather name="globe" class="fea icon-sm icons"></i-feather>
                      <input name="url" id="url" type="url" class="form-control ps-5" placeholder="Url :">
                    </div>
                  </div>
                </div>
                <!--end col-->
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label">Description :</label>
                    <div class="form-icon position-relative">
                      <i-feather name="message-circle" class="fea icon-sm icons"></i-feather>
                      <textarea name="comments" id="comments" rows="4" class="form-control ps-5"
                        placeholder="Describe your problem :"></textarea>
                    </div>
                  </div>
                </div>
                <!--end col-->
                <div class="col-md-12">
                  <div class="mb-3">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">I Accept <a routerLink="/index-customer"
                          class="text-primary">Terms And Condition</a></label>
                    </div>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->

              <div class="row">
                <div class="col-sm-12">
                  <input type="submit" id="submit" name="send" class="btn btn-primary" value="Submit">
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end container-->
  <!-- Submit Ticket End -->
</section>
<!--end section-->
<!-- Communiaction Priority End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->