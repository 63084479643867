import { Component } from '@angular/core';

@Component({
  selector: 'app-shop-grids',
  templateUrl: './shop-grids.component.html',
  styleUrls: ['./shop-grids.component.css']
})
export class ShopGridsComponent {
// Set Topbar
  Menuoption = 'shop'
  footerVariant = 'footer-two';

/***
 * Header button list show
 */
shopPages = true;
}
